import smsApi from '../../api/sms.api';
import { GET_SMSES } from '../types';

export const getSMSesAction = (params) => async (dispatch) => {
  const errors = await smsApi.getSMes(dispatch, params);
  dispatch({
    type: GET_SMSES,
    payload: errors,
  });
};
