import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import moment from 'moment';
import AddCreditTransactionModal from './AddCreditTransaction';

function CarCredits(props) {
  const { car, saveCredit, markCreditUsed } = props;
  const [credits, setCredits] = useState([]);

  useEffect(() => {
    car.credits.sort((a, b) => Date.parse(b.created) - Date.parse(a.created));
    setCredits(car.credits);
  }, [car]);
  return (
    <div className="car-credits">
      <AddCreditTransactionModal onSave={saveCredit} />
      {credits.length ? (
        <TableContainer component={Paper}>
          <Table size="small" padding="none">
            <TableHead className="bg-dark">
              <TableRow>
                <TableCell className="text-center text-white">Amount</TableCell>
                <TableCell className="text-center text-white">Source</TableCell>
                <TableCell className="text-center text-white">Reason</TableCell>
                <TableCell className="text-center text-white">Code</TableCell>
                <TableCell className="text-center text-white">
                  Created
                </TableCell>
                <TableCell className="text-center text-white">Used?</TableCell>
              </TableRow>
            </TableHead>
            {credits.map((c) => (
              <TableRow
                key={c._id}
                className={`${c.used ? 'credit-used' : ''}`}
              >
                <TableCell className="text-center">{c.delta}</TableCell>
                <TableCell className="text-center">{c.source}</TableCell>
                <TableCell className="text-center">{c.reason}</TableCell>
                <TableCell className="text-center">{c.code}</TableCell>
                <TableCell className="text-center">
                  {moment(c.created).format('L')}
                </TableCell>
                <TableCell className="text-center">
                  <Checkbox
                    checked={c.used}
                    onClick={() => markCreditUsed(c._id)}
                    disabled={c.used}
                  />
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </TableContainer>
      ) : (
        <span>No Credit transactions.</span>
      )}
    </div>
  );
}

export default CarCredits;
