import React from 'react';
import { US, UK } from '../assets/images';

function Footer() {
  let regionIcon = US;
  if (process.env.REACT_APP_DEFAULT_REGION === 'UK') {
    regionIcon = UK;
  }

  return (
    <footer className="footer-main mt-auto p-2 text-xs-center text-md-right">
      <table border="1" className="version-table no-border">
        <tr className="no-border">
          <td className="no-border">
            <span className="version-table-text">Region</span>
          </td>
          <td>
            <span className="version-table-text">
              <img className="region-icon" src={regionIcon} alt="region" />
            </span>
          </td>
        </tr>
        <tr className="no-border">
          <td className="no-border">
            <span className="version-table-text">Environment</span>
          </td>
          <td>
            <span className="version-table-text">
              {process.env.REACT_APP_ENV}
            </span>
          </td>
        </tr>
        <tr className="no-border">
          <td className="no-border">
            <span className="version-table-text">Version</span>
          </td>
          <td>
            <span className="version-table-text">
              {process.env.REACT_APP_ADMIN_VERSION}
            </span>
          </td>
        </tr>
      </table>
    </footer>
  );
}

export default Footer;
