import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Grid,
  Tabs,
  Tab,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Select,
  MenuItem,
  DialogActions,
  Button,
} from '@mui/material';
import {
  addCreditAction,
  getCarAction,
  getCarContractsAction,
  markCreditUsedAction,
} from '../store/actions/cars.actions';
import { getUsersAction } from '../store/actions/user.actions';
import CarMetaData from '../components/CarMetaData';
import CarUserManagement from '../components/CarUserManagement';
import CarOdometerReadings from '../components/CarOdometerReadings';
import CarRecalls from '../components/CarRecalls';
import CarMaintenanceHistory from '../components/CarMaintenanceHistory';

import './Car.scss';
import Contracts from '../components/Contracts';
import CarCredits from '../components/CarCredits';
import CarPopulationLog from '../components/CarPopulationLog';
import RepairRequest from '../components/RepairRequest';

function Car() {
  const car = useSelector((state) => state.car.car);
  const contracts = useSelector((state) => state.car.contracts);
  const users = useSelector((state) => state.user.users);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [stagedCredit, setStagedCredit] = useState();
  const [userToUseCredit, setUserToUseCredit] = useState();

  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      if (!car) {
        if (id) {
          await dispatch(getCarAction(id));
          await dispatch(getCarContractsAction(id));
        }
      } else {
        const filter = { _id: { $in: car.userIds } };
        await dispatch(getUsersAction({ filter }));
      }
      setIsLoading(false);
    };
    load();
  }, [id, car, dispatch]);
  const saveCredit = async (credit) => {
    setIsLoading(true);
    await dispatch(addCreditAction(car._id, credit));
    await dispatch(getCarAction(id));
    setTabIndex(2);
    setIsLoading(false);
  };
  const markCreditUsed = async (creditId, userId) => {
    setIsLoading(true);
    await dispatch(markCreditUsedAction(car._id, creditId, userId));
    await dispatch(getCarAction(id));
    setTabIndex(2);
    setIsLoading(false);
    setStagedCredit(null);
  };
  const setCreditToBeUsed = (creditId) => {
    // In theory, this shouldn't happen
    if (!users && !users.data) {
      throw Error('No users associated with this car!');
    }

    if (users.data.length === 1) {
      markCreditUsed(creditId, users.data[0]._id);
      setStagedCredit(null);
    } else {
      setStagedCredit(creditId);
    }
  };
  // TODO: Maybe memoize this... A handful of users shouldn't be bad that
  const userLookup = ((users && users.data) || []).reduce((lookup, user) => {
    return { ...lookup, [user._id]: user };
  }, {});
  if (isLoading) {
    return <LinearProgress style={{ height: '1rem' }} color="secondary" />;
  }
  if (!car) {
    return <div>No car found</div>;
  }
  return (
    <Grid container className="car">
      <Grid item sm={12}>
        <h2>{`Car - ${car.vin || 'No assigned VIN'}`}</h2>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={tabIndex}
          onChange={(e, v) => setTabIndex(v)}
          className="car-tabs mb-5"
        >
          <Tab label="Base Info" />
          <Tab label="User Management" />
          <Tab label="Credits" />
          <Tab label="Odometer" />
          <Tab label="Recalls" />
          <Tab label="Maintenance" />
          <Tab label="Car Population Log" />
          <Tab label="Repair Requests" />
          {contracts && contracts.length && <Tab label="Contracts" />}
        </Tabs>
        {tabIndex === 0 && <CarMetaData car={car} />}
        {tabIndex === 1 && (
          <CarUserManagement car={car} userLookup={userLookup} />
        )}
        {tabIndex === 2 && (
          <CarCredits
            car={car}
            saveCredit={saveCredit}
            markCreditUsed={setCreditToBeUsed}
          />
        )}
        {tabIndex === 3 && <CarOdometerReadings car={car} />}
        {tabIndex === 4 && <CarRecalls car={car} />}
        {tabIndex === 5 && <CarMaintenanceHistory car={car} />}
        {tabIndex === 6 && <CarPopulationLog car={car} />}
        {tabIndex === 7 && <RepairRequest car={car} />}
        {tabIndex === 8 && <Contracts contracts={contracts} />}
      </Grid>
      <Dialog onClose={() => setStagedCredit(null)} open={!!stagedCredit}>
        <DialogTitle>Which user is using this credit?</DialogTitle>
        <DialogContent>
          <Select
            id="user-use-credit"
            value={userToUseCredit}
            onChange={(e) => setUserToUseCredit(e.target.value)}
          >
            {Object.keys(userLookup).map((userId) => (
              <MenuItem key={userId} value={userId}>
                {userLookup[userId].name}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => markCreditUsed(stagedCredit, userToUseCredit)}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setStagedCredit(null)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default Car;
