/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Bar, Line } from 'react-chartjs-2';
import {
  Autocomplete,
  TextField,
  Button,
  Card,
  Grid,
  Paper,
  styled,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  CircularProgress,
  Tab,
  Tabs,
} from '@mui/material';
import {
  addDays,
  addWeeks,
  addMonths,
  addYears,
  startOfYear,
  startOfWeek,
  startOfMonth,
  endOfWeek,
  endOfMonth,
  endOfYear,
  isAfter,
  isToday,
  format,
  endOfDay,
} from 'date-fns';
import { MailOutline } from '@mui/icons-material';
import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import welcomeKitApi from '../api/welcomeKit.api';
import FilterModal from '../components/Filter';
import InvitationsByDealerList from '../components/InvitationsByDealerList';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function WelcomeKitDashboard(props) {
  const { filter } = props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [statsData, setStatsData] = useState({});
  const [totalWelcomeKitStatsData, setTotalWelcomeKitStatsData] = useState({});
  const [appliedFilter, setAppliedFilter] = useState(null);
  const [filterBeforeDate, setFilterBeforeDate] = useState(null);
  const [filterAfterDate, setFilterAfterDate] = useState(null);
  // const [invitationSource, setInvitationSource] = useState('All');
  const [invitationSource, setInvitationSource] = useState('');
  const [tabValue, setTabValue] = useState(false);
  const [crntDate, setCrntDate] = useState(new Date());
  const [disableDate, setDisableDate] = useState(new Date());
  const [disableNext, setDisableNext] = useState(false);
  const [applyQuickFilter, setApplyQuickFilter] = useState(false);
  const [dealerIds, setDealerIds] = useState([]);
  const [dealerId, setDealerId] = useState([]);
  const [filterDisplay, setFilterDisplay] = useState(
    filter ? 'Filter Applied' : ''
  );

  const labels = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const typeLabels = {};
  const colors = {
    Total: 'rgb(125, 125, 192)',
    Accepted: 'rgb(75, 192, 192)',
    Pending: 'rgb(255, 99, 132)',
  };
  const options1 = {
    plugins: {
      title: {
        display: true,
        text: 'Chart.js Bar Chart - Stacked',
      },
    },
  };

  function calculateCumulativeCount(data) {
    const cumulativeCount = {};
    data.forEach(({ month, count }) => {
      cumulativeCount[month] = (cumulativeCount[month] || 0) + count;
    });
    return cumulativeCount;
  }

  // Iterate over each state (Total, Pending, Accepted)
  if (statsData.invitationMonthlyStats) {
    Object.keys(statsData.invitationMonthlyStats).forEach((state) => {
      const stateData = statsData.invitationMonthlyStats[state];

      // Check if an "All" entry already exists
      const allIndex = stateData.findIndex(
        (item) => item._id.inviteSource === 'All'
      );

      // If an "All" entry does not exist, add it
      if (allIndex === -1) {
        const cumulativeCount = calculateCumulativeCount(
          stateData.flatMap((item) => item.data)
        );

        statsData.invitationMonthlyStats[state].unshift({
          _id: { inviteSource: 'All' },
          data: Object.entries(cumulativeCount).map(([month, count]) => ({
            month: parseInt(month, 10),
            count,
          })),
        });
      }
    });
  }

  const transformedData = { invitationMonthlyStats: {} };

  if (statsData.invitationMonthlyStats) {
    Object.keys(statsData.invitationMonthlyStats).forEach((inviteState) => {
      statsData.invitationMonthlyStats[inviteState].forEach((item) => {
        const { inviteSource } = item._id;
        if (!transformedData.invitationMonthlyStats[inviteSource]) {
          transformedData.invitationMonthlyStats[inviteSource] = [];
        }
        transformedData.invitationMonthlyStats[inviteSource].push({
          _id: {
            inviteSource: inviteState,
          },
          data: item.data,
        });
      });
    });
  }

  const aggregateData = (sources) => {
    const aggregated = {};
    sources.forEach((sourceKey) => {
      const sourceData =
        transformedData.invitationMonthlyStats[sourceKey] || [];
      sourceData.forEach((source) => {
        const sourceName = source._id.inviteSource;
        if (!aggregated[sourceName]) {
          aggregated[sourceName] = Array(12).fill(0); // Initialize all months to 0
        }
        source.data.forEach((dataPoint) => {
          const monthIndex = dataPoint.month - 1;
          aggregated[sourceName][monthIndex] += dataPoint.count; // Sum the counts for each month
        });
      });
    });
    return aggregated;
  };

  const aggregatedData =
    invitationSource.length === 0
      ? aggregateData(['All'])
      : aggregateData(invitationSource);

  const inviteData = {
    labels,
    datasets: Object.keys(aggregatedData).map((sourceKey) => ({
      label: typeLabels[sourceKey] || sourceKey,
      data: aggregatedData[sourceKey].map((value) =>
        value === 0 ? null : value
      ), // Replace 0 with null
      backgroundColor: colors[sourceKey],
      stack: sourceKey === 'Total' ? 'stack1' : 'stack2',
    })),
  };

  const fetchDashboardData = async () => {
    const params = {
      filter: JSON.stringify(appliedFilter),
    };
    return welcomeKitApi.getWelcomekitDashboardStats(params, dispatch);
  };

  const fetchDashboardTotalData = async () => {
    const params = {
      filter: JSON.stringify(appliedFilter),
    };
    return welcomeKitApi.getWelcomekitDashboardTotalStats(params, dispatch);
  };

  const formatKey = (key) => {
    const words = key.split(/(?=[A-Z])/);
    const formattedKey = words.join(' ');
    return formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);
  };

  const formatTotalData = (totalData) => {
    const formattedData = Object.fromEntries(
      Object.entries(totalData).map(([key, value]) => [formatKey(key), value])
    );
    return formattedData;
  };

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      try {
        const [dashboardData, totalData] = await Promise.all([
          fetchDashboardData(),
          fetchDashboardTotalData(),
        ]);
        const formattedData = formatTotalData(totalData);
        setStatsData(dashboardData);
        setDealerIds(dashboardData.welcomekitDealers);
        setTotalWelcomeKitStatsData(formattedData);
      } catch (error) {
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };
    loadData();
  }, [appliedFilter]);

  const updateDateFilters = (tab, currentDate) => {
    let startDate;
    let endDate;

    switch (tab) {
      case 0: // Daily
        startDate = currentDate;
        endDate = currentDate;
        break;
      case 1: // Weekly
        startDate = startOfWeek(currentDate);
        endDate = endOfWeek(currentDate);
        break;
      case 2: // Monthly
        startDate = startOfMonth(currentDate);
        endDate = endOfMonth(currentDate);
        break;
      case 3: // Yearly
        startDate = startOfYear(currentDate);
        endDate = endOfYear(currentDate);
        break;
      default:
        break;
    }
    setDisableDate(endDate);
    setFilterAfterDate(startDate);
    setApplyQuickFilter(true);
    setFilterBeforeDate(endDate);
    setApplyQuickFilter(true);
  };

  const saveFilters = () => {
    const newFilter = {};
    const displays = [];
    const filterStartDate = new Date(filterAfterDate);
    // const filterEndDate = new Date(filterBeforeDate);
    let dateDisplay = '';
    if (filterAfterDate) {
      newFilter.createdAt = { $gte: filterStartDate };
      dateDisplay = `Dates: ${format(filterStartDate, 'MM/dd/yyyy')}`;
    }
    if (filterBeforeDate) {
      const filterEndDate = new Date(filterBeforeDate);
      filterEndDate.setHours(23, 59, 59, 999); // Set time to 11:59:59.999 pm
      newFilter.createdAt = {
        ...newFilter.createdAt,
        $lte: filterEndDate,
      };

      if (!dateDisplay) {
        dateDisplay = `Dates: -${format(filterEndDate, 'MM/dd/yyyy')}`;
      } else {
        dateDisplay = `${dateDisplay}-${format(filterEndDate, 'MM/dd/yyyy')}`;
      }
    }
    if (dateDisplay) {
      displays.push(dateDisplay);
    }

    if (invitationSource && invitationSource.length > 0) {
      newFilter.sellingDealerId = { $in: invitationSource };
    }
    setAppliedFilter(newFilter);
    if (displays.length) setFilterDisplay(displays.join(' '));
    else setFilterDisplay('Filter Applied');
  };

  useEffect(() => {
    if (applyQuickFilter && !!filterAfterDate) {
      setApplyQuickFilter(false);
      saveFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyQuickFilter, filterAfterDate, filterBeforeDate]);

  useEffect(() => {
    setDisableNext(isAfter(disableDate, new Date()) || isToday(disableDate));
  }, [disableDate]);

  useEffect(() => {
    if (invitationSource) {
      saveFilters();
    }
  }, [invitationSource]);

  useEffect(() => {
    updateDateFilters(tabValue, crntDate);
  }, [tabValue, crntDate]);

  const resetFilters = () => {
    setTabValue(false);
    setFilterAfterDate(null);
    setFilterBeforeDate(null);
    setFilterDisplay('');
    // setAppliedFilter({});
    if (invitationSource && invitationSource.length > 0) {
      // setDealerId(dealerId);
      setInvitationSource(invitationSource);
      setAppliedFilter({ sellingDealerId: appliedFilter.sellingDealerId });
    } else {
      setDealerId([]);
      setInvitationSource('');
      setAppliedFilter({});
    }
  };

  const handleInvitationTypeChange = (event, options) => {
    setDealerId(options);
    const dealerIdsData = options.map((option) => option.dealerId);
    setInvitationSource(dealerIdsData);
    // if (dealerIdsData.length === 0) {
    //   resetFilters();
    // }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    let newDate;
    switch (newValue) {
      case 0: // Daily
        newDate = new Date();
        break;
      case 1: // Weekly
        newDate = startOfWeek(new Date());
        break;
      case 2: // Monthly
        newDate = startOfMonth(new Date());
        break;
      case 3: // Yearly
        newDate = startOfYear(new Date());
        break;
      default:
        newDate = new Date();
        break;
    }
    setCrntDate(newDate);
    updateDateFilters(newValue, newDate);
  };

  const handleNext = () => {
    setCrntDate((prevDate) => {
      let newDate;
      switch (tabValue) {
        case 0: // Daily
          newDate = addDays(prevDate, 1);
          break;
        case 1: // Weekly
          newDate = addWeeks(prevDate, 1);
          break;
        case 2: // Monthly
          newDate = addMonths(prevDate, 1);
          break;
        case 3: // Yearly
          newDate = addYears(prevDate, 1);
          break;
        default:
          newDate = prevDate;
          break;
      }
      return newDate;
    });
  };

  const handlePrev = () => {
    setCrntDate((prevDate) => {
      let newDate;
      switch (tabValue) {
        case 0: // Daily
          newDate = addDays(prevDate, -1);
          break;
        case 1: // Weekly
          newDate = addWeeks(prevDate, -1);
          break;
        case 2: // Monthly
          newDate = addMonths(prevDate, -1);
          break;
        case 3: // Yearly
          newDate = addYears(prevDate, -1);
          break;
        default:
          newDate = prevDate;
          break;
      }
      return newDate;
    });
  };

  const region = process.env.REACT_APP_DEFAULT_REGION;
  const gridColumns = region === 'UK' ? 4 : 3;

  return (
    <div
      className="container-fluid"
      style={{ paddingLeft: 100, paddingRight: 100 }}
    >
      <div className="d-flex align-items-start justify-content-end">
        <div>
          {dealerIds.length > 0 && (
            <Autocomplete
              multiple
              id="select-dealerId"
              options={dealerIds}
              autoHighlight
              getOptionLabel={(option) => `${option.name} (${option.dealerId})`}
              style={{ width: 300 }}
              value={dealerId}
              isOptionEqualToValue={(option, value) =>
                option.dealerId === value.dealerId
              }
              onChange={handleInvitationTypeChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose a dealer"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          )}
        </div>
        <div className="d-flex flex-row align-items-start justify-content-end">
          <Tabs
            value={tabValue !== null ? tabValue : false}
            onChange={handleTabChange}
          >
            <Tab label="Daily" value={0} />
            <Tab label="Weekly" value={1} />
            <Tab label="Monthly" value={2} />
            <Tab label="Yearly" value={3} />
          </Tabs>
          {appliedFilter && !Object.keys(appliedFilter).length}
          <FilterModal
            currentFilters={appliedFilter}
            onSave={saveFilters}
            itemName="Reports"
            resetFilters={resetFilters}
            filterDisplay={filterDisplay}
            tabValue={tabValue}
            style={{ marginLeft: '16px' }}
          >
            <div style={{ marginBottom: '16px' }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  utils={DateFnsUtils}
                  label="After Date"
                  variant="inline"
                  className="mr-2"
                  fullWidth
                  id="after-date-filter"
                  value={filterAfterDate}
                  onChange={setFilterAfterDate}
                  autoOk
                />
              </LocalizationProvider>
            </div>
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  utils={DateFnsUtils}
                  label="Before Date"
                  variant="inline"
                  className="mr-2"
                  fullWidth
                  id="before-date-filter"
                  value={filterBeforeDate}
                  onChange={(selectedDate) => {
                    // Set default time to 11:59 pm if date is selected
                    if (selectedDate) {
                      const modifiedDate = endOfDay(selectedDate);
                      setFilterBeforeDate(modifiedDate);
                    } else {
                      setFilterBeforeDate(selectedDate);
                    }
                  }}
                  autoOk
                />
              </LocalizationProvider>
            </div>
          </FilterModal>
        </div>
      </div>
      <div className="d-flex flex-row align-items-start justify-content-end">
        <div className="mt-2">
          {tabValue !== false && (
            <>
              <Button
                onClick={handlePrev}
                variant="contained"
                color="primary"
                style={{ marginRight: '10px' }}
              >
                Prev
              </Button>
              {filterDisplay.replace('Dates: ', '')}
              <Button
                onClick={handleNext}
                variant="contained"
                color="primary"
                disabled={disableNext}
                style={{ marginLeft: '10px' }}
              >
                Next
              </Button>
            </>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <Card className="p-2" style={{ marginBottom: '20px' }}>
            <h4 style={{ textAlign: 'left' }}>Welcome Kit Total Stats</h4>
            {isLoading ? (
              <div className="text-center">
                <CircularProgress />
              </div>
            ) : (
              <Row container spacing={0.5}>
                {Object.entries(totalWelcomeKitStatsData).map(
                  ([key, value]) => (
                    <Col key={key} item xs={gridColumns}>
                      <Item
                        className="d-flex justify-content-left align-items-center"
                        style={{
                          paddingLeft: 20,
                          minHeight: 100,
                          marginBottom: 10,
                        }}
                      >
                        <MailOutline color="disabled" fontSize="large" />
                        <p className="card-content-align">
                          <b>{value}</b>
                          <br />
                          <span>{key}</span>
                        </p>
                      </Item>
                    </Col>
                  )
                )}
              </Row>
            )}
          </Card>
        </div>
        <div className="col-sm-12">
          <Card style={{ marginBottom: '20px' }}>
            <Row>
              <Col>
                <div className="container-fluid">
                  <div className="row justify-content-between align-items-center">
                    <div className="col-sm-4">
                      <h4 style={{ textAlign: 'left' }}>
                        Welcome Kit Monthly Stats
                      </h4>
                    </div>
                  </div>
                  <Bar height={100} data={inviteData} />
                </div>
              </Col>
            </Row>
          </Card>
        </div>
        <div className="col-sm-12">
          <Card style={{ marginBottom: '20px' }}>
            <Row>
              <Col>
                <div className="container-fluid">
                  <InvitationsByDealerList appliedFilter={appliedFilter} />
                </div>
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default WelcomeKitDashboard;
