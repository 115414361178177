import React, { useEffect, useRef, useState } from 'react';
import {
  Paper,
  TableRow,
  TableHead,
  Table,
  TableContainer,
  TableCell,
  TableSortLabel,
  TableBody,
  LinearProgress,
  Button,
  CircularProgress,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import { getSMSesAction } from '../store/actions/sms.actions';
import { SMS_ERROR_CODE_MAPPINGS } from '../constants';

const getErrorCodeFromUser = (user) => {
  const { errorCode } = user.MessageStatus.sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  )[0];
  if (errorCode) {
    return SMS_ERROR_CODE_MAPPINGS[errorCode] || 'Unknown error';
  }
  return '';
};

const SMS_TABLE_FIELDS = [
  {
    display: 'Date',
    render: (e) => e.createdAt,
    sortField: 'Date',
    styles: { width: '15vw', overflowWrap: 'break-word' },
  },
  {
    display: 'To',
    render: (e) => e.To,
    sortField: 'To',
    styles: { width: '15vw', overflowWrap: 'break-word' },
  },
  {
    display: 'From',
    render: (e) => e.From,
    sortField: 'From',
    styles: { width: '15vw', overflowWrap: 'break-word' },
  },
  {
    display: 'Status',
    render: (e) =>
      e.MessageStatus.sort((a, b) => new Date(b.date) - new Date(a.date))[0]
        .status,
    sortField: 'Status',
    styles: { width: '15vw', overflowWrap: 'break-word' },
  },
  {
    display: 'Error',
    render: getErrorCodeFromUser,
    styles: { width: '15vw', overflowWrap: 'break-word' },
  },
];

function SMSLogs() {
  const [isLoading, setIsLoading] = useState(false);
  const [sortField, setSortField] = useState('count');
  const [sortDirection, setSortDirection] = useState('desc');
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadData, setDownloadData] = useState([]);

  const permissions = useSelector((state) => state.permissions.permissions);

  const dispatch = useDispatch();
  const csvLinkRef = useRef();
  const sms = useSelector((state) => state.sms.sms);

  useEffect(() => {
    const loadSMSes = async () => {
      setIsLoading(true);
      await dispatch(getSMSesAction());
      setIsLoading(false);
    };
    loadSMSes();
  }, [dispatch]);

  useEffect(() => {
    if (downloadData.length) {
      setTimeout(() => {
        csvLinkRef.current.link.click();
      }, 0);
    }
  }, [downloadData]);

  const downloadResults = async () => {
    setIsDownloading(true);
    const data = sms.map((result) => ({
      date: result.createdAt,
      to: result.To,
      from: result.From,
      status: result.MessageStatus.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      )[0].status,
      errorCode: getErrorCodeFromUser(result),
    }));
    setDownloadData(data);
    setIsDownloading(false);
  };

  const applySort = (field) => {
    let direction = 'desc';
    if (sortField === field) {
      direction = sortDirection === 'desc' ? 'asc' : 'desc';
    }
    setSortDirection(direction);
    setSortField(field);
  };

  return (
    <div className="smsLogs">
      <h2 className="d-flex align-content-center align-items-center mb-0">
        <span className="mr-2">SMS Logs</span>
        {permissions.SMS_LOGS.WRITE && (
          <Button
            disabled={isDownloading}
            color="primary"
            variant="outlined"
            onClick={downloadResults}
          >
            {isDownloading ? (
              <CircularProgress style={{ height: '25px', width: '25px' }} />
            ) : (
              'Download'
            )}
          </Button>
        )}
        <CSVLink filename="report.csv" data={downloadData} ref={csvLinkRef} />
      </h2>
      <p>
        SMS messages are sent as part of the signup process. They often have
        problems being sent and being classified as spam.
      </p>
      {isLoading || !sms ? (
        <LinearProgress style={{ height: '1rem' }} color="secondary" />
      ) : (
        <TableContainer component={Paper}>
          <Table size="small" padding="none" style={{ tableLayout: 'fixed' }}>
            <TableHead className="bg-dark font-white">
              <TableRow>
                {SMS_TABLE_FIELDS.map((field) => {
                  return (
                    <TableCell
                      key={field.display}
                      align="left"
                      className="p-2"
                      component="th"
                      style={field.styles}
                    >
                      <TableSortLabel
                        className="text-white"
                        active={field.sortField === sortField}
                        direction={
                          field.sortField === sortField ? sortDirection : 'desc'
                        }
                        hideSortIcon={!field.sortField}
                        onClick={() => applySort(field.sortField)}
                      >
                        {field.display}
                      </TableSortLabel>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {sms.map((msg) => (
                <TableRow key={`${msg._id}`} hover>
                  {SMS_TABLE_FIELDS.map((field) => (
                    <TableCell
                      key={field.display}
                      align="left"
                      scope="row"
                      className="p-2"
                      style={field.styles}
                    >
                      <div>{field.render(msg)}</div>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}

export default SMSLogs;
