import {
  DialogContent,
  Dialog,
  Typography,
  List,
  ListItem,
  Grid,
  Paper,
  DialogTitle,
  DialogActions,
  CircularProgress,
  Pagination,
} from '@mui/material';
import { format, parseISO } from 'date-fns';

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import 'react-multi-carousel/lib/styles.css';
import { Close } from '@mui/icons-material';
import { styled } from '@mui/system';
import dcrApi from '../../api/dcr.api';
import { PAGINATION_OPTIONS } from '../../constants';
import NotesAdd from './notesAdd';

const useStyles = styled({
  root: {
    '& .custom-dark-theme--header': {
      backgroundColor: '#343a40 !important',
      color: 'white !important',
      '& svg': {
        color: 'white !important',
      },
    },
  },
});

function NotesList(props) {
  const [loading, setLoading] = useState(false);
  const { model, setOpenNotesList } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [dataSource, setDataSource] = useState([]);
  const [pageSize] = React.useState(PAGINATION_OPTIONS[0]);

  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  async function fetchData(skip = page * pageSize, limit = pageSize) {
    const params = {
      skip,
      limit,
    };

    return dcrApi.getNotes(params, model.accountNumber, dispatch);
  }

  function updateDataSource(data) {
    const startIndex =
      dataSource && dataSource.length
        ? dataSource[dataSource.length - 1]._gridId + 1
        : 0;
    const _updatedDataSource = dataSource.concat(
      data.map((item, index) => {
        return { ...item, _gridId: index + startIndex };
      })
    );
    setDataSource(_updatedDataSource);
    return _updatedDataSource;
  }

  useEffect(() => {
    function checkIfDataPresent() {
      const totalDataExpected = (page + 1) * pageSize;
      const startIndex = page * pageSize;
      return (
        dataSource &&
        dataSource.length > startIndex &&
        (totalCount > totalDataExpected
          ? dataSource.length >= totalDataExpected
          : true)
      );
    }

    const loadData = async () => {
      if (!checkIfDataPresent()) {
        setLoading(true);
        const data = await fetchData();
        setLoading(false);
        if (data && data.data) {
          updateDataSource(data.data);
          if (data.totalCount !== totalCount) {
            setTotalCount(data.totalCount);
          }
        }
      }
    };
    loadData();
  }, [page]);

  useEffect(() => {
    if (dataSource.length === 0) {
      setPage(0);
    }
  }, [dataSource]);

  const handlePageChange = (e, p) => {
    setPage(p);
  };

  const handleClose = () => {
    setOpenNotesList(false);
  };

  const notesSecondLineFormatting = (note) => {
    return `${format(parseISO(note.createdAt), 'EEE, d MMM yyyy HH:mm a')} by ${
      note.userName
    }`;
  };

  return (
    <Dialog
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      open
      className={classes.root}
    >
      <DialogTitle>Note List</DialogTitle>

      <DialogContent>
        <div
          style={{
            height: '70vh',
            width: '100%',
          }}
          className={classes.root}
        >
          {loading ? (
            <div className="loader-container">
              <CircularProgress />
            </div>
          ) : (
            <>
              <List p="10" pt="3" spacing={2} dense={false}>
                {dataSource.map((note) => {
                  return (
                    <Paper
                      variant="outlined"
                      round
                      style={{
                        backgroundColor: '#f5f5f5',
                        marginTop: 8,
                      }}
                      elevation={3}
                    >
                      <ListItem key={note.createdAt} listStyleType="disc">
                        <Grid container spacing={2} rowSpacing="4">
                          <Grid item>
                            <Typography variant="body1">{note.note}</Typography>
                          </Grid>
                          <Grid container spacing={2} justifyContent="flex-end">
                            <Grid item>
                              <Typography
                                style={{ color: '#757575' }}
                                variant="caption"
                              >
                                {notesSecondLineFormatting(note)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </ListItem>
                    </Paper>
                  );
                })}
              </List>

              <Pagination
                count={Math.ceil(totalCount / pageSize)}
                size="large"
                page={page}
                pageSize={pageSize}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />
            </>
          )}
        </div>

        <div className="edit-dcr-close-btn" onClick={handleClose}>
          <Close />
        </div>
      </DialogContent>
      <DialogActions>
        <NotesAdd model={model} setOpenNotesList={setOpenNotesList} />
      </DialogActions>
    </Dialog>
  );
}
export default NotesList;
