import api from '.';

const BROADCAST_STRING = `/admin`;

const sendBroadcast = async (data, dispatch) =>
  api.pocketDriveApi(
    dispatch,
    'post',
    `${BROADCAST_STRING}/broadcast-message`,
    data,
    null
  );

export default {
  sendBroadcast,
};
