import React, { useEffect, useState } from 'react';
import { LinearProgress, Button, Card } from '@mui/material';
import { addHours, addMonths, addDays, format, isBefore } from 'date-fns';
import { Row, Col } from 'react-bootstrap';
import DateFnsUtils from '@date-io/date-fns';
import { useSelector } from 'react-redux';
import {
  Email,
  EmailOutlined,
  ErrorOutline,
  InsertChartOutlinedRounded,
  MessageOutlined,
  UnsubscribeOutlined,
} from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import dayjs from 'dayjs';
import FilterModal from '../components/Filter';
import renewalsApi from '../api/renewals.api';
import RenewalOffersBouncedInvalidList from '../components/RenewalOffersBouncedInvalidList';

function SubscriptionsReport() {
  const defaultDate = new Date('2023-10-25');
  const [statsData, setStatsData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [appliedFilter, setAppliedFilter] = useState({});
  const [filterDisplay, setFilterDisplay] = useState('');
  const [filterBeforeDate, setFilterBeforeDate] = useState(defaultDate);
  const [filterAfterDate, setFilterAfterDate] = useState(defaultDate);
  const [applyQuickFilter, setApplyQuickFilter] = useState(false);
  const isDateDisabled = (date) => isBefore(date, defaultDate);

  const permissions = useSelector((state) => state.permissions.permissions);
  const saveFilters = () => {
    const newFilter = {};
    const displays = [];
    const filterStartDate = new Date(filterAfterDate);
    const filterEndDate = new Date(filterBeforeDate);
    let dateDisplay = '';
    if (filterAfterDate) {
      newFilter.createdAt = { $gte: `${filterStartDate}` };
      dateDisplay = `Dates: ${format(filterStartDate, 'MM/dd/yyyy')}`;
    }
    if (filterBeforeDate) {
      newFilter.createdAt = {
        ...newFilter.createdAt,
        $lte: `ISO${filterEndDate}`,
      };
      if (!dateDisplay) {
        dateDisplay = `Dates: -${format(filterEndDate, 'MM/dd/yyyy')}`;
      } else {
        dateDisplay = `${dateDisplay}-${format(filterEndDate, 'MM/dd/yyyy')}`;
      }
    }
    if (dateDisplay) {
      displays.push(dateDisplay);
    }

    setAppliedFilter(newFilter);
    setFilterDisplay(displays.join(' '));
  };

  useEffect(() => {
    if (applyQuickFilter && !!filterAfterDate) {
      setApplyQuickFilter(false);
      saveFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyQuickFilter, filterAfterDate]);

  const setLastDayFilter = () => {
    setFilterAfterDate(addHours(new Date(), -24));
    setApplyQuickFilter(true);
  };

  const setLastMonthFilter = () => {
    setFilterAfterDate(addMonths(new Date(), -1));
    setApplyQuickFilter(true);
  };

  const setLastSevenDays = () => {
    setFilterAfterDate(addDays(new Date(), -7));
    setApplyQuickFilter(true);
  };

  const resetFilters = () => {
    setFilterAfterDate(null);
    setFilterBeforeDate(null);
    setFilterDisplay('');
    setAppliedFilter({});
  };

  useEffect(() => {
    const loadData = async () => {
      const params = {
        filter: JSON.stringify(appliedFilter),
      };
      const result = await renewalsApi.getRenwalsReport(params);
      setStatsData({ ...result });
      setIsLoading(false);
    };
    setIsLoading(true);
    loadData();
  }, [appliedFilter]);

  if (isLoading) {
    return (
      <div className="container">
        <div className="row my-2">
          <h2>Renewals Report</h2>
        </div>
        <LinearProgress style={{ height: '1rem' }} color="secondary" />
      </div>
    );
  }
  return (
    <div
      className="container"
      style={{
        display: permissions.RENEWALS.READ ? 'block' : 'none',
      }}
    >
      <div>
        <h2 className="d-flex align-content-center align-items-center mb-0">
          <span className="mr-2">Renewals Report</span>
          <div className="flex-grow-1" />
          {appliedFilter && !Object.keys(appliedFilter).length && (
            <>
              <Button color="primary" onClick={setLastDayFilter}>
                Last 24 Hours
              </Button>
              <Button color="primary" onClick={setLastSevenDays}>
                Last 7 Days
              </Button>
              <Button color="primary" onClick={setLastMonthFilter}>
                Last month
              </Button>
            </>
          )}
          <FilterModal
            currentFilters={appliedFilter}
            onSave={saveFilters}
            itemName="Reports"
            resetFilters={resetFilters}
            filterDisplay={filterDisplay}
          >
            <div style={{ margin: '5px 0 16px 0' }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  utils={DateFnsUtils}
                  label="After Date (including)"
                  variant="inline"
                  className="mr-2"
                  fullWidth
                  id="after-date-filter"
                  value={filterAfterDate}
                  onChange={setFilterAfterDate}
                  shouldDisableDate={isDateDisabled}
                  autoOk="true"
                />
              </LocalizationProvider>
            </div>
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  utils={DateFnsUtils}
                  label="Before Date (including)"
                  variant="inline"
                  className="mr-2"
                  fullWidth
                  id="before-date-filter"
                  value={filterBeforeDate}
                  onChange={setFilterBeforeDate}
                  shouldDisableDate={isDateDisabled}
                  autoOk="true"
                />
              </LocalizationProvider>
            </div>
          </FilterModal>
        </h2>
      </div>
      <Card className="p-2">
        <h5 className="heading-left">Offers Stats</h5>
        <Row className="t-3" style={{ marginBottom: '15px' }}>
          <Col>
            <div
              className="d-flex justify-content-left align-items-center"
              style={{ paddingLeft: 20 }}
            >
              <Email color="disabled" fontSize="large" />
              <p className="card-content-align">
                <b>{statsData ? statsData.offersSent : 0}</b>
                <br />
                <span style={{ fontSize: 15 }}>Offer Emails Sent</span>
              </p>
            </div>
          </Col>
          <Col>
            <div
              className="d-flex justify-content-left align-items-center"
              style={{ paddingLeft: 20 }}
            >
              <EmailOutlined color="disabled" fontSize="large" />
              <p className="card-content-align">
                <b>{statsData ? statsData.offersDelivered : 0}</b>
                <br />
                <span style={{ fontSize: 15 }}>Offer Emails Delivered</span>
              </p>
            </div>
          </Col>
          <Col>
            <div
              className="d-flex justify-content-left align-items-center"
              style={{ paddingLeft: 20 }}
            >
              <InsertChartOutlinedRounded color="disabled" fontSize="large" />
              <p className="card-content-align">
                <b>{statsData ? statsData.offersInvalidEmail : 0}</b>
                <br />
                <span style={{ fontSize: 15 }}>
                  Offer Emails Invalid Address
                </span>
              </p>
            </div>
          </Col>
        </Row>
        <Row className="t-3" style={{ marginBottom: '10px' }}>
          <Col>
            <div
              className="d-flex justify-content-left align-items-center"
              style={{ paddingLeft: 20 }}
            >
              <ErrorOutline color="disabled" fontSize="large" />
              <p className="card-content-align">
                <b>{statsData ? statsData.offersBounced : 0}</b>
                <br />
                <span style={{ fontSize: 15 }}>Offer Emails Bounced</span>
              </p>
            </div>
          </Col>
          <Col>
            <div
              className="d-flex justify-content-left align-items-center"
              style={{ paddingLeft: 20 }}
            >
              <MessageOutlined color="disabled" fontSize="large" />
              <p className="card-content-align">
                <b>{statsData ? statsData.offersComplaint : 0}</b>
                <br />
                <span style={{ fontSize: 15 }}>Offer Emails Complaints</span>
              </p>
            </div>
          </Col>
          <Col>
            <div
              className="d-flex justify-content-left align-items-center"
              style={{ paddingLeft: 20 }}
            >
              <UnsubscribeOutlined color="disabled" fontSize="large" />
              <p className="card-content-align">
                <b>{statsData ? statsData.offersUnsubscribed : 0}</b>
                <br />
                <span style={{ fontSize: 15 }}>Offer Emails Unsubscribed</span>
              </p>
            </div>
          </Col>
        </Row>
      </Card>
      <br />
      <Card className="p-2">
        <h5 className="heading-left">Contract Activation Stats</h5>
        <Row className="t-3" style={{ marginBottom: '10px' }}>
          <Col>
            <div
              className="d-flex justify-content-left align-items-center"
              style={{ paddingLeft: 20 }}
            >
              <Email color="disabled" fontSize="large" />
              <p className="card-content-align">
                <b>
                  {statsData ? statsData.renewalContractActivationTotal : 0}
                </b>
                <br />
                <span style={{ fontSize: 15 }}> Total</span>
              </p>
            </div>
          </Col>
          <Col>
            <div
              className="d-flex justify-content-left align-items-center"
              style={{ paddingLeft: 20 }}
            >
              <EmailOutlined color="disabled" fontSize="large" />
              <p className="card-content-align">
                <b>
                  {statsData ? statsData.renewalContractActivationDelivered : 0}
                </b>
                <br />
                <span style={{ fontSize: 15 }}>Delivered</span>
              </p>
            </div>
          </Col>
          <Col>
            <div
              className="d-flex justify-content-left align-items-center"
              style={{ paddingLeft: 20 }}
            >
              <InsertChartOutlinedRounded color="disabled" fontSize="large" />
              <p className="card-content-align">
                <b>
                  {statsData
                    ? statsData.renewalContractActivationUnsubscribed
                    : 0}
                </b>
                <br />
                <span style={{ fontSize: 15 }}>Unsubscribed</span>
              </p>
            </div>
          </Col>
        </Row>
      </Card>
      <br />
      <Card className="p-2">
        <h5 className="heading-left">Monthly Payment Stats</h5>
        <Row className="t-3" style={{ marginBottom: '15px' }}>
          <Col>
            <div
              className="d-flex justify-content-left align-items-center"
              style={{ paddingLeft: 20 }}
            >
              <Email color="disabled" fontSize="large" />
              <p className="card-content-align">
                <b>{statsData ? statsData.monthlyPaymentScheduleTotal : 0}</b>
                <br />
                <span style={{ fontSize: 15 }}>Schedule Total</span>
              </p>
            </div>
          </Col>
          <Col>
            <div
              className="d-flex justify-content-left align-items-center"
              style={{ paddingLeft: 20 }}
            >
              <EmailOutlined color="disabled" fontSize="large" />
              <p className="card-content-align">
                <b>
                  {statsData ? statsData.monthlyPaymentScheduleDelivered : 0}
                </b>
                <br />
                <span style={{ fontSize: 15 }}>Schedule Delivered</span>
              </p>
            </div>
          </Col>
          <Col>
            <div
              className="d-flex justify-content-left align-items-center"
              style={{ paddingLeft: 20 }}
            >
              <InsertChartOutlinedRounded color="disabled" fontSize="large" />
              <p className="card-content-align">
                <b>
                  {statsData ? statsData.monthlyPaymentScheduleUnsubscribed : 0}
                </b>
                <br />
                <span style={{ fontSize: 15 }}>Schedule Unsubscribed</span>
              </p>
            </div>
          </Col>
        </Row>
        <Row className="t-3" style={{ marginBottom: '15px' }}>
          <Col>
            <div
              className="d-flex justify-content-left align-items-center"
              style={{ paddingLeft: 20 }}
            >
              <Email color="disabled" fontSize="large" />
              <p className="card-content-align">
                <b>{statsData ? statsData.monthlyPaymentReminderTotal : 0}</b>
                <br />
                <span style={{ fontSize: 15 }}>Reminder Total</span>
              </p>
            </div>
          </Col>
          <Col>
            <div
              className="d-flex justify-content-left align-items-center"
              style={{ paddingLeft: 20 }}
            >
              <EmailOutlined color="disabled" fontSize="large" />
              <p className="card-content-align">
                <b>
                  {statsData ? statsData.monthlyPaymentReminderDelivered : 0}
                </b>
                <br />
                <span style={{ fontSize: 15 }}>Reminder Delivered</span>
              </p>
            </div>
          </Col>
          <Col>
            <div
              className="d-flex justify-content-left align-items-center"
              style={{ paddingLeft: 20 }}
            >
              <InsertChartOutlinedRounded color="disabled" fontSize="large" />
              <p className="card-content-align">
                <b>
                  {statsData ? statsData.monthlyPaymentReminderUnsubscribed : 0}
                </b>
                <br />
                <span style={{ fontSize: 15 }}>Reminder Unsubscribed</span>
              </p>
            </div>
          </Col>
        </Row>
        <Row className="t-3" style={{ marginBottom: '10px' }}>
          <Col>
            <div
              className="d-flex justify-content-left align-items-center"
              style={{ paddingLeft: 20 }}
            >
              <Email color="disabled" fontSize="large" />
              <p className="card-content-align">
                <b>{statsData ? statsData.monthlyPaymentFailureTotal : 0}</b>
                <br />
                <span style={{ fontSize: 15 }}>Failure Total</span>
              </p>
            </div>
          </Col>
          <Col>
            <div
              className="d-flex justify-content-left align-items-center"
              style={{ paddingLeft: 20 }}
            >
              <EmailOutlined color="disabled" fontSize="large" />
              <p className="card-content-align">
                <b>
                  {statsData ? statsData.monthlyPaymentFailureDelivered : 0}
                </b>
                <br />
                <span style={{ fontSize: 15 }}>Failure Delivered</span>
              </p>
            </div>
          </Col>
          <Col>
            <div
              className="d-flex justify-content-left align-items-center"
              style={{ paddingLeft: 20 }}
            >
              <InsertChartOutlinedRounded color="disabled" fontSize="large" />
              <p className="card-content-align">
                <b>
                  {statsData ? statsData.monthlyPaymentFailureUnsubscribed : 0}
                </b>
                <br />
                <span style={{ fontSize: 15 }}>Failure Unsubscribed</span>
              </p>
            </div>
          </Col>
        </Row>
      </Card>
      <br />
      <div className="row my-2 ">
        <RenewalOffersBouncedInvalidList appliedFilter={appliedFilter} />
      </div>
    </div>
  );
}

export default SubscriptionsReport;
