/* eslint-disable prefer-const */
import { Container, Dialog, DialogContent, IconButton } from '@mui/material';
import { ArrowBackIos, Close } from '@mui/icons-material';
import React, { useState } from 'react';
import { Button, Input } from 'reactstrap';
import { getTimeJson } from '../../utils/timeDb';

function EditDcrHours(props) {
  const { hours, dealerName, setVisible, saveHours, onClose } = props;
  const [time, setTime] = useState(hours);

  const handleInputChange = (event, index) => {
    const { name, value } = event.target;
    let temp = time;
    temp[index][name] = value;
    setTime(temp);
  };

  // const setDisabled = (index) => {
  //   let temp = time;
  //   temp[index].disabled = temp[index].disabled ? false : true;
  //   setTime(temp);
  // };

  const onSave = () => {
    saveHours(time);
    setVisible(false);
  };

  return (
    <Dialog fullWidth maxWidth="lg" open>
      <DialogContent>
        <Container>
          <div className="edit-dcr-time-row-container">
            <div className="edit-dcr-time-row-header">
              <IconButton onClick={() => setVisible(false)}>
                <ArrowBackIos />
              </IconButton>
              <div>
                <b>Hours</b>
                <p>{dealerName}</p>
              </div>
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </div>
            {time &&
              time.map((h, i) => (
                <TimeRow
                  key={h.day}
                  row={h}
                  index={i}
                  handleInputChange={handleInputChange}
                  disabled={h.disabled}
                />
              ))}
          </div>
          <div className="edit-dcr-footer hour-footer">
            <Button onClick={onSave} color="primary" className="edit-dcr-btn">
              Save
            </Button>
            <Button
              onClick={() => setVisible(false)}
              color="primary"
              className="edit-dcr-btn"
            >
              Cancel
            </Button>
          </div>
        </Container>
      </DialogContent>
    </Dialog>
  );
}

function TimeRow({ row, index, handleInputChange }) {
  return (
    <div className="edit-dcr-time-row">
      <div className="edit-dcr-time-row-day">{row.day}</div>
      <div className="edit-dcr-time-row-input">
        <Input
          style={{ height: '56px' }}
          type="select"
          name="open"
          id="open"
          defaultValue={row.open}
          onChange={(e) => handleInputChange(e, index)}
        >
          <option value="">Open</option>
          {getTimeJson().map((st, i) => {
            return (
              <option key={i} value={st}>
                {st}
              </option>
            );
          })}
        </Input>
      </div>
      <div className="edit-dcr-time-row-input">
        <Input
          style={{ height: '56px' }}
          type="select"
          name="close"
          id="close"
          defaultValue={row.close}
          onChange={(e) => handleInputChange(e, index)}
        >
          <option value="">Close</option>
          {getTimeJson().map((st, i) => {
            return (
              <option key={i} value={st}>
                {st}
              </option>
            );
          })}
        </Input>
      </div>
      <div className="spacer" />
    </div>
  );
}

export default EditDcrHours;
