/* eslint-disable no-unused-vars */
/* eslint-disable default-param-last */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  LinearProgress,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { addHours, addMonths, format, parseISO } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import {
  DirectionsCarOutlined,
  AccessTime as AccessTimeIcon,
  CheckCircleRounded as CheckCircleRoundedIcon,
  AccountCircle as AccountCircleIcon,
} from '@mui/icons-material';
import ReactExport from 'react-excel-exportz';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useNavigate } from 'react-router-dom';
import { PAGINATION_OPTIONS } from '../constants';
import invitationApi from '../api/invitation.api';
import FilterModal from '../components/Filter';
import carsApi from '../api/cars.api';

const CustomDataGrid = styled('div')({
  '& .custom-dark-theme-header': {
    backgroundColor: '#343a40 !important',
    color: 'white !important',
    '& svg': {
      color: 'white !important',
    },
  },
  '& .custom-link-cell': {
    color: 'blue !important',
    cursor: 'pointer',
  },
});

const StyledComponent = styled('div')(({ theme }) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}));
function Invitations() {
  const permissions = useSelector((state) => state.permissions.permissions);

  const [pageSize, setPageSize] = React.useState(PAGINATION_OPTIONS[2]);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const goToVehicles = (e, carId) => {
    e.stopPropagation();
    navigate(`/cars/${carId}`);
  };

  const goToUser = async (e, carId) => {
    e.stopPropagation();
    const car = await carsApi.getCar(dispatch, carId);
    if (car && car.ownerId) navigate(`/users/${car.ownerId}`);
  };

  const columns = [
    {
      headerName: 'Name',
      render: (e) => e.name,
      field: 'name',
      headerClassName: 'custom-dark-theme--header',
      flex: 1,
    },
    {
      headerName: 'Email',
      render: (e) => e.email,
      field: 'email',
      headerClassName: 'custom-dark-theme--header',
      flex: 2,
    },
    {
      headerName: 'Phone Number',
      render: (e) => e.phone,
      field: 'phone',
      headerClassName: 'custom-dark-theme--header',
      flex: 1,
    },
    {
      headerName: 'Created At',
      valueFormatter: (e) =>
        e.value ? format(parseISO(e.value), 'MM/dd/yyyy') : '',
      field: 'createdAt',
      headerClassName: 'custom-dark-theme--header',
      flex: 1,
    },
    {
      headerName: 'Status',
      renderCell: (e) => {
        return e.value && e.value.toLowerCase() === 'accepted' ? (
          <CheckCircleRoundedIcon style={{ fill: 'green' }} />
        ) : (
          <AccessTimeIcon style={{ fill: '#00000096' }} />
        );
      },
      field: 'status',
      headerClassName: 'custom-dark-theme--header',
      flex: 1,
    },
    {
      headerName: 'Car',
      renderCell: (e) => (
        <IconButton
          disabled={!permissions.INVITATIONS.WRITE}
          onClick={(event) => goToVehicles(event, e.value)}
        >
          <DirectionsCarOutlined color="primary" />
        </IconButton>
      ),
      field: 'vehicleId',
      headerClassName: 'custom-dark-theme--header',
      flex: 1,
    },
    {
      headerName: 'User',
      renderCell: (e) => {
        if (
          e &&
          e.row &&
          e.row.status &&
          e.row.status.toLowerCase() === 'accepted'
        ) {
          return (
            <IconButton onClick={(event) => goToUser(event, e.row.vehicleId)}>
              <AccountCircleIcon color="primary" />
            </IconButton>
          );
        }
        return (
          <IconButton disabled>
            <AccountCircleIcon />
          </IconButton>
        );
      },
      field: '_id',
      headerClassName: 'custom-dark-theme--header',
      flex: 1,
    },
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [downloadData, setDownloadData] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadMsg, setDownloadMsg] = useState(null);
  // const classes = useStyles();
  const [appliedFilter, setAppliedFilter] = useState({});
  const [filterDisplay, setFilterDisplay] = useState('');
  const [filterBeforeDate, setFilterBeforeDate] = useState(null);
  const [filterAfterDate, setFilterAfterDate] = useState(null);
  const [applyQuickFilter, setApplyQuickFilter] = useState(false);
  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ReactExport.ExcelFile;
  const { ExcelColumn } = ReactExport.ExcelFile;

  async function fetchData(skip = dataSource.length, limit = pageSize) {
    const params = {
      skip,
      limit,
      filter: JSON.stringify(appliedFilter),
    };

    return invitationApi.getInvitations(params, dispatch);
  }

  function updateDataSource(data) {
    const startIndex =
      dataSource && dataSource.length
        ? dataSource[dataSource.length - 1]._gridId + 1
        : 0;
    const _updatedDataSource = dataSource.concat(
      data.map((item, index) => {
        return { ...item, _gridId: index + startIndex };
      })
    );
    setDataSource(_updatedDataSource);
    return _updatedDataSource;
  }

  useEffect(() => {
    function checkIfDataPresent() {
      const totalDataExpected = (page + 1) * pageSize;
      const startIndex = page * pageSize;
      return (
        dataSource &&
        dataSource.length > startIndex &&
        (rowCount > totalDataExpected
          ? dataSource.length >= totalDataExpected
          : true)
      );
    }

    const loadData = async () => {
      if (!checkIfDataPresent()) {
        setIsLoading(true);
        const reports = await fetchData();
        if (reports && reports.data) {
          updateDataSource(reports.data);
          if (reports.totalCount !== rowCount) {
            setRowCount(reports.totalCount);
          }
        }
        setIsLoading(false);
      }
    };
    loadData();
  }, [page]);

  useEffect(() => {
    const loadData = async () => {
      if (page !== 0) {
        setDataSource([]);
        return;
      }
      setIsLoading(true);
      const reports = await fetchData(0);
      if (reports && reports.data) {
        setDataSource(
          reports.data.map((item, index) => {
            return { ...item, _gridId: index };
          })
        );
        if (reports.totalCount !== rowCount) {
          setRowCount(reports.totalCount);
        }
        setPage(0);
      }
      setIsLoading(false);
    };
    loadData();
  }, [appliedFilter]);

  useEffect(() => {
    if (dataSource.length === 0) {
      setPage(0);
    }
  }, [dataSource]);

  const saveFilters = () => {
    const newFilter = {};
    const displays = [];
    const filterStartDate = new Date(filterAfterDate);
    const filterEndDate = new Date(filterBeforeDate);
    let dateDisplay = '';
    if (filterAfterDate) {
      newFilter.createdAt = { $gte: filterStartDate };
      dateDisplay = `Dates: ${format(filterStartDate, 'MM/dd/yyyy')}`;
    }
    if (filterBeforeDate) {
      newFilter.createdAt = {
        ...newFilter.createdAt,
        $lte: filterEndDate,
      };
      if (!dateDisplay) {
        dateDisplay = `Dates: -${format(filterEndDate, 'MM/dd/yyyy')}`;
      } else {
        dateDisplay = `${dateDisplay}-${format(filterEndDate, 'MM/dd/yyyy')}`;
      }
    }
    if (dateDisplay) {
      displays.push(dateDisplay);
    }

    setAppliedFilter(newFilter);
    setFilterDisplay(displays.join(' '));
  };

  useEffect(() => {
    if (applyQuickFilter && !!filterAfterDate) {
      setApplyQuickFilter(false);
      saveFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyQuickFilter, filterAfterDate]);

  async function fetchDataInBatches(index = 0, limit = 100, allReports) {
    const report = await fetchData(index * limit, limit);
    allReports = allReports.concat(report.data); // eslint-disable-line no-param-reassign
    if (report.currentPage !== report.pageCount) {
      setDownloadMsg(
        `Downloading ${report.currentPage + 1} of ${report.pageCount} Pages`
      );
      return fetchDataInBatches(report.currentPage, limit, allReports);
    }
    return allReports;
  }

  const downloadResults = async () => {
    setIsDownloading(true);
    setDownloadData([]);
    setDownloadMsg(`Downloading 1 of ${Math.round(rowCount / 100)} Pages`);
    const reports = await fetchDataInBatches(0, 100, []);
    setDownloadData(reports);
    setIsDownloading(false);
    setDownloadMsg(null);
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params.pageSize);
  };

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const setLastDayFilter = () => {
    setFilterAfterDate(addHours(new Date(), -24));
    setApplyQuickFilter(true);
  };

  const setLastMonthFilter = () => {
    setFilterAfterDate(addMonths(new Date(), -1));
    setApplyQuickFilter(true);
  };

  const setLastSixMonthsFilter = () => {
    setFilterAfterDate(addMonths(new Date(), -6));
    setApplyQuickFilter(true);
  };

  const resetFilters = () => {
    setFilterAfterDate(null);
    setFilterBeforeDate(null);
    setFilterDisplay('');
    setAppliedFilter({});
    setPage(0);
  };

  return (
    <div
      style={{
        display: permissions.INVITATIONS.READ ? 'block' : 'none',
      }}
    >
      <Grid container className="reports">
        <Grid item sm={12}>
          <h2 className="d-flex align-content-center align-items-center mb-2">
            <span className="mr-2" style={{ marginRight: '16px' }}>
              Invitations
            </span>
            {permissions.INVITATIONS.WRITE && (
              <Button
                disabled={isDownloading}
                color="primary"
                variant="outlined"
                onClick={downloadResults}
              >
                {downloadMsg ? (
                  <>
                    <span style={{ fontSize: '12px' }}>{downloadMsg}</span>
                    <CircularProgress
                      style={{ height: '25px', width: '25px' }}
                    />
                  </>
                ) : (
                  'Download details'
                )}
              </Button>
            )}
            {downloadData && downloadData.length ? (
              <ExcelFile hideElement>
                <ExcelSheet data={downloadData} name="Invitation">
                  <ExcelColumn label="code" value="code" />
                  <ExcelColumn label="createdAt" value="createdAt" />
                  <ExcelColumn label="creditUnionId" value="creditUnionId" />
                  <ExcelColumn label="dealerId" value="dealerId" />
                  <ExcelColumn label="email" value="email" />
                  <ExcelColumn label="invitationType" value="invitationType" />
                  <ExcelColumn label="name" value="name" />
                  <ExcelColumn label="phone" value="phone" />
                  <ExcelColumn label="smsUrl" value="smsUrl" />
                  <ExcelColumn label="status" value="status" />
                  <ExcelColumn label="updatedAt" value="updatedAt" />
                  <ExcelColumn label="url" value="url" />
                  <ExcelColumn label="vehicleId" value="vehicleId" />
                  <ExcelColumn label="userUpdatedAt" value="userUpdatedAt" />
                  <ExcelColumn label="__v" value="__v" />
                  <ExcelColumn label="_id" value="_id" />
                </ExcelSheet>
              </ExcelFile>
            ) : (
              ''
            )}
            <div className="flex-grow-1" />
            {appliedFilter && !Object.keys(appliedFilter).length && (
              <>
                <Button color="primary" onClick={setLastDayFilter}>
                  Last 24 Hours
                </Button>
                <Button color="primary" onClick={setLastMonthFilter}>
                  Last month
                </Button>
                <Button color="primary" onClick={setLastSixMonthsFilter}>
                  Last 6 month
                </Button>
              </>
            )}
            <FilterModal
              currentFilters={appliedFilter}
              onSave={saveFilters}
              itemName="Reports"
              resetFilters={resetFilters}
              filterDisplay={filterDisplay}
            >
              <div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    utils={DateFnsUtils}
                    label="After Date (including)"
                    variant="inline"
                    className="mr-2"
                    fullWidth
                    id="after-date-filter"
                    value={filterAfterDate}
                    onChange={setFilterAfterDate}
                    autoOk="true"
                  />
                </LocalizationProvider>
              </div>
              <div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    utils={DateFnsUtils}
                    label="Before Date (including)"
                    variant="inline"
                    className="mr-2"
                    fullWidth
                    id="before-date-filter"
                    value={filterBeforeDate}
                    onChange={setFilterBeforeDate}
                    autoOk="true"
                  />
                </LocalizationProvider>
              </div>
            </FilterModal>
          </h2>
          {isLoading ? (
            <LinearProgress style={{ height: '1rem' }} color="secondary" />
          ) : (
            <StyledComponent>
              <div
                style={{ height: '70vh', width: '100%' }}
                className={StyledComponent.root}
              >
                <CustomDataGrid>
                  <DataGrid
                    paginationModel={{ page, pageSize }}
                    pagination
                    pageSizeOptions={PAGINATION_OPTIONS}
                    onPageSizeChange={handlePageSizeChange}
                    rowCount={rowCount}
                    getRowId={(row) => row._gridId}
                    rows={dataSource}
                    columns={columns}
                    onPaginationModelChange={handlePageChange}
                  />
                </CustomDataGrid>
              </div>
            </StyledComponent>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default Invitations;
