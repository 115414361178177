/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from 'react';
import {
  TableContainer,
  Paper,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableHead,
  DialogContent,
  Dialog,
} from '@mui/material';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Edit } from '@mui/icons-material';
import CarEditConfiguration from './CarEditConfiguration';

const MAINTENANCE_FIELDS = [
  {
    display: 'Interval',
    render: (m) => `${m.interval} ${m.type}`,
  },
  {
    display: 'Content',
    render: (m) => m.content.map((x) => x.name).join(', '),
  },
  {
    display: 'Completed',
    render: (m) => moment(m.completedDate).format('lll'),
  },
];

function CarMaintenanceHistory(props) {
  const [openEditConfig, setOpenEditConfig] = useState(false);
  const carr = useSelector((state) => state.car.car);
  const openEditConfiguration = () => {
    setOpenEditConfig(true);
  };

  const {
    car: { maintenance },
  } = props;
  if (!maintenance) {
    return <></>;
  }
  return (
    <div>
      <Button
        className="mb-2"
        variant="outlined"
        color="primary"
        onClick={() => openEditConfiguration()}
      >
        <Edit />
        <span>Edit Configuration</span>
      </Button>
      <TableContainer component={Paper}>
        <Table size="small" padding="none">
          <TableHead className="bg-dark">
            <TableRow>
              {MAINTENANCE_FIELDS.map((m) => (
                <TableCell
                  key={m.display}
                  align="center"
                  className="text-white"
                >
                  {m.display}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {maintenance.map((item) => (
              <TableRow key={item._id} hover>
                {MAINTENANCE_FIELDS.map((field) => (
                  <TableCell
                    key={field.display}
                    align="center"
                    style={{ minWidth: '7.5rem' }}
                  >
                    {field.render(item)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        onClose={() => setOpenEditConfig(false)}
        fullWidth
        fullHeight
        maxWidth="sm"
        open={openEditConfig}
      >
        <DialogContent>
          <CarEditConfiguration car={carr} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CarMaintenanceHistory;
