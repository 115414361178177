/* eslint-disable no-console */
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  ButtonGroup,
  CircularProgress,
  FormControl,
  IconButton,
  Input,
  InputLabel,
} from '@mui/material';
import moment from 'moment';
import { Cancel } from '@mui/icons-material';
import { PAGINATION_OPTIONS } from '../constants';
import subscriptionsApi from '../api/subscriptions.api';
import JSONViewer from './JSONViewer';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <h5 style={{ textAlign: 'left', padding: '5px' }}>Transactions</h5>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

function OfferTransactionEvents({ offerId, viewHeight = '50vh' }) {
  const TRANSACTION_TYPE = {
    ALL: '',
    INITIAL_PAYMENT: 'INITIAL_PAYMENT',
    REFUND: 'REFUND',
    RECURRING_PAYMENT: 'RECURRING_PAYMENT',
    CARD_CHANGE: 'CARD_CHANGE',
    CARD_CHANGE_AND_CHARGE: 'CARD_CHANGE_AND_CHARGE',
  };

  const showTransactionFilter = !offerId;
  const [dataSource, setDataSource] = useState([]);
  const [contractNumber, setContractNumber] = useState('');
  const [pageSize, setPageSize] = useState(PAGINATION_OPTIONS[2]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [filterTransactionType, setFilterTransactionType] = useState(
    TRANSACTION_TYPE.ALL
  );

  const dispatch = useDispatch();

  const permissions = useSelector((state) => state.permissions.permissions);
  const writeAccess = permissions.OFFERS_DATA.WRITE === true;

  const updateDataSource = (data) => {
    const startIndex =
      dataSource && dataSource.length
        ? dataSource[dataSource.length - 1]._id + 1
        : 0;
    const _updatedDataSource = dataSource.concat(
      data.map((item, index) => {
        return { ...item, _id: index + startIndex };
      })
    );
    setDataSource(_updatedDataSource);
    return _updatedDataSource;
  };

  const fetchReport = async () => {
    const filterValue = {};
    if (offerId) {
      filterValue.offer_id = { eq: offerId };
    }
    if (contractNumber) {
      filterValue.contract_number = { eq: contractNumber };
    }
    if (filterTransactionType !== '') {
      filterValue.transaction_type = { eq: filterTransactionType };
    }
    const params = {
      page: page + 1,
      limit: pageSize,
      filter: JSON.stringify(filterValue),
    };
    setIsLoading(true);

    return subscriptionsApi.getTransactionEvents(dispatch, params);
  };

  useEffect(() => {
    const loadData = async () => {
      const reports = await fetchReport();
      if (reports && reports.data) {
        updateDataSource(reports.data.data);
        setRowCount(reports.data.totalItems);
      }
      setIsLoading(false);
    };
    loadData();
  }, [page, pageSize, offerId, filterTransactionType, contractNumber]);

  const handlePageChange = (params) => {
    setPage(params.page);
    setPageSize(params.pageSize);
  };

  function selectTab(type) {
    setDataSource([]);
    setPage(0);
    setFilterTransactionType(type);
  }

  const resetFilters = () => {
    setDataSource([]);
    setPage(0);
    setContractNumber('');
  };

  const handleFilterOfferId = (event) => {
    setDataSource([]);
    setPage(0);
    setContractNumber(event.target.value);
  };

  function getTabVariant(type) {
    if (filterTransactionType === type) {
      return 'contained';
    }
    return 'outlined';
  }

  const columns = [];
  columns.push({
    headerName: 'Date',
    renderCell: (c) =>
      moment(c.value).format(process.env.REACT_APP_DATE_TIME_FORMAT),
    field: 'createdAt',
    headerClassName: 'custom-dark-theme--header',
    width: 200,
  });
  columns.push({
    headerName: 'Type',
    field: 'transactionType',
    headerClassName: 'custom-dark-theme--header',
    renderCell: (c) => {
      return (
        <div>
          <JSONViewer
            title="Transaction Data"
            color="primary"
            jsonData={c.row}
          />
          {c.row.transactionType}
        </div>
      );
    },
    width: 200,
  });
  columns.push({
    headerName: 'Amount',
    renderCell: (c) => `${c.row.amount} ${c.row.currency}`,
    field: 'amount',
    headerClassName: 'custom-dark-theme--header',
    width: 100,
  });
  columns.push({
    headerName: 'Status',
    render: (e) => e.status,
    field: 'status',
    headerClassName: 'custom-dark-theme--header',
    width: 100,
  });
  columns.push({
    headerName: 'Code',
    render: (e) => e.reasonCode,
    field: 'reasonCode',
    headerClassName: 'custom-dark-theme--header',
    width: 60,
  });
  if (writeAccess) {
    columns.push({
      headerName: 'Card',
      renderCell: (c) =>
        `${c.row.cardBrand ? `${c.row.cardBrand} ` : ''}${
          c.row.cardNumber ? c.row.cardNumber : ''
        }`,
      field: 'cardNumber',
      headerClassName: 'custom-dark-theme--header',
      width: 180,
    });
    columns.push({
      headerName: 'Transaction Reference',
      render: (e) => e.transactionReference,
      field: 'transactionReference',
      headerClassName: 'custom-dark-theme--header',
      width: 175,
    });
  }
  columns.push({
    headerName: 'Contract #',
    renderCell: (c) => {
      if (c.row.transactionType === 'INITIAL_PAYMENT')
        return c.row.expiringContractNumber;
      return c.row.contractNumber;
    },
    field: 'contractNumber',
    headerClassName: 'custom-dark-theme--header',
    width: 150,
  });
  if (writeAccess) {
    columns.push({
      headerName: 'Transaction UUID',
      render: (e) => e.transactionUuid,
      field: 'transactionUuid',
      headerClassName: 'custom-dark-theme--header',
      width: 300,
    });
    columns.push({
      headerName: 'Gateway Transaction Id',
      renderCell: (e) => e.row.gatewayTransactionId,
      field: 'gatewayTransactionId',
      headerClassName: 'custom-dark-theme--header',
      width: 240,
    });
  }
  columns.push({
    headerName: 'Tx DateTime',
    renderCell: (e) => e.row.transactionDateTime || e.row.createdAt,
    field: 'transactionDateTime',
    headerClassName: 'custom-dark-theme--header',
    width: 220,
  });
  columns.push({
    headerName: 'Tx Source Id (Quote / SFDC / VCP Cancellation)',
    renderCell: (e) => e.row.quoteId || e.row.sfdcId,
    field: 'quoteId',
    headerClassName: 'custom-dark-theme--header',
    width: 320,
  });

  return (
    <div>
      <div style={{ padding: 5 }} className="d-flex">
        <div style={{ display: showTransactionFilter ? '' : 'none' }}>
          <ButtonGroup disableElevation variant="outlined" color="primary">
            <Button
              variant={getTabVariant(TRANSACTION_TYPE.ALL)}
              onClick={() => selectTab(TRANSACTION_TYPE.ALL)}
            >
              All
            </Button>
            <Button
              variant={getTabVariant(TRANSACTION_TYPE.INITIAL_PAYMENT)}
              onClick={() => selectTab(TRANSACTION_TYPE.INITIAL_PAYMENT)}
            >
              Initial Payment
            </Button>
            <Button
              variant={getTabVariant(TRANSACTION_TYPE.RECURRING_PAYMENT)}
              onClick={() => selectTab(TRANSACTION_TYPE.RECURRING_PAYMENT)}
            >
              Recurring Payment
            </Button>
            <Button
              variant={getTabVariant(TRANSACTION_TYPE.REFUND)}
              onClick={() => selectTab(TRANSACTION_TYPE.REFUND)}
            >
              Refund
            </Button>
            <Button
              variant={getTabVariant(TRANSACTION_TYPE.CARD_CHANGE)}
              onClick={() => selectTab(TRANSACTION_TYPE.CARD_CHANGE)}
            >
              Card Change
            </Button>
            <Button
              variant={getTabVariant(TRANSACTION_TYPE.CARD_CHANGE_AND_CHARGE)}
              onClick={() => selectTab(TRANSACTION_TYPE.CARD_CHANGE_AND_CHARGE)}
            >
              Card Change and charge
            </Button>
          </ButtonGroup>
        </div>
        <div className="flex-grow-1" />
        <div
          className="d-flex align-items-center"
          style={{ display: showTransactionFilter ? '' : 'none' }}
        >
          <FormControl>
            <InputLabel id="input-contract-number" shrink>
              Contract Number
            </InputLabel>
            <Input
              labelId="input-contract-number"
              id="input-contract"
              value={contractNumber}
              style={{ minWidth: 150 }}
              onChange={handleFilterOfferId}
            />
          </FormControl>
          {contractNumber && (
            <IconButton onClick={resetFilters}>
              <Cancel color="error" />
            </IconButton>
          )}
        </div>
      </div>
      <div
        style={{
          height: viewHeight,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <DataGrid
            density="compact"
            paginationModel={{ page, pageSize }}
            pagination
            pageSizeOptions={PAGINATION_OPTIONS}
            rowCount={rowCount}
            getRowId={(row) => row._id}
            rows={dataSource}
            columns={columns}
            onPaginationModelChange={handlePageChange}
            slots={{
              toolbar: CustomToolbar,
            }}
          />
        )}
      </div>
    </div>
  );
}

export default OfferTransactionEvents;
