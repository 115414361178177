/* eslint-disable radix */
import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  IconButton,
} from '@mui/material';

import { Close } from '@mui/icons-material';

const ADMIN_APP_SOURCE = 'ADMIN APP';

function AddCreditTransactionModal(props) {
  const { onSave } = props;
  const [show, setShow] = useState(false);
  const [amount, setAmount] = useState(1);
  const [reason, setReason] = useState('');
  const [isAdding, setIsAdding] = useState(true);

  const onSaveWrapper = async () => {
    const delta = isAdding ? amount : -amount;
    await onSave({ delta, reason, source: ADMIN_APP_SOURCE });
    setShow(false);
    setAmount(1);
    setReason('');
    setIsAdding(true);
  };
  const onModifyClick = (event) => {
    event.stopPropagation();
    setShow(true);
  };
  const onClose = () => {
    setShow(false);
  };
  const preventClickEvents = (event) => {
    event.stopPropagation();
  };
  return (
    <div onClick={preventClickEvents}>
      <Button className="p-0" color="primary" onClick={onModifyClick}>
        Modify
      </Button>
      <Dialog open={show} onClose={onClose}>
        <DialogTitle disableTypography className="d-flex align-items-center">
          <Typography variant="h6" className="flex-grow-1">
            Add Credit Transaction
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormControl>
            <FormLabel>Transaction Type</FormLabel>
            <RadioGroup
              name="isAdding"
              row
              value={isAdding}
              onChange={(e) => setIsAdding(e.target.value === 'true')}
            >
              <FormControlLabel value control={<Radio />} label="Add" />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="Remove"
              />
            </RadioGroup>
          </FormControl>
          <TextField
            id="credit-amount"
            label="Amount"
            type="number"
            className="mb-4"
            value={amount}
            fullWidth
            onChange={(e) => setAmount(parseInt(e.target.value, 0))}
          />
          <TextField
            id="credit-reason"
            label="Reason"
            type="text"
            className="mb-4"
            value={reason}
            fullWidth
            onChange={(e) => setReason(e.currentTarget.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            className="mr-2"
            onClick={onSaveWrapper}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setShow(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddCreditTransactionModal;
