/* eslint-disable default-param-last */
import { GET_ERRORS, GET_DETAILED_ERRORS } from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case GET_ERRORS:
      return {
        ...state,
        errors: action.payload,
      };
    case GET_DETAILED_ERRORS:
      return {
        ...state,
        detailed: action.payload,
      };
    default:
      return state;
  }
};
