import contactUsApi from '../../api/contactUs.api';
import { CONTACT_US_FAILED_REQ } from '../types';

export const getContactUsFailedReq = (data) => async (dispatch) => {
  const contactUsFailedRequests = await contactUsApi.contactUsFailedRequests(
    dispatch,
    data
  );
  dispatch({
    type: CONTACT_US_FAILED_REQ,
    payload: contactUsFailedRequests,
  });
};
