import api from '.';

const REPORT_URL = `/admin`;

const getInvitations = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/invitations`,
    null,
    params
  );
};

const requestInvitation = async (data, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'post',
    `${REPORT_URL}/invitation`,
    data,
    null
  );
};

const sendBulkInvitation = async (data, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'post',
    `${REPORT_URL}/bulk-invitation`,
    data,
    null
  );
};

const getBulkInvitation = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/bulk-invitations`,
    null,
    params
  );
};

const getBulkInvitationStatus = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/bulk-invitations/status`,
    null,
    params
  );
};

export default {
  getInvitations,
  requestInvitation,
  sendBulkInvitation,
  getBulkInvitation,
  getBulkInvitationStatus,
};
