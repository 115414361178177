import React from 'react';
import { Chart as ChartJS, ArcElement, Title, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Title, Tooltip, Legend);

const randomNum = () => Math.floor(Math.random() * (235 - 52 + 1) + 52);

const randomRGB = () => `rgb(${randomNum()}, ${randomNum()}, ${randomNum()})`;

const commonColors = [
  'rgb(255, 99, 132, 0.6)',
  'rgb(75, 192, 192, 0.6)',
  'rgba(255, 159, 64, 0.6)',
  'rgba(153, 102, 255, 0.6)',
  'rgba(230, 246, 157, 0.6)',
  'rgba(170, 222, 167, 0.6)',
  'rgba(121, 130, 185, 0.6)',
  'rgba(160, 160, 120, 0.6)',
];

const getBackGroundColor = (index) => {
  if (index < commonColors.length) {
    return commonColors[index];
  }
  return randomRGB();
};

const indexForLabel = (label, labels) => {
  for (let index = 0; index < labels.length; index++) {
    if (labels[index] === label) {
      return index;
    }
  }
  return -1;
};

function GenericPie({ title, stats, labelKey, legendPosition }) {
  if (!stats || stats.length === 0) {
    return (
      <>
        <div>
          <span className="statsSubTitle">{title}</span>
        </div>
        <div style={{ color: 'grey' }}>No Data Found</div>
      </>
    );
  }
  const labels = stats.map((item) => item[labelKey]);
  const data = {
    labels,
    datasets: [
      {
        label: '#',
        data: stats.map((item) => item.count),
        backgroundColor: stats.map((item) =>
          getBackGroundColor(indexForLabel(item[labelKey], labels))
        ),
        borderWidth: 1,
      },
    ],
  };
  const option = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: legendPosition,
      },
      title: {
        display: true,
        text: title,
      },
    },
  };
  return <Pie data={data} options={option} />;
}

export default GenericPie;
