import errorsApi from '../../api/errors.api';
import { GET_ERRORS, GET_DETAILED_ERRORS } from '../types';

export const getErrorsAction = (params) => async (dispatch) => {
  const errors = await errorsApi.getErrors(dispatch, params);
  dispatch({
    type: GET_ERRORS,
    payload: errors,
  });
};

export const getDetailedErrorsAction = (params) => async (dispatch) => {
  const errors = await errorsApi.getDetailedErrors(dispatch, params);
  dispatch({
    type: GET_DETAILED_ERRORS,
    payload: errors,
  });
};
