import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CircularProgress, IconButton } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
import { PAGINATION_OPTIONS } from '../constants';
import subscriptionsApi from '../api/subscriptions.api';
import JSONViewer from './JSONViewer';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <h5 style={{ textAlign: 'left', padding: '5px' }}>Schedule Jobs</h5>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

function ScheduleJobsList({ filter, viewHeight = '30vh' }) {
  const columns = [
    {
      headerName: 'Id',
      field: 'Id',
      // headerClassName: 'custom-dark-theme--header',
      renderCell: (params) => {
        return (
          <div>
            <JSONViewer title="Payload" jsonData={params.row.payload} />
            {params.row.id}
          </div>
        );
      },
      width: 350,
    },
    {
      headerName: 'Type',
      render: (e) => e.type,
      field: 'type',
      headerClassName: 'custom-dark-theme--header',
      width: 300,
    },
    {
      headerName: 'Schedule Time',
      render: (e) => e.scheduledTime,
      field: 'scheduledTime',
      headerClassName: 'custom-dark-theme--header',
      width: 220,
    },
    {
      headerName: 'createdAt',
      render: (e) => e.createdAt,
      field: 'createdAt',
      headerClassName: 'custom-dark-theme--header',
      width: 220,
    },
    {
      headerName: 'Details',
      field: 'details',
      renderCell: (params) =>
        params.row.type === 'RECURRING_PAYMENT_FAILURE_EMAIL' ? (
          <IconButton>
            <OpenInNew />
          </IconButton>
        ) : null,
      width: 90,
      disableColumnMenu: true,
    },
  ];
  const [dataSource, setDataSource] = useState([]);
  const [pageSize, setPageSize] = React.useState(PAGINATION_OPTIONS[2]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const updateDataSource = (data) => {
    const _updatedDataSource = data.map((item, index) => {
      return { ...item, _id: index }; // Generate new ids based on the current data only
    });
    setDataSource(_updatedDataSource); // Set the new data directly
  };

  const fetchReport = async () => {
    const params = {
      page: page + 1,
      limit: pageSize,
      filter: JSON.stringify(filter),
    };
    setIsLoading(true);
    return subscriptionsApi.getScheduleJobs(dispatch, params);
  };

  useEffect(() => {
    const loadData = async () => {
      const reports = await fetchReport();
      if (reports && reports.data && reports.data.data.length > 0) {
        const totalRecords = reports.data.totalItems;
        const currentPageRecords = reports.data.data;

        // Calculate if we're on the last page
        const isLastPage = page === Math.ceil(totalRecords / pageSize) - 1;

        // If on the last page, only show the remaining records
        const recordsToDisplay = isLastPage
          ? currentPageRecords.slice(0, totalRecords % pageSize)
          : currentPageRecords;

        updateDataSource(recordsToDisplay);
        setRowCount(totalRecords);
      }
      setIsLoading(false);
    };
    loadData();
  }, [page, pageSize, filter]);

  const handlePageChange = (params) => {
    setPage(params.page);
    setPageSize(params.pageSize);
  };

  const goToOfferDetails = (detailOfferId) => {
    window.open(`/offers/${detailOfferId}`, '_blank');
  };

  const handleCellClick = (cellParams) => {
    if (cellParams.field === 'details') {
      goToOfferDetails(cellParams.row.payload.offerId);
    }
  };

  return (
    <div className="container">
      <div
        style={{
          height: viewHeight,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <DataGrid
            density="compact"
            paginationModel={{ page, pageSize }}
            pagination
            pageSizeOptions={PAGINATION_OPTIONS}
            rowCount={rowCount}
            getRowId={(row) => row._id}
            rows={dataSource}
            columns={columns}
            // paginationMode="server"
            onPaginationModelChange={handlePageChange}
            onCellClick={handleCellClick}
            slots={{
              toolbar: CustomToolbar,
            }}
          />
        )}
      </div>
    </div>
  );
}

export default ScheduleJobsList;
