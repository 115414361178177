import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const randomNum = () => Math.floor(Math.random() * (235 - 52 + 1) + 52);

const randomRGB = () => `rgb(${randomNum()}, ${randomNum()}, ${randomNum()})`;

const commonColors = [
  'rgb(255, 99, 132, 0.7)',
  'rgb(75, 192, 192, 0.7)',
  'rgba(255, 159, 64, 0.7)',
  'rgba(153, 102, 255, 0.7)',
  'rgba(230, 246, 157, 0.7)',
  'rgba(170, 222, 167, 0.7)',
  'rgba(121, 130, 185, 0.7)',
  'rgba(160, 160, 120, 0.7)',
];

const getBackGroundColor = (index) => {
  if (index < commonColors.length) {
    return commonColors[index];
  }
  return randomRGB();
};

const indexForLabel = (label, labels) => {
  for (let index = 0; index < labels.length; index++) {
    if (labels[index] === label) {
      return index;
    }
  }
  return -1;
};

function GenericBarChart({
  title,
  dataSet,
  labelKey,
  legendPosition,
  indexAxis,
  dataSetLabel,
}) {
  if (!dataSet || dataSet.length === 0) {
    return (
      <>
        <div>
          <span className="statsSubTitle">{title}</span>
        </div>
        <div style={{ color: 'grey' }}>No Data Found</div>
      </>
    );
  }

  const labels = dataSet.map((item) => item[labelKey]);
  const data = {
    labels,
    datasets: [
      {
        label: dataSetLabel,
        data: dataSet.map((item) => item.count),
        backgroundColor: dataSet.map((item) =>
          getBackGroundColor(indexForLabel(item[labelKey], labels))
        ),
      },
    ],
  };

  const options = {
    indexAxis,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: legendPosition,
      },
      title: {
        display: true,
        text: title,
      },
      datalabels: {
        display: true,
        align: 'end',
        anchor: 'end',
        color(context) {
          return context.dataset.backgroundColor;
        },
      },
    },
  };

  return <Bar options={options} data={data} />;
}

export default GenericBarChart;
