import axios from 'axios';
import api from '.';

const UPLOADS_URL = `/auth/uploads`;

const getUploadUrl = async (imageData) =>
  api.pocketDriveApi(null, 'post', UPLOADS_URL, imageData);

const uploadToS3 = async (url, file, fileType) =>
  axios.put(url, file, {
    headers: {
      'Content-Type': fileType,
    },
  });

export default {
  getUploadUrl,
  uploadToS3,
};
