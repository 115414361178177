import userApi from '../../api/user.api';

import {
  GET_USERS,
  GET_USER,
  UPDATE_USER,
  CREATE_USER,
  DELETE_USER,
  PASSWORD_RESET,
  GET_USER_NOTIFICATIONS,
  GET_USER_REPORTS,
} from '../types';

export const createUserAction = (data) => async (dispatch) => {
  const user = await userApi.createUser(dispatch, data);
  dispatch({
    type: CREATE_USER,
    payload: user,
  });
};

export const getUsersAction = (params) => async (dispatch) => {
  let users = null;
  if (params && Object.keys(params.filter).length > 0) {
    users = await userApi.getUsers(dispatch, params);
  }
  dispatch({
    type: GET_USERS,
    payload: users,
  });
};

export const getUserAction = (id) => async (dispatch) => {
  const user = await userApi.getUser(dispatch, id);
  dispatch({
    type: GET_USER,
    payload: user,
  });
};

export const updateUserAction = (user, id) => async (dispatch) => {
  const updatedUser = await userApi.updateUser(dispatch, user, id);
  dispatch({
    type: UPDATE_USER,
    payload: updatedUser,
  });
};

export const deleteUserAction = (id) => async (dispatch) => {
  await userApi.deleteUser(dispatch, id);
  dispatch({
    type: DELETE_USER,
  });
};

export const triggerPasswordResetAction = (id) => async (dispatch) => {
  await userApi.triggerResetPassword(dispatch, id);
  dispatch({
    type: PASSWORD_RESET,
  });
};

export const getUserNotifications = (id) => async (dispatch) => {
  const notifications = await userApi.getUserNotificationHistory(dispatch, id);
  dispatch({
    type: GET_USER_NOTIFICATIONS,
    payload: notifications,
  });
};

export const getUserReport = (params) => async (dispatch) => {
  const userReport = await userApi.getUserReport(dispatch, params);
  dispatch({
    type: GET_USER_REPORTS,
    payload: userReport,
  });
};
