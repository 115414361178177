import React, { useEffect, useState } from 'react';
import { LinearProgress, Card, IconButton, Tooltip } from '@mui/material';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import {
  CancelRounded,
  CheckCircleRounded,
  ErrorOutline,
  CopyAllTwoTone,
} from '@mui/icons-material';
import subscriptionsApi from '../api/subscriptions.api';
import OfferQuoteList from '../components/OfferQuoteList';
import OfferTransactionEvents from '../components/OfferTransactionEvents';
import OfferPaymentResponse from '../components/OfferPaymentResponse';
import { generateStatusData } from '../utils/offerHelper';
import JSONViewer from '../components/JSONViewer';
import EmailListOnOffer from '../components/EmailListOnOffer';

function OfferDetails() {
  const [offer, setOfferData] = useState({});
  const dispatch = useDispatch();
  const { id } = useParams();
  const [offerStatusData, setOfferStatusData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const permissions = useSelector((state) => state.permissions.permissions);

  useEffect(() => {
    const loadData = async () => {
      const result = await subscriptionsApi.getOfferById(dispatch, id);
      setOfferData({ ...result.data });
      const statusData = generateStatusData(
        process.env.REACT_APP_DEFAULT_REGION,
        result.data.addRenewalJourneyDataToOffers
      );
      setOfferStatusData(statusData || []);
      setIsLoading(false);
    };
    loadData();
  }, []);

  function getStatusIcon(statusValue, title) {
    const status = statusValue ? statusValue.toLowerCase() : null;
    const iconColor =
      status === 'successed' ? 'green' : status === 'errored' ? 'red' : 'gray';

    return (
      <Tooltip title={title} arrow>
        <div>
          {status ? (
            status === 'successed' ? (
              <CheckCircleRounded style={{ fill: iconColor }} />
            ) : (
              <CancelRounded style={{ fill: iconColor }} />
            )
          ) : (
            <ErrorOutline style={{ fill: iconColor }} />
          )}
        </div>
      </Tooltip>
    );
  }

  if (isLoading) {
    return (
      <div>
        <div className="row my-2">
          <h3>Offer Details</h3>
        </div>
        <LinearProgress style={{ height: '1rem' }} color="secondary" />
      </div>
    );
  }
  return (
    <div
      style={{
        display: permissions.OFFERS_DATA.READ ? 'block' : 'none',
      }}
    >
      <Card variant="outlined" className="p-2">
        <h5 style={{ textAlign: 'left' }}>
          Offer Details
          <JSONViewer title="Offer Data" jsonData={offer} />
        </h5>
        <Row>
          <Col>
            <p className="card-content-align">
              <span>Offer Status</span>
              <br />
              <b>{offer.offerStatus}</b>
            </p>
          </Col>
          <Col>
            <p className="card-content-align">
              <span>Last Name</span>
              <br />
              <b>
                {offer.offerCustomer.lastName}
                <IconButton
                  Tooltip="Click to Copy!"
                  onClick={() =>
                    navigator.clipboard.writeText(offer.offerCustomer.lastName)
                  }
                >
                  <CopyAllTwoTone />
                </IconButton>
              </b>
            </p>
          </Col>
          <Col>
            <p className="card-content-align">
              <span>Postal Code</span>
              <br />
              <b>
                {offer.offerCustomer.postalCode}
                <IconButton
                  Tooltip="Click to Copy!"
                  onClick={() =>
                    navigator.clipboard.writeText(
                      offer.offerCustomer.postalCode
                    )
                  }
                >
                  <CopyAllTwoTone />
                </IconButton>
              </b>
            </p>
          </Col>
          <Col>
            <p className="card-content-align">
              <span>
                {process.env.REACT_APP_DEFAULT_REGION === 'UK'
                  ? 'Registration Number'
                  : 'VIN'}
              </span>
              <br />
              <b>
                {process.env.REACT_APP_DEFAULT_REGION === 'UK'
                  ? offer.offerVehicle.registrationNumber
                  : offer.offerVehicle.vin}
                <IconButton
                  Tooltip="Click to Copy!"
                  onClick={() =>
                    navigator.clipboard.writeText(
                      process.env.REACT_APP_DEFAULT_REGION === 'UK'
                        ? offer.offerVehicle.registrationNumber
                        : offer.offerVehicle.vin
                    )
                  }
                >
                  <CopyAllTwoTone />
                </IconButton>
              </b>
            </p>
          </Col>
          <span />
        </Row>
      </Card>
      <br />
      <Card variant="elevation" className="p-2">
        <h5 style={{ textAlign: 'left' }}>
          Offer Journey
          <JSONViewer
            title="Journey Log"
            jsonData={offer.addRenewalJourneyDataToOffers}
          />
        </h5>

        <Row>
          {offerStatusData.map(({ status, title }) => (
            <Col>
              {getStatusIcon(status, title)}
              <br />
              <span style={{ fontSize: 14 }}>{title}</span>
            </Col>
          ))}
        </Row>
      </Card>
      <br />
      <Card variant="outlined" className="p-2">
        <h5 style={{ textAlign: 'left' }}>Offer Details</h5>
        <Row className="t-4">
          <Col>
            <p className="card-content-align">
              <span>Offer Id</span>
              <br />
              <b>{offer.offerId}</b>
            </p>
          </Col>
          <Col>
            <p className="card-content-align">
              <span>Invitation Offer Id</span>
              <br />
              <b>{offer.invitationOfferId}</b>
            </p>
          </Col>
          <Col>
            <p className="card-content-align">
              <span>Email</span>
              <br />
              <b>{offer.offerCustomer.email}</b>
            </p>
          </Col>
          <Col>
            <p className="card-content-align">
              <span>Offer End Date</span>
              <br />
              <b>{offer.offerEndDate}</b>
            </p>
          </Col>
        </Row>
        <br />
        <Row className="t-4">
          <Col>
            <p className="card-content-align">
              <span>New Contract Start Date</span>
              <br />
              <b>{offer.contractStartDate}</b>
            </p>
          </Col>
          <Col>
            <p className="card-content-align">
              <span>Vehicle</span>
              <br />
              <b>
                {offer.offerVehicle.year}
                <br />
                {offer.offerVehicle.make}
                <br />
                {offer.offerVehicle.model}
              </b>
            </p>
          </Col>
          <Col>
            <p className="card-content-align">
              <span>Odometer</span>
              <br />
              <b>
                Starting - {offer.offerVehicle.currentOdometer || 'N/A'}
                <br />
                Estimated - {offer.offerVehicle.calculatedOdometer || 'N/A'}
              </b>
            </p>
          </Col>
          <Col>
            <p className="card-content-align">
              <span>Offer URL</span>
              <br />
              <b>
                <a href={offer.offerUrl} target="_blank" rel="noreferrer">
                  Open
                </a>
                <IconButton
                  Tooltip="Click to Copy!"
                  onClick={() => navigator.clipboard.writeText(offer.offerUrl)}
                >
                  <CopyAllTwoTone />
                </IconButton>
              </b>
            </p>
          </Col>
        </Row>
        <br />
        <Row
          className="t-4"
          style={{
            display:
              process.env.REACT_APP_DEFAULT_REGION === 'UK' ? '' : 'none',
          }}
        >
          <Col>
            <p className="card-content-align">
              <span>Offer Insured</span>
              <br />
              <b>{offer.offerInsured ? 'Insured' : 'Non-Insured'}</b>
            </p>
          </Col>
          <Col>
            <p className="card-content-align">
              <span>Client</span>
              <br />
              <b>{offer.offeredProduct.clientCompanyName}</b>
            </p>
          </Col>
          <Col>
            <p className="card-content-align">
              <span>Installment Details</span>
              <br />
              <b>Annual - {offer.offerAnnualAmount}</b>
              <br />
              <b>Monthly - {offer.offerMonthlyInstalmentAmount}</b>
              <br />
              <b>No. Of Installments - {offer.offerNumberOfInstallments}</b>
            </p>
          </Col>
          <Col>
            <p className="card-content-align">
              <span>Previous Plan Total</span>
              <br />
              <b>{offer.previousPlanTotalPayment}</b>
            </p>
          </Col>
        </Row>
        <br />
        <Row className="t-4">
          <Col>
            <p className="card-content-align">
              <span>Customer</span>
              <br />
              <b>
                {offer.offerCustomer.firstName} {offer.offerCustomer.lastName}
                <br />
                {offer.offerCustomer.addressLine1},{' '}
                {offer.offerCustomer.addressLine2},{' '}
                {offer.offerCustomer.stateCode},{' '}
                {offer.offerCustomer.postalCode},{' '}
                {offer.offerCustomer.countryCode}
                <br />
                {offer.offerCustomer.phoneNumber}
                <br />
                {offer.offerCustomer.email}
              </b>
            </p>
          </Col>
          <Col>
            <p className="card-content-align">
              <span>Expiring Contract Dealer</span>
              <br />
              <b>
                {offer.offerExpiringProduct.dealerId}
                <br />
                {offer.offerExpiringProduct.dealerName}
                <br />
                {offer.offerExpiringProduct.dealerAddress}
                <br />
                {offer.offerExpiringProduct.dealerState},{' '}
                {offer.offerExpiringProduct.dealerZipCode}
              </b>
            </p>
          </Col>
          <Col>
            <p className="card-content-align">
              <span>Expiring Contract</span>
              <br />
              <b>
                {offer.offerExpiringProduct.contractNumber}
                <br />
                {offer.offerExpiringProduct.productName}
                <br />
                {offer.offerExpiringProduct.productCoverage}
                <br />
                {offer.offerExpiringProduct.currentContractStartDate
                  ? `${offer.offerExpiringProduct.currentContractStartDate} - `
                  : ''}
                {offer.offerExpiringProduct.expiryDate}
              </b>
            </p>
          </Col>
          <Col>
            <p className="card-content-align">
              <span>Offered Contract</span>
              <br />
              <b>
                {offer.offeredProduct.productName}
                <br />
                {offer.offeredProduct.productDetailType}
                <br />
                {offer.offeredProduct.productClass}
                <br />
                {offer.offeredProduct.dealerProductCode}
              </b>
            </p>
          </Col>
        </Row>
      </Card>
      <br />
      <Card
        className="p-2"
        style={{
          display: offer.activatedOffer ? '' : 'none',
        }}
      >
        <h5 style={{ textAlign: 'left' }}>Redeem Details</h5>
        <Row className="t-4">
          <Col>
            <p className="card-content-align">
              <span>Updated Customer</span>
              <br />
              <b>
                {`${offer.offeredProduct.firstName} ${offer.offeredProduct.lastName}`}
                <br />
                {`${offer.offeredProduct.addressLine1} ${
                  offer.offeredProduct.city
                    ? offer.offeredProduct.city
                    : offer.offeredProduct.addressLine2
                }`}
                , {offer.offeredProduct.stateCode},{' '}
                {offer.offeredProduct.postalCode}
                <br />
                {offer.offeredProduct.phoneNumber}
                <br />
                {offer.offeredProduct.email}
              </b>
            </p>
          </Col>
          <Col>
            <p className="card-content-align">
              <span>Purchase Details</span>
              <br />
              <b>
                {offer.activatedOffer
                  ? moment(offer.activatedOffer.createdAt).format(
                      process.env.REACT_APP_DATE_TIME_FORMAT
                    )
                  : ''}
                <br />
                {offer.activatedOffer
                  ? `${offer.activatedOffer.cardBrand} ${offer.activatedOffer.cardNumber}`
                  : ''}
                <br />
                {offer.activatedOffer
                  ? `${offer.activatedOffer.initialAmount} ${offer.offerCustomer.currencyIsoCode}`
                  : ''}
                <br />
                {offer.activatedOffer
                  ? `${offer.activatedOffer.paymentOption}`
                  : ''}
              </b>
            </p>
          </Col>
          <Col>
            <p className="card-content-align">
              <span>Contract #</span>
              <br />
              <b>
                {offer.activatedOffer
                  ? `${
                      offer.activatedOffer.externalContractNumberL ||
                      offer.activatedOffer.externalContractNumber ||
                      'N/A'
                    }`
                  : ''}
                <br />
                <br />
                {offer.activatedOffer
                  ? offer.activatedOffer.contractNumber
                  : ''}
              </b>
            </p>
          </Col>
          <Col>
            <p className="card-content-align">
              <span>Transaction Reference</span>
              <br />
              <b>
                {offer.activatedOffer
                  ? `${offer.activatedOffer.rootTransactionReference}`
                  : ''}
              </b>
            </p>
          </Col>
        </Row>
        <br />
      </Card>
      <br />
      <div className="row my-2 ">
        <EmailListOnOffer
          viewHeight="40vh"
          offerId={id}
          contractNumber={
            offer.activatedOffer ? offer.activatedOffer.contractNumber : null
          }
          emailAddress={offer.offerCustomer.email}
        />
      </div>
      <br />
      <div className="row my-2 ">
        <OfferQuoteList offerId={id} />
      </div>
      <br />
      <div className="row my-2 ">
        <OfferTransactionEvents offerId={id} />
      </div>
      <br />
      {process.env.REACT_APP_DEFAULT_REGION === 'US' && offer.activatedOffer ? (
        <div className="row my-2 ">
          <OfferPaymentResponse
            contractNumber={offer.activatedOffer.contractNumber}
          />
        </div>
      ) : (
        <br />
      )}
    </div>
  );
}

export default OfferDetails;
