/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Badge,
  Button,
  Checkbox,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  ChatBubble,
  EditOutlined,
  KeyboardArrowDown,
} from '@mui/icons-material';
import { grayBg } from '../../assets/images';
import { NA } from '../../constants';
import dcrApi from '../../api/dcr.api';

const processVal = (val) => {
  return val && val != null ? val : NA;
};
const TryCatchImage = ({ alt, src, className, handleImageLoadError }) => {
  function addDefaultSrc(ev) {
    // eslint-disable-next-line no-param-reassign
    ev.target.src = grayBg;
    if (handleImageLoadError) {
      handleImageLoadError(alt);
    }
  }
  try {
    return (
      <img
        onError={addDefaultSrc}
        alt={alt}
        className={className}
        src={src || grayBg}
        loading="lazy"
      />
    );
  } catch (error) {
    return <img alt={alt} className={className} src={grayBg} />;
  }
};
function MatchAndSetString1({ string1, string2 }) {
  if (string1 && string2 && string1.toLowerCase() !== string2.toLowerCase()) {
    return <span className="error-string">{` ${string1}`}</span>;
  }
  return <span>{string1}</span>;
}

function DcrComponent(props) {
  const { item, handleEditClick, handleNotesClick, updateData, selected } =
    props;
  const [disableActions, setDisableActions] = useState(false);
  const dcrComponentRef = useRef(null);
  const [imageVerfied, setImageVerified] = useState({
    logo:
      item.googleBrandLogoVerified != null
        ? item.googleBrandLogoVerified
        : false,
    shop:
      item.googleShopPictureVerified != null
        ? item.googleShopPictureVerified
        : false,
    store:
      item.googleStorePictureVerified != null
        ? item.googleStorePictureVerified
        : false,
  });
  const approveRejectGoogleData = async (
    status,
    googlePhoneNumberVerified,
    googleWebsiteVerified,
    googleAddressVerified,
    pgaWhiteLabel,
    pgaMap
  ) => {
    const reqParam = {
      accountNumber: item.accountNumber,
      status,
      googleData: {
        googleName: item.googleName,
        googleStreet: item.googleStreet,
        googleCity: item.googleCity,
        googleState: item.googleState,
        googlePostalCode: item.googlePostalCode,
        googleCountry: item.googleCountry,
        googleWebsite: item.googleWebsite,
        googlePhoneNumber: item.googlePhoneNumber,
        googleRating: item.googleRating,
        googleNumberOfRatings: item.googleNumberOfRatings,
        googleLocationLatitude: item.googleLocationLatitude,
        googleLocationLongitude: item.googleLocationLongitude,
        googleBrandLogoUrl: item.googleBrandLogoUrl,
        googleStorePictureUrl: item.googleStorePictureUrl,
        googleShopPictureUrl: item.googleShopPictureUrl,
        googleOpeningHours: item.googleOpeningHours,
        googleStorePictureVerified: imageVerfied.store,
        googleShopPictureVerified: imageVerfied.shop,
        googleBrandLogoVerified: imageVerfied.logo,
        googlePhoneNumberVerified,
        googleWebsiteVerified,
        googleAddressVerified,
        pgaWhiteLabel,
        pgaMap,
      },
    };
    setDisableActions(true);
    const res = await dcrApi.approveRejectGoogleData(reqParam);
    setDisableActions(false);
    if (res) {
      updateData(res);
    }
  };

  useEffect(() => {
    if (
      selected &&
      dcrComponentRef &&
      dcrComponentRef.current &&
      dcrComponentRef.current.scrollIntoView
    ) {
      dcrComponentRef.current.scrollIntoView();
    }
  }, [selected]);

  const handleImageVerified = (source, value = true) => {
    const tempData = { ...imageVerfied };
    tempData[source] = value;
    if (item.status === 'Not Reviewed') {
      setImageVerified(tempData);
    }
  };

  return (
    <>
      <Grid container justify="flex-start" ref={dcrComponentRef}>
        <Grid item xs={2}>
          <DealerColumn item={item} />
        </Grid>
        <Grid item xs={5}>
          <GlowDataColumn
            item={item}
            handleImageVerified={handleImageVerified}
          />
        </Grid>
        <Grid item xs={5}>
          <GoogleDataColumn
            approveRejectGoogleData={approveRejectGoogleData}
            handleEditClick={handleEditClick}
            handleNotesClick={handleNotesClick}
            item={item}
            disableActions={disableActions}
            imageVerfied={imageVerfied}
            handleImageVerified={handleImageVerified}
          />
        </Grid>
      </Grid>
      <hr />
    </>
  );
}

export default DcrComponent;

function HoursMenu({ googleOpeningHours }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
      >
        <KeyboardArrowDown />
      </IconButton>
      <Menu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {googleOpeningHours &&
          googleOpeningHours.map((d) => (
            <MenuItem key={d.day} onClick={handleClose} disableRipple>
              {` ${d.day} ${d.open} - ${d.close}`}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
}

function DealerColumn({ item }) {
  return (
    <div className="dcr-dealer-detail-column-body">
      <div className="dcr-dealer-detail-col-sm">
        <Typography variant="h6">{item.name}</Typography>
        <Grid container spacing={2} justifyContent="flex-start">
          <Grid item xs={7}>
            <Typography style={{ color: '#757575' }} variant="caption">
              Client Status
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="body1">
              {item.clientStatus ? item.clientStatus : 'Active'}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div className="dcr-dealer-detail-col-lg">
        <Grid container rowSpacing={1} justifyContent="flex-start">
          <Grid item xs={7}>
            <Typography style={{ color: '#757575' }} variant="caption">
              RDG Number
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="body1">{item.rdgNumber}</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography style={{ color: '#757575' }} variant="caption">
              Account Number
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="body1">{item.accountNumber}</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography style={{ color: '#757575' }} variant="caption">
              Account Number Type
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="body1">{item.accountNumberType}</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography style={{ color: '#757575' }} variant="caption">
              Distribution List
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="body1">{item.distributionList}</Typography>
          </Grid>
        </Grid>
        <div className="billing-address">
          <div className="billing-address-heading">Billing Address</div>
          <div className="billing-address-body">
            {`${processVal(item.billingStreet)}, `}
            <br />
            {`${processVal(item.billingCity)}, 
            ${processVal(item.billingState)}
            ${processVal(item.billingPostalCode)}`}
          </div>
        </div>
      </div>
      <div className="dcr-dealer-detail-col-sm" />
    </div>
  );
}
function GlowDataColumn({ item, handleImageVerified }) {
  return (
    <div className="dcr-dealer-detail-column-body margin-horizontal">
      <div className="dcr-dealer-detail-col-sm margin-horizontal" />
      <div className="dcr-dealer-detail-col-lg margin-horizontal">
        <div className="dcr-detail-item-text">
          <div className="dcr-detail-item-title">Phone:</div>
          <div className="dcr-detail-item-body">{processVal(item.phone)}</div>
        </div>
        <div className="dcr-detail-item-text">
          <div className="dcr-detail-item-title">Website:</div>
          <div className="dcr-detail-item-body">{processVal(item.website)}</div>
        </div>
        <div className="dcr-detail-item-text">
          <div className="dcr-detail-item-title">Display Address:</div>
          <div className="dcr-detail-item-body">
            {item.shippingStreet}
            <br />
            {`${item.shippingCity} ${item.shippingCity === '' ? '' : ','}
            ${item.shippingState} 
            ${item.shippingPostalCode}`}
          </div>
        </div>
      </div>
      <div className="dcr-dealer-detail-col-sm">
        <Grid container justify="flex-start">
          <Grid item xs={4}>
            <div className="w-100" style={{ textAlign: 'center' }}>
              <TryCatchImage
                alt="logo"
                className="dcr-image"
                src={item.dealerLogoUrl}
                handleImageLoadError={handleImageVerified}
              />
              <div>
                <p>Logo</p>
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="w-100" style={{ textAlign: 'center' }}>
              <TryCatchImage
                alt="store"
                className="dcr-image"
                src={item.dealerStorePictureUrl}
                handleImageLoadError={handleImageVerified}
              />
              <div>
                <p>Store</p>
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="w-100" style={{ textAlign: 'center' }}>
              <TryCatchImage
                alt="shop"
                className="dcr-image"
                src={item.dealerShopPictureUrl}
                handleImageLoadError={handleImageVerified}
              />
              <div>
                <p>Shop</p>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

function GoogleDataColumn({
  item,
  handleEditClick,
  handleNotesClick,
  approveRejectGoogleData,
  disableActions,
  imageVerfied,
  handleImageVerified,
}) {
  const { status } = item;
  const ipgaWhiteLabel = useRef(
    item.pgaWhiteLabel && item.pgaWhiteLabel != null
      ? item.pgaWhiteLabel
      : item.pgaOptIn
  );
  const ipgaMap = useRef(item.pgaMap != null ? item.pgaMap : true);
  const igooglePhoneNumberVerified = useRef(
    item.googlePhoneNumberVerified != null
      ? item.googlePhoneNumberVerified
      : true
  );
  const igoogleWebsiteVerified = useRef(
    item.googleWebsiteVerified != null ? item.googleWebsiteVerified : true
  );
  const igoogleAddressVerified = useRef(
    item.googleAddressVerified != null ? item.googleAddressVerified : true
  );

  const handleUpldateStatus = (s) => {
    approveRejectGoogleData(
      s,
      igooglePhoneNumberVerified.current,
      igoogleWebsiteVerified.current,
      igoogleAddressVerified.current,
      ipgaWhiteLabel.current,
      ipgaMap.current
    );
  };

  const permissions = useSelector((state) => state.permissions.permissions);

  const shouldDisable = () => {
    if (
      permissions.DEALER_PROFILES.READ === false &&
      permissions.DEALER_PROFILES.WRITE === false
    ) {
      return true;
    }
    return false;
  };

  function StatusChangeButton() {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = (newStatus) => {
      if (newStatus != null) {
        handleUpldateStatus(newStatus);
      }
      setAnchorEl(null);
    };

    return (
      <div className="status-btn-container">
        <Button
          style={{
            color: '#fff',
            backgroundColor:
              status === 'Not Reviewed'
                ? '#007bff'
                : status === 'Approved'
                ? '#28a745'
                : '#dc3545',
          }}
          onClick={handleClick}
          endIcon={<KeyboardArrowDown />}
        >
          {status}
        </Button>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={() => handleClose()}
        >
          {status !== 'Not Reviewed' && (
            <MenuItem
              style={{ width: 150 }}
              onClick={() => handleClose('Not Reviewed')}
            >
              Not Reviewed
            </MenuItem>
          )}
          {status !== 'Approved' && (
            <MenuItem
              style={{ width: 150 }}
              onClick={() => handleClose('Approved')}
            >
              Approved
            </MenuItem>
          )}
          {status !== 'Rejected' && (
            <MenuItem
              style={{ width: 150 }}
              onClick={() => handleClose('Rejected')}
            >
              Rejected
            </MenuItem>
          )}
        </Menu>
      </div>
    );
  }

  return (
    <div className="dcr-dealer-detail-column-body margin-horizontal ">
      <div className="d-flex margin-horizontal">
        <div className="margin-horizontal">
          <div className="dcr-white-label margin-horizontal">
            <Checkbox
              style={{ padding: 1 }}
              defaultChecked={ipgaWhiteLabel.current}
              color="primary"
              disabled
              readOnly
              onChange={(e) => {
                ipgaWhiteLabel.current = e.target.checked;
              }}
            />
            <span>PGA White Label</span>
          </div>
          <div className="dcr-white-label margin-horizontal">
            <Checkbox
              disabled={item.status !== 'Not Reviewed'}
              style={{ padding: 1 }}
              defaultChecked={ipgaMap.current}
              color="primary"
              readOnly
              onChange={(e) => {
                ipgaMap.current = e.target.checked;
              }}
            />
            <span> PGA Map </span>
          </div>
        </div>
        <div className="dcr-dealer-detail-col-sm">
          <div
            className="dcr-btn-icons"
            style={
              shouldDisable() ? { pointerEvents: 'none', opacity: '0.4' } : {}
            }
          >
            <StatusChangeButton />
            {/* <HoverableImage
              disabled={disableActions}
              className="btn-icons"
              style={{ marginRight: 0 }}
              src={edit}
              hoverSrc={edit}
              onClick={() => handleEditClick(item)}
            /> */}
            <Tooltip title="Edit">
              <IconButton
                fontSize="large"
                color="primary"
                component="label"
                disabled={disableActions}
                onClick={() => handleEditClick(item)}
              >
                <EditOutlined />
              </IconButton>
            </Tooltip>
            <Tooltip title="Note List">
              <Badge badgeContent={item.dealerNotesCount} color="secondary">
                <IconButton
                  fontSize="large"
                  color="primary"
                  component="lable"
                  onClick={() => handleNotesClick(item)}
                >
                  <ChatBubble />
                </IconButton>
              </Badge>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="dcr-dealer-detail-col-lg margin-horizontal">
        <div className="dcr-detail-item-text">
          <div style={{ height: 42, width: 42 }} />
          <div className="dcr-detail-item-title">Name:</div>
          <div className="dcr-detail-item-body">
            <b>{item.googleName}</b>
          </div>
        </div>
        <div className="dcr-detail-item-text">
          <Checkbox
            disabled={item.status !== 'Not Reviewed'}
            defaultChecked={igooglePhoneNumberVerified.current}
            color="primary"
            onChange={(e) => {
              igooglePhoneNumberVerified.current = e.target.checked;
            }}
          />
          <div className="dcr-detail-item-title">Phone:</div>
          <div className="dcr-detail-item-body">
            <MatchAndSetString1
              string1={processVal(item.googlePhoneNumber)}
              string2={processVal(item.phone)}
            />
          </div>
        </div>
        <div className="dcr-detail-item-text">
          <Checkbox
            disabled={item.status !== 'Not Reviewed'}
            defaultChecked={igoogleWebsiteVerified.current}
            color="primary"
            onChange={(e) => {
              igoogleWebsiteVerified.current = e.target.checked;
            }}
          />
          <div className="dcr-detail-item-title">Website:</div>
          <div className="dcr-detail-item-body">
            <MatchAndSetString1
              string1={processVal(
                item.googleWebsite && item.googleWebsite.endsWith('/')
                  ? item.googleWebsite.slice(0, -1)
                  : item.googleWebsite
              )}
              string2={processVal(item.website)}
            />
          </div>
        </div>
        <div className="dcr-detail-item-text">
          <Checkbox
            disabled={item.status !== 'Not Reviewed'}
            defaultChecked={igoogleAddressVerified.current}
            color="primary"
            onChange={(e) => {
              igoogleAddressVerified.current = e.target.checked;
            }}
          />
          <div className="dcr-detail-item-title">Address:</div>
          <div className="dcr-detail-item-body">
            <MatchAndSetString1
              string1={processVal(item.googleStreet)}
              string2={processVal(item.shippingStreet)}
            />
            <br />
            <MatchAndSetString1
              string1={processVal(item.googleCity)}
              string2={processVal(item.shippingCity)}
            />
            ,
            <MatchAndSetString1
              string1={processVal(item.googleState)}
              string2={processVal(item.shippingState)}
            />
            ,
            <MatchAndSetString1
              string1={processVal(item.googlePostalCode)}
              string2={processVal(item.shippingPostalCode)}
            />
          </div>
        </div>
        {item.googleOpeningHours && item.googleOpeningHours.length > 1 && (
          <div className="dcr-detail-item-text">
            <div style={{ height: 42, width: 42 }} />
            <div className="dcr-detail-item-title">Hours:</div>
            <div className="dcr-detail-item-body d-flex align-items-center">
              {item.googleOpeningHours[0].day}
              {`${item.googleOpeningHours[0].open} -`}
              {item.googleOpeningHours[0].close}
              <HoursMenu googleOpeningHours={item.googleOpeningHours} />
            </div>
          </div>
        )}
      </div>
      <div className="dcr-dealer-detail-col-sm">
        <Grid container justify="flex-start">
          <Grid item xs={4} style={{ textAlign: 'center' }}>
            <TryCatchImage
              alt="Google Logo"
              className="dcr-image"
              src={item.googleBrandLogoUrl}
            />
            <div>
              <span>Logo</span>
              <Checkbox
                disabled={item.status !== 'Not Reviewed'}
                color="primary"
                checked={imageVerfied.logo}
                onChange={(e) => {
                  handleImageVerified('logo', e.target.checked);
                }}
              />
            </div>
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'center' }}>
            <TryCatchImage
              alt=""
              className="dcr-image"
              src={
                item.googleStorePictureUrl &&
                item.googleStorePictureUrl.startsWith('/google') &&
                item.googlePhotos.length > 0
                  ? item.googlePhotos[0]
                  : item.googleStorePictureUrl
              }
            />
            <div>
              <span>Store</span>
              <Checkbox
                disabled={item.status !== 'Not Reviewed'}
                color="primary"
                checked={imageVerfied.store}
                onChange={(e) => {
                  handleImageVerified('store', e.target.checked);
                }}
              />
            </div>
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'center' }}>
            <TryCatchImage
              alt=""
              className="dcr-image"
              src={
                item.googleShopPictureUrl &&
                item.googleShopPictureUrl.startsWith('/google') &&
                item.googlePhotos.length > 1
                  ? item.googlePhotos[1]
                  : item.googleShopPictureUrl
              }
            />
            <div>
              <span>Shop</span>
              <Checkbox
                disabled={item.status !== 'Not Reviewed'}
                color="primary"
                checked={imageVerfied.shop}
                onChange={(e) => {
                  handleImageVerified('shop', e.target.checked);
                }}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
