import React, { useEffect, useState } from 'react';
import { LinearProgress, Button } from '@mui/material';
import { styled } from '@mui/system';
import { useDispatch } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { addHours, addMonths, addDays, format } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import FilterModal from '../components/Filter';
import reportsApi from '../api/report.api';

const useStyles = styled({
  table: {
    minWidth: 650,
  },
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

function Aggregatedetail({ data }) {
  const classes = useStyles();
  return (
    <Card>
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          {data._id ? data._id : data._id === null ? 'null' : data._id}
        </Typography>
        <Typography variant="body2" component="p">
          {data.count}
        </Typography>
      </CardContent>
    </Card>
  );
}

function InvitationStats(props) {
  const { lastDayFilter } = props;
  const [applyLastDayFilter, setApplyLastDayFilter] = useState(
    lastDayFilter || false
  );
  const [data, setData] = useState([]);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [appliedFilter, setAppliedFilter] = useState({});
  const [filterDisplay, setFilterDisplay] = useState('');
  const [filterBeforeDate, setFilterBeforeDate] = useState(null);
  const [filterAfterDate, setFilterAfterDate] = useState(null);
  const [applyQuickFilter, setApplyQuickFilter] = useState(false);
  const dispatch = useDispatch();

  const saveFilters = () => {
    const newFilter = {};
    const displays = [];
    const filterStartDate = new Date(filterAfterDate);
    const filterEndDate = new Date(filterBeforeDate);
    let dateDisplay = '';
    if (filterAfterDate) {
      newFilter.createdAt = { $gte: `${filterStartDate}` };
      dateDisplay = `Dates: ${format(filterStartDate, 'MM/dd/yyyy')}`;
    }
    if (filterBeforeDate) {
      newFilter.createdAt = {
        ...newFilter.createdAt,
        $lte: `${filterEndDate}`,
      };
      if (!dateDisplay) {
        dateDisplay = `Dates: -${format(filterEndDate, 'MM/dd/yyyy')}`;
      } else {
        dateDisplay = `${dateDisplay}-${format(filterEndDate, 'MM/dd/yyyy')}`;
      }
    }
    if (dateDisplay) {
      displays.push(dateDisplay);
    }

    setAppliedFilter(newFilter);
    setFilterDisplay(displays.join(' '));
  };

  useEffect(() => {
    if (applyQuickFilter && !!filterAfterDate) {
      setApplyQuickFilter(false);
      saveFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyQuickFilter, filterAfterDate]);

  const setLastDayFilter = () => {
    setFilterAfterDate(addHours(new Date(), -24));
    setApplyQuickFilter(true);
  };

  const setLastMonthFilter = () => {
    setFilterAfterDate(addMonths(new Date(), -1));
    setApplyQuickFilter(true);
  };

  const setLastSevenDays = () => {
    setFilterAfterDate(addDays(new Date(), -7));
    setApplyQuickFilter(true);
  };

  const resetFilters = () => {
    setFilterAfterDate(null);
    setFilterBeforeDate(null);
    setFilterDisplay('');
    setAppliedFilter({});
    if (applyLastDayFilter) {
      setApplyLastDayFilter(false);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      const params = {
        filter: JSON.stringify(appliedFilter),
      };
      const response = await reportsApi.getInvitationsStatus(params, dispatch);
      const records = [];
      if (response && response.data) {
        Object.keys(response.data).forEach((item) => {
          records.push([{ _id: item }, ...response.data[item]]);
        });
      }
      if (response && response.totalCounts) {
        records.push(
          Object.entries(response.totalCounts).reduce(
            (acc, [key, value]) => {
              acc.push({
                _id: key,
                count: value && value.length ? value[0].count : 0,
              });
              return acc;
            },
            [{ _id: 'Total Counts' }]
          )
        );
      }
      setData(records);
      setIsLoading(false);
    };
    if (
      applyLastDayFilter &&
      appliedFilter &&
      Object.keys(appliedFilter).length === 0
    ) {
      setLastDayFilter();
    } else {
      setIsLoading(true);
      loadData();
    }
  }, [appliedFilter, dispatch, applyLastDayFilter]);

  return (
    <div className="col-sm-12">
      <div>
        <h2 className="d-flex align-content-center align-items-center mb-0">
          <span className="mr-2">Invitations Status</span>
          <div className="flex-grow-1" />
          {appliedFilter && !Object.keys(appliedFilter).length && (
            <>
              <Button color="primary" onClick={setLastDayFilter}>
                Last 24 Hours
              </Button>
              <Button color="primary" onClick={setLastSevenDays}>
                Last 7 Days
              </Button>
              <Button color="primary" onClick={setLastMonthFilter}>
                Last month
              </Button>
            </>
          )}
          <FilterModal
            currentFilters={appliedFilter}
            onSave={saveFilters}
            itemName="Reports"
            resetFilters={resetFilters}
            filterDisplay={filterDisplay}
          >
            <div style={{ marginBottom: '16px', marginTop: '10px' }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  utils={DateFnsUtils}
                  label="After Date (including)"
                  variant="inline"
                  className="mr-2"
                  fullWidth
                  id="after-date-filter"
                  value={filterAfterDate}
                  onChange={setFilterAfterDate}
                  autoOk="true"
                />
              </LocalizationProvider>
            </div>
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  utils={DateFnsUtils}
                  label="Before Date (including)"
                  variant="inline"
                  className="mr-2"
                  fullWidth
                  id="before-date-filter"
                  value={filterBeforeDate}
                  onChange={setFilterBeforeDate}
                  autoOk="true"
                />
              </LocalizationProvider>
            </div>
          </FilterModal>
        </h2>
      </div>

      <div style={{ width: '100%' }}>
        {isLoading ? (
          <LinearProgress style={{ height: '1rem' }} color="secondary" />
        ) : (
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableBody>
                {data.map((row, index) => (
                  <TableRow key={index}>
                    {row.map((item, rowItr) => {
                      return (
                        <TableCell key={data.length * 10 + rowItr}>
                          <Aggregatedetail data={item} />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  );
}

export default InvitationStats;
