import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CheckCircleRounded as CheckCircleRoundedIcon,
  CancelRounded as CancelRoundedIcon,
} from '@mui/icons-material';
import {
  LinearProgress,
  Table,
  Paper,
  TableRow,
  TableHead,
  TableCell,
  TableContainer,
  TableBody,
  Divider,
} from '@mui/material';
import SubscriptionClearCache from '../components/SubscriptionClearCache';
import subscriptionApi from '../api/subscriptions.api';

function Status() {
  const permissionss = useSelector((state) =>
    typeof state.permissions.permissions !== typeof undefined
      ? state.permissions.permissions
      : null
  );
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [api, setApi] = useState(false);
  const [dataBase, setDataBase] = useState(false);
  // const [apiVersion, setApiVersion] = useState('');
  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      const response = await subscriptionApi.getSubscriptionApiStatus(dispatch);
      setApi(response.api);
      setDataBase(response.dataBase);
      // const responseVersion = await subscriptionApi.getSubscriptionVersion(dispatch);
      // setApiVersion(responseVersion.apiVersion);
      // setApiVersion('Subscription v1');
      setIsLoading(false);
    };
    load();
  }, [dispatch]);

  const getStatusIcon = (value) => {
    return value ? (
      <CheckCircleRoundedIcon style={{ fill: 'green' }} />
    ) : (
      <CancelRoundedIcon style={{ fill: 'red' }} />
    );
  };

  return (
    <div>
      <div className="d-flex">
        <h2>Subscription Status</h2>
      </div>
      {isLoading ? (
        <LinearProgress style={{ height: '1rem' }} color="secondary" />
      ) : (
        <TableContainer component={Paper}>
          <Table size="small" padding="none" style={{ tableLayout: 'fixed' }}>
            <TableHead className="bg-dark">
              <TableRow>
                <TableCell align="center" className="p-2">
                  <span className="text-white">Status Name</span>
                </TableCell>
                <TableCell align="center" className="p-2">
                  <span className="text-white">Value</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* <TableRow>
                <TableCell align="center">Subscription APIs Version</TableCell>
                <TableCell align="center">{apiVersion}</TableCell>
              </TableRow> */}
              <TableRow>
                <TableCell align="center">APIs Health</TableCell>
                <TableCell align="center">{getStatusIcon(api)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">MySQL DB Connection Health</TableCell>
                <TableCell align="center">{getStatusIcon(dataBase)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {permissionss.STATUS.WRITE && (
        <>
          <Divider className="my-3" />
          <SubscriptionClearCache />
          <Divider className="my-3" />
        </>
      )}
    </div>
  );
}

export default Status;
