import React from 'react';
import './App.css';
import { ThemeProvider, createTheme } from '@mui/material';
import { blue } from '@mui/material/colors';
import OktaAuth, { toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, Security } from '@okta/okta-react';

import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
import AppIntermediate from './components/AppIntermediate';

const HTTP = process.env.REACT_APP_ENV === 'local' ? 'http' : 'https';
const authScopes = process.env.REACT_APP_OKTA_SCOPES.split(' ');

const theme = createTheme({
  palette: {
    primary: blue,
  },
});
const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_OKTA_URL,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: `${HTTP}://${window.location.host}/implicit/callback`,
  pkce: true,
  scopes: authScopes,
});

function App() {
  const navigate = useNavigate();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin), {
      replace: true,
    });
  };

  return (
    <div className="App">
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <Routes>
          <Route path="/implicit/callback" element={<LoginCallback />} />
          <Route path="*" element={<AppIntermediate />} />
        </Routes>
      </Security>
    </div>
  );
}

function RouterWrapper() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <App />
      </Router>
    </ThemeProvider>
  );
}

export default RouterWrapper;
