/* eslint-disable default-param-last */
import React, { useEffect, useState, useRef } from 'react';
import {
  Button,
  Grid,
  LinearProgress,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  TextField,
} from '@mui/material';
import { styled } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DateFnsUtils from '@date-io/date-fns';
import {
  addHours,
  addMonths,
  addDays,
  format,
  parseISO,
  endOfDay,
} from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import ReactExport from 'react-excel-exportz';
import { CSVLink } from 'react-csv';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  Close as CloseIcon,
  CancelRounded as CancelRoundedIcon,
  CheckCircleRounded as CheckCircleRoundedIcon,
} from '@mui/icons-material';
import { PAGINATION_OPTIONS } from '../constants';
import reportsApi from '../api/report.api';
import FilterModal from '../components/Filter';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const CustomDataGrid = styled('div')({
  '& .custom-dark-theme-header': {
    backgroundColor: '#343a40 !important',
    color: 'white !important',
    '& svg': {
      color: 'white !important',
    },
  },
  '& .custom-link-cell': {
    color: 'blue !important',
    cursor: 'pointer',
  },
});

const StyledComponent = styled('div')(({ theme }) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}));

const CustomDialogTitle = styled(({ children, onClose, ...other }) => (
  <DialogTitle {...other}>
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {children}
      {onClose && (
        <IconButton
          aria-label="close"
          className={CustomDialogTitle.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      )}
    </div>
  </DialogTitle>
))(({ theme }) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: '#343a40',
    color: 'white',
  },
  closeButton: {
    position: 'relative',
    top: '-50%',
    color: theme.palette.grey[500],
  },
}));

const DialogContent = styled('div')(({ theme }) => ({
  // Define root and padding styles
  root: {
    padding: theme.spacing(2),
  },
}));

function InvitationLogs(props) {
  const { filter, jobStatus, getBulkInvitationStatus } = props;
  const [pageSize, setPageSize] = React.useState(PAGINATION_OPTIONS[2]);
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [status, setStatus] = useState('');
  const [invitationSource, setInvitationSource] = useState('');
  const [invitationIntent, setInvitationIntent] = useState('');
  const [dealerId, setDealerId] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const dataGridRef = useRef();

  const permissions = useSelector((state) => state.permissions.permissions);

  const columns = [
    // {
    //   headerName: 'Request',
    //   field: 'request',
    //   headerClassName: 'custom-dark-theme--header',
    //   cellClassName: 'custom-link-cell',
    //   width: 150,
    //   renderCell: (e) => {
    //     try {
    //       const req = atob(e.value);
    //       return (
    //         <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
    //           {req.startsWith('<env')
    //             ? req
    //             : JSON.stringify(JSON.parse(req), null, 2)}
    //         </div>
    //       );
    //     } catch (err) {
    //       return (
    //         <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
    //           {e.value}
    //         </div>
    //       );
    //     }
    //   },
    // },
    // {
    //   headerName: 'Response',
    //   field: 'response',
    //   headerClassName: 'custom-dark-theme--header',
    //   cellClassName: 'custom-link-cell',
    //   width: 150,
    // },
    {
      headerName: 'Vin',
      field: 'vin',
      headerClassName: 'custom-dark-theme--header',
      width: 150,
    },
    {
      headerName: 'Email',
      field: 'email',
      headerClassName: 'custom-dark-theme--header',
      width: 200,
    },
    {
      headerName: 'Phone',
      field: 'phone',
      headerClassName: 'custom-dark-theme--header',
      width: 150,
    },
    {
      headerName: 'Invitation Source',
      field: 'invitationSource',
      headerClassName: 'custom-dark-theme--header',
      width: 200,
    },
    {
      headerName: 'Invitation Intent',
      field: 'invitationIntent',
      headerClassName: 'custom-dark-theme--header',
      width: 200,
    },
    {
      headerName: 'Dealer Id',
      field: 'dealerId',
      headerClassName: 'custom-dark-theme--header',
      width: 200,
    },
    {
      headerName: 'Created At',
      valueFormatter: (e) =>
        e.value ? format(parseISO(e.value), 'MM/dd/yyyy') : '',
      field: 'createdAt',
      headerClassName: 'custom-dark-theme--header',
      width: 150,
    },
    {
      headerName: 'Status',
      renderCell: (e) => {
        return e.value && e.value.toLowerCase() !== 'failure' ? (
          <CheckCircleRoundedIcon style={{ fill: 'green' }} />
        ) : (
          <CancelRoundedIcon style={{ fill: 'red' }} />
        );
      },
      field: 'status',
      headerClassName: 'custom-dark-theme--header',
      width: 150,
    },
    {
      headerName: 'Sms Status',
      renderCell: (e) => {
        return e.value && e.value === true ? (
          <CheckCircleRoundedIcon style={{ fill: 'green' }} />
        ) : (
          <CancelRoundedIcon style={{ fill: 'red' }} />
        );
      },
      field: 'messageSent',
      headerClassName: 'custom-dark-theme--header',
      width: 150,
    },
    {
      headerName: 'Email Status',
      renderCell: (e) => {
        return e.value && e.value === true ? (
          <CheckCircleRoundedIcon style={{ fill: 'green' }} />
        ) : (
          <CancelRoundedIcon style={{ fill: 'red' }} />
        );
      },
      field: 'emailSent',
      headerClassName: 'custom-dark-theme--header',
      width: 150,
    },
    {
      headerName: 'Reasons',
      headerClassName: 'custom-dark-theme--header',
      cellClassName: 'custom-link-cell',
      width: 150,
      field: 'message',
      renderCell: () => {
        return 'View';
      },
    },
    {
      headerName: 'Request',
      renderCell: () => {
        return 'View';
      },
      field: 'request',
      headerClassName: 'custom-dark-theme--header',
      cellClassName: 'custom-link-cell',
      width: 150,
    },
    {
      headerName: 'Response',
      renderCell: () => {
        return 'View';
      },
      field: 'response',
      headerClassName: 'custom-dark-theme--header',
      cellClassName: 'custom-link-cell',
      width: 150,
    },
    {
      headerName: 'Reminders',
      renderCell: (e) => {
        return e.value && e.value.length > 0 ? e.value.length : '0';
      },
      field: 'reminderLog',
      headerClassName: 'custom-dark-theme--header',
      cellClassName: 'custom-link-cell',
      width: 150,
    },
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [downloadData, setDownloadData] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadMsg, setDownloadMsg] = useState(null);
  // const classes = useStyles();
  const [orignalDataSource, setOrignalDataSource] = useState([]);
  const [isFromBulkInvite, setIsFromBulkInvite] = useState(
    !!(filter && filter.bulkInviteRef)
  );
  const [appliedFilter, setAppliedFilter] = useState(filter || {});
  const previousFilter = usePrevious(appliedFilter);
  const [filterDisplay, setFilterDisplay] = useState(
    filter ? 'Filter Applied' : ''
  );
  const [filterBeforeDate, setFilterBeforeDate] = useState(null);
  const [filterAfterDate, setFilterAfterDate] = useState(null);
  const [applyQuickFilter, setApplyQuickFilter] = useState(false);
  const [qrCodeData, setQrCodeData] = useState(null);
  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ReactExport.ExcelFile;
  const { ExcelColumn } = ReactExport.ExcelFile;
  const csvLinkRef = useRef();
  const [failedData, setFailedData] = useState([]);

  async function fetchData(
    skip = dataSource.length,
    limit = pageSize,
    pipeLine
  ) {
    const params = {
      skip,
      limit,
      filter: pipeLine
        ? JSON.stringify(pipeLine)
        : JSON.stringify(appliedFilter),
    };

    return reportsApi.getInvitationLogs(params, dispatch);
  }

  function updateDataSource(data) {
    const startIndex =
      dataSource && dataSource.length
        ? dataSource[dataSource.length - 1]._gridId + 1
        : 0;
    const _updatedDataSource = dataSource.concat(
      data.map((item, index) => {
        return { ...item, _gridId: index + startIndex };
      })
    );
    setDataSource(_updatedDataSource);
    return _updatedDataSource;
  }
  const setMessagAndEmailStatus = (data) => {
    const modifiedData = data.map((obj) => {
      const smsSent = obj.message.includes('SMS_NOTIFICATION_SENT');
      const delaySmsSent = obj.message.includes('SMS_NOTIFICATION_SENT');
      return {
        ...obj,
        messageSent: smsSent || delaySmsSent,
        emailSent: obj.message.includes('EMAIL_NOTIFICATION_SENT'),
      };
    });

    return modifiedData;
  };

  useEffect(() => {
    function checkIfDataPresent() {
      const totalDataExpected = (page + 1) * pageSize;
      const startIndex = page * pageSize;
      return (
        dataSource &&
        dataSource.length > startIndex &&
        (rowCount > totalDataExpected
          ? dataSource.length >= totalDataExpected
          : true)
      );
    }

    const loadData = async (appliedFilterUpdated) => {
      if (!checkIfDataPresent() || appliedFilterUpdated) {
        if (appliedFilterUpdated && page !== 0) {
          setDataSource([]);
          return;
        }
        setIsLoading(true);
        const reports = await fetchData(
          appliedFilterUpdated ? 0 : dataSource.length
        );
        if (reports && reports.data) {
          const modifiedRes = setMessagAndEmailStatus(reports.data);
          if (appliedFilterUpdated) {
            setDataSource(
              modifiedRes.map((item, index) => {
                return { ...item, _gridId: index };
              })
            );
          } else {
            updateDataSource(reports.data);
          }
          if (reports.totalCount !== rowCount) {
            setRowCount(reports.totalCount);
          }
        }
        setIsLoading(false);
      }
    };
    const appliedFilterUpdated =
      JSON.stringify(appliedFilter) !== JSON.stringify(previousFilter);
    loadData(appliedFilterUpdated);
  }, [page, appliedFilter, dispatch]);

  useEffect(() => {
    if (isFromBulkInvite && jobStatus && !(jobStatus._id || jobStatus.error)) {
      if (getBulkInvitationStatus) {
        getBulkInvitationStatus(filter.bulkInviteRef.$eq);
      }
    }
  }, [jobStatus]);

  useEffect(() => {
    if (dataSource.length === 0) {
      setPage(0);
    }
  }, [dataSource]);

  useEffect(() => {
    if (failedData.length) {
      setTimeout(() => {
        csvLinkRef.current.link.click();
      }, 0);
    }
  }, [failedData]);

  const saveFilters = () => {
    const newFilter = {};
    const displays = [];
    const filterStartDate = new Date(filterAfterDate);
    // const filterEndDate = new Date(filterBeforeDate);
    let dateDisplay = '';
    if (filterAfterDate) {
      newFilter.createdAt = { $gte: filterStartDate };
      dateDisplay = `Dates: ${format(filterStartDate, 'MM/dd/yyyy')}`;
    }
    if (filterBeforeDate) {
      const filterEndDate = new Date(filterBeforeDate);
      filterEndDate.setHours(23, 59, 59, 999); // Set time to 11:59:59.999 pm
      newFilter.createdAt = {
        ...newFilter.createdAt,
        $lte: filterEndDate,
      };

      if (!dateDisplay) {
        dateDisplay = `Dates: -${format(filterEndDate, 'MM/dd/yyyy')}`;
      } else {
        dateDisplay = `${dateDisplay}-${format(filterEndDate, 'MM/dd/yyyy')}`;
      }
    }
    if (status) {
      newFilter.status = { $eq: `${status}` };
    }
    if (invitationIntent) {
      newFilter.invitationIntent = { $eq: `${invitationIntent}` };
    }
    if (dealerId) {
      newFilter.dealerId = { $eq: `${dealerId}` };
    }
    if (invitationSource) {
      newFilter.invitationSource = { $eq: `${invitationSource}` };
    }
    if (dateDisplay) {
      displays.push(dateDisplay);
    }

    setAppliedFilter(newFilter);
    if (displays.length) setFilterDisplay(displays.join(' '));
    else setFilterDisplay('Filter Applied');
  };

  useEffect(() => {
    if (applyQuickFilter && !!filterAfterDate) {
      setApplyQuickFilter(false);
      saveFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyQuickFilter, filterAfterDate]);

  async function fetchDataInBatches(
    index = 0,
    limit = 50,
    allReports,
    pipeLine
  ) {
    const report = await fetchData(index * limit, limit, pipeLine);
    const modifiedRes = setMessagAndEmailStatus(report.data);
    allReports = allReports.concat(modifiedRes); // eslint-disable-line no-param-reassign
    if (report.currentPage !== report.pageCount) {
      setDownloadMsg(
        `Downloading ${report.currentPage + 1} of ${report.pageCount} Pages`
      );
      return fetchDataInBatches(report.currentPage, limit, allReports);
    }
    return allReports;
  }

  const downloadResults = async () => {
    setIsDownloading(true);
    setDownloadData([]);
    setDownloadMsg(`Downloading 1 of ${Math.round(rowCount / 50)} Pages`);
    const reports = await fetchDataInBatches(0, 50, []);
    setDownloadData(reports);
    setIsDownloading(false);
    setDownloadMsg(null);
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params.pageSize);
  };

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const setLastDayFilter = () => {
    setFilterAfterDate(addHours(new Date(), -24));
    setApplyQuickFilter(true);
  };

  const setLastMonthFilter = () => {
    setFilterAfterDate(addMonths(new Date(), -1));
    setApplyQuickFilter(true);
  };

  const setLastSevenDays = () => {
    setFilterAfterDate(addDays(new Date(), -7));
    setApplyQuickFilter(true);
  };

  const resetFilters = () => {
    setFilterAfterDate(null);
    setFilterBeforeDate(null);
    setFilterDisplay('');
    setStatus('');
    setInvitationSource('');
    setInvitationIntent('');
    setDealerId('');
    setAppliedFilter({});
    setPage(0);
    setIsFromBulkInvite(false);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleInvitationSource = (event) => {
    setInvitationSource(event.target.value);
  };

  const handleInvitationIntent = (event) => {
    setInvitationIntent(event.target.value);
  };

  const handleDealerIdChange = (event) => {
    setDealerId(event.target.value);
  };

  const handleCellClick = (cellParams) => {
    if (cellParams.field === 'response') {
      try {
        const parsedBody = JSON.parse(cellParams.value);
        setModalBody(JSON.stringify(parsedBody, null, 2));
        if (
          parseInt(parsedBody.statusCode, 10) === 200 &&
          parsedBody.isBase64Encoded &&
          parsedBody.body
        ) {
          setQrCodeData(parsedBody.body);
        }
        setOpenModal(true);
      } catch (error) {
        setModalBody(`Invitation Source is not defined`);
        setOpenModal(true);
      }
    } else if (cellParams.field === 'request') {
      const parsedBody = cellParams.value;
      let render;
      try {
        const req = atob(parsedBody);
        render = req.startsWith('<env')
          ? req
          : JSON.stringify(JSON.parse(req), null, 2);
      } catch (err) {
        render = parsedBody;
      }
      setModalBody(
        <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
          {render}
        </div>
      );
      setOpenModal(true);
    } else if (cellParams.field === 'message') {
      setModalBody(
        cellParams.row.message && cellParams.row.message.length
          ? cellParams.row.message.join('\n')
          : 'No reason found for this invite.'
      );
      setOpenModal(true);
    } else if (cellParams.field === 'reminderLog') {
      if (cellParams.value && cellParams.value.length > 0) {
        const parsedBody = JSON.stringify(cellParams.value);
        setModalBody(parsedBody);
        setOpenModal(true);
      }
    }
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setQrCodeData(null);
  };

  const applyCustomFilter = (value) => {
    let data = orignalDataSource;
    if (orignalDataSource && !orignalDataSource.length) {
      setOrignalDataSource(dataSource);
      data = dataSource;
    }
    if (data.length === rowCount) {
      if (value && value.length) {
        data = data.filter((item) => {
          return item.status === value;
        });
      }
      setDataSource(data);
    } else {
      const filterValues = { ...appliedFilter };
      if (
        value &&
        value.length &&
        appliedFilter &&
        appliedFilter.status !== value
      ) {
        filterValues.status = value;
      } else {
        delete filterValues.status;
      }
      setAppliedFilter(filterValues);
    }
  };

  const downloadFailedResults = async () => {
    setIsDownloading(true);
    if (dataSource.length === rowCount) {
      setFailedData(
        dataSource.filter((item) => {
          return item.status === 'Failure';
        })
      );
    } else {
      const reports = await fetchDataInBatches(0, 50, [], {
        status: 'Failure',
      });
      setFailedData(reports);
    }
    setIsDownloading(false);
  };

  return (
    <Grid container className="reports">
      <Grid item sm={12}>
        {isFromBulkInvite && jobStatus.totalRows ? (
          <div>
            <Button variant="contained" onClick={() => applyCustomFilter('')}>
              Total:&nbsp;
              {jobStatus.totalRows}
            </Button>
            <Button
              variant="contained"
              onClick={() => applyCustomFilter('Success')}
              className="btn btn-success"
              style={{
                color: '#fff',
                backgroundColor: '#28a745',
                borderColor: '#28a745',
                margin: '0 10px',
              }}
            >
              Success:&nbsp;
              {jobStatus.success}
            </Button>
            <Button
              variant="contained"
              onClick={() => applyCustomFilter('Failure')}
              className="btn btn-warning"
              style={{
                color: '#212529',
                backgroundColor: '#ffc107',
                borderColor: '#ffc107',
              }}
            >
              Failure:&nbsp;
              {jobStatus.failure}
            </Button>
          </div>
        ) : (
          ''
        )}
        <h2 className="d-flex align-content-center align-items-center mb-2">
          <span className="mr-2" style={{ marginRight: '16px' }}>
            Invitation Logs
          </span>
          {permissions.INVITATIONS_LOGS.WRITE && (
            <Button
              disabled={isDownloading}
              color="primary"
              variant="outlined"
              onClick={downloadResults}
            >
              {downloadMsg ? (
                <>
                  <span style={{ fontSize: '12px' }}>{downloadMsg}</span>
                  <CircularProgress style={{ height: '25px', width: '25px' }} />
                </>
              ) : (
                'Download details'
              )}
            </Button>
          )}
          &nbsp;
          {isFromBulkInvite && jobStatus.failure ? (
            <Button
              disabled={isDownloading}
              color="primary"
              variant="outlined"
              onClick={downloadFailedResults}
            >
              {isDownloading ? (
                <>
                  <span style={{ fontSize: '12px' }}>Downloading&nbsp;</span>
                  <CircularProgress style={{ height: '25px', width: '25px' }} />
                </>
              ) : (
                'Download Failed Invites'
              )}
            </Button>
          ) : (
            ''
          )}
          <CSVLink filename="report.csv" data={failedData} ref={csvLinkRef} />
          {downloadData && downloadData.length ? (
            <ExcelFile hideElement>
              <ExcelSheet data={downloadData} name="InvitationLogs">
                <ExcelColumn label="request" value="request" />
                <ExcelColumn label="response" value="response" />
                <ExcelColumn label="vin" value="vin" />
                <ExcelColumn label="email" value="email" />
                <ExcelColumn label="phone" value="phone" />
                <ExcelColumn
                  label="invitationSource"
                  value="invitationSource"
                />
                <ExcelColumn
                  label="invitationIntent"
                  value="invitationIntent"
                />
                <ExcelColumn label="dealerId" value="dealerId" />
                <ExcelColumn label="createdAt" value="createdAt" />
                <ExcelColumn label="status" value="status" />
              </ExcelSheet>
            </ExcelFile>
          ) : (
            ''
          )}
          <div className="flex-grow-1" />
          {appliedFilter && !Object.keys(appliedFilter).length && (
            <>
              <Button color="primary" onClick={setLastDayFilter}>
                Last 24 Hours
              </Button>
              <Button color="primary" onClick={setLastSevenDays}>
                Last 7 Days
              </Button>
              <Button color="primary" onClick={setLastMonthFilter}>
                Last month
              </Button>
            </>
          )}
          <FilterModal
            currentFilters={appliedFilter}
            onSave={saveFilters}
            itemName="Reports"
            resetFilters={resetFilters}
            filterDisplay={filterDisplay}
          >
            <div style={{ marginBottom: '16px' }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  utils={DateFnsUtils}
                  label="After Date (including)"
                  variant="inline"
                  className="mr-2"
                  fullWidth
                  id="after-date-filter"
                  value={filterAfterDate}
                  onChange={setFilterAfterDate}
                  autoOk
                />
              </LocalizationProvider>
            </div>
            <div style={{ marginBottom: '16px' }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  utils={DateFnsUtils}
                  label="Before Date (including)"
                  variant="inline"
                  className="mr-2"
                  fullWidth
                  id="before-date-filter"
                  value={filterBeforeDate}
                  onChange={(selectedDate) => {
                    // Set default time to 11:59 pm if date is selected
                    if (selectedDate) {
                      const modifiedDate = endOfDay(selectedDate);
                      setFilterBeforeDate(modifiedDate);
                    } else {
                      setFilterBeforeDate(selectedDate);
                    }
                  }}
                  autoOk
                />
              </LocalizationProvider>
            </div>
            <div style={{ marginBottom: '16px' }}>
              <FormControl fullWidth>
                <InputLabel htmlFor="select-status">Status</InputLabel>
                <Select
                  labelId="select-status"
                  id="select"
                  value={status}
                  onChange={handleStatusChange}
                >
                  <MenuItem value="Success">Success</MenuItem>
                  <MenuItem value="Failure">Failure</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div style={{ marginBottom: '16px' }}>
              <FormControl fullWidth>
                <InputLabel htmlFor="select-invitationSource">
                  Invitation Source
                </InputLabel>
                <Select
                  labelId="select-invitationSource"
                  id="select"
                  value={invitationSource}
                  onChange={handleInvitationSource}
                >
                  <MenuItem value="WELCOME_KIT">Welcome Kit</MenuItem>
                  <MenuItem value="OPERATIONAL_ACQUISITION">IVR</MenuItem>
                  <MenuItem value="GLOW_AGENT">Glow Agent</MenuItem>
                  <MenuItem value="CUNA">CUNA</MenuItem>
                  <MenuItem value="RENEWALS">Renewals</MenuItem>
                  <MenuItem value="SUBSCRIPTIONS">Subscriptions</MenuItem>
                  <MenuItem value="GEICO">Geico</MenuItem>
                  <MenuItem value="RIVIAN">Rivian</MenuItem>
                  <MenuItem value="ADMIN_PANEL_MANUAL_INVITE">
                    Admin Invite
                  </MenuItem>
                  <MenuItem value="BULK_INVITE">Bulk Invite Campaign</MenuItem>
                  <MenuItem value="FORM_SERVICE">Form Service</MenuItem>
                  <MenuItem value="COULD_NOT_DETERMINE">
                    COULD NOT DETERMINE
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div style={{ marginBottom: '16px' }}>
              <FormControl fullWidth>
                <InputLabel htmlFor="select-invitationIntent">
                  Invitation Intent
                </InputLabel>
                <Select
                  labelId="select-invitationIntent"
                  id="select"
                  value={invitationIntent}
                  onChange={handleInvitationIntent}
                >
                  <MenuItem value="INVITATION">INVITATION</MenuItem>
                  <MenuItem value="CONTRACT_UPDATE">CONTRACT UPDATE</MenuItem>
                  <MenuItem value="COULD_NOT_DETERMINE">
                    COULD NOT DETERMINE
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div style={{ marginBottom: '16px' }}>
              <TextField
                label="Dealer ID"
                variant="outlined"
                fullWidth
                id="dealer-id-filter"
                value={dealerId}
                onChange={handleDealerIdChange}
              />
            </div>
          </FilterModal>
        </h2>
        {isLoading ? (
          <LinearProgress style={{ height: '1rem' }} color="secondary" />
        ) : (
          <StyledComponent>
            <div
              style={{ height: '70vh', width: '100%' }}
              className={StyledComponent.root}
            >
              <CustomDataGrid>
                <DataGrid
                  paginationModel={{ page, pageSize }}
                  pagination
                  pageSizeOptions={PAGINATION_OPTIONS}
                  onPageSizeChange={handlePageSizeChange}
                  rowCount={rowCount}
                  getRowId={(row) => row._gridId}
                  rows={dataSource}
                  columns={columns}
                  onPaginationModelChange={handlePageChange}
                  onCellClick={handleCellClick}
                  ref={dataGridRef}
                  className={CustomDataGrid.root}
                  componentsOverrides={{
                    Header: {
                      cell: 'custom-dark-theme--header',
                    },
                    Cell: {
                      cell: 'custom-link-cell',
                    },
                  }}
                />
              </CustomDataGrid>
            </div>
          </StyledComponent>
        )}
        <Dialog
          onClose={handleModalClose}
          aria-labelledby="customized-dialog-title"
          open={openModal}
        >
          <CustomDialogTitle>
            <Typography variant="h6">Value</Typography>
            <IconButton
              aria-label="close"
              className={CustomDialogTitle.closeButton}
              onClick={handleModalClose}
            >
              <CloseIcon />
            </IconButton>
          </CustomDialogTitle>
          <DialogContent
            style={{
              marginLeft: '20px',
              marginRight: '20px',
            }}
          >
            <pre>{modalBody}</pre>
            {qrCodeData ? (
              <div
                style={{
                  padding: '20px 0px',
                }}
              >
                <div>QR Code:</div>
                <div>
                  <div style={{ textAlign: 'center' }}>
                    <img src={`data:image/png;base64,${qrCodeData}`} alt="" />
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </DialogContent>
        </Dialog>
      </Grid>
    </Grid>
  );
}

export default InvitationLogs;
