import api from '.';

const STATUS_URL = `/status`;

const getVersion = async (dispatch) =>
  api.pocketDrivePublicApi(dispatch, 'get', `${STATUS_URL}/version`);

const getAPIStatus = async (dispatch) =>
  api.pocketDrivePublicApi(dispatch, 'get', `${STATUS_URL}/api`);

export default {
  getVersion,
  getAPIStatus,
};
