/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
import {
  DialogContent,
  Dialog,
  Container,
  TextField,
  Typography,
  IconButton,
  MenuItem,
  Menu,
  CircularProgress,
  Tooltip,
  Grid,
} from '@mui/material';
import {
  Business,
  Close,
  Edit,
  InfoRounded,
  KeyboardArrowDown,
} from '@mui/icons-material';
import React, { useEffect, useState } from 'react';

import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';
import { Button, Input } from 'reactstrap';
import dcrApi from '../../api/dcr.api';
import fileUploadApi from '../../api/fileUpload.api';
import { grayBg, uploadIcon } from '../../assets/images';
import ConfirmationModal from '../../components/ConfirmationModal';
import { getStatesOfCountry } from '../../utils/cityStatedb';
import EditDcrHours from './editDcrHours';
import {
  LogoImageSection,
  RepairImageSection,
  StoreImageSection,
} from './editDcrImageCarouels';

function EditDcrDataDialog(props) {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imagesLoading, setImagesLoading] = useState(true);
  const { item, setOpenEdit, updateData, openFindOtherDealership } = props;
  const [editHourDialogVisible, setEditHourDialogVisible] = useState(false);
  const storePhoto = item.googleStorePictureUrl;
  const shopPhoto = item.googleShopPictureUrl;
  const [form, setForm] = useState({
    accountNumber: item.accountNumber,
    googleName: item.googleName,
    googlePhoneNumber: item.googlePhoneNumber,
    googleWebsite: item.googleWebsite,
    googleStreet: item.googleStreet,
    googleCity: item.googleCity,
    googleState: item.googleState,
    googlePostalCode: item.googlePostalCode,
    googleBrandLogoUrl: item.googleBrandLogoUrl,
    googleStorePictureUrl: storePhoto,
    googleShopPictureUrl: shopPhoto,
    googleOpeningHours: item.googleOpeningHours,
    shippingStreet: item.shippingStreet ? item.shippingStreet : '',
    billingStreet: item.billingStreet ? item.billingStreet : '',
    clientStatus: item.clientStatus ? item.clientStatus : '',
  });

  const [uploadImagePath, setUploadImagePath] = useState({
    logo: null,
    store: null,
    shop: null,
  });

  const [images, setImages] = useState({
    logoImages: [],
    storeImages: [],
    shopImages: [],
  });

  const [clientStatus, setClientStatus] = useState({
    clientStatus: ['Active', 'Closed', 'Out of Business'],
  });

  const getGooglePhotos = async () => {
    const googleImages = await dcrApi.getGooglePhotos({
      placeId: item.googlePlaceId,
    });
    setImages({
      logoImages: googleImages
        ? [
            ...(item.customBrandLogoUrls || []),
            ...googleImages.googlePlaceLogos,
          ]
        : [...(item.customBrandLogoUrls || [])],
      storeImages: googleImages
        ? [...(item.customStorePictureUrls || []), ...googleImages.googlePhotos]
        : [...(item.customStorePictureUrls || [])],
      shopImages: googleImages
        ? [...(item.customShopPictureUrls || []), ...googleImages.googlePhotos]
        : [...(item.customShopPictureUrls || [])],
    });
    setImagesLoading(false);
  };

  useEffect(() => {
    getGooglePhotos();
  }, []);

  const uploadImage = (e, pathName) => {
    switch (pathName) {
      case 'logo':
        setUploadImagePath({ ...uploadImagePath, logo: e.target.files[0] });
        break;
      case 'shop':
        setUploadImagePath({ ...uploadImagePath, shop: e.target.files[0] });
        break;
      case 'store':
        setUploadImagePath({ ...uploadImagePath, store: e.target.files[0] });
        break;
      default:
        break;
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const cloneForm = { ...form };
    cloneForm[name] = value;
    setForm(cloneForm);
  };

  const openEditHourseDialog = () => {
    if (form.googleOpeningHours && form.googleOpeningHours.length > 0) {
      setEditHourDialogVisible(true);
    }
  };

  const saveHours = (hours) => {
    const cloneForm = { ...form };
    cloneForm.googleOpeningHours = hours;
    setForm(cloneForm);
  };

  function HoursMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        <IconButton
          id="demo-customized-button"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
        >
          <KeyboardArrowDown />
        </IconButton>
        <Menu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {form.googleOpeningHours &&
            form.googleOpeningHours.map((d) => (
              <MenuItem onClick={handleClose} disableRipple>
                {` ${d.day} ${d.open} - ${d.close}`}
              </MenuItem>
            ))}
        </Menu>
      </div>
    );
  }

  const uploadFile = async (type, file) => {
    const { accountNumber } = form;
    const fileUrl = await fileUploadApi.getUploadUrl({
      type,
      parameters: { accountNumber },
      imageName: file ? file.name : '',
      contentType: file.type,
    });
    // uploadToS3
    await fileUploadApi.uploadToS3(fileUrl, file, file.type);
    return fileUrl.split('?')[0];
  };

  const onSaveEditChanges = async () => {
    setLoading(true);
    let { googleBrandLogoUrl } = form;
    let { googleStorePictureUrl } = form;
    let { googleShopPictureUrl } = form;
    if (form.googleBrandLogoUrl.startsWith('blob')) {
      googleBrandLogoUrl = await uploadFile(
        'googleBrandLogoUrl',
        uploadImagePath.logo
      );
    }
    if (form.googleStorePictureUrl.startsWith('blob')) {
      googleStorePictureUrl = await uploadFile(
        'googleStorePictureUrl',
        uploadImagePath.store
      );
    }
    if (form.googleShopPictureUrl.startsWith('blob')) {
      googleShopPictureUrl = await uploadFile(
        'googleShopPictureUrl',
        uploadImagePath.shop
      );
    }
    const reqParam = {
      accountNumber: form.accountNumber,
      clientStatus: form.clientStatus,
      status: item.status == 'Approved' ? 'Not Reviewed' : item.status,
      googleData: {
        googleName: form.googleName,
        googleStreet: form.googleStreet,
        googleCity: form.googleCity,
        googleState: form.googleState,
        googlePostalCode: form.googlePostalCode,
        googleCountry: form.googleCountry,
        googleWebsite: form.googleWebsite,
        googlePhoneNumber: form.googlePhoneNumber,
        googleRating: form.googleRating,
        googleNumberOfRatings: form.googleNumberOfRatings,
        googleLocationLatitude: form.googleLocationLatitude,
        googleLocationLongitude: form.googleLocationLongitude,
        googleOpeningHours: form.googleOpeningHours,
        googleStorePictureUrl,
        googleShopPictureUrl,
        googleBrandLogoUrl,
      },
    };
    const res = await dcrApi.approveRejectGoogleData(reqParam);
    setLoading(false);
    if (res) {
      updateData(res);
      setOpenEdit(false);
    }
  };

  const confirmSave = () => {
    if (item.status == 'Approved') {
      setShowConfirmModal(true);
    } else {
      onSaveEditChanges();
    }
  };
  const checkAddressValidity = () => {
    if (form.shippingStreet == '') {
      return (
        (form.googleStreet &&
          form.googleStreet.toLowerCase() !=
            item.billingStreet.toLowerCase()) ||
        (form.googleCity &&
          form.googleCity.toLowerCase() != item.billingCity.toLowerCase()) ||
        (form.googleState &&
          form.googleState.toLowerCase() != item.billingState.toLowerCase()) ||
        (form.googlePostalCode &&
          form.googlePostalCode.toLowerCase() !=
            item.billingPostalCode.toLowerCase())
      );
    }

    return (
      (form.googleStreet &&
        form.googleStreet.toLowerCase() != item.shippingStreet.toLowerCase()) ||
      (form.googleCity &&
        form.googleCity.toLowerCase() != item.shippingCity.toLowerCase()) ||
      (form.googleState &&
        form.googleState.toLowerCase() != item.shippingState.toLowerCase()) ||
      (form.googlePostalCode &&
        form.googlePostalCode.toLowerCase() !=
          item.shippingPostalCode.toLowerCase())
    );
  };

  return (
    <>
      {editHourDialogVisible ? (
        <EditDcrHours
          dealerName={form.googleName}
          hours={form.googleOpeningHours}
          saveHours={saveHours}
          isVisible={editHourDialogVisible}
          setVisible={setEditHourDialogVisible}
          onClose={() => setOpenEdit(false)}
        />
      ) : (
        <Dialog fullWidth maxWidth="lg" open>
          <DialogContent>
            {loading && (
              <div className="loader-container">
                <CircularProgress />
              </div>
            )}
            <Container>
              <ConfirmationModal
                show={showConfirmModal}
                onConfirm={() => {
                  setShowConfirmModal(false);
                  onSaveEditChanges();
                }}
                onCancel={() => setShowConfirmModal(false)}
                headerText="This dealer data has already been approved. Do you still want to edit it? "
                bodyText="(Note: Editing will move the status back to In-Review)"
              />
              <div
                className="edit-dcr-close-btn"
                onClick={() => setOpenEdit(false)}
              >
                <Close />
              </div>
              <div className="edit-dcr-container">
                <div className="edit-dcr-row">
                  <div className="edit-dcr-form-field flex-center">
                    <TextField
                      style={{ width: '40%' }}
                      placeholder="Dealer Name"
                      variant="outlined"
                      value={form.googleName}
                      name="googleName"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="edit-dcr-row">
                  <div className="edit-dcr-form-field-container">
                    <Typography className="edit-dcr-form-label">
                      Phone
                    </Typography>
                    <TextField
                      className="edit-dcr-form-field"
                      placeholder="000-000-0000"
                      variant="outlined"
                      value={form.googlePhoneNumber}
                      name="googlePhoneNumber"
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="edit-dcr-form-field-container">
                    <Typography className="edit-dcr-form-label">
                      Website
                    </Typography>
                    <TextField
                      className="edit-dcr-form-field"
                      placeholder="www.website.com"
                      variant="outlined"
                      value={form.googleWebsite}
                      name="googleWebsite"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="edit-dcr-row">
                  <div className="edit-dcr-form-field-container">
                    <Typography className="edit-dcr-form-label">
                      Address
                    </Typography>
                    {checkAddressValidity() && (
                      <Tooltip title="Google Address does not match the GLOW Address">
                        <IconButton>
                          <InfoRounded color="error" />
                        </IconButton>
                      </Tooltip>
                    )}
                    <TextField
                      className="edit-dcr-form-field"
                      placeholder="Address"
                      variant="outlined"
                      value={form.googleStreet}
                      name="googleStreet"
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="edit-dcr-form-field-container">
                    <Typography className="edit-dcr-form-label">
                      Hours
                    </Typography>
                    <div className="form-control edit-dcr-time-field">
                      <div onClick={openEditHourseDialog}>
                        Monday - Saturday
                        {form.googleOpeningHours &&
                        form.googleOpeningHours.length > 1
                          ? `${form.googleOpeningHours[1].open} - ${form.googleOpeningHours[1].close}`
                          : ''}
                      </div>
                      <HoursMenu />
                    </div>
                  </div>
                </div>
                <div className="edit-dcr-row">
                  <div style={{ flex: 1, display: 'flex' }}>
                    <div style={{ flex: 1 }} />
                    <div style={{ flex: 3, display: 'flex' }}>
                      <div className="edit-dcr-form-field-container">
                        <TextField
                          className="edit-dcr-form-field"
                          placeholder="City"
                          variant="outlined"
                          value={form.googleCity}
                          name="googleCity"
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="edit-dcr-form-field-container">
                        <Input
                          style={{ height: '56px' }}
                          type="select"
                          name="googleState"
                          id="googleState"
                          defaultValue={form.googleState || ''}
                          onChange={handleInputChange}
                        >
                          <option value="">State</option>
                          {getStatesOfCountry('USA').map((st, index) => {
                            return (
                              <option key={index} value={item.isoCode}>
                                {st.isoCode}
                              </option>
                            );
                          })}
                        </Input>
                      </div>
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div className="edit-dcr-form-field-container">
                      <Typography className="edit-dcr-form-label">
                        Zip
                      </Typography>
                      <TextField
                        className="edit-dcr-form-field"
                        placeholder="000-000-0000"
                        variant="outlined"
                        value={form.googlePostalCode}
                        name="googlePostalCode"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="edit-dcr-row">
                  <div className="edit-dcr-form-field-container">
                    <Grid container>
                      <Grid container item xs={6} spacing={1}>
                        <Grid item xs={3}>
                          <div style={{ textAlign: 'center' }}>
                            Client Status
                          </div>
                        </Grid>

                        <Grid item xs={9}>
                          <Input
                            fullWidth
                            style={{ height: '56px' }}
                            type="select"
                            name="clientStatus"
                            id="clientStatus"
                            defaultValue={form.clientStatus || ''}
                            onChange={handleInputChange}
                          >
                            {clientStatus.clientStatus.map((ct, index) => {
                              return (
                                <option key={index} value={ct}>
                                  {ct}
                                </option>
                              );
                            })}
                          </Input>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>

              <div>
                <div className="edit-google-dcr-title">
                  <span>Shop Images </span>
                  <div className="upload-btn-container">
                    <img className="upload-icon" src={uploadIcon} alt="" />
                    <input
                      className="hidden-upload"
                      accept="image/png, image/jpg, image/jpeg"
                      onChange={(e) => uploadImage(e, 'shop')}
                      type="file"
                      name="googleShopPictureUrl"
                    />
                  </div>
                </div>
                {imagesLoading ? (
                  <div className="w-100 progress-indicator-container">
                    <CircularProgress />
                  </div>
                ) : (
                  <RepairImageSection
                    images={images.shopImages}
                    selectedValue={form.googleShopPictureUrl}
                    handleInputChange={handleInputChange}
                    uploadImagePath={uploadImagePath}
                  />
                )}
              </div>
              <div>
                <div className="edit-google-dcr-title">
                  <span>Store Images </span>
                  <div className="upload-btn-container">
                    <img className="upload-icon" src={uploadIcon} alt="" />
                    <input
                      className="hidden-upload"
                      accept="image/png, image/jpg, image/jpeg"
                      onChange={(e) => uploadImage(e, 'store')}
                      type="file"
                      name="googleStorePictureUrl"
                    />
                  </div>
                </div>
                {imagesLoading ? (
                  <div className="w-100 progress-indicator-container">
                    <CircularProgress />
                  </div>
                ) : (
                  <StoreImageSection
                    images={images.storeImages}
                    selectedValue={form.googleStorePictureUrl}
                    handleInputChange={handleInputChange}
                    uploadImagePath={uploadImagePath}
                  />
                )}
              </div>
              <hr />
              <div>
                <div className="edit-google-dcr-title">
                  <span>Logos </span>
                  <div className="upload-btn-container">
                    <img className="upload-icon" src={uploadIcon} alt="" />
                    <input
                      className="hidden-upload"
                      accept="image/png, image/jpg, image/jpeg"
                      onChange={(e) => uploadImage(e, 'logo')}
                      type="file"
                      name="googleBrandLogoUrl"
                    />
                  </div>
                </div>
                {imagesLoading ? (
                  <div className="w-100 progress-indicator-container">
                    <CircularProgress />
                  </div>
                ) : (
                  <LogoImageSection
                    images={images.logoImages}
                    selectedValue={form.googleBrandLogoUrl}
                    handleInputChange={handleInputChange}
                    uploadImagePath={uploadImagePath}
                  />
                )}
              </div>
              <div className="edit-dcr-footer">
                <Button
                  onClick={confirmSave}
                  color="primary"
                  className="edit-dcr-btn"
                >
                  Save
                </Button>
                <Button
                  onClick={() => setOpenEdit(false)}
                  color="primary"
                  className="edit-dcr-btn"
                >
                  Cancel
                </Button>
              </div>
              <div className="edit-dcr-footer">
                <span>Not the one you are looking for? </span>
                <span className="dcr-link" onClick={openFindOtherDealership}>
                  Find other dealerships in the area.
                </span>
              </div>
            </Container>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
export default EditDcrDataDialog;
