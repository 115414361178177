import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { LinearProgress, TextField, IconButton } from '@mui/material';
import moment from 'moment';
import { Cancel } from '@mui/icons-material';
import './Cars.scss';
import { DataGrid } from '@mui/x-data-grid';
import FilterModal from '../components/Filter';
import { PAGINATION_OPTIONS } from '../constants';
import carsApi from '../api/cars.api';

const COLUMNS = [
  {
    headerName: 'VIN',
    render: (e) => e.vin,
    field: 'vin',
    headerClassName: 'custom-dark-theme--header',
    width: 200,
  },
  {
    headerName: 'Owner Name',
    headerClassName: 'custom-dark-theme--header',
    field: 'userName',
    renderCell: (c) => (c.value ? c.value : ''),
    width: 200,
  },
  {
    headerName: 'Owner Email',
    headerClassName: 'custom-dark-theme--header',
    field: 'userEmail',
    renderCell: (c) => (c.value ? c.value : ''),
    width: 250,
  },
  {
    headerName: 'Year',
    field: 'year',
    headerClassName: 'custom-dark-theme--header',
    width: 120,
  },
  {
    headerName: 'Make',
    field: 'make',
    headerClassName: 'custom-dark-theme--header',
    width: 150,
  },
  {
    headerName: 'Model',
    field: 'model',
    headerClassName: 'custom-dark-theme--header',
    width: 150,
  },
  {
    headerName: 'Odometer',
    headerClassName: 'custom-dark-theme--header',
    field: 'odometerReadings',
    renderCell: (c) =>
      c.value.reduce((max, reading) => {
        return reading.odometerReading > max ? reading.odometerReading : max;
      }, 0),
    width: 150,
  },
  {
    headerName: 'Created At',
    headerClassName: 'custom-dark-theme--header',
    field: 'createdAt',
    renderCell: (c) => moment(c.value).format('MMMM Do YYYY'),
    width: 200,
  },
  {
    headerName: 'Purchased At',
    headerClassName: 'custom-dark-theme--header',
    field: 'purchaseDate',
    renderCell: (c) => (c.value ? moment(c.value).format('MMMM Do YYYY') : ''),
    width: 200,
  },
  {
    headerName: 'Has Verified',
    headerClassName: 'custom-dark-theme--header',
    field: 'hasVerified',
    renderCell: (c) => (c.value ? 'YES' : 'NO'),
    width: 170,
  },
  {
    headerName: 'Has Policies',
    headerClassName: 'custom-dark-theme--header',
    field: 'hasAnyPolicy',
    renderCell: (c) => (c.value ? 'YES' : 'NO'),
    width: 170,
  },
];

function Cars() {
  const [dataSource, setDataSource] = useState([]);
  const [pageSize, setPageSize] = React.useState(PAGINATION_OPTIONS[2]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = React.useState(0);
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const { userId } = useParams();
  const [filter, setFilter] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [filterVin, setFilterVin] = useState('');
  const [filterEmail, setFilterEmail] = useState('');
  const [filterMake, setFilterMake] = useState('');
  const [filterModel, setFilterModel] = useState('');
  const [filterDealer, setFilterDealer] = useState('');

  const updateDataSource = (data) => {
    const startIndex =
      dataSource && dataSource.length
        ? dataSource[dataSource.length - 1]._id + 1
        : 0;
    const _updatedDataSource = dataSource.concat(
      data.map((item, index) => {
        return { ...item, _id: index + startIndex };
      })
    );
    setDataSource(_updatedDataSource);
    return _updatedDataSource;
  };

  const getParams = () => {
    return {
      filter,
    };
  };

  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      const params = {
        filter: JSON.stringify(filter),
        skip: pageSize * page,
        limit: pageSize,
      };
      if (userId) {
        const userFilter = { userIds: userId };
        params.filter = userFilter;
        params.skip = 0;
      }
      const carsData = await carsApi.getCars(dispatch, params);
      if (carsData && carsData.data) {
        updateDataSource(carsData.data);
        setRowCount(carsData.totalCount);
      }
      setIsLoading(false);
    };
    load();
  }, [page, userId, filter]);

  const handlePageSizeChange = (params) => {
    setPageSize(params.pageSize);
  };

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const goToCar = (carId) => {
    window.open(`/cars/${carId}`, '_self');
  };

  const handleRowClick = (params) => {
    goToCar(params.row.carId);
  };

  const saveFilters = async () => {
    const params = getParams();
    if (filterEmail) {
      params.filter.email = `${filterEmail}`;
    } else {
      delete params.filter.email;
    }
    if (filterVin) {
      params.filter.vin = `/${filterVin}/`;
    } else {
      delete params.filter.vin;
    }
    if (filterMake) {
      params.filter.make = `/${filterMake}/`;
    } else {
      delete params.filter.make;
    }
    if (filterModel) {
      params.filter.model = `/${filterModel}/`;
    } else {
      delete params.filter.model;
    }
    if (filterDealer) {
      params.filter.dealerName = `/${filterDealer}/`;
    } else {
      delete params.filter.dealerName;
    }
    setDataSource([]);
    setPage(0);
    setFilter({ ...params.filter });
  };
  const resetFilters = () => {
    setFilterEmail('');
    setFilterVin('');
    setFilterMake('');
    setFilterModel('');
    setFilterDealer('');
    setDataSource([]);
    setPage(0);
    setFilter({});
  };

  const permissions = useSelector((state) => state.permissions.permissions);

  return (
    <div
      className="cars"
      style={{
        display: permissions.CARS.READ ? 'block' : 'none',
      }}
    >
      <div className="d-flex pr-2">
        <h2>{userId && user ? `Cars for ${user.name}` : 'All cars'}</h2>
        {!userId && (
          <>
            <FilterModal onSave={saveFilters} itemName="Cars">
              <div>
                <TextField
                  id="filter-vin"
                  label="User Email"
                  placeholder="Enter user email"
                  value={filterEmail}
                  onChange={(e) => setFilterEmail(e.currentTarget.value)}
                />
              </div>
              <div>
                <TextField
                  id="filter-vin"
                  label="VIN"
                  placeholder="Enter a VIN"
                  value={filterVin}
                  onChange={(e) => setFilterVin(e.currentTarget.value)}
                />
              </div>
              <div>
                <TextField
                  id="filter-make"
                  label="Make"
                  placeholder="Enter a make"
                  value={filterMake}
                  onChange={(e) => setFilterMake(e.currentTarget.value)}
                />
              </div>
              <div>
                <TextField
                  id="filter-model"
                  label="Model"
                  placeholder="Enter a model"
                  value={filterModel}
                  onChange={(e) => setFilterModel(e.currentTarget.value)}
                />
              </div>
            </FilterModal>
            {!!Object.keys(filter).length && (
              <IconButton onClick={resetFilters}>
                <Cancel color="error" />
              </IconButton>
            )}
          </>
        )}
      </div>
      {isLoading && (
        <LinearProgress style={{ height: '1rem' }} color="secondary" />
      )}
      {!isLoading && dataSource && dataSource.length > 0 && (
        <div className="d-block d-md-flex flex-wrap">
          <div
            style={{
              height: '80vh',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <DataGrid
              paginationModel={{ page, pageSize }}
              pagination
              pageSizeOptions={PAGINATION_OPTIONS}
              onPageSizeChange={handlePageSizeChange}
              rowCount={rowCount}
              getRowId={(row) => row._id}
              rows={dataSource}
              columns={COLUMNS}
              onPaginationModelChange={handlePageChange}
              onCellClick={handleRowClick}
            />
          </div>
        </div>
      )}
      {!isLoading && userId && (!dataSource || !dataSource.length) && (
        <div>No cars found for this user</div>
      )}
    </div>
  );
}

export default Cars;
