import React, { useEffect, useState } from 'react';
import {
  Grid,
  Paper,
  TableRow,
  TableHead,
  Table,
  TableContainer,
  TableCell,
  TableSortLabel,
  TableBody,
  TablePagination,
  LinearProgress,
  TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
// import MomentUtils from '@date-io/moment';
// import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { getErrorsAction } from '../store/actions/errors.actions';
import { PAGINATION_OPTIONS } from '../constants';
import FilterModal from '../components/Filter';

import './Errors.scss';

const ERROR_TABLE_FIELDS = [
  {
    display: 'Message',
    render: (e) => e._id.message,
    sortField: '_id.message',
    styles: { width: '20vw', overflowWrap: 'break-word' },
  },
  {
    display: 'URL',
    render: (e) => e._id.url,
    sortField: '_id.url',
    styles: { width: '30vw', overflowWrap: 'break-word' },
  },
  {
    display: 'First Seen',
    render: (e) => moment(e.firstSeen).format('L'),
    sortField: 'firstSeen',
  },
  {
    display: 'Last Seen',
    render: (e) => moment(e.lastSeen).format('L'),
    sortField: 'lastSeen',
  },
  {
    display: 'Count',
    render: (e) => e.count,
    size: 'small',
    sortField: 'count',
  },
];

function Errors() {
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [countPerPage, setCountPerPage] = useState(PAGINATION_OPTIONS[2]);
  const [filterMessage, setFilterMessage] = useState('');
  const [filterBeforeDate, setFilterBeforeDate] = useState(null);
  const [filterAfterDate, setFilterAfterDate] = useState(null);
  const [currentFilter, setCurrentFilter] = useState({});
  const [appliedFilterDisplay, setAppliedFilterDisplay] = useState('');
  const [sortField, setSortField] = useState('count');
  const [sortDirection, setSortDirection] = useState('desc');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const errors = useSelector((state) => state.errors.errors);

  useEffect(() => {
    const loadErrors = async () => {
      const initAfterDate = moment().add('months', -1);
      const initFilter = { createdAt: { $gte: initAfterDate.toISOString() } };
      setFilterAfterDate(initAfterDate);
      setAppliedFilterDisplay(`Dates: ${initAfterDate.format('L')}-`);
      setCurrentFilter(initFilter);
      setIsLoading(true);
      await dispatch(getErrorsAction(initFilter));
      setIsLoading(false);
    };
    loadErrors();
  }, [dispatch]);

  const getErrors = async (
    newCountPerPage,
    newPageNumber,
    filter,
    orderField,
    orderDirection
  ) => {
    setIsLoading(true);
    const ordering = encodeURIComponent(
      `${orderDirection === 'desc' ? '-' : ''}${orderField}`
    );
    await dispatch(
      getErrorsAction({
        filter,
        ordering,
        skip: newCountPerPage * newPageNumber,
        limit: newCountPerPage,
      })
    );
    setIsLoading(false);
  };
  const changePage = (event, newPageNumber) => {
    setPageNumber(newPageNumber);
    getErrors(
      countPerPage,
      newPageNumber,
      currentFilter,
      sortField,
      sortDirection
    );
  };
  const changeRowsPerPage = (event) => {
    const newCountPerPage = event.target.value;
    setCountPerPage(newCountPerPage);
    getErrors(
      newCountPerPage,
      pageNumber,
      currentFilter,
      sortField,
      sortDirection
    );
  };
  const saveFilters = async () => {
    const filter = {};
    const filterDisplays = [];
    let dateDisplay = '';

    if (filterAfterDate) {
      const filterStartDate = moment(filterAfterDate).isValid()
        ? moment(filterAfterDate).toISOString()
        : null;
      if (filterStartDate) {
        filter.createdAt = { $gte: filterStartDate };
        dateDisplay = `Dates: ${moment(filterStartDate).format('L')}-`;
      }
    }

    if (filterBeforeDate) {
      const filterEndDate = moment(filterBeforeDate).isValid()
        ? moment(filterBeforeDate).toISOString()
        : null;
      if (filterEndDate) {
        filter.createdAt = { ...filter.createdAt, $lte: filterEndDate };
        dateDisplay += moment(filterEndDate).format('L');
      }
    }

    if (dateDisplay) {
      filterDisplays.push(dateDisplay);
    }

    if (filterMessage) {
      filter.errorMessage = `/${filterMessage}/`;
      filterDisplays.push(`Message: ${filterMessage}`);
    }

    setCurrentFilter(filter);
    getErrors(countPerPage, pageNumber, filter, sortField, sortDirection);
    setAppliedFilterDisplay(filterDisplays.join(' '));
  };

  const resetFilters = async () => {
    setFilterMessage('');
    const resetDate = moment().add('months', -1);
    setFilterAfterDate(resetDate.toISOString());
    setAppliedFilterDisplay(`Dates: ${resetDate.format('L')}-`);
    setFilterBeforeDate(null);
    setCurrentFilter({});
    setPageNumber(0);
    setSortField('count');
    setSortDirection('desc');
    getErrors(countPerPage, 0, {});
  };

  const goToErrorDetails = (message, url) => {
    navigate(
      `/errors/details?message=${encodeURIComponent(
        message
      )}&url=${encodeURIComponent(url)}`
    );
  };

  const applySort = (field) => {
    let direction = 'desc';
    if (sortField === field) {
      direction = sortDirection === 'desc' ? 'asc' : 'desc';
    }
    setSortDirection(direction);
    setSortField(field);
    getErrors(countPerPage, pageNumber, currentFilter, field, direction);
  };

  return (
    <Grid container className="errors">
      <Grid item sm={12}>
        <h2 className="d-flex align-content-center align-items-center mb-0">
          <span>Errors</span>
          <div className="flex-grow-1" />
          <FilterModal
            currentFilters={currentFilter}
            onSave={saveFilters}
            itemName="Errors"
            resetFilters={resetFilters}
            filterDisplay={appliedFilterDisplay}
          >
            <div style={{ marginBottom: '16px', marginTop: '10px' }}>
              <TextField
                id="filter-message"
                label="Message"
                placeholder="Enter a Message"
                fullWidth
                value={filterMessage}
                onChange={(e) => setFilterMessage(e.currentTarget.value)}
              />
            </div>
            <div style={{ marginBottom: '16px' }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  // utils={DateFnsUtils}
                  label="After Date (including)"
                  variant="inline"
                  className="mr-2"
                  fullWidth
                  // format="l"
                  id="after-date-filter"
                  value={filterAfterDate ? new Date(filterAfterDate) : null}
                  onChange={(date) =>
                    setFilterAfterDate(date ? date.toISOString() : null)
                  }
                  autoOk
                />
              </LocalizationProvider>
            </div>
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  // utils={DateFnsUtils}
                  label="Before Date (including)"
                  variant="inline"
                  className="mr-2"
                  id="before-date-filter"
                  fullWidth
                  // format="l"
                  value={filterBeforeDate ? new Date(filterBeforeDate) : null}
                  onChange={(date) =>
                    setFilterBeforeDate(date ? date.toISOString() : null)
                  }
                  autoOk
                />
              </LocalizationProvider>
            </div>
          </FilterModal>
        </h2>
        {isLoading || !errors ? (
          <LinearProgress style={{ height: '1rem' }} color="secondary" />
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table
                size="small"
                padding="none"
                style={{ tableLayout: 'fixed' }}
              >
                <TableHead className="bg-dark font-white">
                  <TableRow>
                    {ERROR_TABLE_FIELDS.map((field) => {
                      return (
                        <TableCell
                          key={field.display}
                          align="left"
                          className="p-2"
                          component="th"
                          style={field.styles}
                        >
                          <TableSortLabel
                            className="text-white"
                            active={field.sortField === sortField}
                            direction={
                              field.sortField === sortField
                                ? sortDirection
                                : 'desc'
                            }
                            hideSortIcon={!field.sortField}
                            onClick={() => applySort(field.sortField)}
                          >
                            {field.display}
                          </TableSortLabel>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {errors.data.map((error) => (
                    <TableRow
                      key={`${error._id.message}-${error._id.url}`}
                      hover
                      onClick={() => {
                        goToErrorDetails(error._id.message, error._id.url);
                      }}
                    >
                      {ERROR_TABLE_FIELDS.map((field) => (
                        <TableCell
                          key={field.display}
                          align="left"
                          scope="row"
                          className="p-2"
                          style={field.styles}
                        >
                          <div>{field.render(error)}</div>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPage={errors.data.length}
              component="div"
              count={errors.data.length * errors.pageCount}
              page={errors.currentPage - 1}
              rowsPerPageOptions={PAGINATION_OPTIONS}
              onPageChange={changePage}
              onRowsPerPageChange={changeRowsPerPage}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
}

export default Errors;
