/* eslint-disable default-param-last */
import { GET_SMSES } from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case GET_SMSES:
      return {
        ...state,
        sms: action.payload,
      };
    default:
      return state;
  }
};
