import React, { useEffect, useState } from 'react';
import { TextField, Button, CircularProgress } from '@mui/material';

import Select from 'react-select';
import carsApi from '../api/cars.api';

function CarEditConfiguration(props) {
  const [isSaving, setSaving] = useState(false);
  const [isLoadingModel, setLoadingModel] = useState(false);
  const [isLoadingMake, setLoadingMake] = useState(false);
  const [isLoadingEngine, setLoadingEngine] = useState(false);
  const [motorModel, setMotorModel] = useState([]);
  const [motorMake, setMotorMake] = useState([]);
  const [motorEngine, setMotorEngine] = useState([]);
  const [motorModelValue, setMotorModelValue] = useState('');
  const [motorModelLabel, setMotorModelLabel] = useState('Select Model');
  const [motorMakeValue, setMotorMakeValue] = useState('');
  const [motorMakeLabel, setMotorMakeLabel] = useState('Select Make');
  const [motorEngineValue, setMotorEngineValue] = useState('');
  const [motorEngineLabel, setMotorEngineLabel] = useState('Select Engine');
  const [motorBaseEngineVehicleId, setMotorBaseEngineVehicleId] = useState('');

  const {
    car: {
      make,
      model,
      year,
      _id,
      motorMakeId,
      motorModelId,
      motorBaseVehicleId,
      engine,
    },
  } = props;

  const resetModel = () => {
    setMotorModelLabel('Select Model');
    setMotorModelValue('');
  };

  const resetEngine = () => {
    setMotorEngineLabel('Select Engine');
    setMotorEngineValue('');
  };

  useEffect(() => {
    setMotorBaseEngineVehicleId(motorBaseVehicleId);
    const getMMakes = async () => {
      setLoadingMake(true);
      const models = await carsApi.getMotorMakes(_id);
      const newArray = models.map((a) => {
        return { value: a.MakeId, label: a.MakeName };
      });

      setMotorMake(newArray);
      // default make setting start
      const preSelection = newArray.filter((a) => {
        return a.value === motorMakeId;
      });
      if (preSelection.length > 0) {
        setMotorMakeValue(preSelection[0].value);
        setMotorMakeLabel(preSelection[0].label);
      }
      // default make setting start

      setLoadingMake(false);
    };
    getMMakes();
  }, []);

  useEffect(() => {
    const getMModels = async () => {
      setLoadingModel(true);
      const models = await carsApi.getMotorModels(_id, motorMakeValue);
      const newArray = models.map((a) => {
        return { value: a.ModelId, label: a.ModelName };
      });
      setMotorModel(newArray);
      resetModel();
      // default model setting start
      const preSelection = newArray.filter((a) => {
        return a.value === motorModelId;
      });
      if (preSelection.length > 0) {
        setMotorModelValue(preSelection[0].value);
        setMotorModelLabel(preSelection[0].label);
      }
      // default model setting end

      setLoadingModel(false);
    };
    if (motorMakeValue !== '') getMModels();
  }, [motorMakeValue]);

  useEffect(() => {
    const getMEngine = async () => {
      setLoadingEngine(true);
      const models = await carsApi.getMotorEngine(
        _id,
        motorMakeValue,
        motorModelValue
      );
      const newArray = models.map((a) => {
        return { value: a.engineId, label: a.name };
      });
      setMotorEngine(newArray);
      resetEngine();
      // default engine setting start
      const preSelection = newArray.filter((a) => {
        return a.label === engine;
      });
      if (preSelection.length > 0) {
        setMotorEngineValue(preSelection[0].value);
        setMotorEngineLabel(preSelection[0].label);
      }
      // default engine setting start
      setLoadingEngine(false);
    };
    if (motorMakeValue !== '' && motorModelValue !== '') getMEngine();
  }, [motorMakeValue, motorModelValue]);

  useEffect(() => {
    const getMBVehicleId = async () => {
      const models = await carsApi.getMotorBaseVehicleId(
        _id,
        motorMakeValue,
        motorModelValue,
        motorEngineValue
      );
      setMotorBaseEngineVehicleId(models.BaseVehicleID);
    };
    if (
      motorEngineValue !== '' &&
      motorMakeValue !== '' &&
      motorModelValue !== ''
    )
      getMBVehicleId();
  }, [motorMakeValue, motorModelValue, motorEngineValue]);

  const handleModelChange = (event) => {
    setMotorModelValue(event.value);
    setMotorModelLabel(event.label);
  };
  const handleMakeChange = (event) => {
    setMotorMakeValue(event.value);
    setMotorMakeLabel(event.label);
    resetEngine();
    resetModel();
  };
  const handleEngineChange = (event) => {
    setMotorEngineValue(event.value);
    setMotorEngineLabel(event.label);
  };
  const handleBaseEngineVehicleIdChange = (event) => {
    setMotorBaseEngineVehicleId(event.value);
  };

  const saveMotorData = () => {
    if (
      motorMakeValue !== '' &&
      motorModelValue !== '' &&
      motorEngineValue !== '' &&
      motorBaseEngineVehicleId !== ''
    ) {
      const data = {
        makeId: motorMakeValue,
        modelId: motorModelValue,
        engineId: motorEngineValue,
        baseVehicleId: motorBaseEngineVehicleId,
      };

      const postData = async () => {
        setSaving(true);
        const value = await carsApi.saveMotorData(_id, data);
        if (value.message === 'success') {
          window.location.reload();
        }
        setSaving(false);
      };
      postData();
    }
  };

  return (
    <div className="car-meta-data">
      <h5 className="mb-4">{`${make}-${model}-${year}`}</h5>
      <h7 className="mb-1" style={{ color: 'grey' }}>
        Make
      </h7>
      <Select
        placeholder="Select Make"
        isLoading={isLoadingMake}
        {...props}
        closeMenuOnScroll="true"
        value={{
          label: `${motorMakeLabel}-${motorMakeValue}`,
          value: motorMakeValue,
        }}
        className="mb-4"
        options={motorMake}
        onChange={handleMakeChange}
      />
      <h7 className="mb-1" style={{ color: 'grey' }}>
        Model
      </h7>
      <Select
        placeholder="Select Model"
        isLoading={isLoadingModel}
        {...props}
        value={{
          label: `${motorModelLabel}-${motorModelValue}`,
          value: motorModelValue,
        }}
        closeMenuOnScroll="true"
        className="mb-4"
        options={motorModel}
        onChange={handleModelChange}
      />
      <h7 className="mb-1" style={{ color: 'grey' }}>
        Engine
      </h7>
      <Select
        placeholder="Select Engine"
        isLoading={isLoadingEngine}
        {...props}
        value={{
          label: `${motorEngineLabel}-${motorEngineValue}`,
          value: motorEngineValue,
        }}
        closeMenuOnScroll="true"
        className="mb-4"
        options={motorEngine}
        onChange={handleEngineChange}
      />
      <TextField
        style={{ zIndex: '0' }}
        disabled
        id="base-vehicle-id"
        label="Base VehicleID"
        type="string"
        className="mb-4"
        value={motorBaseEngineVehicleId}
        onChange={handleBaseEngineVehicleIdChange}
        fullWidth
        variant="outlined"
      />
      {motorEngineValue !== '' &&
        motorMakeValue !== '' &&
        motorModelValue !== '' &&
        !isSaving && (
          <Button
            className="mb-4"
            variant="outlined"
            color="primary"
            onClick={() => saveMotorData()}
          >
            {isSaving ? <CircularProgress color="secondary" /> : 'Save'}
          </Button>
        )}
    </div>
  );
}

export default CarEditConfiguration;
