import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import { Close } from '@mui/icons-material';
import moment from 'moment';
import renewalsApi from '../api/renewals.api';
import { PAGINATION_OPTIONS } from '../constants';

function RenewalOffersBouncedInvalidList({ appliedFilter }) {
  const startDate = new Date('10/23/2023');
  const columns = [
    {
      headerName: 'Email Address',
      render: (e) => e.recipientAddress,
      field: 'recipientAddress',
      headerClassName: 'custom-dark-theme--header',
      width: 300,
    },
    {
      headerName: 'Offer Sent At',
      valueFormatter: (e) =>
        e.value ? format(parseISO(e.value), 'dd-MMM-yyyy') : '',
      headerClassName: 'custom-dark-theme--header',
      field: 'createdAt',
      width: 120,
    },
    {
      headerName: 'Type',
      render: (e) => e.type,
      field: 'type',
      headerClassName: 'custom-dark-theme--header',
      width: 180,
    },
    {
      headerName: 'Reason',
      render: (e) => e.reason,
      field: 'reason',
      headerClassName: 'custom-dark-theme--header',
      width: 150,
    },
    {
      headerName: 'Offer Metadata',
      render: (e) => e.metadata,
      field: 'metadata',
      valueGetter: (params) => `${JSON.stringify(params.row.metadata)}`,
      headerClassName: 'custom-dark-theme--header',
      width: 800,
    },
  ];
  const [dataSource, setDataSource] = useState([]);
  const [pageSize, setPageSize] = React.useState(PAGINATION_OPTIONS[2]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [showSnackbar, setShowsnackbar] = useState(false);
  const [message, setMessage] = useState('');
  const [selectedWeek, setSelectedWeek] = useState(null);

  const handleClose = () => {
    setShowsnackbar(false);
  };
  const dispatch = useDispatch();

  const updateDataSource = (data) => {
    const startIndex =
      dataSource && dataSource.length
        ? dataSource[dataSource.length - 1]._id + 1
        : 0;
    const _updatedDataSource = dataSource.concat(
      data.map((item, index) => {
        return { ...item, _id: index + startIndex };
      })
    );
    setDataSource(_updatedDataSource);
    return _updatedDataSource;
  };

  const fetchReport = async (skip = dataSource.length, limit = pageSize) => {
    const params = {
      skip,
      limit,
      filter: JSON.stringify({
        ...appliedFilter,
      }),
    };
    setIsLoading(true);

    return renewalsApi.getRenewalOffersBouncedList(params, dispatch);
  };

  useEffect(() => {
    const loadData = async () => {
      const reports = await fetchReport();
      if (reports && reports.data) {
        updateDataSource(reports.data);
        setRowCount(reports.count);
      }
      setIsLoading(false);
    };
    loadData();
  }, [page]);

  useEffect(() => {
    const loadData = async () => {
      if (page !== 0) {
        setDataSource([]);
        return;
      }
      setIsLoading(true);
      const reports = await fetchReport(0);
      if (reports && reports.data) {
        setDataSource(
          reports.data.map((item, index) => {
            return { ...item, _id: index };
          })
        );
        if (reports.count !== rowCount) {
          setRowCount(reports.count);
        }
        setPage(0);
      }
      setIsLoading(false);
    };
    loadData();
  }, [appliedFilter]);

  const handlePageSizeChange = (params) => {
    setPageSize(params.pageSize);
  };

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const handleTextChange = (event) => {
    if (event.target.value != null) {
      if (
        /^[a-zA-Z0-9][.a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(
          event.target.value
        )
      ) {
        setEmailAddress(event.target.value);
      } else {
        setEmailAddress('');
      }
    }
  };

  const downloadReport = async () => {
    if (selectedWeek == null) {
      setMessage('Select Week');
      setShowsnackbar(true);
      return;
    }
    if (emailAddress.trim().length === 0) {
      setMessage('Enter valid Email');
      setShowsnackbar(true);
      return;
    }
    const params = {
      recepientEmail: emailAddress,
      filter: selectedWeek,
    };
    const results = await renewalsApi.getRenewalOffersBouncedListDownload(
      params,
      dispatch
    );
    setEmailAddress('');
    setShowsnackbar(true);
    setMessage(results.message);
  };

  const selectWeekOptions = () => {
    const weeks = moment().diff(startDate, 'weeks');
    const weekArr = [];
    for (let i = 0; i < weeks + 1; i++) {
      const st = new Date(startDate);
      const ed = new Date(startDate);
      st.setDate(startDate.getDate() + 7 * i);
      ed.setDate(startDate.getDate() + 7 * (i + 1) - 1);
      weekArr.push({ createdAt: { $gte: `ISO${st}`, $lte: `ISO${ed}` } });
    }
    return weekArr;
  };

  return (
    <div className="container">
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      <div className="row justify-content-between">
        <div className="col-6">
          <h5 className="heading-left">Renewal Offers Bounced</h5>
        </div>
        <div className="col-2">
          <ReportDownloadDialog
            itemName="Download Report"
            onSend={downloadReport}
          >
            <div style={{ width: '100%' }}>
              <InputLabel>Select Week</InputLabel>
              <select
                style={{ width: '100%' }}
                onChange={(ev) => setSelectedWeek(ev.target.value)}
                label="Select Week"
              >
                <option disabled selected>
                  Select Week
                </option>
                {selectWeekOptions().map((opt, index) => {
                  return (
                    <option key={index} value={JSON.stringify(opt)}>
                      Week {index + 1}{' '}
                      {`(${new Date(
                        opt.createdAt.$gte
                      ).toDateString()}-${new Date(
                        opt.createdAt.$lte
                      ).toDateString()})`}
                    </option>
                  );
                })}
              </select>

              <TextField
                id="outlined-basic"
                label="Your email address"
                variant="standard"
                fullWidth
                onChange={handleTextChange}
              />
            </div>
          </ReportDownloadDialog>
          {/* <Button
            disabled={emailAddress === ''}
            variant="contained"
            onClick={downloadReport}
          >
            Download Report
          </Button> */}
        </div>
      </div>
      <div
        style={{
          height: '50vh',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <DataGrid
            pageSize={pageSize}
            page={page}
            pagination
            rowsPerPageOptions={PAGINATION_OPTIONS}
            onPageSizeChange={handlePageSizeChange}
            rowCount={rowCount}
            getRowId={(row) => row._id}
            rows={dataSource}
            columns={columns}
            onPageChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
}

function ReportDownloadDialog({ itemName, children, onSend }) {
  const [isOpen, setIsOpen] = useState(false);

  const onSendWrap = () => {
    onSend();
    setIsOpen(false);
  };
  const closeDialog = () => {
    setIsOpen(false);
  };
  return (
    <>
      <Button variant="contained" onClick={() => setIsOpen(true)}>
        Download Report
      </Button>
      <Dialog open={isOpen}>
        <DialogTitle disableTypography className="d-flex align-items-center">
          <Typography variant="h6" className="flex-grow-1">
            {`Filter ${itemName}`}
          </Typography>
          <IconButton onClick={closeDialog}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            className="mr-2"
            onClick={onSendWrap}
          >
            Send
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default RenewalOffersBouncedInvalidList;
