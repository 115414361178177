import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import moment from 'moment';
import reportApi from '../api/report.api';
import { PAGINATION_OPTIONS } from '../constants';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

function RepairBookingsList({ appliedFilter, viewHeight = '50vh' }) {
  const columns = [
    {
      headerName: 'Created',
      renderCell: (c) =>
        moment(c.value).format(process.env.REACT_APP_DATE_TIME_FORMAT),
      field: 'createdAt',
      headerClassName: 'custom-dark-theme--header',
      width: 160,
    },
    {
      headerName: 'Booking#',
      render: (e) => e.bookingId,
      field: 'bookingId',
      headerClassName: 'custom-dark-theme--header',
      width: 130,
    },
    {
      headerName: 'Status',
      render: (e) => e.bookingStatus,
      field: 'bookingStatus',
      headerClassName: 'custom-dark-theme--header',
      width: 180,
    },
    {
      headerName: 'Drop Off Date/Time',
      renderCell: (c) => `${c.row.bookingDate} / ${c.row.dropOffSlot}`,
      field: 'bookingDate',
      headerClassName: 'custom-dark-theme--header',
      width: 150,
    },
    {
      headerName: 'Customer Name & Email',
      field: 'customer.email',
      renderCell: (c) =>
        `${c.row.customer.firstName} ${c.row.customer.lastName} (${c.row.customer.email})`,
      headerClassName: 'custom-dark-theme--header',
      width: 340,
    },
    {
      headerName: 'Vehicle / Contract#',
      field: 'vehicle.vin',
      renderCell: (c) => `${c.row.vehicle.vin} / ${c.row.contractNumber}`,
      headerClassName: 'custom-dark-theme--header',
      width: 220,
    },
    {
      headerName: 'Garage Name & Id',
      field: 'garage.name',
      renderCell: (c) => `${c.row.garage.name} (${c.row.garage.id})`,
      headerClassName: 'custom-dark-theme--header',
      width: 300,
    },
    {
      headerName: 'Notes',
      render: (e) => e.notes,
      field: 'notes',
      headerClassName: 'custom-dark-theme--header',
      width: 300,
    },
    {
      headerName: 'Cancel Reason (If cancelled)',
      render: (e) => e.cancelReason,
      field: 'cancelReason',
      headerClassName: 'custom-dark-theme--header',
      width: 300,
    },
  ];
  const [dataSource, setDataSource] = useState([]);
  const [pageSize, setPageSize] = React.useState(PAGINATION_OPTIONS[0]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const updateDataSource = (data) => {
    const startIndex =
      dataSource && dataSource.length
        ? dataSource[dataSource.length - 1]._id + 1
        : 0;
    const _updatedDataSource = dataSource.concat(
      data.map((item, index) => {
        return { ...item, _id: index + startIndex };
      })
    );
    setDataSource(_updatedDataSource);
    return _updatedDataSource;
  };

  const fetchReport = async () => {
    const params = {
      page: page + 1,
      limit: pageSize,
      filter: JSON.stringify({
        ...appliedFilter,
      }),
    };
    setIsLoading(true);

    return reportApi.getRepairBookingsList(params, dispatch);
  };

  useEffect(() => {
    const loadData = async () => {
      const reports = await fetchReport();
      if (reports && reports.data) {
        updateDataSource(reports.data);
        setRowCount(reports.totalCount);
      }
      setIsLoading(false);
    };
    loadData();
  }, [page, pageSize]);

  const handlePageChange = (params) => {
    setPage(params.page);
    setPageSize(params.pageSize);
  };

  const handlePageSizeChange = (params) => {
    setPage(params.page);
    setPageSize(params.pageSize);
  };

  return (
    <div className="container">
      <div
        style={{
          height: viewHeight,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <DataGrid
            density="compact"
            paginationModel={{ page, pageSize }}
            pagination
            pageSizeOptions={PAGINATION_OPTIONS}
            onPageSizeChange={handlePageSizeChange}
            rowCount={rowCount}
            getRowId={(row) => row._id}
            rows={dataSource}
            columns={columns}
            onPaginationModelChange={handlePageChange}
            slots={{
              toolbar: CustomToolbar,
            }}
          />
        )}
      </div>
    </div>
  );
}

export default RepairBookingsList;
