import React from 'react';
import { Chart as ChartJS, ArcElement, Title, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Title, Tooltip, Legend);

const getBackGroundColor = (label) => {
  switch (label.toLowerCase()) {
    case 'delivery':
    case 'delivered':
    case 'approved':
    case 'sent':
    case 'bounce':
    case 'pending':
    case 'mobile':
    case 'self':
    case 'form_service':
    case 'pending sent':
    case 'cuna':
      return 'rgb(255, 99, 132,0.4)';
    case 'accepted':
    case 'welcome_kit':
    case 'invitation':
    case 'website':
    case 'accepted sent':
    case 'undelivered':
    case 'carmax':
      return 'rgb(75, 192, 192, 0.4)';
    case 'invalidemail':
    case 'autonation':
      return 'rgba(255, 159, 64, 0.4)';
    case 'blocked':
    case 'enterprise':
      return 'rgba(153, 102, 255, 0.4)';
    case 'loyal_dealers':
      return 'rgba(230, 246, 157, 0.4)';
    case 'fe':
      return 'rgba(170, 222, 167, 0.4)';
    case 'other':
      return 'rgba(121, 130, 185, 0.4)';
    default:
      return 'rgba(160, 160, 120, 0.4)';
  }
};

function CustomPie({ title, stats }) {
  if (!stats || stats.length === 0) {
    return (
      <>
        <div>
          <span className="statsSubTitle">{title}</span>
        </div>
        <div style={{ color: 'grey' }}>No Data Found</div>
      </>
    );
  }
  const data = {
    labels: stats.map((item) => item.status),
    datasets: [
      {
        label: '# of Votes',
        data: stats.map((item) => item.count),
        backgroundColor: stats.map((item) => getBackGroundColor(item.status)),
        borderWidth: 1,
      },
    ],
  };
  const option = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: title,
      },
    },
  };
  return <Pie data={data} options={option} />;
}

export default CustomPie;
