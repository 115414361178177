export function generateStatusData(region, regionalStatus, clientType) {
  if (!regionalStatus) {
    return [];
  }
  if (clientType === 'GEICO') {
    return [
      { title: 'Show Quote', status: regionalStatus.getRatesStatus },
      {
        title: 'Customer Checkout',
        status: regionalStatus.customerCheckoutStatus,
      },
      {
        title: 'Payment Webhook',
        status: regionalStatus.paymentWebhookStatus,
      },
      {
        title: 'Create Contract',
        status: regionalStatus.createContractStatus,
      },
      {
        title: 'Contract Inquiry and Document PDF',
        status: regionalStatus.createPaymentUpdateStatus,
      },
      {
        title: 'Confirmation Email',
        status: regionalStatus.sendConfirmationEmailStatus,
      },
    ];
  }
  if (clientType === 'RAC' || region === 'UK') {
    return [
      {
        title: 'Offer Processed',
        status: regionalStatus.offerRecordProcessedStatus,
      },
      {
        title: 'Offer Email',
        status: regionalStatus.sendOfferEmailRequestStatus,
      },
      { title: 'Get Offer Details', status: regionalStatus.getOfferStatus },
      {
        title: 'Customer Validation Completed',
        status: regionalStatus.customerValidationStatus,
      },
      {
        title: 'Show Product Selection',
        status: regionalStatus.getRatesStatus,
      },
      {
        title: 'Needs & Demands Shown',
        status: regionalStatus.needsAndDemandsStatus,
      },
      {
        title: 'Customer Checkout',
        status: regionalStatus.customerCheckoutStatus,
      },
      {
        title: 'Payment Webhook',
        status: regionalStatus.paymentWebhookStatus,
      },
      {
        title: 'Create Contract',
        status: regionalStatus.createContractStatus,
      },
      {
        title: 'GLOW Activation Callback',
        status: regionalStatus.contractRenewalActivationStatus,
      },
      {
        title: 'Payment Info Updation',
        status: regionalStatus.createPaymentUpdateStatus,
      },
      {
        title: 'Monthly Schedule Email',
        status: regionalStatus.sendMonthlyScheduleEmailStatus,
      },
    ];
  }
  if (clientType === 'SUBSCRIPTION' || region === 'US') {
    return [
      {
        title: 'Offer Processed',
        status: regionalStatus.offerRecordProcessedStatus,
      },
      {
        title: 'Offer Email',
        status: regionalStatus.sendOfferEmailRequestStatus,
      },
      { title: 'Show Offer', status: regionalStatus.getOfferStatus },
      { title: 'Show Quote', status: regionalStatus.getRatesStatus },
      {
        title: 'Customer Checkout',
        status: regionalStatus.customerCheckoutStatus,
      },
      {
        title: 'Payment Webhook',
        status: regionalStatus.paymentWebhookStatus,
      },
      {
        title: 'Create Contract',
        status: regionalStatus.createContractStatus,
      },
      {
        title: 'Contract Inquiry and Document PDF',
        status: regionalStatus.createPaymentUpdateStatus,
      },
      {
        title: 'Confirmation Email',
        status: regionalStatus.sendConfirmationEmailStatus,
      },
    ];
  }
  return [];
}
