import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

function ConfirmationModal(props) {
  const { onConfirm, onCancel, show, headerText, bodyText, yesCta, noCta } =
    props;

  return (
    <Dialog open={show}>
      {headerText && <DialogTitle>{headerText}</DialogTitle>}
      <DialogContent>
        <DialogContentText>{bodyText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className="d-flex">
          <Button
            variant="contained"
            color="primary"
            className="mr-2"
            onClick={onConfirm}
          >
            {yesCta || 'Yes'}
          </Button>
          <Button variant="outlined" color="primary" onClick={onCancel}>
            {noCta || 'No'}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationModal;
