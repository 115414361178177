import carsBackgroundImagesApi from '../../api/carBackgroundImages.api';
import {
  GET_CAR_BACKGROUND_IMAGES,
  CREATE_CAR_BACKGROUND_IMAGE,
  UPDATE_CAR_BACKGROUND_IMAGE,
  DELETE_CAR_BACKGROUND_IMAGE,
} from '../types';

export const getCarBackgroundImagesAction = () => async (dispatch) => {
  const images = await carsBackgroundImagesApi.getCarBackgroundImages(dispatch);
  dispatch({
    type: GET_CAR_BACKGROUND_IMAGES,
    payload: images,
  });
};

export const createCarBackgroundImageAction = (data) => async (dispatch) => {
  const image = await carsBackgroundImagesApi.createCarBackgroundImage(
    dispatch,
    data
  );
  dispatch({
    type: CREATE_CAR_BACKGROUND_IMAGE,
    payload: image,
  });
  dispatch(getCarBackgroundImagesAction());
};

export const updateCarBackgroundImageAction = (data) => async (dispatch) => {
  const image = await carsBackgroundImagesApi.updateCarBackgroundImage(
    dispatch,
    data
  );
  dispatch({
    type: UPDATE_CAR_BACKGROUND_IMAGE,
    payload: image,
  });
  dispatch(getCarBackgroundImagesAction());
};

export const deleteCarBackgroundImageAction = (id) => async (dispatch) => {
  await carsBackgroundImagesApi.deleteCarBackgroundImage(dispatch, id);
  dispatch({
    type: DELETE_CAR_BACKGROUND_IMAGE,
  });
  dispatch(getCarBackgroundImagesAction());
};
