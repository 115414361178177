import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  IconButton,
  DialogActions,
  Button,
  Typography,
  Alert,
} from '@mui/material';
import { Create } from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';
import fileUploadApi from '../api/fileUpload.api';

/**
 * Model
 *  - _id
 *  - name
 *  - originalImage
 */

function CarBackgroundUpdateModal(props) {
  const { image, save, cancel } = props;

  const [name, setName] = useState('');
  const [originalImage, setOriginalImage] = useState('');
  const [smallImage, setSmallImage] = useState('');
  const [newFile, setNewFile] = useState(null);
  const [newSmallFile, setNewSmallFile] = useState(null);
  const [newFileDataUrl, setNewFileDataUrl] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [dropError, setDropError] = useState('');
  const [hasDropped, setHasDropped] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const permissionss = useSelector((state) =>
    typeof state.permissions.permissions !== typeof undefined
      ? state.permissions.permissions
      : null
  );

  const createSmallImage = (dataUrl, smFileName, mimeType) => {
    const smallImageSize = 250;
    const smImage = new Image();
    // smImage.width = smallImageSize;
    // smImage.height = smallImageSize;
    smImage.src = dataUrl;
    smImage.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = smallImageSize;
      canvas.height = smallImageSize;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(smImage, 0, 0, smallImageSize, smallImageSize);
      ctx.canvas.toBlob(
        (blob) => {
          const file = new File([blob], smFileName, { type: mimeType });
          setNewSmallFile(file);
          setSmallImage(smFileName);
          setIsUploading(false);
        },
        mimeType,
        1
      );
    };
  };

  const onDrop = useCallback(
    ([file]) => {
      setHasDropped(true);
      setNewFile(file);
      setOriginalImage(file.name);
      const splitName = file.name.split('.');
      const extension = splitName.length > 1 ? splitName.pop() : '';
      const filename = splitName.join('.');
      if (!name) {
        setName(filename);
      }
      const reader = new FileReader();
      reader.onabort = () => setDropError('File failed to upload');
      reader.onerror = () => setDropError('File failed to upload');
      reader.onload = async () => {
        setDropError('');
        setNewFileDataUrl(reader.result);
        createSmallImage(
          reader.result,
          `${filename}-small.${extension}`,
          file.type
        );
      };
      reader.readAsDataURL(file);
    },
    [name]
  );
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: 'image/*',
  });

  const uploadFile = async (imageName, file, fileType) => {
    const fileUrl = await fileUploadApi.getUploadUrl({
      type: 'backgroundImage',
      parameters: {},
      imageName,
      contentType: fileType,
    });
    // uploadToS3
    await fileUploadApi.uploadToS3(fileUrl, file, fileType);
  };

  const onSave = async () => {
    setIsSaving(true);
    try {
      if (hasDropped) {
        await Promise.all([
          uploadFile(originalImage, newFile, newFile.type),
          uploadFile(smallImage, newSmallFile, newFile.type),
        ]);
      }
      await save({
        _id: image._id,
        name,
        originalImage,
        smallImage,
      });
      cancel();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('error', err);
    }
    setIsSaving(false);
  };

  useEffect(() => {
    if (Object.keys(image).length) {
      setName(image.name);
      setOriginalImage(image.originalImage);
      setSmallImage(image.smallImage);
      setHasDropped(false);
    } else {
      setIsUploading(true);
    }
  }, [image]);
  const color = isDragAccept
    ? 'rgb(183, 223, 185)'
    : isDragReject
    ? 'rgb(250, 179, 174)'
    : '#999';
  return (
    <Dialog open fullWidth maxWidth="md">
      <DialogTitle>{image ? image.name : 'New Image'}</DialogTitle>
      <DialogContent>
        {dropError && <Alert severity="error">{dropError}</Alert>}
        {isUploading ? (
          <div
            {...getRootProps()}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: 'dashed 2px #999',
              margin: '1rem',
              fontSize: '2rem',
              width: '95%',
              height: '50vh',
              borderColor: color,
              color,
            }}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              isDragReject ? (
                <p className="font-weight-bold">File must be an image</p>
              ) : (
                <p>Drop files here</p>
              )
            ) : (
              <p>Drag and drop a PNG here, or click to select an image</p>
            )}
          </div>
        ) : (
          <>
            {permissionss.CAR_BACKGROUNDS.WRITE && (
              <TextField
                id="background-image-name"
                label="Name"
                type="text"
                className="mb-4"
                value={name}
                fullWidth
                onChange={(e) => setName(e.currentTarget.value)}
              />
            )}
            <div className="background-image">
              <div className="d-flex align-items-center">
                <Typography variant="subtitle1">Current Image</Typography>
                {permissionss.CAR_BACKGROUNDS.WRITE && (
                  <IconButton onClick={() => setIsUploading(true)}>
                    <Create color="primary" />
                    <Typography className="ml-1" color="primary">
                      Change
                    </Typography>
                  </IconButton>
                )}
              </div>
              {!newFile ? (
                <img
                  style={{ maxWidth: '100%' }}
                  src={image.signedOriginalImageUrl}
                  alt={image.originalFile}
                />
              ) : (
                <img
                  src={newFileDataUrl}
                  alt={originalImage}
                  style={{ width: '100%' }}
                />
              )}
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions>
        {permissionss.CAR_BACKGROUNDS.WRITE && (
          <Button
            onClick={onSave}
            color="primary"
            variant="contained"
            disabled={!name || !originalImage || isSaving}
            hidden={!permissionss.CAR_BACKGROUNDS.WRITE}
          >
            Save
          </Button>
        )}
        <Button onClick={cancel} color="primary" variant="outlined">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CarBackgroundUpdateModal;
