import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  List,
  ListItemText,
  ListItem,
  ListItemIcon,
  Typography,
  IconButton,
} from '@mui/material';
import { Add, Close } from '@mui/icons-material';
import { getUsersAction } from '../store/actions/user.actions';
import './UserSearch.scss';

const HARD_LIMIT = 5;

function UserSearch(props) {
  const { show, addUser, onHide } = props;
  const users = useSelector((state) => state.user.users);
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);

  const search = async () => {
    setIsSearching(true);
    const filter = {};
    if (name) {
      filter.name = `/${name}/`;
    }
    if (email) {
      filter.email = `/${email}/`;
    }
    await dispatch(
      getUsersAction({
        filter,
        limit: HARD_LIMIT,
      })
    );
    setHasSearched(true);
    setIsSearching(false);
  };

  return (
    <Dialog className="user-search" open={show} onClose={onHide}>
      <DialogTitle disableTypography className="d-flex align-items-center">
        <Typography variant="h6" className="flex-grow-1">
          User Search
        </Typography>
        <IconButton onClick={onHide}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          type="text"
          label="Name"
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
          fullWidth
        />
        <TextField
          type="text"
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
          fullWidth
        />
        {hasSearched && users.data && (
          <List>
            {users.data.map((user) => (
              <ListItem key={user._id}>
                <ListItemIcon onClick={() => addUser(user._id)}>
                  <Add color="primary" />
                </ListItemIcon>
                <ListItemText>{user.name}</ListItemText>
                <ListItemText>{user.email}</ListItemText>
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={search}>
          {isSearching ? <CircularProgress color="secondary" /> : 'Search'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UserSearch;
