import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  LinearProgress,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Grid,
  Button,
} from '@mui/material';
import { Info, Add, Delete } from '@mui/icons-material';
import {
  getCarBackgroundImagesAction,
  updateCarBackgroundImageAction,
  createCarBackgroundImageAction,
  deleteCarBackgroundImageAction,
} from '../store/actions/carBackgroundImages.actions';
import CarBackgroundUpdateModal from '../components/CarBackgroundUpdateModal';
import ConfirmationModal from '../components/ConfirmationModal';

function CarBackgroundImages() {
  const carBackgroundImages = useSelector(
    (state) => state.carBackgroundImages.images
  );
  const [isLoading, setIsLoading] = useState(false);
  const [imageToUpdate, setImageToUpdate] = useState(null);
  const [imageToDelete, setImageToDelete] = useState(null);

  const permissions = useSelector((state) => state.permissions.permissions);

  const dispatch = useDispatch(getCarBackgroundImagesAction());

  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      await dispatch(getCarBackgroundImagesAction());
      setIsLoading(false);
    };
    load();
  }, [dispatch]);

  const saveImage = async (newImage) => {
    setIsLoading(true);
    if (newImage._id) {
      await dispatch(updateCarBackgroundImageAction(newImage));
    } else {
      await dispatch(createCarBackgroundImageAction(newImage));
    }
    setIsLoading(false);
  };

  const deleteImage = async () => {
    await dispatch(deleteCarBackgroundImageAction(imageToDelete._id));
    setImageToDelete(null);
  };

  return (
    <div className="car-background-images">
      <div className="d-flex">
        <h2>Car Background Images</h2>
        {permissions.CAR_BACKGROUNDS.WRITE && (
          <Button
            className="my-3 ml-2"
            color="primary"
            onClick={() => setImageToUpdate({})}
          >
            <Add />
            <span className="ml-2">Add Background</span>
          </Button>
        )}
      </div>
      {isLoading ? (
        <LinearProgress style={{ height: '1rem' }} color="secondary" />
      ) : (
        <Grid container className="car-background-images">
          <Grid item sm={12} className="d-flex justify-content-center">
            <ImageList cellHeight={250} cols={3}>
              {(carBackgroundImages || []).map((image) => (
                <ImageListItem key={image._id} className="p-1">
                  <img src={image.signedSmallImageUrl} alt={image.name} />
                  <ImageListItemBar
                    title={image.name}
                    actionIcon={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <>
                        <IconButton onClick={() => setImageToUpdate(image)}>
                          <Info style={{ color: '#fff' }} />
                        </IconButton>
                        {permissions.CAR_BACKGROUNDS.WRITE && (
                          <IconButton onClick={() => setImageToDelete(image)}>
                            <Delete color="error" />
                          </IconButton>
                        )}
                      </>
                    }
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Grid>
        </Grid>
      )}
      {imageToUpdate && (
        <CarBackgroundUpdateModal
          image={imageToUpdate}
          save={saveImage}
          cancel={() => setImageToUpdate(null)}
        />
      )}
      <ConfirmationModal
        show={!!imageToDelete}
        onConfirm={deleteImage}
        onCancel={() => setImageToDelete(null)}
        headerText="Remove Background"
        bodyText={`Are you sure you want to remove ${
          imageToDelete ? imageToDelete.name : 'this image'
        }?`}
      />
    </div>
  );
}

export default CarBackgroundImages;
