import carsApi from '../../api/cars.api';

import {
  GET_CARS,
  GET_CAR,
  UPDATE_CAR,
  CREATE_CAR,
  DELETE_CAR,
  GET_CAR_CONTRACTS,
  MARK_CREDIT_USED,
  ADD_CREDIT_TRANSACTION,
} from '../types';

export const createCarAction = (data) => async (dispatch) => {
  const user = await carsApi.createCar(dispatch, data);
  dispatch({
    type: CREATE_CAR,
    payload: user,
  });
};

export const getCarsAction = (params) => async (dispatch) => {
  const Cars = await carsApi.getCars(dispatch, params);
  dispatch({
    type: GET_CARS,
    payload: Cars,
  });
};

export const getCarAction = (id) => async (dispatch) => {
  const user = await carsApi.getCar(dispatch, id);
  dispatch({
    type: GET_CAR,
    payload: user,
  });
};

export const getCarContractsAction = (id) => async (dispatch) => {
  const claims = await carsApi.getCarContracts(dispatch, id);
  dispatch({
    type: GET_CAR_CONTRACTS,
    payload: claims,
  });
};

export const updateCarAction = (user, id) => async (dispatch) => {
  const updatedCar = await carsApi.updateCar(dispatch, user, id);
  dispatch({
    type: UPDATE_CAR,
    payload: updatedCar,
  });
};

export const updateCarGloveBoxAction =
  (id, userId, status) => async (dispatch) => {
    const updatedCar = await carsApi.updateGloveBox(
      dispatch,
      id,
      userId,
      status
    );
    dispatch({
      type: UPDATE_CAR,
      payload: updatedCar,
    });
  };

export const updateCarVerificationAction =
  (id, userId, verified) => async (dispatch) => {
    const updatedCar = await carsApi.updateUserVerification(
      dispatch,
      id,
      userId,
      verified
    );
    dispatch({
      type: UPDATE_CAR,
      payload: updatedCar,
    });
  };

export const removeUserFromCarAction = (id, userId) => async (dispatch) => {
  const updatedCar = await carsApi.removeUser(dispatch, id, userId);
  dispatch({
    type: UPDATE_CAR,
    payload: updatedCar,
  });
};

export const addUserToCarAction = (id, userId) => async (dispatch) => {
  const updatedCar = await carsApi.addUser(dispatch, id, userId);
  dispatch({
    type: UPDATE_CAR,
    payload: updatedCar,
  });
};

export const updateOdometerReadingsAction =
  (id, odometerReadings) => async (dispatch) => {
    const updated = await carsApi.updateOdometerReadings(
      dispatch,
      id,
      odometerReadings
    );
    dispatch({
      type: UPDATE_CAR,
      payload: updated,
    });
  };

export const deleteCarAction = (id) => async (dispatch) => {
  await carsApi.deleteCar(dispatch, id);
  dispatch({
    type: DELETE_CAR,
  });
};

export const markCreditUsedAction =
  (id, creditId, userId) => async (dispatch) => {
    await carsApi.markCreditUsed(dispatch, id, creditId, userId);
    dispatch({
      type: MARK_CREDIT_USED,
    });
  };

export const addCreditAction = (id, credit) => async (dispatch) => {
  await carsApi.addCredits(dispatch, id, credit);
  dispatch({
    type: ADD_CREDIT_TRANSACTION,
  });
};
