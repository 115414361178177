import api from '.';

const SMS_URL = `/admin/sms`;

const getSMes = async (dispatch, params) =>
  api.pocketDriveApi(dispatch, 'get', SMS_URL, null, params);

export default {
  getSMes,
};
