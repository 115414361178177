import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import reportApi from '../api/report.api';
import { PAGINATION_OPTIONS } from '../constants';
import GenericPie from './GenericPie';

function DashboadInvitationOverallStats({ appliedFilter }) {
  const columns = [
    {
      headerName: 'Dealer Id',
      render: (e) => e.dealerId,
      field: 'dealerId',
      headerClassName: 'custom-dark-theme--header',
      width: 120,
    },
    {
      headerName: 'Dealer Name',
      render: (e) => e.dealerName,
      headerClassName: 'custom-dark-theme--header',
      field: 'dealerName',
      width: 350,
    },
    {
      headerName: 'Total',
      render: (e) => e.totalInvites,
      field: 'totalInvites',
      headerClassName: 'custom-dark-theme--header',
      width: 100,
    },
    {
      headerName: 'Delivery',
      render: (e) => e.emailDelivered,
      field: 'emailDelivered',
      headerClassName: 'custom-dark-theme--header',
      width: 100,
    },
    {
      headerName: 'Email Open',
      render: (e) => e.emailOpened,
      field: 'emailOpened',
      headerClassName: 'custom-dark-theme--header',
      width: 100,
    },
    {
      headerName: 'Link Open',
      render: (e) => e.linkOpened,
      field: 'linkOpened',
      headerClassName: 'custom-dark-theme--header',
      width: 100,
    },
    {
      headerName: 'Accepted',
      render: (e) => e.inviteAccepted,
      field: 'inviteAccepted',
      headerClassName: 'custom-dark-theme--header',
      width: 100,
    },
    {
      headerName: 'Bounced',
      render: (e) => e.emailBounced,
      field: 'emailBounced',
      headerClassName: 'custom-dark-theme--header',
      width: 100,
    },
    {
      headerName: 'Unsubscribed',
      render: (e) => e.emailUnsubscribed,
      field: 'emailUnsubscribed',
      headerClassName: 'custom-dark-theme--header',
      width: 110,
    },
    {
      headerName: 'Blocked',
      render: (e) => e.emailBlocked,
      field: 'emailBlocked',
      headerClassName: 'custom-dark-theme--header',
      width: 100,
    },
    {
      headerName: 'No Status',
      render: (e) => e.emailNoStatus,
      field: 'emailNoStatus',
      headerClassName: 'custom-dark-theme--header',
      width: 100,
    },
  ];
  const [dataSource, setDataSource] = useState([]);
  const [data, setData] = useState({});
  const [pageSize, setPageSize] = React.useState(PAGINATION_OPTIONS[2]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [invitationSource, setInvitationSource] = useState('');
  const dispatch = useDispatch();

  const updateDataSource = (newData) => {
    const startIndex =
      dataSource && dataSource.length
        ? dataSource[dataSource.length - 1]._id + 1
        : 0;
    const _updatedDataSource = dataSource.concat(
      newData.map((item, index) => {
        return { ...item, _id: index + startIndex };
      })
    );
    setDataSource(_updatedDataSource);
    return _updatedDataSource;
  };

  const fetchReport = async (skip = dataSource.length, limit = pageSize) => {
    const params = {
      skip,
      limit,
      filter: JSON.stringify({
        ...(invitationSource !== '' && { invitationSource }),
        ...appliedFilter,
      }),
    };
    setIsLoading(true);
    return reportApi.getInvitationOverallStats(params, dispatch);
  };

  useEffect(() => {
    const loadData = async () => {
      const reports = await fetchReport();
      if (reports && reports.dealerStat && reports.dealerStat.data) {
        updateDataSource(reports.dealerStat.data);
        setRowCount(reports.dealerStat.totalCount);
      }
      setIsLoading(false);
    };
    loadData();
  }, [page]);

  useEffect(() => {
    const loadData = async () => {
      if (page !== 0) {
        setDataSource([]);
        return;
      }
      setIsLoading(true);
      const reports = await fetchReport(0);
      setData(reports);
      if (reports && reports.dealerStat && reports.dealerStat.data) {
        setDataSource(
          reports.dealerStat.data.map((item, index) => {
            return { ...item, _id: index };
          })
        );
        if (reports.dealerStat.totalCount !== rowCount) {
          setRowCount(reports.dealerStat.totalCount);
        }
        setPage(0);
      }
      setIsLoading(false);
    };
    loadData();
  }, [appliedFilter, invitationSource, page, pageSize]);

  const handlePageChange = (params) => {
    setPage(params.page);
    setPageSize(params.pageSize);
  };

  const handleInvitationTypeChange = (params) => {
    setInvitationSource(params.target.value);
  };

  return (
    <div className="container-fluid">
      <div className="row justify-content-between align-items-center">
        <div className="col-3">
          <h4 style={{ textAlign: 'left' }}>Invitation Stats</h4>
        </div>
        <div className="col-3" style={{ marginTop: '10px' }}>
          <select
            style={{ width: '100%', padding: 10, borderColor: '#ccc' }}
            value={invitationSource}
            onChange={handleInvitationTypeChange}
            defaultValue=""
          >
            <option value="">All</option>
            <option value="WELCOME_KIT">Welcome Kit</option>
            <option value="OPERATIONAL_ACQUISITION">IVR</option>
            <option value="GLOW_AGENT">Glow Agent</option>
            <option value="CUNA">CUNA</option>
            <option value="REIMBURSEMENT_INQUIRY">Reimbursement Inquiry</option>
            <option value="SUBSCRIPTIONS">Subscriptions</option>
            <option value="RENEWALS">Renewals</option>
            <option value="GEICO">Geico</option>
            <option value="RIVIAN">Rivian</option>
            <option value="BULK_INVITE">Bulk Invite</option>
            <option value="FORM_SERVICE">Form Service</option>
            <option value="ADMIN_PANEL_MANUAL_INVITE">
              Admin Panel Manual Invite
            </option>
            <option value="COULD_NOT_DETERMINE">Unknown</option>
          </select>
        </div>
      </div>
      <Row>
        <Col xs={8}>
          <div
            style={{
              height: '50vh',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 10,
            }}
          >
            {isLoading ? (
              <CircularProgress />
            ) : (
              <DataGrid
                density="compact"
                paginationModel={{ page, pageSize }}
                pagination
                pageSizeOptions={PAGINATION_OPTIONS}
                rowCount={rowCount}
                getRowId={(row) => row._id}
                rows={dataSource}
                columns={columns}
                onPaginationModelChange={handlePageChange}
              />
            )}
          </div>
        </Col>
        <Col xs={4}>
          <GenericPie
            labelKey="status"
            stats={data.inviteStat}
            title="Invitations"
          />
        </Col>
      </Row>
    </div>
  );
}

export default DashboadInvitationOverallStats;
