import React, { useEffect, useState } from 'react';
import {
  Typography,
  LinearProgress,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import moment from 'moment';

import { useDispatch } from 'react-redux';
import carsApi from '../api/cars.api';

function RepairRequest(props) {
  // used to fetch the data for tab
  const {
    car: { _id },
  } = props;

  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(true);
  const [claims, setClaims] = useState([]);

  // this is called once to get the all details needed for this tab
  useEffect(() => {
    const getPData = async () => {
      setLoading(true);
      carsApi.getClaims(_id, dispatch).then((res) => {
        if (res.data !== '') {
          setClaims(res.data);
        }
        setLoading(false);
      });
    };
    getPData();
  }, []);

  function getCNPTFN(contractNumber, formNumber, productType) {
    return `${contractNumber} / ${formNumber} / ${productType}`;
  }

  return (
    <div>
      {!isLoading ? (
        claims.length === 0 ? (
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
            No claims available.
          </Typography>
        ) : (
          <TableContainer component={Paper}>
            <Table size="small" padding="none">
              <TableHead className="bg-dark">
                <TableRow>
                  <TableCell className="text-center text-white">Date</TableCell>
                  <TableCell className="text-center text-white">
                    Request Number
                  </TableCell>
                  <TableCell className="text-center text-white">
                    Contract Number / Form Number / Product Type
                  </TableCell>
                  <TableCell className="text-center text-white">
                    Assistance Required - YES / NO
                  </TableCell>
                  <TableCell className="text-center text-white">
                    Category value
                  </TableCell>
                  <TableCell className="text-center text-white">
                    TOWED / ONSITE
                  </TableCell>
                  <TableCell className="text-center text-white">
                    Mileage value
                  </TableCell>
                </TableRow>
              </TableHead>
              {claims.map((c) => (
                <TableRow>
                  <TableCell className="text-center">
                    {moment(c.createdAt).format('L')}
                  </TableCell>
                  <TableCell className="text-center">
                    {c.requestNumber}
                  </TableCell>
                  <TableCell className="text-center">
                    {getCNPTFN(c.contractNumber, c.formNumber, c.productType)}
                  </TableCell>
                  <TableCell className="text-center">
                    {c.roadAssistanceRequired}
                  </TableCell>
                  <TableCell className="text-center">
                    {c.repairCategory}
                  </TableCell>
                  <TableCell className="text-center">
                    {c.roadAssistanceOptions}
                  </TableCell>
                  <TableCell className="text-center">
                    {c.enteredMileage}
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </TableContainer>
        )
      ) : (
        <>
          <LinearProgress />
          <Typography color="secondary">Loading...</Typography>
        </>
      )}
    </div>
  );
}

export default RepairRequest;
