import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import moment from 'moment';
import reportApi from '../api/report.api';
import { PAGINATION_OPTIONS } from '../constants';
import JSONViewer from './JSONViewer';

const dateTimeFormat = process.env.REACT_APP_DATE_TIME_FORMAT;

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

function ConversationsList({ appliedFilter, viewHeight = '50vh' }) {
  const columns = [
    {
      headerName: 'Updated At',
      field: 'updatedAt',
      renderCell: (c) => moment(c.value).format(dateTimeFormat),
      headerClassName: 'custom-dark-theme--header',
      width: 160,
    },
    {
      headerName: 'Case Number',
      field: 'caseNumber',
      renderCell: (params) => {
        return (
          <div>
            <JSONViewer title="Case" jsonData={params.row} />
            {params.row.caseNumber}
          </div>
        );
      },
      width: 140,
    },
    {
      headerName: 'Status',
      render: (e) => e.status,
      field: 'status',
      headerClassName: 'custom-dark-theme--header',
      width: 90,
    },
    {
      headerName: 'Source',
      render: (e) => e.source,
      field: 'source',
      headerClassName: 'custom-dark-theme--header',
      width: 90,
    },
    {
      headerName: 'Topic',
      renderCell: (params) => {
        if (params.row.car) {
          return (
            <div
              style={{
                textAlign: 'left',
              }}
            >
              {params.row.subject}
              <br />
              {params.row.issue}
            </div>
          );
        }
        return <div />;
      },
      field: 'topic',
      headerClassName: 'custom-dark-theme--header',
      width: 350,
    },
    {
      headerName: 'Car',
      renderCell: (params) => {
        if (params.row.car) {
          return (
            <div
              style={{
                textAlign: 'left',
              }}
            >
              {params.row.car.year} {params.row.car.make} {params.row.car.model}
              <br />
              {params.row.car.vin}
            </div>
          );
        }
        return <div />;
      },
      field: 'car',
      headerClassName: 'custom-dark-theme--header',
      width: 230,
    },
    {
      headerName: 'User',
      renderCell: (params) => {
        if (params.row.user) {
          return (
            <div
              style={{
                textAlign: 'left',
              }}
            >
              {params.row.user.name}
              <br />
              {params.row.user.email}
            </div>
          );
        }
        return <div />;
      },
      field: 'user',
      headerClassName: 'custom-dark-theme--header',
      width: 300,
    },
  ];
  const [dataSource, setDataSource] = useState([]);
  const [pageSize, setPageSize] = React.useState(PAGINATION_OPTIONS[1]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const updateDataSource = (data) => {
    const startIndex =
      dataSource && dataSource.length
        ? dataSource[dataSource.length - 1]._id + 1
        : 0;
    const _updatedDataSource = dataSource.concat(
      data.map((item, index) => {
        return { ...item, _id: index + startIndex };
      })
    );
    setDataSource(_updatedDataSource);
    return _updatedDataSource;
  };

  const fetchReport = async () => {
    const params = {
      page: page + 1,
      limit: pageSize,
      filter: appliedFilter,
    };
    setIsLoading(true);

    return reportApi.getConversationList(params, dispatch);
  };

  useEffect(() => {
    const loadData = async () => {
      const reports = await fetchReport();
      if (reports && reports.data) {
        updateDataSource(reports.data);
        setRowCount(reports.totalCount);
      }
      setIsLoading(false);
    };
    loadData();
  }, [page, pageSize]);

  const handlePageChange = (params) => {
    setPage(params.page);
    setPageSize(params.pageSize);
  };

  const handlePageSizeChange = (params) => {
    setPage(params.page);
    setPageSize(params.pageSize);
  };

  return (
    <div className="container">
      <div
        style={{
          height: viewHeight,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <DataGrid
            // density="compact"
            paginationModel={{ page, pageSize }}
            pagination
            pageSizeOptions={PAGINATION_OPTIONS}
            onPageSizeChange={handlePageSizeChange}
            rowCount={rowCount}
            getRowId={(row) => row._id}
            rows={dataSource}
            columns={columns}
            onPaginationModelChange={handlePageChange}
            slots={{
              toolbar: CustomToolbar,
            }}
          />
        )}
      </div>
    </div>
  );
}

export default ConversationsList;
