import React from 'react';

import { TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDateTimePicker,
// } from '@material-ui/pickers';
// import MomentUtils from '@date-io/moment';

function CarMetaData(props) {
  const {
    car: { make, model, trim, color, vin, purchasedDate },
  } = props;
  return (
    <div className="car-meta-data">
      <TextField
        id="car-make"
        label="Make"
        type="string"
        className="mb-4"
        value={make}
        fullWidth
        variant="outlined"
      />
      <TextField
        id="car-model"
        label="Model"
        type="string"
        className="mb-4"
        value={model}
        fullWidth
        variant="outlined"
      />
      <TextField
        id="car-trim"
        label="Trim"
        type="string"
        className="mb-4"
        value={trim}
        fullWidth
        variant="outlined"
      />
      <TextField
        id="car-color"
        label="Color"
        type="string"
        className="mb-4"
        value={(color && color.simpleColor) || 'N/A'}
        fullWidth
        variant="outlined"
      />
      <TextField
        id="car-vin"
        label="VIN"
        type="string"
        className="mb-4"
        value={vin}
        fullWidth
        variant="outlined"
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          id="car-purchased"
          label="Purchased"
          value={purchasedDate}
          defaultValue={purchasedDate}
          readOnly
          inputVariant="outlined"
        />
      </LocalizationProvider>
    </div>
  );
}

export default CarMetaData;
