import React, { useEffect, useState } from 'react';
import { LinearProgress, Button, Card } from '@mui/material';
import { addHours, addMonths, addDays, format } from 'date-fns';
import { Row, Col } from 'react-bootstrap';
import DateFnsUtils from '@date-io/date-fns';
import { useSelector, useDispatch } from 'react-redux';
import {
  CarRental,
  CarRentalTwoTone,
  Code,
  SellOutlined,
} from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import FilterModal from '../components/Filter';
import reportApi from '../api/report.api';
import dealersApi from '../api/dealer.api';
import GenericPie from '../components/GenericPie';
import SellingDealersList from '../components/SellingDealers';

function ClientsStats() {
  const [clientsStats, setClientsStatsData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [appliedFilter, setAppliedFilter] = useState({});
  const [filterDisplay, setFilterDisplay] = useState('');
  const [filterBeforeDate, setFilterBeforeDate] = useState(null);
  const [filterAfterDate, setFilterAfterDate] = useState(null);
  const [applyQuickFilter, setApplyQuickFilter] = useState(false);
  const [dealerIds, setDealerIds] = useState([]);
  const [otherDealerIds, setOtherDealerIds] = useState([]);
  const [dealerType, setDealerType] = useState('');
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.permissions.permissions);

  const saveFilters = () => {
    const newFilter = {};
    const displays = [];
    const filterStartDate = new Date(filterAfterDate);
    const filterEndDate = new Date(filterBeforeDate);
    let dateDisplay = '';
    if (filterAfterDate) {
      newFilter.createdAt = { $gte: `${filterStartDate}` };
      dateDisplay = `Dates: ${format(filterStartDate, 'MM/dd/yyyy')}`;
    }
    if (filterBeforeDate) {
      newFilter.createdAt = {
        ...newFilter.createdAt,
        $lte: `${filterEndDate}`,
      };
      if (!dateDisplay) {
        dateDisplay = `Dates: -${format(filterEndDate, 'MM/dd/yyyy')}`;
      } else {
        dateDisplay = `${dateDisplay}-${format(filterEndDate, 'MM/dd/yyyy')}`;
      }
    }
    if (dealerType) {
      switch (dealerType) {
        case 'CUNA':
          newFilter.clientType = dealerType;
          break;
        case 'Welcome_Kit':
          newFilter.sellingDealerId = { $in: dealerIds };
          break;
        case 'Other':
          newFilter.sellingDealerId = { $in: otherDealerIds };
          break;
        default:
          break;
      }
    }

    if (dateDisplay) {
      displays.push(dateDisplay);
    }

    setAppliedFilter(newFilter);
    setFilterDisplay(displays.join(' '));
  };

  useEffect(() => {
    if (applyQuickFilter && !!filterAfterDate) {
      setApplyQuickFilter(false);
      saveFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyQuickFilter, filterAfterDate]);

  const setLastDayFilter = () => {
    setFilterAfterDate(addHours(new Date(), -24));
    setApplyQuickFilter(true);
  };

  const setLastMonthFilter = () => {
    setFilterAfterDate(addMonths(new Date(), -1));
    setApplyQuickFilter(true);
  };

  const setLastSevenDays = () => {
    setFilterAfterDate(addDays(new Date(), -7));
    setApplyQuickFilter(true);
  };

  const resetFilters = () => {
    setFilterAfterDate(null);
    setFilterBeforeDate(null);
    setFilterDisplay('');
    setAppliedFilter({});
  };

  useEffect(() => {
    const loadData = async () => {
      let params = {
        filter: JSON.stringify(appliedFilter),
      };
      if (!appliedFilter || !appliedFilter.createdAt) {
        params = {
          filter: JSON.stringify({}),
        };
      }
      const result = await reportApi.getClientsStats(params);
      setClientsStatsData({ ...result });
      setIsLoading(false);
    };
    setIsLoading(true);
    loadData();
  }, [appliedFilter]);

  async function fetchWelcomeKitIds(
    pipeLine = { vcoRegistrationPreference: { $eq: 'Y' } },
    stateToUpdate = null
  ) {
    const params = {
      filter: JSON.stringify(pipeLine),
    };
    const response = await dealersApi.getAllUserDealer(params, dispatch);
    if (response && response.data) {
      const dealers = response.data.map((dealer) => dealer.dealerId);
      if (stateToUpdate === 'dealerIds') {
        setDealerIds(dealers); // Update dealerIds state
      } else if (stateToUpdate === 'otherDealerIds') {
        setOtherDealerIds(dealers); // Update otherDealerIds state
      }
    }
  }

  useEffect(() => {
    fetchWelcomeKitIds(
      { vcoRegistrationPreference: { $eq: 'Y' } },
      'dealerIds'
    );
    fetchWelcomeKitIds(
      { vcoRegistrationPreference: { $ne: 'Y' } },
      'otherDealerIds'
    );
  }, []);

  useEffect(() => {
    if (dealerType) {
      saveFilters();
    }
  }, [dealerType]);

  const handleDealerIdsChange = (event) => {
    const selectedDealerType = event.target.value;
    if (selectedDealerType === '') {
      setDealerType('');
      setAppliedFilter({});
    } else {
      setDealerType(selectedDealerType);
    }
  };

  if (isLoading) {
    return (
      <div className="container">
        <div className="row my-2">
          <h3>Clients Stats</h3>
        </div>
        <LinearProgress style={{ height: '1rem' }} color="secondary" />
      </div>
    );
  }
  return (
    <div
      className="container"
      style={{
        display: permissions.SUPPORT.READ ? 'block' : 'none',
      }}
    >
      <div>
        <h2 className="d-flex align-content-center align-items-center mb-0">
          <span className="mr-4">Car Clients Stats</span>
          <div className="flex-grow-1" />
          {appliedFilter && !Object.keys(appliedFilter).length && (
            <>
              <Button color="primary" onClick={setLastDayFilter}>
                Last 24 Hours
              </Button>
              <Button color="primary" onClick={setLastSevenDays}>
                Last 7 Days
              </Button>
              <Button color="primary" onClick={setLastMonthFilter}>
                Last month
              </Button>
            </>
          )}
          <FilterModal
            currentFilters={appliedFilter}
            onSave={saveFilters}
            itemName="Stats"
            resetFilters={resetFilters}
            filterDisplay={filterDisplay}
          >
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  utils={DateFnsUtils}
                  label="After Date (including)"
                  variant="inline"
                  className="mr-2"
                  fullWidth
                  id="after-date-filter"
                  value={filterAfterDate}
                  onChange={setFilterAfterDate}
                  autoOk="true"
                />
              </LocalizationProvider>
            </div>
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  utils={DateFnsUtils}
                  label="Before Date (including)"
                  variant="inline"
                  className="mr-2"
                  fullWidth
                  id="before-date-filter"
                  value={filterBeforeDate}
                  onChange={setFilterBeforeDate}
                  autoOk="true"
                />
              </LocalizationProvider>
            </div>
          </FilterModal>
        </h2>
      </div>
      <Card className="p-2">
        <h5 style={{ textAlign: 'left' }}>Overall</h5>
        <Row className="t-4">
          <Col>
            <div
              className="d-flex justify-content-left align-items-center"
              style={{ paddingLeft: 20 }}
            >
              <CarRental color="disabled" fontSize="large" />
              <p className="card-content-align">
                <b>{clientsStats.carStats.totalCars}</b>
                <br />
                <span>Total Cars</span>
              </p>
            </div>
          </Col>
          <Col>
            <div
              className="d-flex justify-content-left align-items-center"
              style={{ paddingLeft: 20 }}
            >
              <CarRentalTwoTone color="disabled" fontSize="large" />
              <p className="card-content-align">
                <b>{clientsStats.carStats.activeCars}</b>
                <br />
                <span>Active Cars</span>
              </p>
            </div>
          </Col>
          <Col>
            <div
              className="d-flex justify-content-left align-items-center"
              style={{ paddingLeft: 20 }}
            >
              <Code color="disabled" fontSize="large" />
              <p className="card-content-align">
                <b>{clientsStats.carStats.classificationCode}</b>
                <br />
                <span>Classification Codes</span>
              </p>
            </div>
          </Col>
          <Col>
            <div
              className="d-flex justify-content-left align-items-center"
              style={{ paddingLeft: 20 }}
            >
              <SellOutlined color="disabled" fontSize="large" />
              <p className="card-content-align">
                <b>{clientsStats.carStats.sellingDealers}</b>
                <br />
                <span>Selling Dealers</span>
              </p>
            </div>
          </Col>
        </Row>
      </Card>
      <br />
      <br />
      <Card>
        <Row className="t-1">
          <Col>
            <div
              style={{
                height: '40vh',
                position: 'relative',
                marginBottom: '1%',
                padding: '1%',
              }}
            >
              <GenericPie
                title="Client Types"
                stats={clientsStats.clientTypes}
                labelKey="name"
                legendPosition="right"
              />
            </div>
          </Col>
        </Row>
        <Row className="t-2">
          <Col>
            <div
              style={{
                height: '40vh',
                position: 'relative',
                marginBottom: '1%',
                padding: '1%',
              }}
            >
              <GenericPie
                title="Account Types"
                stats={clientsStats.accountTypes}
                labelKey="name"
                legendPosition="right"
              />
            </div>
          </Col>
          <Col>
            <div
              style={{
                height: '40vh',
                position: 'relative',
                marginBottom: '1%',
                padding: '1%',
              }}
            >
              <GenericPie
                title="Account Number Types"
                stats={clientsStats.accountNumberTypes}
                labelKey="name"
                legendPosition="right"
              />
            </div>
          </Col>
        </Row>
      </Card>
      <br />
      <br />
      <div className="row justify-content-between">
        <div className="col-6">
          <h5 className="heading-left">Selling Dealers</h5>
        </div>
        <div className="col-3">
          <select
            style={{ width: '100%', padding: 10, borderColor: '#ccc' }}
            onChange={handleDealerIdsChange}
            value={dealerType}
          >
            <option value="">All</option>
            <option value="CUNA">Cuna</option>
            <option value="Welcome_Kit">Welcome Kit</option>
            <option value="Other">Other</option>
          </select>
        </div>
      </div>
      <div className="row my-2 ">
        <SellingDealersList appliedFilter={appliedFilter} />
      </div>
    </div>
  );
}

export default ClientsStats;
