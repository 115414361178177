import api from '.';

const CARS_URL = `/admin/cars`;
const CLAIMS_URL = `/admin/claims`;

const CARS_WITH_DATA_ISSUE_URL = `/admin/stats/cars?type=`;
const PUBLIC_CARS_URL = `/public/cars`;

const createCar = async (dispatch, data) => {
  return api.pocketDriveApi(dispatch, 'post', PUBLIC_CARS_URL, data);
};

const getMotorModels = async (carId, makeId, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${CARS_URL}/${carId}/motor/models/${makeId}`,
    null,
    null
  );
};

const getMotorMakes = async (carId, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${CARS_URL}/${carId}/motor/makes`,
    null,
    null
  );
};

const getMotorEngine = async (carId, makeId, modelId, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${CARS_URL}/${carId}/motor/engines/${makeId}/${modelId}`,
    null,
    null
  );
};

const getMotorBaseVehicleId = async (
  carId,
  makeId,
  modelId,
  engineId,
  dispatch
) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${CARS_URL}/${carId}/motor/base/${makeId}/${modelId}/${engineId}`,
    null,
    null
  );
};

// eslint-disable-next-line default-param-last
const getCarWithDataIssues = async (type, skip, limit = 10, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${CARS_WITH_DATA_ISSUE_URL}${type}&skip=${skip}&limit=${limit}`,
    null,
    null
  );
};

const saveMotorData = async (id, data, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'post',
    `${CARS_URL}/${id}/motor/maintenance/populate`,
    data
  );
};

const getCarPopulationData = async (carId, dispatch) => {
  return api._pocketDriveApi(
    dispatch,
    'get',
    `${CARS_URL}/${carId}/getCarPopulationLogs`,
    null,
    null
  );
};

const getClaims = async (carId, dispatch) => {
  return api._pocketDriveApi(
    dispatch,
    'get',
    `${CLAIMS_URL}/${carId}/claims`,
    null,
    null
  );
};

const restartPopulation = async (carId, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'post',
    `${CARS_URL}/${carId}/restartPopulation`,
    null,
    null
  );
};

const getCars = async (dispatch, params) => {
  return api.pocketDriveApi(dispatch, 'get', CARS_URL, null, params);
};

const getCar = async (dispatch, id) => {
  return api.pocketDriveApi(dispatch, 'get', `${CARS_URL}/${id}`);
};

const getCarContracts = async (dispatch, id) => {
  return api.pocketDriveApi(
    api.suppressApiErrors,
    'get',
    `${CARS_URL}/${id}/contracts`
  );
};

const updateCar = async (dispatch, user, id) => {
  return api.pocketDriveApi(dispatch, 'put', `${CARS_URL}/${id}`, user);
};

const updateGloveBox = async (dispatch, id, userId, status) => {
  return api.pocketDriveApi(dispatch, 'put', `${CARS_URL}/${id}/glovebox`, {
    userId,
    status,
  });
};

const updateUserVerification = async (dispatch, id, userId, verified) => {
  return api.pocketDriveApi(
    dispatch,
    'put',
    `${CARS_URL}/${id}/unlockVerification`,
    {
      userId,
      verified,
    }
  );
};

const addUser = async (dispatch, id, userId) => {
  return api.pocketDriveApi(dispatch, 'put', `${CARS_URL}/${id}/${userId}`);
};

const removeUser = async (dispatch, id, userId) => {
  return api.pocketDriveApi(dispatch, 'delete', `${CARS_URL}/${id}/${userId}`);
};

const updateOdometerReadings = async (dispatch, id, odometerReadings) => {
  return api.pocketDriveApi(dispatch, 'put', `${CARS_URL}/${id}/odometer`, {
    odometerReadings,
  });
};

const deleteCar = async (dispatch, id) => {
  return api.pocketDriveApi(dispatch, 'delete', `${CARS_URL}/${id}`);
};

const addCredits = async (dispatch, id, credit) => {
  return api.pocketDriveApi(
    dispatch,
    'post',
    `${CARS_URL}/${id}/credits`,
    credit
  );
};

const markCreditUsed = async (dispatch, id, creditId, userId) => {
  return api.pocketDriveApi(
    dispatch,
    'put',
    `${CARS_URL}/${id}/${userId}/credits/${creditId}`
  );
};

export default {
  createCar,
  getCars,
  getCar,
  getCarContracts,
  updateCar,
  updateGloveBox,
  deleteCar,
  addUser,
  removeUser,
  updateOdometerReadings,
  updateUserVerification,
  addCredits,
  markCreditUsed,
  getMotorModels,
  getMotorMakes,
  getMotorEngine,
  getMotorBaseVehicleId,
  saveMotorData,
  getCarPopulationData,
  restartPopulation,
  getCarWithDataIssues,
  getClaims,
};
