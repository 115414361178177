import { Snackbar, Alert as MuiAlert } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spinner } from 'reactstrap';
import systemApi from '../api/system.api';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ClearCache() {
  const [isLoading, setIsLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState({
    toastOpen: false,
    message: '',
    success: false,
  });
  const dispatch = useDispatch();
  const permissionss = useSelector((state) =>
    typeof state.permissions.permissions !== typeof undefined
      ? state.permissions.permissions
      : null
  );

  const clearCache = async () => {
    setIsLoading(true);
    const response = await systemApi.clearCache(dispatch);
    setIsLoading(false);
    const message =
      response && response.success
        ? 'Cached Cleared Successfully'
        : 'Cached Clearing Failed';
    setApiResponse({
      toastOpen: true,
      message,
      success: response && response.success,
    });
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setApiResponse({
      toastOpen: false,
      message: '',
      success: false,
    });
  };

  return (
    <div className="d-flex">
      <h4>Clear Api Cache: </h4>
      {permissionss.STATUS.WRITE && (
        <Button onClick={clearCache} disabled={isLoading} className="mx-2">
          {isLoading ? (
            <>
              <span>Clearing Cache</span>
              <Spinner color="dark" style={{ height: '25px', width: '25px' }} />
            </>
          ) : (
            'Clear Cache'
          )}
        </Button>
      )}
      <Snackbar
        open={apiResponse.toastOpen}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <div>
          <Alert
            onClose={handleToastClose}
            severity={apiResponse.success ? 'success' : 'error'}
          >
            {apiResponse.message}
          </Alert>
        </div>
      </Snackbar>
    </div>
  );
}

export default ClearCache;
