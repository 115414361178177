import { Close, PictureAsPdf, RemoveRedEyeRounded } from '@mui/icons-material';
import {
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  styled,
} from '@mui/material';
import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';

const CustomDialogTitle = styled(({ children, onClose, ...other }) => (
  <DialogTitle {...other}>
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {children}
      {onClose && (
        <IconButton
          aria-label="close"
          className={CustomDialogTitle.closeButton}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      )}
    </div>
  </DialogTitle>
))(({ theme }) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: '#343a40',
    color: 'white',
  },
  closeButton: {
    position: 'relative',
    top: '-50%',
    color: theme.palette.grey[500],
  },
}));

function AttachmentsSection({ attachments }) {
  return (
    <div className="p-2">
      <Row>
        {attachments.map(({ path, filename }) => (
          <Col>
            <Card variant="outlined">
              <IconButton
                Tooltip="Open"
                onClick={() => window.open(path, '_blank')}
              >
                <PictureAsPdf />
                <span style={{ fontSize: 14 }}>{filename}</span>
              </IconButton>
            </Card>
          </Col>
        ))}
        <Col />
        <Col />
      </Row>
    </div>
  );
}

function EmailDialog({ emailBody, subject, attachments }) {
  const [show, setShow] = useState(false);
  const onClose = () => setShow(false);

  return (
    <>
      <IconButton onClick={() => setShow(true)}>
        <RemoveRedEyeRounded color="primary" />
      </IconButton>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={show}
        fullWidth
        maxWidth="lg"
        style={{
          overflow: 'hidden', // Hide the dialog scrolling
        }}
      >
        <CustomDialogTitle>
          <Typography variant="h6">{subject}</Typography>
          <IconButton
            aria-label="close"
            className={CustomDialogTitle.closeButton}
            onClick={onClose}
          >
            <Close />
          </IconButton>
        </CustomDialogTitle>
        <DialogContent
          style={{
            marginLeft: '20px',
            marginRight: '20px',
            minHeight: 'auto', // Set your preferred minHeight
            overflow: 'auto',
          }}
        >
          {emailBody !== null && (
            <>
              <div
                className={DialogContent.iframeContainer}
                style={{ overflow: 'hidden' }}
              >
                <iframe
                  title="Email Event Body"
                  srcDoc={emailBody}
                  style={{
                    border: 'none',
                    width: '100%',
                    height: '500px',
                    overflow: 'hidden',
                  }}
                />
              </div>
              <br />
              <div>
                <AttachmentsSection attachments={attachments} />
              </div>
              <br />
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

export default EmailDialog;
