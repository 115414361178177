import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Button,
  Grid,
  Typography,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  Table,
  LinearProgress,
  Alert,
} from '@mui/material';
import moment from 'moment';
import { ExpandMore } from '@mui/icons-material';
import {
  getUserAction,
  updateUserAction,
  createUserAction,
  deleteUserAction,
  triggerPasswordResetAction,
  getUserNotifications,
} from '../store/actions/user.actions';
import ConfirmationModal from '../components/ConfirmationModal';

import './User.scss';

const PASSWORD_RESET_MESSAGE_FADE_TIME = 3000;

function User() {
  const params = useParams();
  const user = useSelector((state) => state.user.user);
  const notifications = useSelector((state) => state.user.notifications);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [tempPassword, setTempPassword] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [passwordResetMessage, setPasswordResetMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const permissions = useSelector((state) => state.permissions.permissions);

  const isCreating = params.id.toLowerCase() === 'create';

  useEffect(() => {
    const loadUser = async () => {
      setIsLoading(true);
      await dispatch(getUserAction(params.id));
      await dispatch(getUserNotifications(params.id));
      setIsLoading(false);
    };
    if (user) {
      setEmail(user.email);
      setName(user.name);
      setPhoneNumber(user.phoneNumber);
    } else if (!isCreating) {
      loadUser();
    }
  }, [dispatch, isCreating, params.id, user]);
  if (!params.id) {
    return <Navigate to="/users" />;
  }
  const saveUser = async () => {
    const data = {
      name,
      email,
      phoneNumber,
    };
    setIsLoading(true);
    if (isCreating) {
      data.password = tempPassword;
      await dispatch(createUserAction(data));
    } else {
      await dispatch(updateUserAction(data, user._id));
    }
    setIsLoading(false);
  };
  const deleteUser = () => {
    dispatch(deleteUserAction(user._id));
    navigate('/users');
  };
  const triggerPasswordReset = async () => {
    await dispatch(triggerPasswordResetAction(user._id));
    setPasswordResetMessage('An SMS has been sent to the user');
    setTimeout(
      () => setPasswordResetMessage(''),
      PASSWORD_RESET_MESSAGE_FADE_TIME
    );
  };
  const goToCars = () => {
    navigate(`/users/${user._id}/cars`);
  };
  if (isLoading) {
    return <LinearProgress style={{ height: '1rem' }} color="secondary" />;
  }
  return (
    <Grid container className="user">
      <Grid item sm={3} />
      <Grid item sm={6}>
        <h2>User</h2>
        <TextField
          id="user-name"
          label="Name"
          type="string"
          className="mb-4"
          value={name}
          fullWidth
          disabled={!permissions.USERS.WRITE}
          onChange={(e) => setName(e.currentTarget.value)}
        />
        <TextField
          id="user-email"
          label="Email"
          type="email"
          className="mb-4"
          value={email}
          fullWidth
          disabled={!permissions.USERS.WRITE}
          onChange={(e) => setEmail(e.currentTarget.value)}
        />
        <TextField
          id="user-phone"
          label="Phone Number"
          type="phone"
          className="mb-4"
          value={phoneNumber}
          fullWidth
          disabled={!permissions.USERS.WRITE}
          onChange={(e) => setPhoneNumber(e.currentTarget.value)}
        />
        {notifications && !!notifications.length && (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>Message History</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer component={Paper}>
                <Table size="small" padding="none">
                  <TableHead className="bg-dark">
                    <TableRow>
                      <TableCell className="text-center text-white">
                        Date
                      </TableCell>
                      <TableCell className="text-center text-white">
                        Type
                      </TableCell>
                      <TableCell className="text-center text-white">
                        Message
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {notifications.map((n) => (
                    <TableRow>
                      <TableCell className="text-center px-2">
                        {moment(n.createdAt).format('L')}
                      </TableCell>
                      <TableCell className="text-center px-2">
                        {n.targetUserId === params.id ? 'Received' : 'Sent'}
                      </TableCell>
                      <TableCell className="text-center px-2">
                        {n.message}
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        )}
        <div className="text-right" />
        {isCreating ? (
          <TextField
            id="user-temporary"
            label="Temporary Password"
            type="password"
            className="mb-4"
            value={tempPassword}
            fullWidth
            onChange={(e) => setTempPassword(e.currentTarget.value)}
          />
        ) : (
          <>
            <Button className="d-block" color="primary" onClick={goToCars}>
              View Cars
            </Button>
            {permissions.USERS.WRITE && (
              <Button color="primary" onClick={triggerPasswordReset}>
                Trigger Password Reset
              </Button>
            )}
          </>
        )}
        {passwordResetMessage && (
          <Alert severity="success">{passwordResetMessage}</Alert>
        )}
        <div className="mt-4 text-right">
          {permissions.USERS.WRITE && (
            <>
              <Button variant="contained" color="primary" onClick={saveUser}>
                Save
              </Button>
              {!isCreating && (
                <Button
                  variant="contained"
                  color="secondary"
                  className="ml-2"
                  onClick={() => setShowConfirmationModal(true)}
                >
                  Delete
                </Button>
              )}
            </>
          )}
        </div>
      </Grid>
      <ConfirmationModal
        show={showConfirmationModal}
        onConfirm={deleteUser}
        onCancel={() => setShowConfirmationModal(false)}
        headerText="Delete User"
        bodyText="Are you sure you want to delete this user?"
      />
    </Grid>
  );
}

export default User;
