import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { PAGINATION_OPTIONS } from '../constants';
import subscriptionsApi from '../api/subscriptions.api';
import JSONViewer from './JSONViewer';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <h5 style={{ textAlign: 'left', padding: '5px' }}>Recurring File Logs</h5>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

function RecurringFileLogList({ filter, viewHeight = '30vh' }) {
  const columns = [
    {
      headerName: 'id',
      field: 'id',
      renderCell: (params) => {
        return (
          <div>
            <JSONViewer title="Recurring File Logs" jsonData={params.row} />
            {params.row.id}
          </div>
        );
      },
      width: 350,
    },
    {
      headerName: 'sfdcId',
      renderCell: (params) => {
        return (
          <div>
            <JSONViewer
              title="Raw SFDC Record JSON"
              jsonData={JSON.parse(params.row.rawSfdcRecord)}
            />
            {params.row.sfdcId}
          </div>
        );
      },
      field: 'sfdcId',
      headerClassName: 'custom-dark-theme--header',
      width: 240,
    },
    {
      headerName: 'contractNumber',
      render: (e) => e.contractNumber,
      field: 'contractNumber',
      headerClassName: 'custom-dark-theme--header',
      width: 160,
    },
    {
      headerName: 'vinNumber',
      field: 'vinNumber',
      renderCell: (c) => JSON.parse(c.row.rawSfdcRecord).vinNumber,
      headerClassName: 'custom-dark-theme--header',
      width: 200,
    },
    {
      headerName: 'rootTransactionNumber',
      field: 'rootTransactionNumber',
      renderCell: (c) =>
        c.row.rootTransactionNumber
          ? c.row.rootTransactionNumber
          : JSON.parse(c.row.rawSfdcRecord).referenceTransactionUUID,
      headerClassName: 'custom-dark-theme--header',
      width: 220,
    },
    {
      headerName: 'processStatus',
      render: (e) => e.processStatus,
      field: 'processStatus',
      headerClassName: 'custom-dark-theme--header',
      width: 120,
    },
    {
      headerName: 'accountPaymentId',
      field: 'accountPaymentId',
      renderCell: (c) =>
        c.row.accountPaymentId
          ? c.row.accountPaymentId
          : JSON.parse(c.row.rawSfdcRecord).accountPayment,
      headerClassName: 'custom-dark-theme--header',
      width: 160,
    },
    {
      headerName: 'accountPaymentEmail',
      field: 'accountPaymentEmail',
      renderCell: (c) => JSON.parse(c.row.rawSfdcRecord).accountPaymentEmail,
      headerClassName: 'custom-dark-theme--header',
      width: 220,
    },
    {
      headerName: 'amount',
      field: 'amount',
      renderCell: (c) =>
        `${JSON.parse(c.row.rawSfdcRecord).currency} ${
          JSON.parse(c.row.rawSfdcRecord).amount
        }`,
      headerClassName: 'custom-dark-theme--header',
      width: 140,
    },
    {
      headerName: 'paymentMode',
      render: (e) => e.paymentMode,
      field: 'paymentMode',
      headerClassName: 'custom-dark-theme--header',
      width: 140,
    },
    {
      headerName: 'nextPaymentDueDate',
      render: (e) => e.nextPaymentDueDate,
      field: 'nextPaymentDueDate',
      headerClassName: 'custom-dark-theme--header',
      width: 170,
    },
    {
      headerName: 'cancellationDate',
      render: (e) => e.cancellationDate,
      field: 'cancellationDate',
      headerClassName: 'custom-dark-theme--header',
      width: 220,
    },
    {
      headerName: 'merchantTransactionID',
      field: 'merchantTransactionID',
      renderCell: (c) => JSON.parse(c.row.rawSfdcRecord).merchantTransactionID,
      headerClassName: 'custom-dark-theme--header',
      width: 360,
    },
    {
      headerName: 'createdAt',
      render: (e) => e.createdAt,
      field: 'createdAt',
      headerClassName: 'custom-dark-theme--header',
      width: 210,
    },
  ];
  const [dataSource, setDataSource] = useState([]);
  const [pageSize, setPageSize] = React.useState(PAGINATION_OPTIONS[2]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const updateDataSource = (data) => {
    const _updatedDataSource = data.map((item, index) => {
      return { ...item, _id: index }; // Generate new ids based on the current data only
    });
    setDataSource(_updatedDataSource); // Set the new data directly
  };

  const fetchReport = async () => {
    const params = {
      page: page + 1,
      limit: pageSize,
      filter: JSON.stringify(filter),
    };
    setIsLoading(true);

    return subscriptionsApi.getRecurringFileLogs(dispatch, params);
  };

  useEffect(() => {
    const loadData = async () => {
      const reports = await fetchReport();
      if (reports && reports.data) {
        const totalRecords = reports.data.totalItems;
        const currentPageRecords = reports.data.data;

        // Calculate if we're on the last page
        const isLastPage = page === Math.ceil(totalRecords / pageSize) - 1;

        // If on the last page, only show the remaining records
        const recordsToDisplay = isLastPage
          ? currentPageRecords.slice(0, totalRecords % pageSize)
          : currentPageRecords;

        updateDataSource(recordsToDisplay);
        setRowCount(totalRecords);
      }
      setIsLoading(false);
    };
    loadData();
  }, [page, pageSize, filter]);

  const handlePageChange = (params) => {
    setPage(params.page);
    setPageSize(params.pageSize);
  };

  return (
    <div className="container">
      <div
        style={{
          height: viewHeight,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <DataGrid
            density="compact"
            paginationModel={{ page, pageSize }}
            pagination
            pageSizeOptions={PAGINATION_OPTIONS}
            rowCount={rowCount}
            getRowId={(row) => row._id}
            rows={dataSource}
            columns={columns}
            paginationMode="server"
            onPaginationModelChange={handlePageChange}
            slots={{
              toolbar: CustomToolbar,
            }}
          />
        )}
      </div>
    </div>
  );
}

export default RecurringFileLogList;
