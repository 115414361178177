import {
  TextField,
  Button,
  Grid,
  CircularProgress,
  Snackbar,
} from '@mui/material';

import React, { useState } from 'react';

import 'react-multi-carousel/lib/styles.css';
import dcrApi from '../../api/dcr.api';

function NotesAdd(props) {
  const [noteValue, setNote] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState('');

  const { model, setOpenNotesList } = props;

  const handleSave = () => {
    if (noteValue !== '') {
      if (noteValue.length > 100) {
        setError('Note cannot be more than 100 characters.');
        setOpen(true);
        return;
      }
      const data = {
        note: noteValue,
      };

      const postData = async () => {
        setLoading(true);
        const value = await dcrApi.saveNote(model.accountNumber, data);
        setLoading(false);
        if (value.note === noteValue) {
          setOpenNotesList(false);
        } else {
          setError('Something went wrong, please try again.');
          setOpen(true);
        }
      };
      postData();
    } else {
      setError('Please enter note.');
      setOpen(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Grid container>
      <TextField
        style={{ marginBottom: 8 }}
        fullWidth
        placeholder="Note"
        variant="outlined"
        name="note"
        label="Note"
        multiline
        rows={4}
        onChange={(e) => setNote(e.currentTarget.value)}
      />
      <Grid container justifyContent="flex-end">
        {loading && <CircularProgress variant="indeterminate" size={25} />}
        <Button
          style={{ marginLeft: 8 }}
          backgroundColor="primary"
          color="primary"
          variant="contained"
          onClick={handleSave}
        >
          Save
        </Button>
      </Grid>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={error}
      />
    </Grid>
  );
}
export default NotesAdd;
