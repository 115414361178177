import api from '.';

const USERS_URL = `/admin/users`;
const USER_URL = `/admin/user`;
// const PUBLIC_USERS_URL = `/public/users`;

const createUser = async (dispatch, data) => {
  return api.pocketDrivePublicApi(dispatch, 'post', USERS_URL, data);
};

const getUsers = async (dispatch, params) => {
  return api.pocketDriveApi(dispatch, 'get', `${USERS_URL}/list`, null, params);
};

const getUser = async (dispatch, id) => {
  return api.pocketDriveApi(dispatch, 'get', `${USERS_URL}/${id}`);
};

const updateUser = async (dispatch, user, id) => {
  return api.pocketDriveApi(dispatch, 'put', `${USERS_URL}/${id}`, user);
};

const deleteUser = async (dispatch, id) => {
  return api.pocketDriveApi(dispatch, 'delete', `${USERS_URL}/${id}`);
};

const triggerResetPassword = async (dispatch, id) => {
  return api.pocketDriveApi(
    dispatch,
    'post',
    `${USERS_URL}/${id}/resetPassword`
  );
};

const getUserNotificationHistory = async (dispatch, id) =>
  api.pocketDriveApi(dispatch, 'get', `${USERS_URL}/${id}/notifications`);

const getUserReport = async (dispatch, params) =>
  api.pocketDriveApi(
    dispatch,
    'get',
    `/admin/report/user-report`,
    null,
    params
  );

// const getUserGroups = async (dispatch) =>
//   api.pocketDriveApi(dispatch, 'get', `${USER_URL}/groups`);

const getLockedCars = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${USER_URL}/locked-cars`,
    null,
    params
  );
};

const getPermissions = async (dispatch) => {
  return api.pocketDriveApi(dispatch, 'get', `${USER_URL}/permissions`);
};

export default {
  createUser,
  getUsers,
  getUser,
  updateUser,
  deleteUser,
  triggerResetPassword,
  getUserNotificationHistory,
  getUserReport,
  // getUserGroups,
  getLockedCars,
  getPermissions,
};
