import api from '.';

const DEALER_URL = `/admin/dealers`;

const getDealerWhiteLabel = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${DEALER_URL}/whitelabel?dealerId=${params.dealerId}`,
    null,
    null
  );
};

const getAllUserDealer = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `/admin/dealer/userDealers`,
    null,
    params
  );
};

export default {
  getDealerWhiteLabel,
  getAllUserDealer,
};
