import React from 'react';
import { Check, Close } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { IconButton } from '@mui/material';
import { updateCarVerificationAction } from '../store/actions/cars.actions';

function UserVerificationToggle(props) {
  const { carId, userId, isVerified, callback } = props;

  const dispatch = useDispatch();

  const toggleVerification = async (e) => {
    e.stopPropagation();
    await dispatch(updateCarVerificationAction(carId, userId, !isVerified));
    if (callback) {
      callback();
    }
  };

  return (
    <div className="d-flex align-items-center">
      <span style={{ fontSize: '0.75rem' }}>Zip code verified</span>
      <IconButton onClick={toggleVerification}>
        {isVerified ? (
          <Check style={{ color: 'green' }} />
        ) : (
          <Close color="error" />
        )}
      </IconButton>
    </div>
  );
}

export default UserVerificationToggle;
