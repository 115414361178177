import React, { useEffect, useState } from 'react';
import { LinearProgress, Button } from '@mui/material';
import { addHours, addMonths, addDays, format } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { useSelector } from 'react-redux';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import reportsApi from '../api/report.api';
import CustomPie from '../components/CustomPie';
import CustomBarChart from '../components/CustomBarChart';
import FilterModal from '../components/Filter';

function OperationStats() {
  const [statsData, setStatsData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [appliedFilter, setAppliedFilter] = useState({});
  const [filterDisplay, setFilterDisplay] = useState('');
  const [filterBeforeDate, setFilterBeforeDate] = useState(null);
  const [filterAfterDate, setFilterAfterDate] = useState(null);
  const [applyQuickFilter, setApplyQuickFilter] = useState(false);

  const permissions = useSelector((state) => state.permissions.permissions);

  const saveFilters = () => {
    const newFilter = {};
    const displays = [];
    const filterStartDate = new Date(filterAfterDate);
    const filterEndDate = new Date(filterBeforeDate);
    let dateDisplay = '';
    if (filterAfterDate) {
      newFilter.updatedAt = { $gte: `${filterStartDate}` };
      dateDisplay = `Dates: ${format(filterStartDate, 'MM/dd/yyyy')}`;
    }
    if (filterBeforeDate) {
      newFilter.updatedAt = {
        ...newFilter.updatedAt,
        $lte: `ISO${filterEndDate}`,
      };
      if (!dateDisplay) {
        dateDisplay = `Dates: -${format(filterEndDate, 'MM/dd/yyyy')}`;
      } else {
        dateDisplay = `${dateDisplay}-${format(filterEndDate, 'MM/dd/yyyy')}`;
      }
    }
    if (dateDisplay) {
      displays.push(dateDisplay);
    }

    setAppliedFilter(newFilter);
    setFilterDisplay(displays.join(' '));
  };

  useEffect(() => {
    if (applyQuickFilter && !!filterAfterDate) {
      setApplyQuickFilter(false);
      saveFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyQuickFilter, filterAfterDate]);

  const setLastDayFilter = () => {
    setFilterAfterDate(addHours(new Date(), -24));
    setApplyQuickFilter(true);
  };

  const setLastMonthFilter = () => {
    setFilterAfterDate(addMonths(new Date(), -1));
    setApplyQuickFilter(true);
  };

  const setLastSevenDays = () => {
    setFilterAfterDate(addDays(new Date(), -7));
    setApplyQuickFilter(true);
  };

  const resetFilters = () => {
    setFilterAfterDate(null);
    setFilterBeforeDate(null);
    setFilterDisplay('');
    setAppliedFilter({});
  };

  function getKey(item) {
    const filterKeys = Object.keys(item).filter((key) => key !== 'count');
    return filterKeys.length ? filterKeys[0] : '';
  }

  function getDataSet(item) {
    if (!item || item.length === 0) {
      return [];
    }
    const key = getKey(item[0]);
    return item.map((data) => {
      return { count: data.count, label: data[key] };
    });
  }

  useEffect(() => {
    const loadData = async () => {
      const params = {
        filter: JSON.stringify(appliedFilter),
      };
      const result = await reportsApi.getOperationalStats(params);
      setStatsData({ ...result });
      setIsLoading(false);
    };
    setIsLoading(true);
    loadData();
  }, [appliedFilter]);

  if (isLoading) {
    return (
      <div className="container">
        <div className="row my-2">
          <h2>Operational Stats</h2>
        </div>
        <LinearProgress style={{ height: '1rem' }} color="secondary" />
      </div>
    );
  }
  return (
    <div
      className="container"
      style={{
        display: permissions.OPERATIONAL_STATS.READ ? 'block' : 'none',
      }}
    >
      <div>
        <h2 className="d-flex align-content-center align-items-center mb-0">
          <span className="mr-2">Operational Status</span>
          <div className="flex-grow-1" />
          {appliedFilter && !Object.keys(appliedFilter).length && (
            <>
              <Button color="primary" onClick={setLastDayFilter}>
                Last 24 Hours
              </Button>
              <Button color="primary" onClick={setLastSevenDays}>
                Last 7 Days
              </Button>
              <Button color="primary" onClick={setLastMonthFilter}>
                Last month
              </Button>
            </>
          )}
          <FilterModal
            currentFilters={appliedFilter}
            onSave={saveFilters}
            itemName="Reports"
            resetFilters={resetFilters}
            filterDisplay={filterDisplay}
          >
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  utils={DateFnsUtils}
                  label="After Date (including)"
                  variant="inline"
                  className="mr-2"
                  fullWidth
                  id="after-date-filter"
                  value={filterAfterDate}
                  onChange={setFilterAfterDate}
                  autoOk="true"
                />
              </LocalizationProvider>
            </div>
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  utils={DateFnsUtils}
                  label="Before Date (including)"
                  variant="inline"
                  className="mr-2"
                  fullWidth
                  id="before-date-filter"
                  value={filterBeforeDate}
                  onChange={setFilterBeforeDate}
                  autoOk="true"
                />
              </LocalizationProvider>
            </div>
          </FilterModal>
        </h2>
      </div>
      <div className="row my-2 ">
        <div className="col-sm-4">
          <CustomPie stats={statsData.emailLogsStats} title="Email Logs" />
        </div>
        <div className="col-sm-4">
          <CustomPie
            stats={statsData.emailDeliveryStats}
            title="Email Delivery Stats"
          />
        </div>
        <div className="col-sm-4">
          <CustomPie
            stats={statsData.invitationStats}
            title="Invitation Stats"
          />
        </div>
      </div>
      <div className="row my-2">
        <div className="col-sm-8">
          <CustomBarChart
            title="Active Mobile Users"
            dataSet={getDataSet(statsData.activeMobileUsers)}
          />
        </div>
        <div className="col-sm-4">
          <CustomPie
            stats={statsData.clientTypeStats}
            title="ClientType Stats"
          />
        </div>
        <div className="col-sm-12">
          <CustomBarChart
            title="Api Error Stats"
            dataSet={getDataSet(
              statsData.apiErrorStats ? statsData.apiErrorStats.errorTypes : []
            )}
          />
        </div>
      </div>
      <div className="row my-2">
        <div className="col-sm-12">
          <CustomBarChart
            title="PushNotificationLogsStats"
            dataSet={getDataSet(statsData.pushNotificationLogsStats)}
          />
        </div>
      </div>
      <div className="row my-2">
        <div className="col-sm-4">
          <span className="statsSubTitle">
            Factory Warranty BumperToBumper LastJob
          </span>
          <pre>
            {JSON.stringify(
              statsData.factoryWarrantyBumperToBumperLastJob,
              null,
              2
            )}
          </pre>
        </div>
        <div className="col-sm-4">
          <span className="statsSubTitle">
            Factory Warranty DriveTrain LastJob
          </span>
          <pre>
            {JSON.stringify(
              statsData.factoryWarrantyDriveTrainLastJob,
              null,
              2
            )}
          </pre>
        </div>
        <div className="col-sm-4">
          <span className="statsSubTitle">
            Factory Warranty ProductExpiration LastJob
          </span>
          <pre>
            {JSON.stringify(statsData.productExpirationJobStats, null, 2)}
          </pre>
        </div>
      </div>
      <div
        className="row my-2"
        style={{ height: '200px', margin: '10px 0', overflowY: 'scroll' }}
      >
        <span className="statsSubTitle">Refresh Recalls LastJob</span>
        <pre>{JSON.stringify(statsData.refreshRecallsLastJob, null, 2)}</pre>
      </div>
    </div>
  );
}

export default OperationStats;
