import React, { useState } from 'react';
import {
  TextField,
  IconButton,
  Button,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import MomentUtils from '@date-io/moment';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { Add, DeleteForever } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { updateOdometerReadingsAction } from '../store/actions/cars.actions';

function CarOdometerReadings(props) {
  const { car } = props;
  const dispatch = useDispatch();

  const [odometerReadings, setOdometerReadings] = useState(
    car.odometerReadings
  );
  const [hasEdits, setHasEdits] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const removeReading = (idx) => {
    const readings = odometerReadings.slice(0);
    readings.splice(idx, 1);
    setOdometerReadings(readings);
  };
  const setField = (field, value, idx) => {
    const readings = odometerReadings.slice(0);
    readings[idx][field] = value;
    setOdometerReadings(readings);
    setHasEdits(true);
  };
  const saveOdometer = async () => {
    setIsUpdating(true);
    await dispatch(updateOdometerReadingsAction(car._id, odometerReadings));
    setIsUpdating(false);
    setHasEdits(false);
  };
  return (
    <div className="car-odometer-readings">
      {odometerReadings.map((reading, idx) => (
        <div key={idx} className="d-flex align-items-center">
          <div className="mr-2">{`${idx + 1}. `}</div>
          <TextField
            type="number"
            className="mr-2 text-right"
            value={reading.odometerReading}
            onChange={(e) => {
              setField('odometerReading', e.currentTarget.value, idx);
            }}
          />
          <Select
            className="mr-2"
            value={reading.distanceUnit}
            onChange={(e) => {
              setField('distanceUnit', e.target.value, idx);
            }}
          >
            <MenuItem value="miles">Miles</MenuItem>
            <MenuItem value="kilometers">Kilometers</MenuItem>
          </Select>
          <Select
            className="mr-2"
            value={reading.quality}
            onChange={(e) => {
              setField('quality', e.target.value, idx);
            }}
          >
            <MenuItem value="userEntered">User Entered</MenuItem>
            <MenuItem value="repairShopEntered">Repair Shop Entered</MenuItem>
          </Select>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              utils={MomentUtils}
              variant="inline"
              className="mr-2"
              id={`car-odometer-${idx}`}
              value={dayjs(reading.date)}
              onChange={(v) => {
                setField('date', v.toISOString(), idx);
              }}
            />
          </LocalizationProvider>
          <IconButton onClick={() => removeReading(idx)}>
            <DeleteForever color="error" />
          </IconButton>
        </div>
      ))}
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          setHasEdits(true);
          setOdometerReadings([
            ...odometerReadings,
            { distanceUnit: 'miles', quality: 'userEntered' },
          ]);
        }}
      >
        <Add />
        Add One
      </Button>
      {hasEdits && (
        <Button
          className="ml-2"
          variant="contained"
          color="primary"
          onClick={saveOdometer}
        >
          {isUpdating ? <CircularProgress color="secondary" /> : 'Save'}
        </Button>
      )}
    </div>
  );
}

export default CarOdometerReadings;
