import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import moment from 'moment';
import { PAGINATION_OPTIONS } from '../constants';
import JSONViewer from './JSONViewer';
import reportApi from '../api/report.api';
import EmailDialog from './EmailDialog';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <h5 style={{ textAlign: 'left', padding: '5px' }}>
        Email List for Offer
      </h5>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

function EmailListOnOffer({
  offerId,
  contractNumber,
  emailAddress,
  viewHeight = '50vh',
}) {
  const [dataSource, setDataSource] = useState([]);
  const [pageSize, setPageSize] = React.useState(PAGINATION_OPTIONS[2]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const permissions = useSelector((state) => state.permissions.permissions);
  const writeAccess = permissions.OFFERS_DATA.WRITE === true;

  const columns = [];
  columns.push({
    headerName: 'Date',
    renderCell: (c) =>
      moment(c.value).format(process.env.REACT_APP_DATE_TIME_FORMAT),
    field: 'createdAt',
    headerClassName: 'custom-dark-theme--header',
    width: 160,
  });
  columns.push({
    headerName: 'Type',
    render: (e) => e.type,
    field: 'type',
    headerClassName: 'custom-dark-theme--header',
    width: 240,
  });
  columns.push({
    headerName: 'Email',
    render: (e) => e.recipientAddress,
    field: 'recipientAddress',
    headerClassName: 'custom-dark-theme--header',
    width: 300,
  });
  columns.push({
    headerName: 'Status',
    render: (e) => e.status,
    field: 'status',
    headerClassName: 'custom-dark-theme--header',
    width: 90,
  });
  columns.push({
    headerName: 'Delivery',
    renderCell: (e) => e.row.deliveryStatus,
    field: 'delivery',
    headerClassName: 'custom-dark-theme--header',
    width: 90,
  });
  columns.push({
    headerName: 'Attach.',
    renderCell: (c) => {
      return c.row.eventBody.attachments
        ? c.row.eventBody.attachments.length
        : 0;
    },
    field: 'attachments',
    headerClassName: 'custom-dark-theme--header',
    width: 90,
  });
  columns.push({
    headerName: 'Open',
    renderCell: (c) => c.row.openCount,
    field: 'openCount',
    headerClassName: 'custom-dark-theme--header',
    width: 70,
  });
  if (writeAccess) {
    columns.push({
      headerName: 'Metadata',
      renderCell: (c) => {
        return (
          <JSONViewer
            title="Payload"
            color="primary"
            jsonData={c.row.eventBody.metadata || ''}
          />
        );
      },
      field: 'metadata',
      headerClassName: 'custom-dark-theme--header',
      width: 120,
    });
  }
  columns.push({
    headerName: 'View',
    renderCell: (c) => {
      return (
        <EmailDialog
          emailBody={c.row.eventBody.body}
          subject={c.row.subject}
          attachments={c.row.eventBody.attachments}
        />
      );
    },
    field: 'view',
    headerClassName: 'custom-dark-theme--header',
    width: 90,
  });

  const updateDataSource = (data) => {
    const startIndex =
      dataSource && dataSource.length
        ? dataSource[dataSource.length - 1]._id + 1
        : 0;
    const _updatedDataSource = dataSource.concat(
      data.map((item, index) => {
        return { ...item, _id: index + startIndex };
      })
    );
    setDataSource(_updatedDataSource);
    return _updatedDataSource;
  };

  const fetchReport = async (skip = dataSource.length, limit = pageSize) => {
    const filter = {};
    if (offerId) {
      filter.offerId = offerId;
    }
    if (contractNumber) {
      filter.contractNumber = contractNumber;
    }
    if (emailAddress) {
      filter.emailAddress = emailAddress;
    }
    const params = {
      skip,
      limit,
      filter: JSON.stringify(filter),
    };
    setIsLoading(true);

    return reportApi.getEmailsListForOffer(params, dispatch);
  };

  useEffect(() => {
    const loadData = async () => {
      const reports = await fetchReport();
      if (reports && reports.data) {
        updateDataSource(reports.data);
        setRowCount(reports.totalCount);
      }
      setIsLoading(false);
    };
    loadData();
  }, [page, pageSize]);

  const handlePageChange = (params) => {
    setPageSize(params.pageSize);
    setPage(params.page);
  };

  return (
    <div className="container">
      <div
        style={{
          height: viewHeight,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <DataGrid
            density="compact"
            paginationModel={{ page, pageSize }}
            pageSizeOptions={PAGINATION_OPTIONS}
            onPaginationModelChange={handlePageChange}
            pagination
            rowCount={rowCount}
            getRowId={(row) => row._id}
            rows={dataSource}
            columns={columns}
            slots={{
              toolbar: CustomToolbar,
            }}
          />
        )}
      </div>
    </div>
  );
}

export default EmailListOnOffer;
