import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { PAGINATION_OPTIONS } from '../constants';
import subscriptionsApi from '../api/subscriptions.api';
import JSONViewer from './JSONViewer';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <h5 style={{ textAlign: 'left', padding: '5px' }}>
        Recurring Journey Logs
      </h5>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

function RecurringJourneyLogList({ filter, viewHeight = '30vh' }) {
  const columns = [
    {
      headerName: 'sfdcId',
      field: 'sfdcId',
      renderCell: (params) => {
        return (
          <div>
            <JSONViewer title="Recurring Journey Logs" jsonData={params.row} />
            {params.row.sfdcId}
          </div>
        );
      },
      width: 250,
    },
    {
      headerName: 'Processed Status',
      field: 'recurringRecordProcessedStatus',
      headerClassName: 'custom-dark-theme--header',
      renderCell: (params) => {
        return (
          <div>
            <JSONViewer
              title="Recurring Record Processed Data"
              jsonData={JSON.parse(params.row.recurringRecordProcessedData)}
            />
            {params.row.recurringRecordProcessedStatus}
          </div>
        );
      },
      width: 180,
    },
    {
      headerName: 'Validate Status',
      field: 'validateRecurringRecordStatus',
      headerClassName: 'custom-dark-theme--header',
      renderCell: (params) => {
        return (
          <div>
            <JSONViewer
              title="Validate Recurring Record Data"
              jsonData={JSON.parse(params.row.validateRecurringRecordData)}
            />
            {params.row.validateRecurringRecordStatus}
          </div>
        );
      },
      width: 180,
    },
    {
      headerName: 'Payment Status',
      field: 'recurringPaymentStatus',
      headerClassName: 'custom-dark-theme--header',
      renderCell: (params) => {
        return (
          <div>
            <JSONViewer
              title="Recurring Payment Data"
              jsonData={JSON.parse(params.row.recurringPaymentData)}
            />
            {params.row.recurringPaymentStatus}
          </div>
        );
      },
      width: 180,
    },
    {
      headerName: 'Payment Email Status',
      field: 'sendFailedPaymentEmailStatus',
      headerClassName: 'custom-dark-theme--header',
      renderCell: (params) => {
        return (
          <div>
            <JSONViewer
              title="Send Failed Payment Email Data"
              jsonData={JSON.parse(params.row.sendFailedPaymentEmailData)}
            />
            {params.row.sendFailedPaymentEmailStatus}
          </div>
        );
      },
      width: 180,
    },
    {
      headerName: 'Response Table Status',
      field: 'saveRecordToPaymentResponseTableStatus',
      headerClassName: 'custom-dark-theme--header',
      renderCell: (params) => {
        return (
          <div>
            <JSONViewer
              title="Save Record To Payment Response Table Data"
              jsonData={JSON.parse(
                params.row.saveRecordToPaymentResponseTableData
              )}
            />
            {params.row.saveRecordToPaymentResponseTableStatus}
          </div>
        );
      },
      width: 180,
    },
    {
      headerName: 'File Queue Status',
      field: 'addToResponseFileQueueStatus',
      headerClassName: 'custom-dark-theme--header',
      renderCell: (params) => {
        return (
          <div>
            <JSONViewer
              title="Add To Response File Queue Data"
              jsonData={JSON.parse(params.row.addToResponseFileQueueData)}
            />
            {params.row.addToResponseFileQueueStatus}
          </div>
        );
      },
      width: 180,
    },
    {
      headerName: 'createdAt',
      render: (e) => e.createdAt,
      field: 'createdAt',
      headerClassName: 'custom-dark-theme--header',
      width: 200,
    },
    {
      headerName: 'updatedAt',
      render: (e) => e.updatedAt,
      field: 'updatedAt',
      headerClassName: 'custom-dark-theme--header',
      width: 200,
    },
  ];
  const [dataSource, setDataSource] = useState([]);
  const [pageSize, setPageSize] = React.useState(PAGINATION_OPTIONS[2]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const updateDataSource = (data) => {
    const _updatedDataSource = data.map((item, index) => {
      return { ...item, _id: index }; // Generate new ids based on the current data only
    });
    setDataSource(_updatedDataSource); // Set the new data directly
  };

  const fetchReport = async () => {
    const params = {
      page: page + 1,
      limit: pageSize,
      filter: JSON.stringify(filter),
    };
    setIsLoading(true);

    return subscriptionsApi.getRecurringJourneyLogs(dispatch, params);
  };

  useEffect(() => {
    const loadData = async () => {
      const reports = await fetchReport();
      if (reports && reports.data) {
        const totalRecords = reports.data.totalItems;
        const currentPageRecords = reports.data.data;

        // Calculate if we're on the last page
        const isLastPage = page === Math.ceil(totalRecords / pageSize) - 1;

        // If on the last page, only show the remaining records
        const recordsToDisplay = isLastPage
          ? currentPageRecords.slice(0, totalRecords % pageSize)
          : currentPageRecords;

        updateDataSource(recordsToDisplay);
        setRowCount(totalRecords);
      }
      setIsLoading(false);
    };
    loadData();
  }, [page, pageSize, filter]);

  const handlePageChange = (params) => {
    setPage(params.page);
    setPageSize(params.pageSize);
  };

  return (
    <div className="container">
      <div
        style={{
          height: viewHeight,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <DataGrid
            density="compact"
            paginationModel={{ page, pageSize }}
            pagination
            pageSizeOptions={PAGINATION_OPTIONS}
            rowCount={rowCount}
            getRowId={(row) => row._id}
            rows={dataSource}
            columns={columns}
            paginationMode="server"
            onPaginationModelChange={handlePageChange}
            slots={{
              toolbar: CustomToolbar,
            }}
          />
        )}
      </div>
    </div>
  );
}

export default RecurringJourneyLogList;
