import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { Button, TextField, Grid, Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import broadCastApi from '../api/broadCast.api';

let message = '';
const MAX_TITLE_LENGTH = 80;
const MAX_MESSAGE_LENGTH = 250;

function BroadcastMessage() {
  const dispatch = useDispatch();
  const dropDownOptions = [
    {
      label: `All Users`,
      value: `ALL_USERS`,
    },
    {
      label: `All Glovebox Users`,
      value: `GLOVEBOX_USERS`,
    },
    {
      label: ` All Mobile App Users`,
      value: `MOBILE_APP_USERS`,
    },
  ];

  const [valu, setValu] = useState('ALL_USERS');
  const [labl, setLabl] = useState('All Users');

  const handleChange = (event) => {
    setValu(event.value);
    setLabl(event.label);
  };
  const [_title, setTitle] = useState('');
  const [_message, setMessage] = useState('');
  const [showSnackbar, setShowsnackbar] = useState(false);
  const [titleError, setTitleError] = useState('');
  const [messageError, setMessageError] = useState('');
  const getBody = () => {
    return {
      title: _title,
      message: _message,
      type: valu,
    };
  };
  const handleClick = async () => {
    if (_title === '' || _message === '') {
      // Show error if title or message is empty
      setTitleError(_title === '' ? 'Title is required.' : '');
      setMessageError(_message === '' ? 'Message is required.' : '');
      return;
    }

    if (_title.length > MAX_TITLE_LENGTH) {
      setTitleError(`Title should be ${MAX_TITLE_LENGTH} characters or less`);
      return;
    }

    if (_message.length > MAX_MESSAGE_LENGTH) {
      setMessageError(
        `Message should be ${MAX_MESSAGE_LENGTH} characters or less`
      );
      return;
    }

    const postBody = getBody();
    const response = await broadCastApi.sendBroadcast(postBody, dispatch);
    message = response.message;
    setShowsnackbar(true);
    setTitle('');
    setMessage('');
  };

  const handleClose = () => {
    setShowsnackbar(false);
  };

  return (
    <div className="container">
      <div className="pr-2">
        <h2 className="d-flex align-content-center align-items-center mb-0">
          <span className="mr-2">Broadcast Messages</span>
          &nbsp;
        </h2>
        <Grid
          spacing={24}
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12}>
            <Grid spacing={2} xs={12}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '900px', // Consistent width
                }}
              >
                <TextField
                  value={_title}
                  required={_title === ''}
                  margin="normal"
                  variant="outlined"
                  id="title"
                  style={{ width: '100%', maxWidth: '80%' }} // Adjust width of the text field
                  label="Title"
                  name="title"
                  onChange={(e) => setTitle(e.target.value)}
                />
                {titleError && (
                  <Typography
                    variant="caption"
                    color="error"
                    style={{
                      marginLeft: '10px',
                      flex: 1,
                      whiteSpace: 'nowrap',
                    }} // Align error message to the right
                  >
                    {titleError}
                  </Typography>
                )}
              </div>
            </Grid>
            <Grid spacing={2} xs={12}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '900px', // Consistent width
                }}
              >
                <TextField
                  value={_message}
                  required={_message === ''}
                  multiline
                  rows={20}
                  maxRows={20}
                  variant="outlined"
                  id="message"
                  style={{ width: '100%', maxWidth: '80%' }} // Adjust width of the text field
                  label="Message"
                  name="message"
                  placeholder="Enter Your Message"
                  onChange={(e) => setMessage(e.target.value)}
                />
                {messageError && (
                  <Typography
                    variant="caption"
                    color="error"
                    style={{
                      marginLeft: '10px',
                      flex: 1,
                      whiteSpace: 'nowrap',
                    }} // Align error message to the right
                  >
                    {messageError}
                  </Typography>
                )}
              </div>
            </Grid>

            <Grid
              style={{ marginTop: 20 }}
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid>
                <Select
                  closeMenuOnScroll="true"
                  value={{
                    label: `${labl}`,
                    value: valu,
                  }}
                  options={dropDownOptions}
                  onChange={handleChange}
                />
              </Grid>
              <Grid>
                <Button
                  style={{ marginLeft: 20 }}
                  color="primary"
                  variant="contained"
                  onClick={handleClick}
                  disabled={titleError || messageError}
                >
                  Send Broadcast
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={showSnackbar}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: '100%' }}
          >
            {message}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}

export default BroadcastMessage;
