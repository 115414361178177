import api from '.';

const RENEWALS_URL = `/admin/report`;

const getRenwalsReport = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${RENEWALS_URL}/renewals-stats`,
    null,
    params
  );
};

const getRenewalOffersBouncedList = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${RENEWALS_URL}/renewals-offers/bounced-invalid-list`,
    null,
    params
  );
};

const getRenewalOffersBouncedListDownload = async (data, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'post',
    `${RENEWALS_URL}/renewals-offers/bounced-invalid-list/download`,
    data,
    null
  );
};

export default {
  getRenwalsReport,
  getRenewalOffersBouncedList,
  getRenewalOffersBouncedListDownload,
};
