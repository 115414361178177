import api from '.';

const ERRORS_URL = `/admin/errors`;

const getErrors = async (dispatch, params) =>
  api.pocketDriveApi(dispatch, 'get', ERRORS_URL, null, params);

const getDetailedErrors = async (dispatch, params) =>
  api.pocketDriveApi(dispatch, 'get', `${ERRORS_URL}/detailed`, null, params);

export default {
  getErrors,
  getDetailedErrors,
};
