import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { PAGINATION_OPTIONS } from '../constants';
import subscriptionsApi from '../api/subscriptions.api';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <h5 style={{ textAlign: 'left', padding: '5px' }}>Payment Response</h5>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

function OfferPaymentResponse({ filter, contractNumber, viewHeight = '30vh' }) {
  const columns = [
    {
      headerName: 'transactionUuid',
      render: (e) => e.transactionUuid,
      field: 'transactionUuid',
      headerClassName: 'custom-dark-theme--header',
      width: 310,
    },
    {
      headerName: 'refrenceTransactionId',
      render: (e) => e.refrenceTransactionId,
      field: 'refrenceTransactionId',
      headerClassName: 'custom-dark-theme--header',
      width: 170,
    },
    {
      headerName: 'merchantId',
      render: (e) => e.merchantId,
      field: 'merchantId',
      headerClassName: 'custom-dark-theme--header',
      width: 140,
    },
    {
      headerName: 'accountNumber',
      render: (e) => e.accountNumber,
      field: 'accountNumber',
      headerClassName: 'custom-dark-theme--header',
      width: 120,
    },
    {
      headerName: 'contractId',
      render: (e) => e.contractId,
      field: 'contractId',
      headerClassName: 'custom-dark-theme--header',
      width: 180,
    },
    {
      headerName: 'contractNumber',
      render: (e) => e.contractNumber,
      field: 'contractNumber',
      headerClassName: 'custom-dark-theme--header',
      width: 160,
    },
    {
      headerName: 'contractType',
      render: (e) => e.contractType,
      field: 'contractType',
      headerClassName: 'custom-dark-theme--header',
      width: 160,
    },
    {
      headerName: 'contractStartDate',
      render: (e) => e.contractStartDate,
      field: 'contractStartDate',
      headerClassName: 'custom-dark-theme--header',
      width: 125,
    },
    {
      headerName: 'formNumber',
      render: (e) => e.formNumber,
      field: 'formNumber',
      headerClassName: 'custom-dark-theme--header',
      width: 220,
    },
    {
      headerName: 'vinNumber',
      render: (e) => e.vinNumber,
      field: 'vinNumber',
      headerClassName: 'custom-dark-theme--header',
      width: 200,
    },
    {
      headerName: 'termMonth',
      render: (e) => e.termMonth,
      field: 'termMonth',
      headerClassName: 'custom-dark-theme--header',
      width: 80,
    },
    {
      headerName: 'termMiles',
      render: (e) => e.termMiles,
      field: 'termMiles',
      headerClassName: 'custom-dark-theme--header',
      width: 90,
    },
    {
      headerName: 'odometerReading',
      render: (e) => e.odometerReading,
      field: 'odometerReading',
      headerClassName: 'custom-dark-theme--header',
      width: 130,
    },
    {
      headerName: 'accountPaymentName',
      render: (e) => e.accountPaymentName,
      field: 'accountPaymentName',
      headerClassName: 'custom-dark-theme--header',
      width: 160,
    },
    {
      headerName: 'accountPaymentEmail',
      render: (e) => e.accountPaymentEmail,
      field: 'accountPaymentEmail',
      headerClassName: 'custom-dark-theme--header',
      width: 350,
    },
    {
      headerName: 'processingStatus',
      render: (e) => e.processingStatus,
      field: 'processingStatus',
      headerClassName: 'custom-dark-theme--header',
      width: 220,
    },
    {
      headerName: 'transactionType',
      render: (e) => e.transactionType,
      field: 'transactionType',
      headerClassName: 'custom-dark-theme--header',
      width: 140,
    },
    {
      headerName: 'isInitialPayment',
      render: (e) => e.isInitialPayment,
      field: 'isInitialPayment',
      headerClassName: 'custom-dark-theme--header',
      width: 80,
    },
    {
      headerName: 'bankTransactionReferenceNumber',
      render: (e) => e.bankTransactionReferenceNumber,
      field: 'bankTransactionReferenceNumber',
      headerClassName: 'custom-dark-theme--header',
      width: 200,
    },
    {
      headerName: 'gatewayTransactionId',
      render: (e) => e.gatewayTransactionId,
      field: 'gatewayTransactionId',
      headerClassName: 'custom-dark-theme--header',
      width: 200,
    },
    {
      headerName: 'transactionResponseCode',
      render: (e) => e.transactionResponseCode,
      field: 'transactionResponseCode',
      headerClassName: 'custom-dark-theme--header',
      width: 100,
    },
    {
      headerName: 'transactionResponseText',
      render: (e) => e.transactionResponseText,
      field: 'transactionResponseText',
      headerClassName: 'custom-dark-theme--header',
      width: 350,
    },
    {
      headerName: 'transactionStatus',
      render: (e) => e.transactionStatus,
      field: 'transactionStatus',
      headerClassName: 'custom-dark-theme--header',
      width: 180,
    },
    {
      headerName: 'transactionDateTime',
      render: (e) => e.transactionDateTime,
      field: 'transactionDateTime',
      headerClassName: 'custom-dark-theme--header',
      width: 120,
    },
    {
      headerName: 'cardDetails',
      render: (e) => e.cardDetails,
      field: 'cardDetails',
      headerClassName: 'custom-dark-theme--header',
      width: 270,
    },
    {
      headerName: 'currency',
      render: (e) => e.currency,
      field: 'currency',
      headerClassName: 'custom-dark-theme--header',
      width: 90,
    },
    {
      headerName: 'region',
      render: (e) => e.region,
      field: 'region',
      headerClassName: 'custom-dark-theme--header',
      width: 60,
    },
    {
      headerName: 'salesTax',
      render: (e) => e.salesTax,
      field: 'salesTax',
      headerClassName: 'custom-dark-theme--header',
      width: 70,
    },
    {
      headerName: 'salesTaxPercentage',
      render: (e) => e.salesTaxPercentage,
      field: 'salesTaxPercentage',
      headerClassName: 'custom-dark-theme--header',
      width: 90,
    },
    {
      headerName: 'amount',
      render: (e) => e.amount,
      field: 'amount',
      headerClassName: 'custom-dark-theme--header',
      width: 90,
    },
    {
      headerName: 'customerCost',
      render: (e) => e.customerCost,
      field: 'customerCost',
      headerClassName: 'custom-dark-theme--header',
      width: 110,
    },
    {
      headerName: 'customerCity',
      render: (e) => e.customerCity,
      field: 'customerCity',
      headerClassName: 'custom-dark-theme--header',
      width: 100,
    },
    {
      headerName: 'customerState',
      render: (e) => e.customerState,
      field: 'customerState',
      headerClassName: 'custom-dark-theme--header',
      width: 100,
    },
    {
      headerName: 'customerZipCode',
      render: (e) => e.customerZipCode,
      field: 'customerZipCode',
      headerClassName: 'custom-dark-theme--header',
      width: 100,
    },
    {
      headerName: 'isInstallmentProduct',
      render: (e) => e.isInstallmentProduct,
      field: 'isInstallmentProduct',
      headerClassName: 'custom-dark-theme--header',
      width: 110,
    },
    {
      headerName: 'paymentMode',
      render: (e) => e.paymentMode,
      field: 'paymentMode',
      headerClassName: 'custom-dark-theme--header',
      width: 130,
    },
    {
      headerName: 'sourceOrigin',
      render: (e) => e.sourceOrigin,
      field: 'sourceOrigin',
      headerClassName: 'custom-dark-theme--header',
      width: 130,
    },
    {
      headerName: 'createdAt',
      render: (e) => e.createdAt,
      field: 'createdAt',
      headerClassName: 'custom-dark-theme--header',
      width: 120,
    },
    {
      headerName: 'createdBy',
      render: (e) => e.createdBy,
      field: 'createdBy',
      headerClassName: 'custom-dark-theme--header',
      width: 310,
    },
    {
      headerName: 'updatedAt',
      render: (e) => e.updatedAt,
      field: 'updatedAt',
      headerClassName: 'custom-dark-theme--header',
      width: 120,
    },
    {
      headerName: 'updatedBy',
      render: (e) => e.updatedBy,
      field: 'updatedBy',
      headerClassName: 'custom-dark-theme--header',
      width: 310,
    },
  ];
  const [dataSource, setDataSource] = useState([]);
  const [pageSize, setPageSize] = React.useState(PAGINATION_OPTIONS[2]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const updateDataSource = (data) => {
    const _updatedDataSource = data.map((item, index) => {
      return { ...item, _id: index }; // Generate new ids based on the current data only
    });
    setDataSource(_updatedDataSource); // Set the new data directly
  };

  const fetchReport = async () => {
    const params = {
      page: page + 1,
      limit: pageSize,
      filter: contractNumber
        ? JSON.stringify({
            contract_number: {
              eq: contractNumber,
            },
          })
        : filter
        ? JSON.stringify(filter)
        : {},
    };
    setIsLoading(true);

    return subscriptionsApi.getPaymentResponses(dispatch, params);
  };

  useEffect(() => {
    const loadData = async () => {
      const reports = await fetchReport();
      if (reports && reports.data) {
        updateDataSource(reports.data.data);
        setRowCount(reports.data.totalItems);
      }
      setIsLoading(false);
    };
    loadData();
  }, [page, pageSize, filter]);

  const handlePageChange = (params) => {
    setPage(params.page);
    setPageSize(params.pageSize);
  };

  return (
    <div className="container">
      <div
        style={{
          height: viewHeight,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <DataGrid
            density="compact"
            paginationModel={{ page, pageSize }}
            pagination
            pageSizeOptions={PAGINATION_OPTIONS}
            rowCount={rowCount}
            getRowId={(row) => row._id}
            rows={dataSource}
            columns={columns}
            paginationMode="server"
            onPaginationModelChange={handlePageChange}
            slots={{
              toolbar: CustomToolbar,
            }}
          />
        )}
      </div>
    </div>
  );
}

export default OfferPaymentResponse;
