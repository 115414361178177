import React, { useEffect, useState } from 'react';
import {
  AccordionDetails,
  Accordion,
  AccordionSummary,
  Typography,
  CardContent,
  Box,
  Grid,
  LinearProgress,
  Chip,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import carsApi from '../api/cars.api';

function CarPopulationLog(props) {
  // used to fetch the data for tab
  const {
    car: { _id },
  } = props;

  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(true);
  const [showMessageNotData, setShowMessageNotData] = useState(false);
  const [addCarImageStatus, setAddCarImageStatus] = useState(false);
  const [addCarImageResult, setAddCarImageResult] = useState(null);

  const [addPurchaseDateStatus, setAddPurchaseDateStatus] = useState(false);
  const [addPurchaseDateResult, setAddPurchaseDateResult] = useState(null);

  const [addExpiryDateStatus, setAddExpiryDateStatus] = useState(false);
  const [addExpiryDateResult, setAddExpiryDateResult] = useState(null);

  const [updatedAt, setUpdatedAt] = useState('');

  const [addWarrantyAnnotationStatus, setAddWarrantyAnnotationStatus] =
    useState(false);
  const [addWarrantyAnnotationResult, setAddWarrantyAnnotationResult] =
    useState(null);

  const [motorDetailsStatus, setMotorDetailsStatus] = useState(false);
  const [motorDetailsResult, setMotorDetailsResult] = useState(null);

  const [maintenanceDetailsStatus, setMaintenanceDetailsStatus] =
    useState(false);
  const [maintenanceDetailsResult, setMaintenanceDetailsResult] =
    useState(null);

  const [
    repairPalMaintenanceDetailsStatus,
    setRepairPalMaintenanceDetailsStatus,
  ] = useState(false);
  const [
    repairPalMaintenanceDetailsResult,
    setRepairPalMaintenanceDetailsResult,
  ] = useState(null);

  const [addRecallsResult, setAddRecallsResult] = useState(false);
  const [addRecallsStatus, setAddRecallsStatus] = useState(null);

  // this is called once to get the all details needed for this tab
  useEffect(() => {
    const getPData = async () => {
      setLoading(true);
      carsApi.getCarPopulationData(_id, dispatch).then((res) => {
        setShowMessageNotData(
          (res.status === 200 || res.status === 204) && res.data === ''
        );
        if (res.data !== '') {
          setUpdatedAt(res.data.updatedAt);

          setAddCarImageStatus(res.data.data.addCarImageStatus);
          setAddCarImageResult(res.data.data.addCarImageResult);

          setAddExpiryDateStatus(res.data.data.addExpiryDateStatus);
          setAddExpiryDateResult(res.data.data.addExpiryDateResult);

          setAddPurchaseDateStatus(res.data.data.addPurchaseDateStatus);
          setAddPurchaseDateResult(res.data.data.addPurchaseDateResult);

          setAddWarrantyAnnotationStatus(
            res.data.data.addWarrantyAnnotationStatus
          );
          setAddWarrantyAnnotationResult(
            res.data.data.addWarrantyAnnotationResult
          );

          setMaintenanceDetailsStatus(res.data.data.maintenanceDetailsStatus);
          setMaintenanceDetailsResult(res.data.data.maintenanceDetailsResult);

          setMotorDetailsStatus(res.data.data.motorDetailsStatus);
          setMotorDetailsResult(res.data.data.motorDetailsResult);

          setRepairPalMaintenanceDetailsStatus(
            res.data.data.repairPalMaintenanceDetailsStatus
          );
          setRepairPalMaintenanceDetailsResult(
            res.data.data.repairPalMaintenanceDetailsResult
          );

          setAddRecallsResult(res.data.data.addRecallsResult);
          setAddRecallsStatus(res.data.data.addRecallsStatus);
        }
        setLoading(false);
      });
    };
    getPData();
  }, []);

  const restartPopulation = () => {
    const restartPop = async () => {
      setLoading(true);
      await carsApi.restartPopulation(_id, dispatch);
      setLoading(false);
    };
    restartPop();
  };

  return (
    <div>
      {!isLoading ? (
        showMessageNotData ? (
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
            Car Population logs have not been populated. Most probably car has
            not been claimed yet for an invitation.
          </Typography>
        ) : (
          <div>
            <Grid container justify="flex-end">
              <Box
                sx={{
                  marginLeft: 1,
                  marginRight: 'auto',
                  marginBottom: 16,
                }}
              >
                <Chip
                  label={`Last Car Population Date : ${moment(updatedAt).format(
                    'lll'
                  )}`}
                />
              </Box>
              <Box
                sx={{
                  marginLeft: 'auto',
                  marginRight: 1,
                  marginBottom: 16,
                }}
              >
                <Button color="primary" onClick={restartPopulation}>
                  Restart Car Population
                </Button>
              </Box>
            </Grid>
            <CarAccordion
              header="Car Image"
              keyy={addCarImageStatus}
              value={addCarImageResult}
            />
            <CarAccordion
              header="Purchase Date"
              keyy={addPurchaseDateStatus}
              value={addPurchaseDateResult}
            />
            <CarAccordion
              header="Factory Warranty"
              keyy={addExpiryDateStatus}
              value={addExpiryDateResult}
            />
            <CarAccordion
              header="Contracts"
              keyy={addWarrantyAnnotationStatus}
              value={addWarrantyAnnotationResult}
            />
            <CarAccordion
              header="Motor Details"
              keyy={motorDetailsStatus}
              value={motorDetailsResult}
            />
            <CarAccordion
              header="Maintenance"
              keyy={maintenanceDetailsStatus}
              value={maintenanceDetailsResult}
            />
            <CarAccordion
              header="Repair Pal Maintenance"
              keyy={repairPalMaintenanceDetailsStatus}
              value={repairPalMaintenanceDetailsResult}
            />
            <CarAccordion
              header="Recalls"
              keyy={addRecallsStatus}
              value={addRecallsResult}
            />
          </div>
        )
      ) : (
        <>
          <LinearProgress />
          <Typography color="secondary">Loading...</Typography>
        </>
      )}
    </div>
  );
}

function CarAccordion({ header, keyy, value }) {
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Accordion
      expanded={expanded === 'panel1'}
      onChange={handleChange('panel1')}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Grid container direction="row" spacing={2}>
          <Box sx={{ marginRight: 20, display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" sx={{ width: '33%', flexShrink: 0 }}>
              {header}
            </Typography>
          </Box>
          <Box
            sx={{
              marginLeft: 'auto',
              marginRight: 20,
              display: 'flex',
              alignItems: 'right',
            }}
          >
            {value == null || (value && keyy == null) ? (
              <Button color="warning">No Logs</Button>
            ) : keyy ? (
              <Button color="success">Success</Button>
            ) : (
              <Button color="danger">Failed</Button>
            )}
          </Box>
        </Grid>
      </AccordionSummary>
      <CarAccordionData values={value} keyyy={keyy} />
    </Accordion>
  );
}

function CarAccordionData({ values, keyyy }) {
  const parseCamelCase = (text) => {
    const result = text.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  };
  const stringifyValueBasedOnType = (v, k) => {
    if (k === 'b2bExpiryDate' || k === 'driveTrainExpiryDate') {
      return moment(v).format('L');
    }
    return v;
  };

  return (
    <AccordionDetails>
      {values && keyyy && (
        <CardContent fullWidth>
          {Object.keys(values).map((valuee) => (
            <Grid direction="row" spacing={2}>
              <Typography variant="caption" color="text.secondary">
                <strong>{`${parseCamelCase(valuee)}`}</strong>
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {`${stringifyValueBasedOnType(values[valuee], valuee)}`}
              </Typography>
            </Grid>
          ))}
        </CardContent>
      )}
    </AccordionDetails>
  );
}

export default CarPopulationLog;
