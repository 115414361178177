import {
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PAGINATION_OPTIONS } from '../constants';
import { getContactUsFailedReq } from '../store/actions/contactUs.actions';
import './ContactUsFailedRequests.scss';

const CONTACT_US_FIELDS = [
  {
    display: 'Request Number',
    render: (u) => u.requestNumber,
    sortField: 'requestNumber',
    width: 200,
  },
  {
    display: 'Name',
    render: (u) => u.name,
    sortField: 'name',
    width: 200,
  },
  {
    display: 'Email',
    render: (u) => u.email,
    sortField: 'email',
    width: 200,
  },
  {
    display: 'Phone Number',
    render: (u) => u.phoneNumber,
    width: 200,
  },
  {
    display: 'VIN',
    render: (u) => u.vin,
    width: 200,
  },
  {
    display: 'Subject',
    render: (u) => u.subject,
    width: 200,
  },
  {
    display: 'Query',
    render: (u) => u.query,
    width: 200,
  },
  {
    display: 'Created At',
    render: (u) => moment(u.createdAt).format('ll'),
    sortField: 'createdAt',
    width: 200,
  },
];

function ContactUsFailedRequests() {
  const dispatch = useDispatch();
  const contactUsFailedReq = useSelector(
    (state) => state.contactUs.contactUsFailedReq
  );
  const [pageNumber, setPageNumber] = useState(0);
  const [countPerPage, setCountPerPage] = useState(PAGINATION_OPTIONS[2]);
  const [isLoading, setIsLoading] = useState(true);
  const [sortField, setSortField] = useState('createdAt');
  const [sortDirection, setSortDirection] = useState('desc');

  const getContactUsRequests = (newCountPerPage, newPageNumber) => {
    dispatch(
      getContactUsFailedReq({
        skip: newCountPerPage * newPageNumber,
        limit: newCountPerPage,
      })
    );
  };

  const changePage = (event, newPageNumber) => {
    setPageNumber(newPageNumber);
    getContactUsRequests(countPerPage, newPageNumber);
  };

  const changeRowsPerPage = (event) => {
    const newCountPerPage = event.target.value;
    setCountPerPage(newCountPerPage);
    getContactUsRequests(newCountPerPage, pageNumber);
  };

  const updateSort = (field) => {
    let newSortDirection = 'desc';
    if (sortField === field) {
      newSortDirection = sortDirection === 'desc' ? 'asc' : 'desc';
    }
    setSortDirection(newSortDirection);
    setSortField(field);
  };

  useEffect(() => {
    getContactUsRequests(countPerPage, pageNumber);
  }, []);

  useEffect(() => {
    setIsLoading(false);
  }, [contactUsFailedReq]);

  return (
    <div>
      {isLoading && (
        <LinearProgress style={{ height: '1rem' }} color="secondary" />
      )}
      {!isLoading && !!contactUsFailedReq && (
        <>
          <div className="contactus-table-container">
            <TableContainer component={Paper}>
              <Table size="small" padding="none">
                <TableHead className="bg-dark">
                  <TableRow>
                    {CONTACT_US_FIELDS.map((field) => {
                      return (
                        <TableCell key={field.display} align="center">
                          <TableSortLabel
                            active={field.sortField === sortField}
                            className="text-white"
                            direction={
                              field.sortField === sortField
                                ? sortDirection
                                : 'desc'
                            }
                            hideSortIcon={!field.sortField}
                            onClick={() => updateSort(field.sortField)}
                          >
                            {field.display}
                          </TableSortLabel>
                        </TableCell>
                      );
                    })}
                    <TableCell align="center" className="text-white">
                      <span>&nbsp;</span>
                    </TableCell>
                    <TableCell align="center" className="text-white">
                      <span>&nbsp;</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {contactUsFailedReq.data.map((user) => (
                    <TableRow key={user._id} hover className="cursor-pointer">
                      {CONTACT_US_FIELDS.map((field) => (
                        <TableCell key={field.display} align="center">
                          {field.render(user)}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <TablePagination
            rowsPerPage={contactUsFailedReq.data.length}
            component="div"
            count={
              contactUsFailedReq.data.length * contactUsFailedReq.pageCount
            }
            page={contactUsFailedReq.currentPage - 1}
            rowsPerPageOptions={PAGINATION_OPTIONS}
            onPageChange={changePage}
            onRowsPerPageChange={changeRowsPerPage}
          />
        </>
      )}
    </div>
  );
}

export default ContactUsFailedRequests;
