import userApi from '../../api/user.api';

import {
  DELETE_USER_PERMISSIONS,
  GET_USER_GROUP,
  GET_USER_PERMISSIONS,
} from '../types';

export const getUserGroups = () => async (dispatch, getState) => {
  let payload;
  if (
    getState &&
    getState().currentUser &&
    getState().currentUser.userGroup &&
    Object.hasOwnProperty.call(
      getState().currentUser.userGroup,
      'userHasAdminWriteAccess'
    ) &&
    Object.hasOwnProperty.call(
      getState().currentUser.userGroup,
      'userHasDealerProfileAccess'
    )
  ) {
    payload = getState().currentUser.userGroup;
  } else {
    payload = await userApi.getUserGroups(dispatch);
  }
  dispatch({
    type: GET_USER_GROUP,
    payload,
  });
};

export const getUserPermissions = () => async (dispatch, getState) => {
  let payload;
  if (
    getState &&
    getState().permissions &&
    getState().permissions.permissions &&
    Object.keys(getState().permissions.permissions).length !== 0
  ) {
    payload = getState().permissions.permissions;
  } else {
    payload = await userApi.getPermissions(dispatch);
  }
  dispatch({
    type: GET_USER_PERMISSIONS,
    payload,
  });
};

export const deleteUserPermission = () => async (dispatch) => {
  dispatch({
    type: DELETE_USER_PERMISSIONS,
  });
};
