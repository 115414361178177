import React, { useState, useEffect } from 'react';
import {
  Button,
  Grid,
  LinearProgress,
  Dialog,
  CircularProgress,
  IconButton,
  Typography,
  Snackbar,
  Alert as MuiAlert,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
} from '@mui/material';
import { styled } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { format, parseISO } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import { CheckCircleRounded, AccessTime, Close } from '@mui/icons-material';
import { PAGINATION_OPTIONS } from '../constants';
import invitationApi from '../api/invitation.api';
import BulkInvitationForm from '../components/BulkInvitationForm';
import InvitationLogs from './InvitationLogs';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// const useStyles = styled({
//   root: {
//     '& .custom-dark-theme--header': {
//       backgroundColor: '#343a40 !important',
//       color: 'white !important',
//       '& svg': {
//         color: 'white !important',
//       },
//     },
//     '& .custom-link-cell': {
//       color: 'blue !important',
//       cursor: 'pointer',
//     },
//   },
// });
const CustomDataGrid = styled('div')({
  '& .custom-dark-theme-header': {
    backgroundColor: '#343a40 !important',
    color: 'white !important',
    '& svg': {
      color: 'white !important',
    },
  },
  '& .custom-link-cell': {
    color: 'blue !important',
    cursor: 'pointer',
  },
});
const StyledComponent = styled('div')(({ theme }) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}));

const DialogTitle = styled(({ children, classes, onClose, ...other }) => (
  <MuiDialogTitle disableTypography {...other}>
    <Typography variant="h6">{children}</Typography>
    {onClose ? (
      <IconButton
        aria-label="close"
        style={{ right: 8, top: 8, color: '#9e9e9e', position: 'absolute' }}
        onClick={onClose}
      >
        <Close />
      </IconButton>
    ) : null}
  </MuiDialogTitle>
))(({ theme }) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}));

// const DialogContent = styled((theme) => ({
//   root: {
//     padding: theme.spacing(2),
//   },
// }))(MuiDialogContent);
const DialogContent = styled('div')(({ theme }) => ({
  // Define root and padding styles
  root: {
    padding: theme.spacing(2),
  },
}));

function BulkInvitation() {
  // const classes = useStyles();
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.permissions.permissions);

  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize, setPageSize] = React.useState(PAGINATION_OPTIONS[2]);
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [toastOpen, setToastOpen] = useState(false);
  const [openInvitationLogs, setOpenInvitationLogs] = useState(false);
  const [logsFilter, setLogsFilter] = useState({});
  const [currentJobId, setCurrentJobId] = useState(null);
  const [jobStatus, setJobStatus] = useState({});
  const [openJobStatus, setOpenJobStatus] = useState(false);
  const columns = [
    {
      headerName: 'User Name',
      field: 'userName',
      headerClassName: 'custom-dark-theme--header',
      flex: 1,
    },
    {
      headerName: 'Job Id',
      field: 'jobId',
      headerClassName: 'custom-dark-theme--header',
      cellClassName: 'custom-link-cell',
      flex: 2,
    },
    {
      headerName: 'Dealer ID',
      field: 'dealerId',
      headerClassName: 'custom-dark-theme--header',
      flex: 1,
    },
    {
      headerName: 'Channels',
      field: 'channels',
      headerClassName: 'custom-dark-theme--header',
      renderCell: (e) => {
        return e.value && e.value.length ? e.value.toString() : '';
      },
      flex: 1,
    },
    {
      headerName: 'Total Rows',
      field: 'totalRows',
      headerClassName: 'custom-dark-theme--header',
      cellClassName: 'custom-link-cell',
      flex: 1,
    },
    {
      headerName: 'Created At',
      valueFormatter: (e) =>
        e.value ? format(parseISO(e.value), 'MM/dd/yyyy') : '',
      field: 'createdAt',
      headerClassName: 'custom-dark-theme--header',
      flex: 1,
    },
    {
      headerName: 'Status',
      renderCell: (e) => {
        return e.value && e.value.toLowerCase() === 'processed' ? (
          <CheckCircleRounded style={{ fill: 'green' }} />
        ) : (
          <AccessTime style={{ fill: '#00000096' }} />
        );
      },
      field: 'status',
      headerClassName: 'custom-dark-theme--header',
      flex: 1,
    },
  ];

  useEffect(() => {
    function updateDataSource(data) {
      const startIndex =
        dataSource && dataSource.length
          ? dataSource[dataSource.length - 1]._gridId + 1
          : 0;
      const _updatedDataSource = dataSource.concat(
        data.map((item, index) => {
          return { ...item, _gridId: index + startIndex };
        })
      );
      setDataSource(_updatedDataSource);
      return _updatedDataSource;
    }

    const fetchData = async (skip = dataSource.length, limit = pageSize) => {
      const params = {
        skip,
        limit,
      };

      return invitationApi.getBulkInvitation(params, dispatch);
    };
    function checkIfDataPresent() {
      const totalDataExpected = rowCount || (page + 1) * pageSize;
      const startIndex = page * pageSize;
      if (dataSource.length === rowCount) {
        return true;
      }
      return (
        dataSource &&
        dataSource.length > startIndex &&
        (rowCount > totalDataExpected
          ? dataSource.length >= totalDataExpected
          : true)
      );
    }
    const loadData = async () => {
      if (!checkIfDataPresent()) {
        setIsLoading(true);
        const reports = await fetchData();
        if (reports && reports.data) {
          updateDataSource(reports.data);
          if (reports.totalCount !== rowCount) {
            setRowCount(reports.totalCount);
          }
        }
        setIsLoading(false);
      }
    };
    loadData();
  }, [page, pageSize]);

  const addToDataSourceAtStart = (data) => {
    if (!data || !data._id) {
      return;
    }
    const updateDataSource = dataSource.map((item) => {
      // eslint-disable-next-line no-param-reassign
      item._gridId++;
      return item;
    });
    // eslint-disable-next-line no-param-reassign
    data._gridId = 0;
    setDataSource([data, ...updateDataSource]);
    setToastOpen(true);
  };

  const handleModalClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpenModal(false);
    }
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params.pageSize);
  };

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const getBulkInvitationStatus = async (jobId) => {
    if (jobId && jobStatus._id) {
      if (jobStatus._id === jobId) {
        return;
      }
      setJobStatus({});
    }
    const response = await invitationApi.getBulkInvitationStatus(
      { jobId },
      dispatch
    );
    if (response && response.length === 1) {
      setJobStatus(response[0]);
    } else if (response && response.length === 0) {
      setJobStatus({ _id: jobId });
    } else {
      setJobStatus({ _id: jobId, error: true });
    }
  };

  const handleJobStatusClose = () => {
    setOpenJobStatus(false);
  };

  const handleCellClick = (cellParams) => {
    if (cellParams.field === 'jobId') {
      setOpenInvitationLogs(true);
      setCurrentJobId(cellParams.value);
      setLogsFilter({
        bulkInviteRef: {
          $eq: cellParams.value,
        },
      });
    } else if (cellParams.field === 'totalRows') {
      setOpenJobStatus(true);
      getBulkInvitationStatus(cellParams.row.jobId);
    }
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setToastOpen(false);
  };

  const handleInvitationLogsClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpenInvitationLogs(false);
    }
  };

  return (
    <Grid container className="reports">
      <Grid item sm={12}>
        <h2 className="d-flex align-content-center align-items-center mb-2">
          <span className="mr-2">Bulk Invitations</span>
          <div className="flex-grow-1" />
          {permissions.BULK_INVITATION.WRITE && (
            <Button
              color="primary"
              variant="outlined"
              onClick={() => setOpenModal(true)}
            >
              Send Bulk Invite
            </Button>
          )}
        </h2>
        {isLoading ? (
          <LinearProgress style={{ height: '1rem' }} color="secondary" />
        ) : (
          <StyledComponent>
            <div
              style={{ height: '70vh', width: '100%' }}
              className={StyledComponent.root}
            >
              <CustomDataGrid>
                <DataGrid
                  paginationModel={{ page, pageSize }}
                  pagination
                  pageSizeOptions={PAGINATION_OPTIONS}
                  onPageSizeChange={handlePageSizeChange}
                  rowCount={rowCount || 0}
                  getRowId={(row) => row._gridId}
                  rows={dataSource}
                  columns={columns}
                  onPaginationModelChange={handlePageChange}
                  onCellClick={handleCellClick}
                  // className={classes.root}
                  className={CustomDataGrid.root}
                />
              </CustomDataGrid>
            </div>
          </StyledComponent>
        )}
        <Dialog
          onClose={handleModalClose}
          aria-labelledby="customized-dialog-title"
          open={openModal}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="customized-dialog-title" onClose={handleModalClose}>
            Create Bulk Invite
          </DialogTitle>
          <MuiDialogContent>
            <BulkInvitationForm
              closeDialog={handleModalClose}
              updateListData={addToDataSourceAtStart}
            />
          </MuiDialogContent>
        </Dialog>
        <Dialog
          onClose={handleInvitationLogsClose}
          aria-labelledby="customized-dialog-title"
          open={openInvitationLogs}
          fullWidth
          maxWidth="lg"
          disableEnforceFocus
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleInvitationLogsClose}
          >
            {currentJobId ? `Invite Logs for Job Id: ${currentJobId}` : ''}
          </DialogTitle>
          <DialogContent style={{ minHeight: '80vh' }}>
            <InvitationLogs
              filter={logsFilter}
              jobStatus={
                jobStatus._id ===
                (logsFilter.bulkInviteRef && logsFilter.bulkInviteRef.$eq)
                  ? jobStatus
                  : {}
              }
              getBulkInvitationStatus={getBulkInvitationStatus}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          onClose={handleJobStatusClose}
          aria-labelledby="customized-dialog-title"
          open={openJobStatus}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleJobStatusClose}
          >
            Value
          </DialogTitle>
          <DialogContent>
            {jobStatus && Object.keys(jobStatus).length === 0 ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </div>
            ) : jobStatus && jobStatus.error ? (
              'Error in fetching detailed status'
            ) : (
              <div>
                <pre>{JSON.stringify(jobStatus, null, 2)}</pre>
              </div>
            )}
          </DialogContent>
        </Dialog>
        <Snackbar
          open={toastOpen}
          autoHideDuration={6000}
          onClose={handleToastClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <div>
            <Alert onClose={handleToastClose} severity="success">
              Bulk Invite Created Successfully
            </Alert>
          </div>
        </Snackbar>
      </Grid>
    </Grid>
  );
}

export default BulkInvitation;
