import api from '.';

const REPORT_URL = `/admin`;

const getNotes = async (params, dealerId, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/dealer/profile/${dealerId}/notes`,
    null,
    params
  );
};

const saveNote = async (dealerId, data, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'post',
    `${REPORT_URL}/dealer/profile/${dealerId}/notes`,
    data
  );
};

const getDcr = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/dealer/profile`,
    null,
    params
  );
};

const getDcrFilters = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/dealer/profile/filter/values`,
    null,
    params
  );
};

const approveRejectGoogleData = async (data, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'post',
    `${REPORT_URL}/dealer/profile/${data.accountNumber}`,
    data,
    null
  );
};

const getGooglePhotos = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/dealer/profile/googlePhotos`,
    null,
    params
  );
};

const fetchGoogleCandidates = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/dealer/profile/candidate/${params.placeId}`,
    null,
    null
  );
};

const searchGoogleCandidates = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/dealer/profile/candidates`,
    null,
    params
  );
};

const updateGoogleCandidate = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'post',
    `${REPORT_URL}/dealer/profile/updateCandidate`,
    params,
    null
  );
};

export default {
  getDcr,
  getDcrFilters,
  approveRejectGoogleData,
  getGooglePhotos,
  fetchGoogleCandidates,
  searchGoogleCandidates,
  updateGoogleCandidate,
  getNotes,
  saveNote,
};
