import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import moment from 'moment';
import {
  Delete,
  Add,
  DirectionsCarOutlined,
  Cancel,
} from '@mui/icons-material';
import {
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableSortLabel,
  TableBody,
  TablePagination,
  Paper,
  Button,
  Table,
  IconButton,
  TextField,
  LinearProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  getUsersAction,
  deleteUserAction,
} from '../store/actions/user.actions';
import ConfirmationModal from '../components/ConfirmationModal';
import { PAGINATION_OPTIONS } from '../constants';

import './Users.scss';

const USER_FIELDS = [
  {
    display: 'Name',
    render: (u) => u.name,
    sortField: 'name',
  },
  {
    display: 'Email',
    render: (u) => u.email,
    sortField: 'email',
  },
  {
    display: 'Phone Number',
    render: (u) => u.phoneNumber,
    sortField: 'phoneNumber',
  },
  {
    display: 'OKTA Id',
    render: (u) => u.oktaId,
    sortField: 'oktaId',
  },
  {
    display: 'Creating Dealer',
    render: (u) => (u.dealerName ? u.dealerName : u.creatingDealerId),
    sortField: 'creatingDealerId',
  },
  {
    display: 'Created',
    render: (u) => moment(u.createdAt).format('ll'),
    sortField: 'createdAt',
  },
  {
    display: 'Updated',
    render: (u) => moment(u.updatedAt).format('ll'),
    sortField: 'updatedAt',
  },
];

function Users() {
  const users = useSelector((state) => state.user.users);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState('');
  const [filterName, setFilterName] = useState('');
  const [filterEmail, setFilterEmail] = useState('');
  const [currentFilter, setCurrentFilter] = useState({});
  const [pageNumber, setPageNumber] = useState(0);
  const [countPerPage, setCountPerPage] = useState(PAGINATION_OPTIONS[2]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortField, setSortField] = useState('createdAt');
  const [sortDirection, setSortDirection] = useState('desc');

  const permissions = useSelector((state) => state.permissions.permissions);

  const getUsers = (newCountPerPage, newPageNumber) => {
    let ordering;
    if (sortField) {
      ordering = sortField;
    }
    dispatch(
      getUsersAction({
        filter: currentFilter,
        skip: newCountPerPage * newPageNumber,
        limit: newCountPerPage,
        ordering,
        sortDirection,
      })
    );
  };
  const goToUser = (id) => {
    window.open(`/users/${id}`, '_blank');
    // history.push(`/users/${id}`);
  };
  const goToVehicles = (e, userId) => {
    e.stopPropagation();
    navigate(`/users/${userId}/cars`);
  };
  const deleteUser = async () => {
    setIsLoading(true);
    setShowConfirmationModal(false);
    await dispatch(deleteUserAction(userIdToDelete));
    await dispatch(getUsersAction());
    setIsLoading(false);
  };
  const openConfirmDelete = (e, id) => {
    e.stopPropagation();
    setUserIdToDelete(id);
    setShowConfirmationModal(true);
  };
  const saveFilters = async () => {
    const filter = {};
    if (filterName) {
      filter.name = `/${filterName}/`;
    }
    if (filterEmail) {
      filter.email = `/${filterEmail}/`;
    }
    setCurrentFilter(filter);
    let sort;
    if (sortField) {
      sort = `${sortDirection === 'desc' ? '-' : ''}${sortField}`;
    }
    setIsLoading(true);
    await dispatch(getUsersAction({ filter, sort }));
    setIsLoading(false);
  };
  const resetFilters = async () => {
    setFilterEmail('');
    setFilterName('');
    setCurrentFilter({});
    setIsLoading(true);
    await dispatch(getUsersAction());
    setIsLoading(false);
  };
  const changePage = (event, newPageNumber) => {
    setPageNumber(newPageNumber);
    getUsers(countPerPage, newPageNumber);
  };
  const changeRowsPerPage = (event) => {
    const newCountPerPage = event.target.value;
    setCountPerPage(newCountPerPage);
    getUsers(newCountPerPage, pageNumber);
  };
  const updateSort = (field) => {
    let newSortDirection = 'desc';
    if (sortField === field) {
      newSortDirection = sortDirection === 'desc' ? 'asc' : 'desc';
    }
    setSortDirection(newSortDirection);
    setSortField(field);
    let ordering;
    if (field) {
      ordering = `${
        newSortDirection === 'desc' ? encodeURIComponent('-') : ''
      }${field}`;
    }
    dispatch(
      getUsersAction({
        filter: currentFilter,
        ordering,
      })
    );
  };

  return (
    <div
      className="users"
      style={{
        display: permissions.USERS.READ ? 'block' : 'none',
      }}
    >
      <h2 className="d-flex align-content-center align-items-center mb-0">
        <span>Users</span>
        {permissions.USERS.WRITE && (
          <Button
            variant="outlined"
            color="primary"
            className="mx-2 px-1"
            onClick={() => goToUser('create')}
          >
            <Add />
            Add
          </Button>
        )}
        <div style={{ display: 'flex', marginLeft: 'auto', marginRight: 0 }}>
          <div style={{ padding: '4px' }}>
            <TextField
              id="filter-name"
              label="Name"
              placeholder="Enter a name"
              value={filterName}
              onChange={(e) => setFilterName(e.currentTarget.value)}
            />
          </div>
          <div style={{ padding: '4px' }}>
            <TextField
              id="filter-email"
              label="Email"
              placeholder="Enter an email address"
              value={filterEmail}
              onChange={(e) => setFilterEmail(e.currentTarget.value)}
            />
          </div>
          {(filterName || filterEmail) && (
            <IconButton onClick={resetFilters}>
              <Cancel color="error" />
            </IconButton>
          )}
          <div style={{ padding: '4px' }}>
            <Button
              variant="contained"
              color="primary"
              className="mr-2"
              onClick={saveFilters}
            >
              Search
            </Button>
          </div>
        </div>
      </h2>
      {isLoading && (
        <LinearProgress style={{ height: '1rem' }} color="secondary" />
      )}
      {!isLoading && !!users && (
        <>
          <TableContainer component={Paper}>
            <Table size="small" padding="none">
              <TableHead className="bg-dark">
                <TableRow>
                  {USER_FIELDS.map((field) => {
                    return (
                      <TableCell key={field.display} align="center">
                        <TableSortLabel
                          active={field.sortField === sortField}
                          className="text-white"
                          direction={
                            field.sortField === sortField
                              ? sortDirection
                              : 'desc'
                          }
                          hideSortIcon={!field.sortField}
                          onClick={() => updateSort(field.sortField)}
                        >
                          {field.display}
                        </TableSortLabel>
                      </TableCell>
                    );
                  })}
                  <TableCell align="center" className="text-white">
                    <span>&nbsp;</span>
                  </TableCell>
                  <TableCell align="center" className="text-white">
                    <span>&nbsp;</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.data.map((user) => (
                  <TableRow
                    key={user._id}
                    hover
                    className="cursor-pointer"
                    onClick={() => goToUser(user._id)}
                  >
                    {USER_FIELDS.map((field) => (
                      <TableCell key={field.display} align="center">
                        {field.render(user)}
                      </TableCell>
                    ))}
                    <TableCell align="center">
                      <IconButton onClick={(e) => goToVehicles(e, user._id)}>
                        <DirectionsCarOutlined color="primary" />
                      </IconButton>
                    </TableCell>
                    {permissions.USERS.WRITE && (
                      <TableCell align="center">
                        <IconButton
                          onClick={(e) => openConfirmDelete(e, user._id)}
                        >
                          <Delete color="error" />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPage={users.data.length}
            component="div"
            count={users.data.length * users.pageCount}
            page={users.currentPage - 1}
            rowsPerPageOptions={PAGINATION_OPTIONS}
            onPageChange={changePage}
            onRowsPerPageChange={changeRowsPerPage}
          />
        </>
      )}
      <ConfirmationModal
        show={showConfirmationModal}
        onConfirm={deleteUser}
        onCancel={() => setShowConfirmationModal(false)}
        headerText="Delete User"
        bodyText="Are you sure you want to delete this user?"
      />
    </div>
  );
}

export default Users;
