import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { addHours, addMonths, addDays, format } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FilterModal from '../components/Filter';
import ConversationsList from '../components/ConversationsList';

function ConversationDashboard() {
  const [appliedFilter, setAppliedFilter] = useState({});
  const [filterDisplay, setFilterDisplay] = useState('');
  const [filterBeforeDate, setFilterBeforeDate] = useState(null);
  const [filterAfterDate, setFilterAfterDate] = useState(null);
  const [applyQuickFilter, setApplyQuickFilter] = useState(false);

  const saveFilters = () => {
    const newFilter = {};
    const displays = [];
    const filterStartDate = new Date(filterAfterDate);
    const filterEndDate = new Date(filterBeforeDate);
    let dateDisplay = '';
    if (filterAfterDate) {
      newFilter.createdAt = { $gte: `${filterStartDate}` };
      dateDisplay = `Dates: ${format(filterStartDate, 'MM/dd/yyyy')}`;
    }
    if (filterBeforeDate) {
      newFilter.createdAt = {
        ...newFilter.createdAt,
        $lte: `${filterEndDate}`,
      };
      if (!dateDisplay) {
        dateDisplay = `Dates: -${format(filterEndDate, 'MM/dd/yyyy')}`;
      } else {
        dateDisplay = `${dateDisplay}-${format(filterEndDate, 'MM/dd/yyyy')}`;
      }
    }
    if (dateDisplay) {
      displays.push(dateDisplay);
    }

    setAppliedFilter(newFilter);
    setFilterDisplay(displays.join(' '));
  };

  useEffect(() => {
    if (applyQuickFilter && !!filterAfterDate) {
      setApplyQuickFilter(false);
      saveFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyQuickFilter, filterAfterDate]);

  const setLastDayFilter = () => {
    setFilterAfterDate(addHours(new Date(), -24));
    setApplyQuickFilter(true);
  };

  const setLastMonthFilter = () => {
    setFilterAfterDate(addMonths(new Date(), -1));
    setApplyQuickFilter(true);
  };

  const setLastSevenDays = () => {
    setFilterAfterDate(addDays(new Date(), -7));
    setApplyQuickFilter(true);
  };

  const resetFilters = () => {
    setFilterAfterDate(null);
    setFilterBeforeDate(null);
    setFilterDisplay('');
    setAppliedFilter({});
  };

  return (
    <div>
      <div>
        <h2 className="d-flex align-content-center align-items-center mb-0">
          <div className="flex-grow-1" />
          {appliedFilter && !Object.keys(appliedFilter).length && (
            <>
              <Button color="primary" onClick={setLastDayFilter}>
                Last 24 Hours
              </Button>
              <Button color="primary" onClick={setLastSevenDays}>
                Last 7 Days
              </Button>
              <Button color="primary" onClick={setLastMonthFilter}>
                Last month
              </Button>
            </>
          )}
          <FilterModal
            currentFilters={appliedFilter}
            onSave={saveFilters}
            itemName="Stats"
            resetFilters={resetFilters}
            filterDisplay={filterDisplay}
          >
            <div>
              <DatePicker
                utils={DateFnsUtils}
                label="After Date (including)"
                variant="inline"
                className="mr-2"
                fullWidth
                id="after-date-filter"
                value={filterAfterDate}
                onChange={setFilterAfterDate}
                autoOk="true"
              />
            </div>
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  utils={DateFnsUtils}
                  label="Before Date (including)"
                  variant="inline"
                  className="mr-2"
                  fullWidth
                  id="before-date-filter"
                  value={filterBeforeDate}
                  onChange={setFilterBeforeDate}
                  autoOk="true"
                />
              </LocalizationProvider>
            </div>
          </FilterModal>
        </h2>
      </div>
      <h4 style={{ textAlign: 'left' }}>Conversations</h4>
      <div className="row my-2 ">
        <ConversationsList viewHeight="75vh" />
      </div>
    </div>
  );
}

export default ConversationDashboard;
