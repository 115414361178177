import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Tabs,
  Tab,
  Switch,
  IconButton,
  Tooltip,
  LinearProgress,
} from '@mui/material';
import Form from 'react-bootstrap/Form';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { HelpOutline } from '@mui/icons-material';
import appConfigApi from '../../api/appConfig.api';
import './BusinessConfig.scss';
import config from './businessConfig.json';

function ToolTipTitle(props) {
  const { toolTipTitle } = props;
  return <h5 style={{ color: 'white', padding: '10px' }}>{toolTipTitle}</h5>;
}

function SaveButton(props) {
  const { catIndex, fieldIndex, updateConfig } = props;

  return (
    <button
      type="button"
      className="btn btn-primary"
      onClick={() => updateConfig(catIndex, fieldIndex)}
    >
      Save
    </button>
  );
}

function BusinessConfig() {
  const dispatch = useDispatch();
  const [originalResponse, setOriginalResponse] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setIsLoading] = useState(0);
  const [data, setData] = useState(config);
  const permissions = useSelector((state) => state.permissions.permissions);

  const handleTab = (event, value) => {
    setActiveTab(value);
  };

  const index = (obj, is, value) => {
    try {
      if (typeof is === 'string') {
        return index(obj, is.split('.'), value);
      }
      if (is.length === 1 && value !== undefined) {
        // eslint-disable-next-line no-return-assign, no-param-reassign
        return (obj[is[0]] = value);
      }
      if (is.length === 0) return obj;
      return index(obj[is[0]], is.slice(1), value);
    } catch (er) {
      return null;
    }
  };

  const processResponse = (response) => {
    const modifiedData = [...data];
    modifiedData.forEach((tab) => {
      tab.categories.forEach((cat) => {
        cat.fields.forEach((field) => {
          // eslint-disable-next-line no-param-reassign
          field.defaultValue = index(response, field.configField);
          // eslint-disable-next-line no-param-reassign
          field.value = index(response, field.configField);
        });
      });
    });
  };

  const loadData = async () => {
    setIsLoading(true);
    const response = await appConfigApi.getBusinessConfigs(null, dispatch);
    const records = [];
    processResponse(response, records);
    if (records && records.length) {
      setData(records);
    }
    setOriginalResponse(response);
    setIsLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const updateConfig = async (catIndex, fieldIndex) => {
    const tempPath =
      data[activeTab].categories[catIndex].fields[fieldIndex].configField;
    const tempVal =
      data[activeTab].categories[catIndex].fields[fieldIndex].value;
    const response = { ...originalResponse };
    index(response, tempPath, tempVal);
    setIsLoading(true);
    await appConfigApi.updateBusinessConfigs(response, null, dispatch);
    setIsLoading(false);
    loadData();
  };

  const handleInputChange = (catIndex, fieldIndex, val) => {
    const modifiedData = [...data];
    modifiedData[activeTab].categories[catIndex].fields[fieldIndex].value = val;
    setData(modifiedData);
  };

  const handleSwitchChange = (catIndex, fieldIndex, val) => {
    const modifiedData = [...data];
    modifiedData[activeTab].categories[catIndex].fields[fieldIndex].value = val;
    setData(modifiedData);
    setTimeout(() => {
      updateConfig(catIndex, fieldIndex);
    }, 500);
  };

  return (
    <Grid container>
      <Grid item sm={12}>
        {isLoading ? (
          <LinearProgress style={{ height: '10px' }} color="secondary" />
        ) : (
          <div style={{ height: '10px' }} />
        )}
        {originalResponse && (
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Row>
              <Col xs={2}>
                <Tabs
                  textColor="secondary"
                  orientation="vertical"
                  value={activeTab}
                  onChange={handleTab}
                  aria-label="config"
                >
                  {data.map((tab) => (
                    <Tab key={tab.name} label={tab.name} />
                  ))}
                </Tabs>
              </Col>
              <Col className="overflow-auto" style={{ height: '85vh' }} xs={10}>
                {data[activeTab].categories.map((cat, catIndex) => (
                  <Col key={cat.name} xs={12}>
                    <h4>{cat.name}</h4>
                    {cat.fields.map((field, fieldIndex) => (
                      <Row key={field.title} className="p-1">
                        <Col xs={8}>
                          <div className="d-flex align-items-center">
                            {field.title}
                            <Tooltip
                              title={
                                <ToolTipTitle toolTipTitle={field.tooltip} />
                              }
                              arrow
                            >
                              <IconButton>
                                <HelpOutline />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </Col>
                        <Col xs={4}>
                          {field.type === 'checkbox' ? (
                            <Switch
                              disabled={!permissions.BUSINESS_CONFIG.WRITE}
                              defaultChecked={field.defaultValue}
                              onChange={(event) => {
                                handleSwitchChange(
                                  catIndex,
                                  fieldIndex,
                                  event.target.checked
                                );
                              }}
                            />
                          ) : field.type === 'text' ? (
                            <div className="d-flex">
                              <Form.Control
                                disabled={!permissions.BUSINESS_CONFIG.WRITE}
                                defaultValue={field.value}
                                onChange={(event) => {
                                  handleInputChange(
                                    catIndex,
                                    fieldIndex,
                                    event.target.value
                                  );
                                }}
                                type={field.type}
                              />
                              {field.defaultValue !== field.value ? (
                                <SaveButton
                                  catIndex={catIndex}
                                  fieldIndex={fieldIndex}
                                  updateConfig={updateConfig}
                                />
                              ) : null}
                            </div>
                          ) : field.type === 'dropdown' ? (
                            <div className="d-flex">
                              <select
                                disabled={!permissions.BUSINESS_CONFIG.WRITE}
                                className="select-style"
                                defaultValue={field.defaultValue}
                                onChange={
                                  (event) =>
                                    handleSwitchChange(
                                      catIndex,
                                      fieldIndex,
                                      event.target.value
                                    )
                                  // eslint-disable-next-line react/jsx-curly-newline
                                }
                              >
                                {field.options.map((opt) => (
                                  <option key={opt}>{opt}</option>
                                ))}
                              </select>
                            </div>
                          ) : (
                            <div className="d-flex">
                              <Form.Control
                                disabled={!permissions.BUSINESS_CONFIG.WRITE}
                                defaultValue={field.value}
                                onChange={(event) => {
                                  // eslint-disable-next-line radix
                                  const val = parseInt(event.target.value);
                                  handleInputChange(catIndex, fieldIndex, val);
                                }}
                                type={field.type}
                              />
                              {field.defaultValue !== field.value ? (
                                <SaveButton
                                  catIndex={catIndex}
                                  fieldIndex={fieldIndex}
                                  updateConfig={updateConfig}
                                />
                              ) : null}
                            </div>
                          )}
                        </Col>
                      </Row>
                    ))}
                    <hr />
                  </Col>
                ))}
              </Col>
            </Row>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}

export default BusinessConfig;
