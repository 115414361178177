import React from 'react';
import { LockOpen, Lock, NoEncryption } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { IconButton } from '@mui/material';
import { updateCarGloveBoxAction } from '../store/actions/cars.actions';

function GloveBoxToggle(props) {
  const {
    carId,
    gloveBox, // userId + status
    callback,
  } = props;

  const dispatch = useDispatch();
  const getUserStatus = () => {
    if (gloveBox) {
      return gloveBox.status;
    }
    return 'incomplete';
  };

  const renderLockIcon = () => {
    const status = getUserStatus();
    switch (status) {
      case 'complete':
        return <LockOpen color="primary" />;
      case 'locked':
        return <Lock color="primary" />;
      case 'incomplete':
      default:
        return <NoEncryption color="disabled" />;
    }
  };
  const toggleLock = async (e) => {
    e.stopPropagation();
    const newStatus = gloveBox.status === 'locked' ? 'complete' : 'locked';
    await dispatch(updateCarGloveBoxAction(carId, gloveBox.userId, newStatus));
    if (callback) {
      callback();
    }
  };

  return (
    <div className="d-flex align-items-center">
      {/* Change these labels based on blakes feedback */}
      <span style={{ fontSize: '0.75rem' }}>Glove box access</span>
      <IconButton onClick={toggleLock}>{renderLockIcon()}</IconButton>
    </div>
  );
}

export default GloveBoxToggle;
