import React, { useState } from 'react';
import { LockOpen, Lock } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { IconButton } from '@mui/material';
import { updateCarVerificationAction } from '../store/actions/cars.actions';

function ResetUserVerification(props) {
  const { carId, userId, isLocked, callback, disabled } = props;
  const [disableIcon, setDisableIcon] = useState(false);
  const dispatch = useDispatch();

  const renderLockIcon = () => {
    if (isLocked) {
      return <Lock color="primary" disabled={disabled || disableIcon} />;
    }
    return <LockOpen color="primary" disabled={disabled || disableIcon} />;
  };
  const toggleLock = async (e) => {
    e.stopPropagation();
    if (isLocked) {
      setDisableIcon(true);
      await dispatch(updateCarVerificationAction(carId, userId, !isLocked));
      setDisableIcon(false);
      if (callback) {
        callback();
      }
    }
  };

  return (
    <div className="d-flex align-items-center">
      <IconButton disabled={disabled || disableIcon} onClick={toggleLock}>
        {renderLockIcon(disabled)}
      </IconButton>
    </div>
  );
}

export default ResetUserVerification;
