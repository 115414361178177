import React, { useState } from 'react';
import { DeleteForever, Add } from '@mui/icons-material';
import { IconButton, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import GloveBoxToggle from './GloveBoxToggle';
import ConfirmationModal from './ConfirmationModal';
import {
  removeUserFromCarAction,
  addUserToCarAction,
} from '../store/actions/cars.actions';
import UserSearch from './UserSearch';
import UserVerificationToggle from './UserVerificationToggle';

function CarUserManagement(props) {
  const {
    userLookup,
    car: { _id, userIds, gloveBoxes, userVerifications },
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUserSearch, setShowUserSearch] = useState(false);
  const [userToRemove, setUserToRemove] = useState('');
  const openDeleteModal = (userId) => {
    setUserToRemove(userId);
    setShowDeleteModal(true);
  };
  const addUser = async (userId) => {
    setShowUserSearch(false);
    dispatch(addUserToCarAction(_id, userId));
  };
  const deleteUser = async () => {
    setShowDeleteModal(false);
    await dispatch(removeUserFromCarAction(_id, userToRemove));
  };

  const parseIsVerified = (userId) => {
    if (userVerifications) {
      const userVerification = userVerifications.find(
        (uv) => uv.userId === userId
      );
      if (userVerification) {
        return userVerification.verified;
      }
    }
    return false;
  };
  const goToUser = (userId) => {
    navigate(`/users/${userId}`);
  };
  return (
    <div className="car-user-management">
      {userIds.map((userId, index) => (
        <div className="d-flex align-items-center" key={`${userId}-${index}`}>
          <Button color="primary" onClick={() => goToUser(userId)}>
            {`${index + 1}. ${
              userLookup[userId] ? userLookup[userId].name : userId
            }`}
          </Button>
          <div className="flex-grow-1" />
          <GloveBoxToggle
            carId={_id}
            gloveBox={
              gloveBoxes.find((gb) => gb.userId === userId) || {
                userId,
                status: 'incomplete',
              }
            }
          />
          <UserVerificationToggle
            carId={_id}
            userId={userId}
            isVerified={parseIsVerified(userId)}
          />
          <IconButton onClick={() => openDeleteModal(userId)}>
            <DeleteForever color="error" />
          </IconButton>
        </div>
      ))}
      <Button
        variant="outlined"
        color="primary"
        onClick={() => setShowUserSearch(true)}
      >
        <Add />
        <span>Share Vehicle</span>
      </Button>
      <UserSearch
        show={showUserSearch}
        addUser={addUser}
        onHide={() => setShowUserSearch(false)}
      />
      <ConfirmationModal
        show={showDeleteModal}
        onConfirm={deleteUser}
        onCancel={() => setShowDeleteModal(false)}
        headerText="Remove User from car"
        bodyText="Are you sure you want to remove this user from the car?"
      />
    </div>
  );
}

export default CarUserManagement;
