import React, { useEffect, useState } from 'react';
import { LinearProgress, Button, Card } from '@mui/material';
import { addHours, addMonths, addDays, format } from 'date-fns';
import { Row, Col } from 'react-bootstrap';
import DateFnsUtils from '@date-io/date-fns';
import { useSelector } from 'react-redux';
import {
  BookOutlined,
  Email,
  EmailOutlined,
  PersonOutline,
  ReplyOutlined,
  SmsFailed,
} from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import FilterModal from '../components/Filter';
import reportApi from '../api/report.api';
import GenericPie from '../components/GenericPie';
import GenericBarChart from '../components/GenericBarChart';

const isUK = process.env.REACT_APP_DEFAULT_REGION === 'UK';

function SupportReport() {
  const [supportStatsData, setSupportStatsData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [appliedFilter, setAppliedFilter] = useState({});
  const [filterDisplay, setFilterDisplay] = useState('');
  const [filterBeforeDate, setFilterBeforeDate] = useState(null);
  const [filterAfterDate, setFilterAfterDate] = useState(null);
  const [applyQuickFilter, setApplyQuickFilter] = useState(false);

  const permissions = useSelector((state) => state.permissions.permissions);

  const saveFilters = () => {
    const newFilter = {};
    const displays = [];
    const filterStartDate = new Date(filterAfterDate);
    const filterEndDate = new Date(filterBeforeDate);
    let dateDisplay = '';
    if (filterAfterDate) {
      newFilter.createdAt = { $gte: `${filterStartDate}` };
      dateDisplay = `Dates: ${format(filterStartDate, 'MM/dd/yyyy')}`;
    }
    if (filterBeforeDate) {
      newFilter.createdAt = {
        ...newFilter.createdAt,
        $lte: `${filterEndDate}`,
      };
      if (!dateDisplay) {
        dateDisplay = `Dates: -${format(filterEndDate, 'MM/dd/yyyy')}`;
      } else {
        dateDisplay = `${dateDisplay}-${format(filterEndDate, 'MM/dd/yyyy')}`;
      }
    }
    if (dateDisplay) {
      displays.push(dateDisplay);
    }

    setAppliedFilter(newFilter);
    setFilterDisplay(displays.join(' '));
  };

  useEffect(() => {
    if (applyQuickFilter && !!filterAfterDate) {
      setApplyQuickFilter(false);
      saveFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyQuickFilter, filterAfterDate]);

  const setLastDayFilter = () => {
    setFilterAfterDate(addHours(new Date(), -24));
    setApplyQuickFilter(true);
  };

  const setLastMonthFilter = () => {
    setFilterAfterDate(addMonths(new Date(), -1));
    setApplyQuickFilter(true);
  };

  const setLastSevenDays = () => {
    setFilterAfterDate(addDays(new Date(), -7));
    setApplyQuickFilter(true);
  };

  const resetFilters = () => {
    setFilterAfterDate(null);
    setFilterBeforeDate(null);
    setFilterDisplay('');
    setAppliedFilter({});
  };

  useEffect(() => {
    const loadData = async () => {
      let params = {
        filter: JSON.stringify(appliedFilter),
      };
      if (!appliedFilter || !appliedFilter.createdAt) {
        params = {
          filter: JSON.stringify({ createdAt: { $gte: '2023-01-01' } }),
        };
      }
      const result = await reportApi.getSupportContactUsStats(params);
      setSupportStatsData({ ...result });
      setIsLoading(false);
    };
    setIsLoading(true);
    loadData();
  }, [appliedFilter]);

  if (isLoading) {
    return (
      <div className="container">
        <div className="row my-2">
          <h2>Support Stats</h2>
        </div>
        <LinearProgress style={{ height: '1rem' }} color="secondary" />
      </div>
    );
  }
  return (
    <div
      className="container"
      style={{
        display: permissions.SUPPORT.READ ? 'block' : 'none',
      }}
    >
      <div>
        <h2 className="d-flex align-content-center align-items-center mb-0">
          <span className="mr-2">Support Stats</span>
          <div className="flex-grow-1" />
          {appliedFilter && !Object.keys(appliedFilter).length && (
            <>
              <Button color="primary" onClick={setLastDayFilter}>
                Last 24 Hours
              </Button>
              <Button color="primary" onClick={setLastSevenDays}>
                Last 7 Days
              </Button>
              <Button color="primary" onClick={setLastMonthFilter}>
                Last month
              </Button>
            </>
          )}
          <FilterModal
            currentFilters={appliedFilter}
            onSave={saveFilters}
            itemName="Reports"
            resetFilters={resetFilters}
            filterDisplay={filterDisplay}
          >
            <div style={{ marginBottom: '16px', marginTop: '10px' }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  utils={DateFnsUtils}
                  label="After Date (including)"
                  variant="inline"
                  className="mr-2"
                  fullWidth
                  id="after-date-filter"
                  value={filterAfterDate}
                  onChange={setFilterAfterDate}
                  autoOk="true"
                />
              </LocalizationProvider>
            </div>
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  utils={DateFnsUtils}
                  label="Before Date (including)"
                  variant="inline"
                  className="mr-2"
                  fullWidth
                  id="before-date-filter"
                  value={filterBeforeDate}
                  onChange={setFilterBeforeDate}
                  autoOk="true"
                />
              </LocalizationProvider>
            </div>
          </FilterModal>
        </h2>
      </div>
      <div style={{ display: isUK ? 'none' : '' }}>
        <Card className="p-2">
          <h4 style={{ textAlign: 'left' }}>Overall</h4>
          <Row className="t-3">
            <Col>
              <div
                className="d-flex justify-content-left align-items-center"
                style={{ paddingLeft: 20 }}
              >
                <BookOutlined color="disabled" fontSize="large" />
                <p className="card-content-align">
                  <b>
                    {supportStatsData ? supportStatsData.supportTotalCases : 0}
                  </b>
                  <br />
                  <span>Total Cases</span>
                </p>
              </div>
            </Col>
            <Col>
              <div
                className="d-flex justify-content-left align-items-center"
                style={{ paddingLeft: 20 }}
              >
                <PersonOutline color="disabled" fontSize="large" />
                <p className="card-content-align">
                  <b>
                    {supportStatsData ? supportStatsData.supportUniqueUsers : 0}
                  </b>
                  <br />
                  <span>Unique Customers with Cases</span>
                </p>
              </div>
            </Col>
            <Col>
              <div
                className="d-flex justify-content-left align-items-center"
                style={{ paddingLeft: 20 }}
              >
                <ReplyOutlined color="disabled" fontSize="large" />
                <p className="card-content-align">
                  <b>
                    {supportStatsData
                      ? supportStatsData.supportRepliesCount
                      : 0}
                  </b>
                  <br />
                  <span>Total Replies by Agents</span>
                </p>
              </div>
            </Col>
          </Row>
        </Card>
        <br />
        <Card>
          <Row className="t-2">
            <Col>
              <div
                style={{
                  height: '40vh',
                  position: 'relative',
                  marginBottom: '1%',
                  padding: '1%',
                }}
              >
                <GenericPie
                  title="Support Sources"
                  stats={supportStatsData.supportSources}
                  labelKey="source"
                  legendPosition="right"
                />
              </div>
            </Col>
            <Col>
              <div
                style={{
                  height: '40vh',
                  position: 'relative',
                  marginBottom: '1%',
                  padding: '1%',
                }}
              >
                <GenericPie
                  title="Support Status"
                  stats={supportStatsData.supportStatus}
                  labelKey="status"
                  legendPosition="right"
                />
              </div>
            </Col>
          </Row>
        </Card>
        <Card>
          <Row className="t-1">
            <Col>
              <div
                style={{
                  height: '60vh',
                  position: 'relative',
                  marginBottom: '1%',
                  padding: '1%',
                }}
              >
                <GenericBarChart
                  title="Agent Replies"
                  dataSet={supportStatsData.supportReplyAuthorStats}
                  labelKey="author"
                  legendPosition="none"
                  indexAxis="y"
                />
              </div>
            </Col>
          </Row>
          <Row className="t-1">
            <Col>
              <div
                style={{
                  height: '45vh',
                  position: 'relative',
                  marginBottom: '1%',
                  padding: '1%',
                }}
              >
                <GenericBarChart
                  title="Subject Stats"
                  dataSet={supportStatsData.supportSubjectsStats}
                  labelKey="subject"
                  legendPosition="none"
                  indexAxis="y"
                />
              </div>
            </Col>
          </Row>
          <Row className="t-1">
            <Col>
              <div
                style={{
                  height: '70vh',
                  position: 'relative',
                  marginBottom: '1%',
                  padding: '1%',
                }}
              >
                <GenericBarChart
                  title="Issue Stats"
                  dataSet={supportStatsData.supportIssuesStats}
                  labelKey="issue"
                  legendPosition="none"
                  indexAxis="y"
                />
              </div>
            </Col>
          </Row>
        </Card>
      </div>
      <br />
      <br />
      <Card className="p-2">
        <h4 style={{ textAlign: 'left' }}>Contact Us Stats</h4>
        <Row className="t-3">
          <Col>
            <div
              className="d-flex justify-content-left align-items-center"
              style={{ paddingLeft: 20 }}
            >
              <Email color="disabled" fontSize="large" />
              <p className="card-content-align">
                <b>
                  {supportStatsData ? supportStatsData.contactUsTotalCases : 0}
                </b>
                <br />
                <span>Total Contact Us Cases</span>
              </p>
            </div>
          </Col>
          <Col>
            <div
              className="d-flex justify-content-left align-items-center"
              style={{ paddingLeft: 20 }}
            >
              <EmailOutlined color="disabled" fontSize="large" />
              <p className="card-content-align">
                <b>
                  {supportStatsData ? supportStatsData.contactUsSubmitted : 0}
                </b>
                <br />
                <span>Submitted Cases</span>
              </p>
            </div>
          </Col>
          <Col>
            <div
              className="d-flex justify-content-left align-items-center"
              style={{ paddingLeft: 20 }}
            >
              <SmsFailed color="disabled" fontSize="large" />
              <p className="card-content-align">
                <b>{supportStatsData ? supportStatsData.contactUsFailed : 0}</b>
                <br />
                <span>Failed Cases</span>
              </p>
            </div>
          </Col>
        </Row>
      </Card>
      <br />
      <Card>
        <Row className="t-2">
          <Col>
            <div
              style={{
                height: '40vh',
                position: 'relative',
                marginBottom: '1%',
                padding: '1%',
              }}
            >
              <GenericPie
                title="Contact Us Sources"
                stats={supportStatsData.contactUsSources}
                labelKey="source"
                legendPosition="right"
              />
            </div>
          </Col>
          <Col>
            <div
              style={{
                height: '40vh',
                position: 'relative',
                marginBottom: '1%',
                padding: '1%',
              }}
            >
              <GenericPie
                title="Contact Us Subjects"
                stats={supportStatsData.contactUsSubjectStats}
                labelKey="subject"
                legendPosition="right"
              />
            </div>
          </Col>
        </Row>
      </Card>
      <br />
    </div>
  );
}

export default SupportReport;
