/* eslint-disable default-param-last */
import { GET_LOCALIZED_STRINGS, UPDATE_LOCALIZED_STRING } from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case GET_LOCALIZED_STRINGS:
      return {
        ...state,
        localizedStrings: action.payload,
      };
    case UPDATE_LOCALIZED_STRING: {
      const localizedString = state.localizedStrings
        ? state.localizedStrings
        : [];
      const { data } = action;
      const index = localizedString.findIndex((item) => item._id === data._id);
      if (index !== -1) {
        localizedString[index] = data;
      } else {
        localizedString.push(data);
      }
      return {
        ...state,
        localizedStrings: [...localizedString],
      };
    }
    default:
      return state;
  }
};
