/* eslint-disable react/destructuring-assignment */
import { IconButton } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import React from 'react';

function TablePaginationActions(props) {
  const { count, page, rowsPerPage } = props;
  const pageCount = Math.ceil(count / rowsPerPage);
  const rightDisabled = page >= pageCount - 1;
  const leftDisabled = page === 0;

  const handleFirstPageButtonClick = (event) => {
    props.onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    props.onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    props.onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    props.onPageChange(event, pageCount - 1);
  };

  return (
    <div style={{ width: 350, margin: '0 0 16px' }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={leftDisabled}
        aria-label="First Page"
      >
        <span className={`arrow-left ${leftDisabled ? 'disabled' : ''}`} />
        <span className={`arrow-left ${leftDisabled ? 'disabled' : ''}`} />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={leftDisabled}
        aria-label="Previous Page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={rightDisabled}
        aria-label="Next Page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={rightDisabled}
        aria-label="Last Page"
      >
        <span className={`arrow-right ${rightDisabled ? 'disabled' : ''}`} />
        <span className={`arrow-right ${rightDisabled ? 'disabled' : ''}`} />
      </IconButton>
    </div>
  );
}

const TablePaginationActionsWrapped = TablePaginationActions;

export default TablePaginationActionsWrapped;
