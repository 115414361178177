import React, { useEffect, useState } from 'react';

import { DataGrid } from '@mui/x-data-grid';
import { Alert, Button, Grid, LinearProgress } from '@mui/material';
import { addMonths, format, parseISO } from 'date-fns';
import { useDispatch } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import Snackbar from '@mui/material/Snackbar';
import { CheckCircleRounded, CancelRounded } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { styled } from '@mui/system';
import { PAGINATION_OPTIONS } from '../constants';
import reportsApi from '../api/report.api';
import FilterModal from '../components/Filter';
import EmailReportDownload from './EmailReportDownload';

const getStatusIcon = (value) => {
  return value ? (
    <CheckCircleRounded style={{ fill: 'green' }} />
  ) : (
    <CancelRounded style={{ fill: 'red' }} />
  );
};

// const useStyles = styled({
//   root: {
//     '& .custom-dark-theme--header': {
//       backgroundColor: '#343a40 !important',
//       color: 'white !important',
//       '& svg': {
//         color: 'white !important',
//       },
//     },
//   },
// });

const CustomDataGrid = styled('div')({
  '& .custom-dark-theme-header': {
    backgroundColor: '#343a40 !important',
    color: 'white !important',
    '& svg': {
      color: 'white !important',
    },
  },
});

const StyledComponent = styled('div')(({ theme }) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}));

const REPORT_TABLE_FIELDS = [
  {
    headerName: 'Name',
    render: (e) => e.name,
    field: 'name',
    headerClassName: 'custom-dark-theme--header',
    width: 150,
  },
  {
    headerName: 'Year',
    render: (e) => e.year,
    headerClassName: 'custom-dark-theme--header',
    field: 'year',
    width: 120,
  },
  {
    headerName: 'Make',
    render: (e) => e.make,
    field: 'make',
    headerClassName: 'custom-dark-theme--header',
    width: 120,
  },
  {
    headerName: 'Model',
    render: (e) => e.model,
    field: 'model',
    headerClassName: 'custom-dark-theme--header',
    width: 120,
  },
  {
    headerName: 'Email',
    render: (e) => e.email,
    field: 'email',
    headerClassName: 'custom-dark-theme--header',
    width: 250,
  },
  {
    headerName: 'PhoneNumber',
    render: (e) => e.phoneNumber,
    field: 'phoneNumber',
    headerClassName: 'custom-dark-theme--header',
    width: 150,
  },
  {
    headerName: 'Phone Number Verified',
    renderCell: (e) => getStatusIcon(e.value),
    field: 'phoneNumberVerified',
    headerClassName: 'custom-dark-theme--header',
    width: 150,
  },
  {
    headerName: 'User Verified Policies',
    renderCell: (e) => getStatusIcon(e.value),
    field: 'userVerifiedPolicies',
    headerClassName: 'custom-dark-theme--header',
    width: 150,
  },
  {
    headerName: 'Car Created At',
    valueFormatter: (e) =>
      e.value ? format(parseISO(e.value), 'MM/dd/yyyy') : '',
    field: 'carCreatedAt',
    headerClassName: 'custom-dark-theme--header',
    width: 150,
  },
  {
    headerName: 'Car Updated At',
    valueFormatter: (e) =>
      e.value ? format(parseISO(e.value), 'MM/dd/yyyy') : '',
    field: 'carUpdatedAt',
    headerClassName: 'custom-dark-theme--header',
    width: 150,
  },
  {
    headerName: 'Last Odometer User Entry Date',
    valueFormatter: (e) =>
      e.value ? format(parseISO(e.value), 'MM/dd/yyyy') : '',
    field: 'lastOdometerUserEntryDate',
    headerClassName: 'custom-dark-theme--header',
    width: 150,
  },
  {
    headerName: 'Okta Id',
    render: (e) => e.oktaId,
    field: 'oktaId',
    headerClassName: 'custom-dark-theme--header',
    width: 150,
  },
  {
    headerName: 'Repair Shop Id',
    field: 'repairShopId',
    valueFormatter: (e) => {
      if (e && e.row && e.row.dealerCaches && e.row.dealerCaches[e.value]) {
        return e.row.dealerCaches[e.value].dealerName;
      }
      return e.value;
    },
    headerClassName: 'custom-dark-theme--header',
    width: 150,
  },
  {
    headerName: 'Dealer',
    field: 'dealerId',
    valueFormatter: (e) => {
      if (e && e.row && e.row.dealerCaches && e.row.dealerCaches[e.value]) {
        return e.row.dealerCaches[e.value].dealerName;
      }
      return e.value;
    },
    headerClassName: 'custom-dark-theme--header',
    width: 300,
  },
  {
    headerName: 'CreditUnionId',
    field: 'creditUnionId',
    valueFormatter: (e) => {
      if (e && e.row && e.row.dealerCaches && e.row.dealerCaches[e.value]) {
        return e.row.dealerCaches[e.value].dealerName;
      }
      return e.value;
    },
    headerClassName: 'custom-dark-theme--header',
    width: 150,
  },
  {
    headerName: 'Vin',
    render: (e) => e.vin,
    field: 'vin',
    headerClassName: 'custom-dark-theme--header',
    width: 200,
  },
  {
    headerName: 'Successful messages',
    render: (e) => e.successSMS,
    field: 'successSMS',
    headerClassName: 'custom-dark-theme--header',
    width: 150,
  },
  {
    headerName: 'Failed messages',
    render: (e) => e.failedSMS,
    field: 'failedSMS',
    headerClassName: 'custom-dark-theme--header',
    width: 150,
  },
];

function CreditUnionReports() {
  const [pageSize, setPageSize] = React.useState(PAGINATION_OPTIONS[2]);
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [columns, setColumns] = useState(REPORT_TABLE_FIELDS);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailAvailable, setIsEmailAvailable] = useState('');
  // const classes = useStyles();
  const [appliedFilter, setAppliedFilter] = useState({});
  const [filterDisplay, setFilterDisplay] = useState('');
  const [filterBeforeDate, setFilterBeforeDate] = useState(null);
  const [filterAfterDate, setFilterAfterDate] = useState(null);
  const [applyQuickFilter, setApplyQuickFilter] = useState(false);

  async function fetchReports(skip = dataSource.length, limit = pageSize) {
    const params = {
      skip,
      limit,
      filter: JSON.stringify(appliedFilter),
    };

    return reportsApi.getCreditUnionReports(params, dispatch);
  }
  async function fetchS3Url() {
    const params = {
      filter: JSON.stringify(appliedFilter),
      recipientEmail: isEmailAvailable,
    };

    return reportsApi.getS3DownlaodUrlForReports(params, dispatch);
  }

  function updateDataSource(data) {
    const startIndex =
      dataSource && dataSource.length
        ? dataSource[dataSource.length - 1]._id + 1
        : 0;
    const _updatedDataSource = dataSource.concat(
      data.map((item, index) => {
        return { ...item, _id: index + startIndex };
      })
    );
    setDataSource(_updatedDataSource);
    return _updatedDataSource;
  }

  useEffect(() => {
    let isMounted = true;

    const loadData = async () => {
      setIsLoading(true);
      try {
        const reports = await fetchReports();
        if (isMounted && reports && reports.data) {
          updateDataSource(reports.data);
          setRowCount(reports.totalCount);
          setColumns(
            columns.filter((col) => {
              if (col.field === 'phoneNumber') {
                return reports.includePhoneNumber;
              }
              if (col.field === 'email') {
                return reports.includeEmail;
              }
              return true;
            })
          );
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    loadData();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;

    function checkIfDataPresent() {
      const totalDataExpected = (page + 1) * pageSize;
      const startIndex = page * pageSize;
      return (
        dataSource &&
        dataSource.length > startIndex &&
        (rowCount > totalDataExpected
          ? dataSource.length >= totalDataExpected
          : true)
      );
    }

    const loadData = async () => {
      if (!checkIfDataPresent()) {
        setIsLoading(true);
        try {
          const reports = await fetchReports();
          if (isMounted && reports && reports.data) {
            updateDataSource(reports.data);
            if (reports.totalCount !== rowCount) {
              setRowCount(reports.totalCount);
            }
          }
        } finally {
          if (isMounted) {
            setIsLoading(false);
          }
        }
      }
    };

    loadData();

    return () => {
      isMounted = false;
    };
  }, [page]);

  useEffect(() => {
    let isMounted = true;

    const loadData = async () => {
      if (page !== 0) {
        setDataSource([]);
        return;
      }
      setIsLoading(true);
      try {
        const reports = await fetchReports(0);
        if (isMounted && reports && reports.data) {
          setDataSource(
            reports.data.map((item, index) => {
              return { ...item, _id: index };
            })
          );
          if (reports.totalCount !== rowCount) {
            setRowCount(reports.totalCount);
          }
          setPage(0);
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    loadData();

    return () => {
      isMounted = false;
    };
  }, [appliedFilter]);

  useEffect(() => {
    if (dataSource.length === 0) {
      setPage(0);
    }
  }, [dataSource]);

  const [showSnackbar, setShowsnackbar] = useState(false);
  const [message, setMessage] = useState('');

  const handleClose = () => {
    setShowsnackbar(false);
  };
  const downloadResults = async () => {
    const results = await fetchS3Url();
    setIsEmailAvailable('');
    setShowsnackbar(true);
    setMessage(results.message);
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params.pageSize);
  };

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const setLastMonthFilter = () => {
    setFilterAfterDate(addMonths(new Date(), -1));
    setApplyQuickFilter(true);
  };

  const setLastSixMonthsFilter = () => {
    setFilterAfterDate(addMonths(new Date(), -6));
    setApplyQuickFilter(true);
  };

  const resetFilters = () => {
    setFilterAfterDate(null);
    setFilterBeforeDate(null);
    setFilterDisplay('');
    setAppliedFilter({});
    setPage(0);
  };

  const saveFilters = () => {
    const newFilter = {};
    const displays = [];
    const filterStartDate = new Date(filterAfterDate);
    const filterEndDate = new Date(filterBeforeDate);
    let dateDisplay = '';
    if (filterAfterDate) {
      newFilter.createdAt = { $gte: filterStartDate };
      dateDisplay = `Dates: ${format(filterStartDate, 'MM/dd/yyyy')}`;
    }
    if (filterBeforeDate) {
      newFilter.createdAt = {
        ...newFilter.createdAt,
        $lte: filterEndDate,
      };
      if (!dateDisplay) {
        dateDisplay = `Dates: -${format(filterEndDate, 'MM/dd/yyyy')}`;
      } else {
        dateDisplay = `${dateDisplay}-${format(filterEndDate, 'MM/dd/yyyy')}`;
      }
    }
    if (dateDisplay) {
      displays.push(dateDisplay);
    }

    setAppliedFilter(newFilter);
    setFilterDisplay(displays.join(' '));
  };

  useEffect(() => {
    if (applyQuickFilter && !!filterAfterDate) {
      setApplyQuickFilter(false);
      saveFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyQuickFilter, filterAfterDate]);

  return (
    <Grid container className="reports">
      <Grid item sm={12}>
        <h2 className="d-flex align-content-center align-items-center mb-0">
          <span style={{ marginRight: '10px' }} className="mr-2">
            Credit Union Reports
          </span>
          <EmailReportDownload
            isEmailAvailable={isEmailAvailable}
            setIsEmailAvailable={setIsEmailAvailable}
            onDownload={downloadResults}
          />
          <div className="flex-grow-1" />
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={showSnackbar}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: '100%' }}
            >
              {message}
            </Alert>
          </Snackbar>

          {appliedFilter && !Object.keys(appliedFilter).length && (
            <>
              <Button color="primary" onClick={setLastMonthFilter}>
                Last month
              </Button>
              <Button color="primary" onClick={setLastSixMonthsFilter}>
                Last 6 month
              </Button>
            </>
          )}
          <FilterModal
            currentFilters={appliedFilter}
            onSave={saveFilters}
            itemName="Reports"
            resetFilters={resetFilters}
            filterDisplay={filterDisplay}
          >
            <div style={{ marginBottom: '16px', marginTop: '10px' }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  utils={DateFnsUtils}
                  label="After Date (including)"
                  variant="inline"
                  className="mr-2"
                  fullWidth
                  id="after-date-filter"
                  value={filterAfterDate}
                  onChange={setFilterAfterDate}
                  autoOk
                />
              </LocalizationProvider>
            </div>
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  utils={DateFnsUtils}
                  label="Before Date (including)"
                  variant="inline"
                  className="mr-2"
                  fullWidth
                  id="before-date-filter"
                  value={filterBeforeDate}
                  onChange={setFilterBeforeDate}
                  autoOk
                />
              </LocalizationProvider>
            </div>
          </FilterModal>
        </h2>
        {isLoading ? (
          <LinearProgress style={{ height: '1rem' }} color="secondary" />
        ) : (
          <StyledComponent>
            <div
              style={{ height: '70vh', width: '100%' }}
              className={StyledComponent.root}
            >
              <CustomDataGrid>
                <DataGrid
                  paginationModel={{ page, pageSize }}
                  pagination
                  pageSizeOptions={PAGINATION_OPTIONS}
                  onPageSizeChange={handlePageSizeChange}
                  rowCount={rowCount}
                  getRowId={(row) => row._id}
                  rows={dataSource}
                  columns={columns}
                  onPaginationModelChange={handlePageChange}
                />
              </CustomDataGrid>
            </div>
          </StyledComponent>
        )}
      </Grid>
    </Grid>
  );
}

export default CreditUnionReports;
