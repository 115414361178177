/* eslint-disable default-param-last */
import { GET_CAR_BACKGROUND_IMAGES } from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case GET_CAR_BACKGROUND_IMAGES:
      return {
        images: action.payload,
      };
    default:
      return state;
  }
};
