/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
  TablePagination,
  Input,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Close as CloseIcon } from '@mui/icons-material';

import _ from 'lodash';
import dcrApi from '../../api/dcr.api';
import './dcr.style.scss';
import DcrComponent from './dcrComponent';
import { PAGINATION_OPTIONS } from '../../constants';
import EditDcrDataDialog from './editDcrData';
import TablePaginationActionsWrapped from '../../components/TablePaginationWrapped';
import UpdateGoogleDealerDialog from '../updateGoogleDealer/updateGoogleDealerPage';
import { downArrow } from '../../assets/images';
import NotesList from './notesList';

function DcrPage() {
  const permissions = useSelector((state) => state.permissions.permissions);
  const alphabets = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    'All',
  ];
  const selectedRowForEdit = useRef(null);
  const [data, setData] = useState(null);
  const [dataArr, setDataArr] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allFilters, setAllFilters] = useState({
    owners: [],
    regions: [],
    status: [],
    clientStatus: [],
  });
  const [filter, setFilter] = useState({
    nameIndex: '',
    region: '',
    ownerName: '',
    status: '',
    clientStatus: '',
    rdgNumber: '',
    name: '',
  });
  const [countPerPage, setCountPerPage] = useState(PAGINATION_OPTIONS[0]);
  const [pageNumber, setPageNumber] = useState(0);
  const [openEdit, setOpenEdit] = useState(false);
  const [openNotesList, setOpenNotesList] = useState(false);
  const [openUpdateGoogleDealer, setOpenUpdateGoogleDealer] = useState(false);
  const dispatch = useDispatch();
  const getDcrData = async () => {
    const o = Object.fromEntries(
      Object.entries(filter).filter(([i, v]) => v !== '')
    );
    const params = {
      filter: o || {},
      skip: countPerPage * pageNumber,
      limit: countPerPage,
    };
    setLoading(true);
    const dcrdata = await dcrApi.getDcr(params, dispatch);
    setLoading(false);
    setData(dcrdata);
  };
  const getDcrFilters = async () => {
    const filters = await dcrApi.getDcrFilters(null, dispatch);
    if (filters) {
      setAllFilters(filters);
    }
  };

  // FETCH EFFECT AND API
  useEffect(() => {
    setDataArr([]);
    getDcrData();
    getDcrFilters();
  }, [filter, pageNumber, countPerPage]);

  function updateDataSource(d) {
    const startIndex =
      dataArr && dataArr.length ? dataArr[dataArr.length - 1]._gridId + 1 : 0;
    const _updatedDataSource = dataArr.concat(
      d.map((item, index) => {
        return { ...item, _gridId: index + startIndex };
      })
    );
    setDataArr(_updatedDataSource);
    return _updatedDataSource;
  }
  useEffect(() => {
    if (data && data.data && data.data.length > 0) {
      updateDataSource(data.data);
    }
  }, [data]);

  const clearFilter = () => {
    setFilter({
      nameIndex: '',
      ownerName: '',
      region: '',
      status: '',
      clientStatus: '',
      rdgNumber: '',
      name: '',
    });
  };

  // HANDLE FILTER AND PAGE CHANGE
  const handleFilter = (al) => {
    switch (al) {
      case 'All':
        setPageNumber(0);
        clearFilter();
        break;
      case 'clear':
        setPageNumber(0);
        clearFilter();
        break;
      default:
        setPageNumber(0);
        setFilter({
          ...filter,
          nameIndex: al,
        });
    }
  };

  const changePage = (event, newPageNumber) => {
    setPageNumber(newPageNumber);
  };

  const changeRowsPerPage = (event) => {
    const newCountPerPage = event.target.value;
    setCountPerPage(newCountPerPage);
  };

  const handleEditClick = (selectedValue) => {
    selectedRowForEdit.current = selectedValue;
    setOpenEdit(true);
  };

  const handleNotesClick = (selectedValue) => {
    selectedRowForEdit.current = selectedValue;
    setOpenNotesList(true);
  };

  const debounceFilter = _.debounce(async (name, value) => {
    const cloneForm = { ...filter };
    cloneForm[name] = value.trim();
    setPageNumber(0);
    setFilter(cloneForm);
  }, 1000);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    debounceFilter(name, value);
  };

  const updateComponentData = (res) => {
    selectedRowForEdit.current = res;
    const index = dataArr.findIndex(
      (i) => i.accountNumber === res.accountNumber
    );
    const tempState = dataArr;
    tempState[index] = { ...tempState[index], ...res };
    setLoading(true);
    setDataArr(tempState);
    setLoading(false);
  };

  const postUpdateAction = () => {
    setOpenUpdateGoogleDealer(false);
    setDataArr([]);
    getDcrData();
  };

  function HeaderComponent() {
    return (
      <>
        <div>Select dealer by:</div>
        <Grid container justify="flex-start">
          <Grid
            container
            justify="flex-start"
            className="dcr-header-label"
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
          >
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <div className="dcr-dropdown-container">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Dealer Name
                  </InputLabel>
                  <Input
                    type="text"
                    name="name"
                    defaultValue={filter.name}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <div className="dcr-dropdown-container">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Region</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Age"
                    value={filter.region}
                    onChange={(e) => {
                      setFilter({ ...filter, region: e.target.value });
                    }}
                    IconComponent={(props) => (
                      <img
                        {...props}
                        style={{ marginTop: 5 }}
                        alt=""
                        src={downArrow}
                      />
                    )}
                  >
                    {allFilters.regions.map((val) => (
                      <MenuItem key={val} value={val}>
                        {val}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <div className="dcr-dropdown-container">
                <FormControl fullWidth>
                  <InputLabel
                    id="demo-simple-select-label"
                    className="label-overflow"
                  >
                    Account Owner
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Age"
                    value={filter.ownerName}
                    onChange={(e) => {
                      setFilter({ ...filter, ownerName: e.target.value });
                    }}
                    IconComponent={(props) => (
                      <img
                        {...props}
                        style={{ marginTop: 5 }}
                        alt=""
                        src={downArrow}
                      />
                    )}
                  >
                    {allFilters.owners
                      .filter(
                        (v) =>
                          filter.region === '' || v.region === filter.region
                      )
                      .map((val) => (
                        <MenuItem key={val.ownerName} value={val.ownerName}>
                          {val.ownerName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <div className="dcr-dropdown-container">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Client Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Age"
                    value={filter.clientStatus}
                    onChange={(e) => {
                      setFilter({ ...filter, clientStatus: e.target.value });
                    }}
                    IconComponent={(props) => (
                      <img
                        {...props}
                        style={{ marginTop: 5 }}
                        alt=""
                        src={downArrow}
                      />
                    )}
                  >
                    <MenuItem key="all" val="">
                      All
                    </MenuItem>
                    {allFilters.clientStatus.map((val) => (
                      <MenuItem key={val} value={val}>
                        {val}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <div className="dcr-dropdown-container">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Approval Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Age"
                    value={filter.status}
                    onChange={(e) => {
                      setFilter({ ...filter, status: e.target.value });
                    }}
                    IconComponent={(props) => (
                      <img
                        {...props}
                        style={{ marginTop: 5 }}
                        alt=""
                        src={downArrow}
                      />
                    )}
                  >
                    <MenuItem key="all" val="">
                      All
                    </MenuItem>
                    {allFilters.status.map((val) => (
                      <MenuItem key={val} value={val}>
                        {val}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <div className="dcr-dropdown-container">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    RDG Number
                  </InputLabel>
                  <Input
                    type="text"
                    name="rdgNumber"
                    defaultValue={filter.rdgNumber}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <div
                className="dcr-dropdown-container"
                style={{ display: 'flex', marginTop: 20 }}
              >
                {alphabets.map((al) => (
                  <div
                    onClick={() => handleFilter(al)}
                    className="dcr-alphabets"
                    key={al}
                    style={{
                      textDecoration:
                        filter.nameIndex === al ? 'underline' : 'none',
                    }}
                  >
                    <b>{al}</b>
                  </div>
                ))}
                <div
                  className="dcr-alphabets"
                  onClick={() => handleFilter('clear')}
                >
                  <Button
                    endIcon={<CloseIcon />}
                    disableElevation
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    Clear
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justify="flex-start">
          <Grid item xs={2}>
            <div className="dcr-dealer-detail-column-header">Dealer Name</div>
          </Grid>
          <Grid item xs={5}>
            <div className="dcr-dealer-detail-column-header margin-horizontal">
              GLOW Vehicle Care Online
            </div>
          </Grid>
          <Grid item xs={5}>
            <div className="dcr-dealer-detail-column-header margin-horizontal">
              Google Data
            </div>
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <>
      {openUpdateGoogleDealer && (
        <UpdateGoogleDealerDialog
          setOpenUpdateGoogleDealer={setOpenUpdateGoogleDealer}
          item={selectedRowForEdit.current}
          postUpdateAction={postUpdateAction}
        />
      )}
      {openEdit && (
        <EditDcrDataDialog
          item={selectedRowForEdit.current}
          setOpenEdit={setOpenEdit}
          updateData={updateComponentData}
          openFindOtherDealership={() => {
            setOpenEdit(false);
            setOpenUpdateGoogleDealer(true);
          }}
        />
      )}
      {openNotesList && (
        <NotesList
          model={selectedRowForEdit.current}
          setOpenNotesList={setOpenNotesList}
        />
      )}
      <div
        className="dcr-container"
        style={{
          display: permissions.DEALER_PROFILES.READ ? 'block' : 'none',
        }}
      >
        <HeaderComponent />
        <hr />
        <div style={{ height: '60vh', width: '100%', overflowY: 'scroll' }}>
          {loading ? (
            <div className="dcr-progress-container">
              <CircularProgress />
            </div>
          ) : (
            dataArr.map((item) => (
              <DcrComponent
                handleEditClick={handleEditClick}
                handleNotesClick={handleNotesClick}
                key={item._id}
                item={item}
                updateData={updateComponentData}
                selected={
                  (selectedRowForEdit &&
                    selectedRowForEdit.current &&
                    selectedRowForEdit.current._id === item._id) ||
                  false
                }
              />
            ))
          )}
        </div>
        <hr />
        {data && data.data && (
          <TablePagination
            rowsPerPageOptions={PAGINATION_OPTIONS}
            rowsPerPage={countPerPage}
            component="div"
            count={
              data.totalCount
                ? data.totalCount
                : data.data.length * data.pageCount
            }
            page={data.currentPage - 1}
            onRowsPerPageChange={changeRowsPerPage}
            onPageChange={changePage}
            ActionsComponent={TablePaginationActionsWrapped}
          />
        )}
      </div>
    </>
  );
}

export default DcrPage;
