import React, { useState, useRef } from 'react';
import { Dialog, CircularProgress } from '@mui/material';
import {
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  InputGroup,
  FormFeedback,
  Container,
  Row,
  Spinner,
} from 'reactstrap';
import { styled } from '@mui/system';
import DialogTitle from '@mui/material/DialogTitle';
// import MuiDialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import { Close as CloseIcon } from '@mui/icons-material';
// import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import { getAllCountrys, getStatesOfCountry } from '../utils/cityStatedb';
import './SendInvitation.scss';
import invitationApi from '../api/invitation.api';
import dealersApi from '../api/dealer.api';
import { API_ERROR, CLEAR_API_ERROR } from '../store/types';
import { DEFAULT_TIMEOUT } from '../constants';

const CustomDialogTitle = styled(({ children, onClose, ...other }) => (
  <DialogTitle {...other}>
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {children}
      {onClose && (
        <IconButton
          aria-label="close"
          className={CustomDialogTitle.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      )}
    </div>
  </DialogTitle>
))(({ theme }) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: '#343a40',
    color: 'white',
  },
  closeButton: {
    position: 'relative',
    top: '-50%',
    color: theme.palette.grey[500],
  },
}));

const defaultMessage =
  'Congratulations on your purchase! You can maximize your investment by downloading Vehicle Care Online. Reply Stop to discontinue receiving text messages from this number.';

// const DialogContent = styled((theme) => ({
//   root: {
//     padding: theme.spacing(2),
//   },
// }))(MuiDialogContent);
const DialogContent = styled('div')(({ theme }) => ({
  // Define root and padding styles
  root: {
    padding: theme.spacing(2),
  },
}));

function SendInvitation() {
  const [validVin, setValidVin] = useState(true);
  const [validPhone, setValidPhone] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [validDealer, setValidDealer] = useState(true);
  const [dealerFeedback, setDealerFeedback] = useState('');
  const [message, setMessage] = useState(defaultMessage);
  const countryList = getAllCountrys();
  const [openModal, setOpenModal] = useState(false);
  const [modalBody, setModalBody] = useState('');
  // const classes = useStyles();
  const [dealerWhiteLabelMap, setDealerWhiteLabelMap] = useState({});
  const [downloadingWhiteLabel, setDownloadingWhiteLabel] = useState(false);
  const dispatch = useDispatch();
  const textAreaRef = useRef(null);
  const [sendingInvite, setSendingInvite] = useState(false);
  const [qrCodeData, setQrCodeData] = useState('');
  const US_PRESET = {
    cellPhonePrefix: '1',
    country: 'USA',
    emailChannel: false,
    phoneChannel: false,
  };
  const UK_PRESET = {
    cellPhonePrefix: '44',
    country: 'GB',
    emailChannel: false,
    phoneChannel: false,
  };
  const [form, setForm] = useState(
    process.env.REACT_APP_DEFAULT_REGION === 'UK' ? UK_PRESET : US_PRESET
  );

  const getPayload = () => {
    return {
      firstName: form.firstName,
      lastName: form.lastName,
      vin: form.vin,
      email: form.email,
      phoneNumber: `+1${form.cellPhone}`,
      dealer: form.dealer,
      address: {
        add1: form.addressOne,
        add2: form.addressTwo,
        country: form.country,
        state: form.state,
        city: form.city,
        zip: form.zipCode,
      },
      welcomeMessage: message,
      smsEnabled: form.phoneChannel,
      emailEnabled: form.emailChannel,
    };
  };

  const validateForm = () => {
    let isValid = true;
    if (!form.vin || form.vin.length === 0) {
      setValidVin(false);
      isValid = false;
    }
    if (!form.cellPhone || form.cellPhone.length === 0) {
      setValidPhone(false);
      isValid = false;
    }
    return isValid;
  };

  const resetForm = () => {
    setForm({
      cellPhonePrefix: '1',
      country: 'USA',
      emailChannel: false,
      phoneChannel: false,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    setSendingInvite(true);
    const payload = getPayload();
    const response = await invitationApi.requestInvitation(payload, dispatch);
    if (response && parseInt(response.statusCode, 10) === 200) {
      setModalBody(
        `Invite sent successfully. Invite has been sent to User's number +1${form.cellPhone}.`
      );
      if (response.isBase64Encoded && response.body) {
        setQrCodeData(response.body);
      }
      setOpenModal(true);
      resetForm();
    } else {
      if (
        response &&
        parseInt(response.statusCode, 10) === 500 &&
        response.body
      ) {
        dispatch({
          type: API_ERROR,
          payload:
            typeof response.body === 'string'
              ? response.body
              : 'INVITATION_PROCESSING_FAILED',
        });
        setTimeout(() => {
          dispatch({
            type: CLEAR_API_ERROR,
          });
        }, DEFAULT_TIMEOUT);
      }
      window.scrollTo(0, 0);
    }
    setSendingInvite(false);
  };

  const findNearbyDealer = (e) => {
    e.preventDefault();
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const fetchDealerWhiteLabel = async () => {
    const dealerWhiteLabel = dealerWhiteLabelMap;
    const { dealer = '' } = form;
    if (dealer && dealer.length) {
      if (dealerWhiteLabel[dealer] && dealerWhiteLabel[dealer].smsForDownload) {
        setMessage(dealerWhiteLabel[dealer].smsForDownload);
      } else {
        setDownloadingWhiteLabel(true);
        dealersApi
          .getDealerWhiteLabel({ dealerId: dealer }, dispatch)
          .then((res) => {
            dealerWhiteLabel[dealer] = res;
            if (res && res.smsForDownload) {
              setMessage(res.smsForDownload);
              setValidDealer(true);
            } else {
              setMessage(defaultMessage);
              if (res && res.status === 'error' && res.message) {
                setValidDealer(false);
                setDealerFeedback(res.message);
              }
            }
            setDealerWhiteLabelMap(dealerWhiteLabel);
            setDownloadingWhiteLabel(false);
          })
          .catch(() => {
            setDownloadingWhiteLabel(false);
          });
      }
    } else if (message !== defaultMessage) {
      setMessage(defaultMessage);
    }
  };

  const handleInputChange = (event) => {
    const { name } = event.target;
    let { value } = event.target;
    const cloneForm = { ...form };

    if (name === 'dealer') {
      value = value.toUpperCase();
    }
    if (event.target.type === 'checkbox') {
      value = event.target.checked;
    }
    cloneForm[name] = value;
    setForm(cloneForm);

    if (name === 'vin' && !validVin) {
      setValidVin(true);
    }

    if (name === 'cellPhone' && !validPhone) {
      setValidPhone(true);
    }

    if (name === 'email' && !validEmail) {
      setValidEmail(true);
    }

    if (name === 'dealer' && !validDealer) {
      setValidDealer(true);
    }
  };

  const getPhoneEmailValidity = (e) => {
    const { value, validity, name } = e.target;
    if (name === 'email') {
      return value && value.length
        ? /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
          )
        : true;
    }

    return value && value.length ? validity.valid : true;
  };

  return (
    <div>
      <div className="text-center p-2">
        <h2>Registrant Contact</h2>
      </div>
      <div>
        <Container>
          <Row>
            <Col sm="1" />
            <Col sm="10">
              <Form onSubmit={handleFormSubmit}>
                <FormGroup row>
                  <Label for="name" sm={2}>
                    Name
                  </Label>
                  <Col sm={5}>
                    <Input
                      type="text"
                      name="firstName"
                      id="firstName"
                      value={form.firstName || ''}
                      onChange={handleInputChange}
                      placeholder="First Name"
                    />
                  </Col>
                  <Col sm={5}>
                    <Input
                      type="text"
                      name="lastName"
                      id="lastName"
                      value={form.lastName || ''}
                      onChange={handleInputChange}
                      placeholder="Last Name"
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="vin" sm={2}>
                    Vin*
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="text"
                      name="vin"
                      id="vin"
                      placeholder=""
                      value={form.vin || ''}
                      onChange={handleInputChange}
                      invalid={!validVin}
                    />
                    <FormFeedback>This field is required</FormFeedback>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="email" sm={2}>
                    Email
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      value={form.email || ''}
                      onChange={handleInputChange}
                      invalid={!validEmail}
                      onBlur={(e) => setValidEmail(getPhoneEmailValidity(e))}
                    />
                    <FormFeedback>Please enter a valid email</FormFeedback>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="cellPhone" sm={2}>
                    Cell Phone*
                  </Label>
                  <Col sm={2}>
                    <InputGroup>
                      <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon2">
                          +
                        </span>
                      </div>
                      <Input
                        type="number"
                        name="cellPhone"
                        id="cellPhone"
                        value={form.cellPhonePrefix}
                        disabled
                      />
                    </InputGroup>
                  </Col>
                  <Col sm={8}>
                    <Input
                      type="tel"
                      name="cellPhone"
                      id="cellPhone"
                      placeholder="3115555018"
                      value={form.cellPhone || ''}
                      pattern="[0-9]{10}"
                      onChange={handleInputChange}
                      invalid={!validPhone}
                      onBlur={(e) => setValidPhone(getPhoneEmailValidity(e))}
                      maxLength="10"
                    />
                    <FormText color="muted">
                      Enter Country calling code and phone number
                    </FormText>
                    <FormFeedback>
                      Please enter a valid phone number
                    </FormFeedback>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="dealer" sm={2}>
                    Dealer
                  </Label>
                  <Col sm={7}>
                    <Input
                      type="text"
                      name="dealer"
                      id="dealer"
                      value={form.dealer || ''}
                      onChange={handleInputChange}
                      onBlur={fetchDealerWhiteLabel}
                      invalid={form.dealer && !validDealer}
                    />
                    <FormFeedback>{dealerFeedback}</FormFeedback>
                  </Col>
                  <div style={{ width: '30px' }}>
                    {downloadingWhiteLabel ? (
                      <CircularProgress
                        style={{ height: '25px', width: '25px' }}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                  <Col sm="auto">
                    <Button onClick={findNearbyDealer} hidden>
                      Find Nearby Dealer!
                    </Button>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="addressOne" sm={2}>
                    Address 1
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="text"
                      name="addressOne"
                      id="addressOne"
                      value={form.addressOne || ''}
                      onChange={handleInputChange}
                    />
                    <FormText color="muted">Street address, P.O. box</FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="addressOne" sm={2}>
                    Address 2
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="text"
                      name="addressTwo"
                      id="addressTwo"
                      value={form.addressTwo || ''}
                      onChange={handleInputChange}
                      placeholder="Optional"
                    />
                    <FormText color="muted">
                      Apt, suite, unit, building, floor etc.
                    </FormText>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="country" sm={2}>
                    Country
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="select"
                      name="country"
                      id="country"
                      placeholder="Select a country"
                      value={form.country || ''}
                      onChange={handleInputChange}
                      disabled
                    >
                      <option value="">Select a country</option>
                      {countryList && countryList.length
                        ? countryList.map((item, index) => {
                            return (
                              <option key={index} value={item.isoCode}>
                                {item.name}
                              </option>
                            );
                          })
                        : ''}
                    </Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="state" sm={2}>
                    State
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="select"
                      name="state"
                      id="state"
                      value={form.state || ''}
                      onChange={handleInputChange}
                    >
                      <option value="">State not required</option>
                      {form.country && form.country.length
                        ? getStatesOfCountry(form.country).map(
                            (item, index) => {
                              return (
                                <option key={index} value={item.isoCode}>
                                  {item.name}
                                </option>
                              );
                            }
                          )
                        : ''}
                    </Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="city" sm={2}>
                    City
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="city"
                      name="city"
                      id="city"
                      value={form.city || ''}
                      onChange={handleInputChange}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="zipCode" sm={2}>
                    Postal/Zip Code
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="text"
                      name="zipCode"
                      id="zipCode"
                      value={form.zipCode || ''}
                      onChange={handleInputChange}
                      placeholder="Optional"
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="message" sm={2}>
                    Welcome Message
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="textarea"
                      name="message"
                      id="message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      placeholder="Optional"
                      ref={textAreaRef}
                      disabled
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="invitationChannel" sm={2}>
                    Invitation Channel
                  </Label>
                  <Col sm={{ size: 2 }}>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          id="emailChannel"
                          name="emailChannel"
                          checked={form.emailChannel}
                          onChange={handleInputChange}
                        />
                        Email
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col sm={{ size: 2 }}>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          id="phoneChannel"
                          name="phoneChannel"
                          checked={form.phoneChannel}
                          onChange={handleInputChange}
                        />
                        SMS
                      </Label>
                    </FormGroup>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={{ size: 10, offset: 2 }}>
                    <Button disabled={downloadingWhiteLabel || sendingInvite}>
                      {!sendingInvite ? (
                        'Send Invite'
                      ) : (
                        <>
                          <span>Sending Invite&nbsp;</span>
                          <Spinner
                            color="dark"
                            style={{ height: '25px', width: '25px' }}
                          />
                        </>
                      )}
                    </Button>
                  </Col>
                </FormGroup>
              </Form>
            </Col>
            <Col sm="1" />
          </Row>
        </Container>
      </div>
      <Dialog
        onClose={handleModalClose}
        aria-labelledby="customized-dialog-title"
        open={openModal}
        maxWidth="400px" // Set the desired maximum width
        // fullWidth
        // classes={{ paper: classes.paper }}
      >
        <CustomDialogTitle
          id="customized-dialog-title"
          onClose={handleModalClose}
        >
          Response
        </CustomDialogTitle>
        <DialogContent style={{ margin: '20px' }}>
          <div>{modalBody}</div>
          {qrCodeData && qrCodeData.length ? (
            <div
              style={{
                padding: '20px 0px',
              }}
            >
              <div>
                You can use below QR Code which user can scan and register in
                PGA app
              </div>
              <div style={{ textAlign: 'center' }}>
                <img src={`data:image/png;base64,${qrCodeData}`} alt="" />
              </div>
            </div>
          ) : (
            ''
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default SendInvitation;
