import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

function CustomBarChart({ title, dataSet }) {
  if (!dataSet || dataSet.length === 0) {
    return (
      <>
        <div>
          <span className="statsSubTitle">{title}</span>
        </div>
        <div style={{ color: 'grey' }}>No Data Found</div>
      </>
    );
  }

  const data = {
    labels: dataSet.map((item) => item.label),
    datasets: [
      {
        label: 'Dataset 1',
        data: dataSet.map((item) => item.count),
        backgroundColor: 'rgba(20, 20, 19, 0.32)',
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: title,
      },
      datalabels: {
        display: true,
        align: 'end',
        anchor: 'end',
        color(context) {
          return context.dataset.backgroundColor;
        },
      },
    },
  };

  return <Bar options={options} data={data} />;
}

export default CustomBarChart;
