/* eslint-disable default-param-last */
import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  CircularProgress,
  IconButton,
  LinearProgress,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import ExcelFile from 'react-excel-exportz/dist/ExcelPlugin/components/ExcelFile';
import ExcelSheet from 'react-excel-exportz/dist/ExcelPlugin/elements/ExcelSheet';
import ExcelColumn from 'react-excel-exportz/dist/ExcelPlugin/elements/ExcelColumn';
import { DirectionsCarOutlined } from '@mui/icons-material';
import { PAGINATION_OPTIONS } from '../constants';
import carsApi from '../api/cars.api';

function CarIssues() {
  const goToCarDetail = (e, carId) => {
    e.stopPropagation();
    window.open(`/cars/${carId}`, '_blank', 'noopener,noreferrer');
  };

  function getOwner(params) {
    if (params.row.owner != null) return `${params.row.owner.name || ''}`;
    return '';
  }

  function getEmail(params) {
    if (params.row.owner != null) return `${params.row.owner.email || ''}`;
    return '';
  }
  function getYearMakeModel(params) {
    return `${params.row.year || ''} ${params.row.make || ''} ${
      params.row.model || ''
    }`;
  }
  function getCreatedAtFormatted(params) {
    return `${moment(params.row.createdAt).format('ll') || ''}`;
  }
  function getReason(params) {
    if (
      params.row.motorModelId === '' ||
      params.row.motorModelId === undefined ||
      params.row.motorModelId === null
    )
      return 'Model Name Mismatch';
    if (
      (params.row.motorBaseVehicleId === '' ||
        params.row.motorBaseVehicleId === undefined ||
        params.row.motorBaseVehicleId === null) &&
      (params.row.motorModelId !== '' ||
        params.row.motorMakeId === undefined ||
        params.row.motorMakeId === null)
    )
      return 'No Base Vehicle Found';
    if (
      (params.row.motorBaseVehicleId === '' ||
        params.row.motorBaseVehicleId === undefined ||
        params.row.motorBaseVehicleId === null) &&
      (params.row.motorMakeId === '' ||
        params.row.motorMakeId === undefined ||
        params.row.motorMakeId === null) &&
      (params.row.motorModelId === '' ||
        params.row.motorModelId === undefined ||
        params.row.motorModelId === null)
    )
      return 'No Maintenance Data Found';
    return '';
  }
  const useStyles = styled({
    root: {
      '& .custom-dark-theme--header': {
        backgroundColor: '#343a40 !important',
        color: 'white !important',
        '& svg': {
          color: 'white !important',
        },
      },
      '& .custom-link-cell': {
        color: 'blue !important',
        cursor: 'pointer',
      },
    },
  });

  const classes = useStyles();

  const columns = [
    {
      headerName: 'Owner Name',
      field: 'owner',
      headerClassName: 'custom-dark-theme--header',
      cellClassName: 'custom-link-cell',
      width: 300,
      valueGetter: getOwner,
      flex: 1,
    },
    {
      headerName: 'Email',
      field: 'email',
      headerClassName: 'custom-dark-theme--header',
      cellClassName: 'custom-link-cell',
      width: 300,
      valueGetter: getEmail,
      flex: 1,
    },
    {
      headerName: 'VIN',
      field: 'vin',
      headerClassName: 'custom-dark-theme--header',
      width: 200,
      flex: 1,
    },
    {
      headerName: 'Year/Make/Model',
      field: 'year',
      headerClassName: 'custom-dark-theme--header',
      width: 270,
      valueGetter: getYearMakeModel,
      flex: 1,
    },
    {
      headerName: 'Created On',
      field: 'createdAt',
      headerClassName: 'custom-dark-theme--header',
      width: 150,
      valueGetter: getCreatedAtFormatted,
      flex: 1,
    },
    {
      headerName: 'Reason',
      field: 'motorModelId',
      headerClassName: 'custom-dark-theme--header',
      width: 350,
      valueGetter: getReason,
      flex: 1,
    },
    {
      headerName: 'Car',
      renderCell: (e) => (
        <IconButton onClick={(event) => goToCarDetail(event, e.value)}>
          <DirectionsCarOutlined color="primary" />
        </IconButton>
      ),
      field: '_id',
      headerClassName: 'custom-dark-theme--header',
      flex: 1,
    },
  ];

  const [downloadData, setDownloadData] = useState([]);
  const [downloadMsg, setDownloadMsg] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);

  const [pageSize, setPageSize] = React.useState(PAGINATION_OPTIONS[2]);
  const [page, setPage] = useState(0);
  const [hasFilterChanged, setHasFilterChanged] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const dataGridRef = useRef();

  const handlePageSizeChange = (params) => {
    setPageSize(params.pageSize);
  };

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const dispatch = useDispatch();
  const dropDownOptions = [
    {
      label: `Without Motor ModelID`,
      value: `WithoutMotorModelId`,
    },
    {
      label: `Without Motor Base Vehicle And Maintenance`,
      value: `WithoutMotorBaseVehicleAndMaintenance`,
    },
    {
      label: `Motor Base Vehicle Without Maintenance`,
      value: `MotorBaseVehicleWithoutMaintenance`,
    },
    {
      label: `Cars with Missing Purchase Date (if they have VIN)`,
      value: `MissingPurchaseDate`,
    },
    {
      label: `Cars with Image Issues`,
      value: `FuelImageIssue`,
    },
  ];

  const [valu, setValu] = useState('WithoutMotorModelId');
  const [labl, setLabl] = useState('Without Motor ModelID');
  const [isLoading, setLoading] = useState(false);

  const handleChange = (event) => {
    setValu(event.value);
    setLabl(event.label);
    setPage(0);
    setDataSource([]);
  };

  function updateDataSource(data) {
    const startIndex =
      dataSource && dataSource.length
        ? dataSource[dataSource.length - 1]._gridId + 1
        : 0;
    const _updatedDataSource = dataSource.concat(
      data.map((item, index) => {
        return { ...item, _gridId: index + startIndex };
      })
    );
    setDataSource(_updatedDataSource);
    return _updatedDataSource;
  }

  useEffect(() => {
    function checkIfDataPresent() {
      const totalDataExpected = (page + 1) * pageSize;
      const startIndex = page * pageSize;
      return (
        dataSource &&
        dataSource.length > startIndex &&
        (rowCount > totalDataExpected
          ? dataSource.length >= totalDataExpected
          : true)
      );
    }
    const getCarIssueData = async () => {
      setLoading(true);
      if (!checkIfDataPresent()) {
        const skip = page * pageSize;
        const models = await carsApi.getCarWithDataIssues(
          valu,
          skip,
          pageSize,
          dispatch
        );

        if (models && models.data && dataSource.length === 0) {
          setDataSource(
            models.data.map((item, index) => {
              return { ...item, _gridId: index };
            })
          );
        } else {
          updateDataSource(models.data);
        }
        if (models.totalCount !== rowCount) {
          setRowCount(models.totalCount);
        }

        setLoading(false);
      }
    };
    getCarIssueData();
  }, [hasFilterChanged, page]);

  const searchNewData = () => {
    setHasFilterChanged(!hasFilterChanged);
  };

  async function fetchCarIssues(skip, limit) {
    return carsApi.getCarWithDataIssues(valu, skip, limit, dispatch);
  }

  async function fetchRecursively(index = 0, limit = 100, allReports) {
    const report = await fetchCarIssues(index * limit, limit);
    allReports = allReports.concat(report.data); // eslint-disable-line no-param-reassign
    if (report.currentPage !== report.pageCount) {
      setDownloadMsg(
        `Downloading ${report.currentPage + 1} of ${report.pageCount} Pages`
      );
      return fetchRecursively(report.currentPage, limit, allReports);
    }
    return allReports;
  }

  const downloadReports = async () => {
    setIsDownloading(true);
    setDownloadData([]);
    setDownloadMsg(`Downloading 1 of ${Math.round(rowCount / 100)} Pages`);
    const reports = await fetchRecursively(0, 100, []);
    setDownloadData(reports);
    setIsDownloading(false);
    setDownloadMsg(null);
  };

  return (
    <div>
      <Typography variant="h4">Search Cars</Typography>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Select
          placeholder="Select Type"
          isLoading={isLoading}
          closeMenuOnScroll="true"
          value={{
            label: `${labl}`,
            value: valu,
          }}
          className="mb-4"
          options={dropDownOptions}
          onChange={handleChange}
        />
        <Button
          onClick={() => {
            searchNewData();
          }}
          color="primary"
          variant="contained"
          style={{
            marginInline: 16,
            maxWidth: '90px',
            maxHeight: '37px',
            minWidth: '90px',
            minHeight: '37px',
          }}
        >
          Search
        </Button>
        <Button
          style={{
            marginInline: 16,
            maxWidth: '250px',
            maxHeight: '37px',
            minWidth: '250px',
            minHeight: '37px',
          }}
          disabled={isDownloading}
          color="primary"
          variant="outlined"
          onClick={downloadReports}
        >
          {downloadMsg ? (
            <>
              <span style={{ fontSize: '12px' }}>{downloadMsg}</span>
              <CircularProgress style={{ height: '25px', width: '25px' }} />
            </>
          ) : (
            'Export as Excel'
          )}
        </Button>

        {downloadData && downloadData.length ? (
          <ExcelFile hideElement>
            <ExcelSheet data={downloadData} name="CarIssueReport">
              <ExcelColumn label="vin" value="vin" />
              <ExcelColumn label="make" value="make" />
              <ExcelColumn label="model" value="model" />
              <ExcelColumn label="year" value="year" />
              <ExcelColumn label="ownerId" value="ownerId" />
              <ExcelColumn label="createdAt" value="createdAt" />
              <ExcelColumn
                label="motorBaseVehicleId"
                value="motorBaseVehicleId"
              />
              <ExcelColumn label="motorEngineId" value="motorEngineId" />
              <ExcelColumn label="motorMakeId" value="motorMakeId" />
              <ExcelColumn label="motorModelId" value="motorModelId" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          ''
        )}
      </div>
      <Typography variant="body1" color="grey">
        Choose the option from above dropdown and press search in order to view
        the data.
      </Typography>
      {isLoading && <LinearProgress />}
      <div className={classes.root} style={{ height: '70vh', width: '100%' }}>
        {dataSource && dataSource.length > 0 && (
          <DataGrid
            pageSize={pageSize}
            page={page}
            pagination
            rowsPerPageOptions={PAGINATION_OPTIONS}
            onPageSizeChange={handlePageSizeChange}
            rowCount={rowCount}
            getRowId={(row) => row._gridId}
            rows={dataSource}
            columns={columns}
            onPageChange={handlePageChange}
            ref={dataGridRef}
          />
        )}
      </div>
    </div>
  );
}

export default CarIssues;
