/* eslint-disable default-param-last */
import { API_ERROR, CLEAR_API_ERROR } from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case API_ERROR:
      return {
        error: action.payload,
      };
    case CLEAR_API_ERROR:
      return {
        error: null,
      };
    default:
      return state;
  }
};
