import React from 'react';

import { Alert, Divider, Grid, Skeleton } from '@mui/material';

function EmptyDashboard() {
  return (
    <>
      <Alert
        style={{
          marginTop: 70,
        }}
        severity="info"
      >
        Setting up the app, please wait...
      </Alert>
      <div>
        <div className="col-sm-12">
          <div
            style={{
              margin: 10,
              width: 400,
              height: 48,
            }}
          >
            <Skeleton
              style={{
                margin: 10,
                width: 400,
                height: 48,
              }}
            />
          </div>
          <div style={{ padding: 30 }}>
            <Grid container spacing={10} justifyContent="center">
              <Skeleton
                style={{
                  margin: 10,
                  width: 300,
                  height: 250,
                }}
              />
              <Skeleton
                style={{
                  margin: 10,
                  width: 300,
                  height: 250,
                }}
              />
              <Skeleton
                style={{
                  margin: 10,
                  width: 300,
                  height: 250,
                }}
              />
              <Skeleton
                style={{
                  margin: 10,
                  width: 300,
                  height: 250,
                }}
              />
            </Grid>
          </div>
        </div>
        <div className="container">
          <div
            style={{
              margin: '20px 0',
            }}
          >
            <Divider />
          </div>
        </div>
        <div style={{ padding: 30 }}>
          <Grid container spacing={10} justifyContent="center">
            <Skeleton
              style={{
                margin: 10,
                width: 200,
                height: 110,
              }}
            />
            <Skeleton
              style={{
                margin: 10,
                width: 200,
                height: 110,
              }}
            />
            <Skeleton
              style={{
                margin: 10,
                width: 200,
                height: 110,
              }}
            />
            <Skeleton
              style={{
                margin: 10,
                width: 200,
                height: 110,
              }}
            />
            <Skeleton
              style={{
                margin: 10,
                width: 200,
                height: 80,
              }}
            />
            <Skeleton
              style={{
                margin: 10,
                width: 200,
                height: 80,
              }}
            />
            <Skeleton
              style={{
                margin: 10,
                width: 200,
                height: 110,
              }}
            />
            <Skeleton
              style={{
                margin: 10,
                width: 200,
                height: 110,
              }}
            />
          </Grid>
          <div className="container">
            <div
              style={{
                margin: '20px 0',
              }}
            >
              <Divider />
            </div>
          </div>
          <Grid container spacing={10} justifyContent="center">
            <Skeleton
              style={{
                margin: 10,
                width: 200,
                height: 110,
              }}
            />
            <Skeleton
              style={{
                margin: 10,
                width: 200,
                height: 110,
              }}
            />
            <Skeleton
              style={{
                margin: 10,
                width: 200,
                height: 110,
              }}
            />
            <Skeleton
              style={{
                margin: 10,
                width: 200,
                height: 110,
              }}
            />
            <Skeleton
              style={{
                margin: 10,
                width: 200,
                height: 80,
              }}
            />
            <Skeleton
              style={{
                margin: 10,
                width: 200,
                height: 80,
              }}
            />
            <Skeleton
              style={{
                margin: 10,
                width: 200,
                height: 110,
              }}
            />
            <Skeleton
              style={{
                margin: 10,
                width: 200,
                height: 110,
              }}
            />
          </Grid>
          <div className="container">
            <div
              style={{
                margin: '20px 0',
              }}
            >
              <Divider />
            </div>
          </div>
          <Grid container spacing={10} justifyContent="center">
            <Skeleton
              style={{
                margin: 10,
                width: 200,
                height: 110,
              }}
            />
            <Skeleton
              style={{
                margin: 10,
                width: 200,
                height: 110,
              }}
            />
            <Skeleton
              style={{
                margin: 10,
                width: 200,
                height: 110,
              }}
            />
            <Skeleton
              style={{
                margin: 10,
                width: 200,
                height: 110,
              }}
            />
          </Grid>
        </div>
      </div>
    </>
  );
}

export default EmptyDashboard;
