import React, { useState } from 'react';
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';

function EmailReportDownload({
  isEmailAvailable,
  setIsEmailAvailable,
  onDownload,
}) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    if (event.target.value != null) {
      if (
        /^[a-zA-Z0-9][.a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(
          event.target.value
        )
      ) {
        setIsEmailAvailable(event.target.value);
      } else {
        setIsEmailAvailable('');
      }
    }
  };

  const handleDownload = async () => {
    if (isEmailAvailable !== '') {
      await onDownload(isEmailAvailable);
      setIsEmailAvailable('');
      handleClose();
    }
  };

  return (
    <>
      <Button
        color="primary"
        variant="outlined"
        size="small"
        onClick={handleOpen}
      >
        Email Report
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        sx={{
          width: '50%',
          marginLeft: 'auto',
          marginRight: 'auto',
          maxHeight: '80vh',
        }}
      >
        <DialogTitle>Download Credit Union Report</DialogTitle>
        <DialogContent>
          <TextField
            id="outlined-basic"
            label="Your email address"
            variant="outlined"
            size="small"
            fullWidth
            sx={{ marginBottom: 2 }}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            disabled={isEmailAvailable === ''}
            color="primary"
            variant="outlined"
            size="small"
            onClick={handleDownload}
          >
            Download
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EmailReportDownload;
