import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';

function DealerCodes() {
  const [dealerCode, setDealerCode] = useState('');
  const [visibleDealerCode, setVisibleDealerCode] = useState('');
  const [codeVisible, setCodeVisible] = useState(false);

  return (
    <div className="dealerCodes">
      <h2 className="d-flex align-content-center align-items-center mb-0">
        <span>Dealer QR Code</span>
      </h2>
      <p>
        QR codes generated here can be used to link people directly to a version
        of the app that has a dealer code pre-entered.
      </p>
      <div className="d-flex">
        <TextField
          id="dealer-code"
          label="Dealer Code such as PSL00011"
          type="string"
          className="mb-4"
          value={dealerCode}
          fullWidth
          variant="outlined"
          onChange={(e) => setDealerCode(e.target.value.toUpperCase())}
        />
      </div>
      <div className="d-flex">
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setVisibleDealerCode(dealerCode);
            setCodeVisible(true);
          }}
        >
          Generate Code
        </Button>
      </div>
      {codeVisible && (
        <div>
          <img
            alt="dealer qr code"
            src={`${process.env.REACT_APP_API_URL.replace(
              '/api/v1',
              ''
            )}/dealerinvitation?dealerCode=${visibleDealerCode}`}
          />
        </div>
      )}
    </div>
  );
}

export default DealerCodes;
