import api from '.';

const PUBLIC_CAR_BACKGROUND_IMAGE_URL = `/cars/images/background`;
const CAR_BACKGROUND_IMAGE_URL = `/admin/cars/images/background`;

const getCarBackgroundImages = async (dispatch) =>
  api.pocketDrivePublicApi(dispatch, 'get', PUBLIC_CAR_BACKGROUND_IMAGE_URL);
const createCarBackgroundImage = async (dispatch, data) =>
  api.pocketDriveApi(dispatch, 'post', CAR_BACKGROUND_IMAGE_URL, data);
const updateCarBackgroundImage = async (dispatch, data) =>
  api.pocketDriveApi(dispatch, 'put', CAR_BACKGROUND_IMAGE_URL, data);
const deleteCarBackgroundImage = async (dispatch, id) =>
  api.pocketDriveApi(dispatch, 'delete', `${CAR_BACKGROUND_IMAGE_URL}/${id}`);

export default {
  getCarBackgroundImages,
  updateCarBackgroundImage,
  createCarBackgroundImage,
  deleteCarBackgroundImage,
};
