import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CheckCircleRounded as CheckCircleRoundedIcon,
  CancelRounded as CancelRoundedIcon,
} from '@mui/icons-material';
import {
  LinearProgress,
  Table,
  Paper,
  TableRow,
  TableHead,
  TableCell,
  TableContainer,
  TableBody,
  Divider,
} from '@mui/material';
import statusApi from '../api/status.api';
import ClearCache from '../components/ClearCache';

function Status() {
  const permissionss = useSelector((state) =>
    typeof state.permissions.permissions !== typeof undefined
      ? state.permissions.permissions
      : null
  );
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [api, setApi] = useState(false);
  const [mongo, setMongo] = useState(false);
  const [apiVersion, setApiVersion] = useState('');
  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      const response = await statusApi.getAPIStatus(dispatch);
      const responseVersion = await statusApi.getVersion(dispatch);
      setApi(response.api);
      setMongo(response.mongo);
      setApiVersion(responseVersion.apiVersion);
      setIsLoading(false);
    };
    load();
  }, [dispatch]);

  const getStatusIcon = (value) => {
    return value ? (
      <CheckCircleRoundedIcon style={{ fill: 'green' }} />
    ) : (
      <CancelRoundedIcon style={{ fill: 'red' }} />
    );
  };

  return (
    <div>
      <div className="d-flex">
        <h2>Vehicle Care Online Status</h2>
      </div>
      {isLoading ? (
        <LinearProgress style={{ height: '1rem' }} color="secondary" />
      ) : (
        <TableContainer component={Paper}>
          <Table size="small" padding="none" style={{ tableLayout: 'fixed' }}>
            <TableHead className="bg-dark">
              <TableRow>
                <TableCell align="center" className="p-2">
                  <span className="text-white">Status Name</span>
                </TableCell>
                <TableCell align="center" className="p-2">
                  <span className="text-white">Value</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">VCP APIs Version</TableCell>
                <TableCell align="center">{apiVersion}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">VCP APIs Health</TableCell>
                <TableCell align="center">{getStatusIcon(api)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">
                  VCP MongoDB Connection Health
                </TableCell>
                <TableCell align="center">{getStatusIcon(mongo)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {permissionss.STATUS.WRITE && (
        <>
          <Divider className="my-3" />
          <ClearCache />
          <Divider className="my-3" />
          {/* Disabled Endpoint Updater as UK has separate deployment now. */}
          {/* <ApiEndPointUpdater /> */}
        </>
      )}
    </div>
  );
}

export default Status;
