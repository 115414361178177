import countryList from '../assets/country.json';
import stateList from '../assets/state.json';

const compare = (a, b) => {
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
};

// export default function getAllCountrys() {
//   return countryList;
// }

function getAllCountrys() {
  return countryList;
}

function getStatesOfCountry(countryCode) {
  const states = stateList.filter((value) => {
    return value.countryCode === countryCode;
  });
  return states.sort(compare);
}

export { getAllCountrys, getStatesOfCountry };
