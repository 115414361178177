import React from 'react';
import {
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import MomentUtils from '@date-io/moment';
import { ExpandMore } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

function Contracts(props) {
  const { contracts } = props;

  return (
    <div className="contracts">
      {contracts.map((contract) => (
        <Accordion key={contract.contractNumber} className="contract">
          <AccordionSummary expandIcon={<ExpandMore />} className="d-flex">
            <div>
              <span>{contract.contractNumber}</span>
              <span className="ml-2">{contract.productName}</span>
            </div>
            <div className="flex-grow-1" />
          </AccordionSummary>
          <AccordionDetails className="d-block">
            <TextField
              label="Product Name"
              type="string"
              className="mb-4"
              value={contract.productName}
              fullWidth
              variant="outlined"
            />
            <TextField
              label="Expiration Miles"
              type="string"
              className="mb-4"
              value={contract.expirationMiles}
              fullWidth
              variant="outlined"
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                utils={MomentUtils}
                label="Expiration Date"
                fullWidth
                variant="inline"
                className="mr-2 mb-4"
                value={dayjs(contract.expirationDate)}
                readOnly
                format="ll"
                inputVariant="outlined"
              />
            </LocalizationProvider>
            <TextField
              label="Deductible"
              type="string"
              className="mb-4"
              value={contract.deductible}
              fullWidth
              variant="outlined"
            />
            <TextField
              label="Deductible Out Of Network"
              type="string"
              className="mb-4"
              value={contract.deductibleOutOfNetwork}
              fullWidth
              variant="outlined"
            />
            <TextField
              label="Manufacturer CPO"
              type="string"
              className="mb-4"
              value={contract.manufacturerCPO}
              fullWidth
              variant="outlined"
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                utils={MomentUtils}
                label="Effective Date"
                fullWidth
                variant="inline"
                className="mr-2 mb-4"
                value={dayjs(contract.effectiveDate)}
                readOnly
                format="ll"
                inputVariant="outlined"
              />
            </LocalizationProvider>
            <TextField
              label="Term Months"
              type="string"
              className="mb-4"
              value={contract.termMonths}
              fullWidth
              variant="outlined"
            />
            <TextField
              label="Term Distance"
              type="string"
              className="mb-4"
              value={contract.termDistance}
              fullWidth
              variant="outlined"
            />
            <TextField
              label="Selling Dealer"
              multiline
              className="mb-4"
              value={`${contract.sellingDealerName} ${contract.sellingDealerAddress} ${contract.sellingDealerPhone}`}
              fullWidth
              variant="outlined"
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

export default Contracts;
