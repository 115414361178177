import React from 'react';

import { Card, CardContent, Typography } from '@mui/material';

function NoPermission() {
  return (
    <div
      style={{
        margin: 300,
        justifySelf: 'center',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
      }}
    >
      <Card sx={{ width: 345 }}>
        <CardContent>
          <Typography variant="h5" color="text.secondary">
            You do not have permission to use this app. Please contact support.
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}

export default NoPermission;
