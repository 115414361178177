import React, { useEffect, useState } from 'react';
import { Grid, LinearProgress } from '@mui/material';
import { styled } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch } from 'react-redux';
import { format, parseISO } from 'date-fns';
import { PAGINATION_OPTIONS } from '../constants';
import userApi from '../api/user.api';
import ResetUserVerification from '../components/ResetUserVerification';

// const useStyles = styled({
//   root: {
//     '& .custom-dark-theme--header': {
//       backgroundColor: '#343a40 !important',
//       color: 'white !important',
//       '& svg': {
//         color: 'white !important',
//       },
//     },
//   },
// });
const CustomDataGrid = styled('div')({
  '& .custom-dark-theme-header': {
    backgroundColor: '#343a40 !important',
    color: 'white !important',
    '& svg': {
      color: 'white !important',
    },
  },
});

const StyledComponent = styled('div')(({ theme }) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}));

function LockedCars() {
  const [pageSize, setPageSize] = React.useState(PAGINATION_OPTIONS[2]);
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  // const classes = useStyles();

  async function fetchLockedCars(skip = page * pageSize, limit = pageSize) {
    const params = {
      skip,
      limit,
    };

    return userApi.getLockedCars(params, dispatch);
  }

  const loadData = async (skip, limit) => {
    setIsLoading(true);
    const reports = await fetchLockedCars(skip, limit);
    if (reports && reports.data) {
      setDataSource(
        reports.data.map((item, index) => {
          return { id: index, ...item };
        })
      );
      setRowCount(reports.totalCount);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [page, pageSize]);

  const handlePageSizeChange = (params) => {
    setPageSize(params.pageSize);
  };

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const reloadData = async () => {
    setDataSource([]);
    loadData();
  };

  const columns = [
    {
      headerName: 'Name',
      render: (e) => e.name,
      field: 'name',
      headerClassName: 'custom-dark-theme--header',
      width: 150,
    },
    {
      headerName: 'Email',
      render: (e) => e.email,
      field: 'email',
      headerClassName: 'custom-dark-theme--header',
      width: 250,
    },
    {
      headerName: 'Phone Number',
      render: (e) => e.phoneNumber,
      field: 'phoneNumber',
      headerClassName: 'custom-dark-theme--header',
      width: 180,
    },
    {
      headerName: 'Vin',
      field: 'car.vin',
      headerClassName: 'custom-dark-theme--header',
      width: 200,
      valueFormatter: (params) =>
        (params && params.row && params.row.car && params.row.car.vin) || 'N/A',
    },
    {
      headerName: 'Year',
      headerClassName: 'custom-dark-theme--header',
      field: 'car.year',
      width: 120,
      valueFormatter: (params) =>
        (params && params.row && params.row.car && params.row.car.year) ||
        'N/A',
    },
    {
      headerName: 'Make',
      field: 'car.make',
      headerClassName: 'custom-dark-theme--header',
      width: 150,
      valueFormatter: (params) =>
        (params && params.row && params.row.car && params.row.car.make) ||
        'N/A',
    },
    {
      headerName: 'Model',
      field: 'car.model',
      headerClassName: 'custom-dark-theme--header',
      width: 120,
      valueFormatter: (params) =>
        (params && params.row && params.row.car && params.row.car.model) ||
        'N/A',
    },
    {
      headerName: 'Locked At',
      field: 'lockedAt',
      headerClassName: 'custom-dark-theme--header',
      width: 160,
      valueFormatter: (e) => (e.value ? format(parseISO(e.value), 'Pp') : ''),
    },
    {
      headerName: 'Color',
      field: 'car.color',
      headerClassName: 'custom-dark-theme--header',
      width: 120,
      valueFormatter: (params) =>
        (params &&
          params.row &&
          params.row.car &&
          params.row.car.color &&
          params.row.car.color.simpleName) ||
        'N/A',
    },
    {
      headerName: 'Zip Code',
      render: (e) => e.customerPostalCode,
      field: 'customerPostalCode',
      headerClassName: 'custom-dark-theme--header',
      width: 150,
    },
    {
      headerName: 'Policies Locked',
      renderCell: (params) => {
        return (
          <ResetUserVerification
            disabled={false}
            carId={params.row.car._id}
            userId={params.value}
            isLocked
            callback={() => {
              reloadData();
            }}
          />
        );
      },
      field: '_id',
      headerClassName: 'custom-dark-theme--header',
      width: 200,
    },
  ];

  return (
    <Grid container className="reports">
      <Grid item sm={12}>
        <h2 className="d-flex align-content-center align-items-center mb-0">
          <span className="mr-2">Locked Cars</span>
        </h2>
        {isLoading ? (
          <LinearProgress style={{ height: '1rem' }} color="secondary" />
        ) : (
          <StyledComponent>
            <CustomDataGrid>
              <div style={{ height: '70vh', width: '100%' }}>
                <DataGrid
                  paginationModel={{ page, pageSize }}
                  pagination
                  pageSizeOptions={PAGINATION_OPTIONS}
                  onPageSizeChange={handlePageSizeChange}
                  rowCount={rowCount}
                  getRowId={(row) => row.id}
                  rows={dataSource}
                  columns={columns}
                  onPaginationModelChange={handlePageChange}
                  paginationMode="server"
                />
              </div>
            </CustomDataGrid>
          </StyledComponent>
        )}
      </Grid>
    </Grid>
  );
}

export default LockedCars;
