import React, { useEffect, useState, forwardRef } from 'react';
import { Grid, LinearProgress } from '@mui/material';
// import { Button, Grid, LinearProgress, CircularProgress } from '@mui/material';
import MaterialTable from '@material-table/core';
import {
  AddBox,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  // Search,
  ViewColumn,
  ArrowDownward,
} from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
// import appConfigApi from '../api/appConfig.api';
import subscriptionApi from '../api/subscriptions.api';
import { AppConfigMapping } from '../constants';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  // Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

// const SaveMessages = {
//   save: 'Save',
//   savePending: 'Save Pending',
//   savingChanges: 'Saving Changes',
// };

function SubscriptionAppConfig() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  // const [originalResponse, setOriginalResponse] = useState({});
  // const [saveMsg, setSaveMsg] = useState(SaveMessages.save);

  const permissions = useSelector((state) => state.permissions.permissions);

  const dispatch = useDispatch();

  function isObject(item) {
    return typeof item === 'object' && !Array.isArray(item) && item !== null;
  }

  function processResponse(responseData, items) {
    let id = 1;
    let level = 1;
    const processFn = (response, parentId, records) => {
      const keys = Object.keys(response);
      for (let itr = 0; itr < keys.length; itr++) {
        const item = response[keys[itr]];
        const itemToPush = {
          key: keys[itr],
          label: AppConfigMapping[keys[itr]]
            ? AppConfigMapping[keys[itr]]
            : keys[itr],
          id,
          level,
        };
        if (parentId) {
          itemToPush.parentId = parentId;
        }
        if (isObject(item)) {
          const nextParentId = id;
          records.push(itemToPush);
          id++;
          level++;
          processFn(item, nextParentId, records);
          level--;
        } else {
          id++;
          itemToPush.value = item;
          itemToPush.isArray = Array.isArray(item);
          records.push(itemToPush);
        }
      }
    };
    processFn(responseData, null, items);
  }

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      const response = await subscriptionApi.getSubscriptionConfig(dispatch);
      const records = [];
      // setOriginalResponse(response.data);
      processResponse(response.data, records);
      if (records && records.length) {
        setData(records);
      }
      setIsLoading(false);
    };
    loadData();
  }, []);

  // const updateValueInResponse = (value, rowItem) => {
  //   let rowData = rowItem;
  //   const parentKeys = [];
  //   const { key } = rowData;
  //   while (rowData && rowData.parentId) {
  //     rowData = data[rowData.parentId - 1];
  //     parentKeys.push(rowData.key);
  //   }
  //   let response = originalResponse;
  //   for (let itr = parentKeys.length - 1; itr >= 0; itr--) {
  //     response = response[parentKeys[itr]];
  //   }
  //   if (response && Object.prototype.hasOwnProperty.call(response, key)) {
  //     response[key] = value;
  //     setOriginalResponse(originalResponse);
  //     if (saveMsg === 'Save') {
  //       setSaveMsg(SaveMessages.savePending);
  //     }
  //   }
  // };

  // const updateConfig = async () => {
  //   if (saveMsg === SaveMessages.save) {
  //     return;
  //   }
  //   setSaveMsg(SaveMessages.savingChanges);
  //   await appConfigApi.updateConfigs(originalResponse, null, dispatch);
  //   setSaveMsg(SaveMessages.save);
  // };

  return (
    <Grid container className="reports">
      <Grid item sm={12}>
        <h2 className="d-flex align-content-center align-items-center justify-content-between mb-2">
          <span className="mr-auto">App Config</span>
          {/* {permissions.APP_CONFIG.WRITE && (
            <Button color="primary" variant="outlined" onClick={updateConfig}>
              {saveMsg}
              {saveMsg === SaveMessages.savingChanges ? (
                <CircularProgress style={{ height: '25px', width: '25px' }} />
              ) : (
                ''
              )}
            </Button>
          )} */}
        </h2>
        {isLoading ? (
          <LinearProgress style={{ height: '1rem' }} color="secondary" />
        ) : (
          <MaterialTable
            icons={tableIcons}
            data={data}
            columns={[
              {
                title: 'Feature Name',
                field: 'label',
                editable: 'never',
              },
              {
                title: 'Value',
                field: 'value',
                searchable: false,
                render: (rowData) => {
                  if (rowData.isArray) {
                    if (
                      typeof rowData.value[0] === 'object' &&
                      rowData.value[0] !== null
                    ) {
                      return rowData.value
                        .map((obj) =>
                          Object.entries(obj)
                            .map(
                              ([key, val]) =>
                                `${key}: ${
                                  Array.isArray(val) ? val.join(', ') : val
                                }`
                            )
                            .join(', ')
                        )
                        .join('; ');
                    }
                    return rowData.value.join(',');
                  }
                  if (typeof rowData.value === 'boolean') {
                    return rowData.value.toString();
                  }
                  return rowData.value;
                },
                editable: permissions.APP_CONFIG.WRITE ? 'always' : 'never',
              },
            ]}
            // cellEditable={{
            //   onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
            //     return new Promise((resolve) => {
            //       const rowItem = rowData;
            //       let value = newValue.trim();
            //       if (rowItem.isArray) {
            //         value = value
            //           .split(',')
            //           .map((item) => item.trim())
            //           .filter((item) => item.length > 0);
            //       } else if (typeof oldValue === 'number') {
            //         value = Number(value);
            //       } else if (typeof oldValue === 'boolean') {
            //         value = value.toLowerCase() === 'true';
            //       }
            //       rowItem[columnDef.field] = value;
            //       updateValueInResponse(value, rowItem);
            //       setTimeout(resolve, 1000);
            //     });
            //   },
            // }}
            options={{
              search: true,
              rowStyle: (rowData) => ({
                fontWeight:
                  rowData.level === 1
                    ? '800'
                    : rowData.level === 2 && rowData.value === undefined
                    ? '600'
                    : '400',
              }),
              showTitle: false,
              draggable: false,
              pageSize: 20,
            }}
            parentChildData={(r, rows) => rows.find((a) => a.id === r.parentId)}
          />
        )}
      </Grid>
    </Grid>
  );
}
export default SubscriptionAppConfig;
