import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from '@mui/material';
import { Cancel } from '@mui/icons-material';
import { addMonths, addDays, format } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import FilterModal from '../components/Filter';
import ScheduleJobsList from '../components/ScheduleJobsList';

const scheduledJobOptions = [
  { value: 'OFFER_FILE_POST_PROCESSING', label: 'OFFER_FILE_POST_PROCESSING' },
  { value: 'GENERATE_OFFERS', label: 'GENERATE_OFFERS' },
  {
    value: 'RECURRING_PAYMENT_FAILURE_EMAIL',
    label: 'RECURRING_PAYMENT_FAILURE_EMAIL',
  },
];

function ScheduleJobs() {
  const [filter, setFilter] = useState({});
  const [filterDisplay, setFilterDisplay] = useState('');
  const [filterBeforeDate, setFilterBeforeDate] = useState(null);
  const [filterAfterDate, setFilterAfterDate] = useState(null);
  const [applyQuickFilter, setApplyQuickFilter] = useState(false);
  const [filterScheduledJob, setFilterScheduledJob] = useState('');

  const saveFilters = () => {
    const newFilter = {};
    const displays = [];
    let dateDisplay = '';
    if (filterAfterDate || filterBeforeDate) {
      const startDate = filterAfterDate
        ? format(new Date(filterAfterDate), 'yyyy-MM-dd')
        : '1970-01-01';
      const endDate = filterBeforeDate
        ? format(new Date(filterBeforeDate), 'yyyy-MM-dd')
        : format(new Date(), 'yyyy-MM-dd');
      newFilter.created_at = { between: [startDate, endDate] };
      dateDisplay = `Dates: ${startDate} - ${endDate}`;
    }
    if (filterScheduledJob) {
      newFilter.type = { eq: filterScheduledJob };
    }
    if (dateDisplay) {
      displays.push(dateDisplay);
    }

    setFilter(newFilter);
    setFilterDisplay(displays.join(' '));
  };

  useEffect(() => {
    if (applyQuickFilter && filterAfterDate && filterAfterDate) {
      setApplyQuickFilter(false);
      saveFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyQuickFilter, filterAfterDate, filterAfterDate]);

  useEffect(() => {
    if (applyQuickFilter && filterScheduledJob) {
      setApplyQuickFilter(false);
      saveFilters();
    }
  }, [filterScheduledJob]);

  const setLastDayFilter = () => {
    setFilterBeforeDate(new Date());
    setFilterAfterDate(new Date());
    // setFilterAfterDate(addHours(new Date(), -24));
    setApplyQuickFilter(true);
  };

  const setLastMonthFilter = () => {
    setFilterBeforeDate(new Date());
    setFilterAfterDate(addMonths(new Date(), -1));
    setApplyQuickFilter(true);
  };

  const setLastSevenDays = () => {
    setFilterBeforeDate(new Date());
    setFilterAfterDate(addDays(new Date(), -7));
    setApplyQuickFilter(true);
  };

  const handleFilterScheduledJob = (event) => {
    setFilterScheduledJob(event.target.value);
    setApplyQuickFilter(true);
  };

  const resetFilters = () => {
    setFilterAfterDate(null);
    setFilterBeforeDate(null);
    setFilterDisplay('');
    setFilterScheduledJob('');
    setFilter({});
  };

  return (
    <div>
      <div>
        <h5 className="d-flex align-content-center align-items-center mb-0">
          <div className="flex-grow-1" />
          <FormControl style={{ marginBottom: '16px', width: '350px' }}>
            <InputLabel htmlFor="select-scheduled-job-label">
              Scheduled Job Type
            </InputLabel>
            <Select
              labelId="select-scheduled-job-label"
              id="select-scheduled-job"
              value={filterScheduledJob}
              onChange={handleFilterScheduledJob}
              fullWidth
              style={{ overflowY: 'auto' }}
            >
              {scheduledJobOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {filterScheduledJob && (
            <IconButton onClick={resetFilters}>
              <Cancel color="black" />
            </IconButton>
          )}
          {filter && !Object.keys(filter).length && (
            <>
              <Button color="primary" onClick={setLastDayFilter}>
                Last 24 Hours
              </Button>
              <Button color="primary" onClick={setLastSevenDays}>
                Last 7 Days
              </Button>
              <Button color="primary" onClick={setLastMonthFilter}>
                Last month
              </Button>
            </>
          )}

          <FilterModal
            currentFilters={filter}
            onSave={saveFilters}
            itemName="Stats"
            resetFilters={resetFilters}
            filterDisplay={filterDisplay}
          >
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  utils={DateFnsUtils}
                  label="After Date (including)"
                  variant="inline"
                  className="mr-2"
                  fullWidth
                  id="after-date-filter"
                  value={filterAfterDate}
                  onChange={setFilterAfterDate}
                  autoOk
                />
              </LocalizationProvider>
            </div>
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  utils={DateFnsUtils}
                  label="Before Date (including)"
                  variant="inline"
                  className="mr-2"
                  fullWidth
                  id="before-date-filter"
                  value={filterBeforeDate}
                  onChange={setFilterBeforeDate}
                  autoOk
                />
              </LocalizationProvider>
            </div>
          </FilterModal>
        </h5>
      </div>
      <div className="row my-2 ">
        <ScheduleJobsList filter={filter} viewHeight="75vh" />
      </div>
    </div>
  );
}

export default ScheduleJobs;
