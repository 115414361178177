import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import {
  applyMiddleware,
  compose,
  legacy_createStore as createStore,
} from 'redux';
import App from './App';
import reducers from './store/reducers';

const root = ReactDOM.createRoot(document.getElementById('root'));
function configureStore(initialState) {
  const composeEnhancers =
    (process.env.REACT_APP_ENV === 'dev' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;
  const enhancer = composeEnhancers(applyMiddleware(thunkMiddleware));
  return createStore(reducers, initialState, enhancer);
}
const store = configureStore({});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
