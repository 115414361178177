/* eslint-disable default-param-last */
import { GET_USER_GROUP } from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case GET_USER_GROUP:
      return {
        ...state,
        userGroup: action.payload,
      };
    default:
      return state;
  }
};
