/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { Alert } from '@mui/material';
import { useSelector } from 'react-redux';

// This in theory should be a container, but it's so small that I'm going to leave it here
function Error() {
  const error = useSelector((state) => state.error.error);
  if (!error) {
    return <></>;
  }
  return <Alert severity="error">{error}</Alert>;
}

export default Error;
