import React, { useState } from 'react';
import {
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { JsonView, allExpanded, defaultStyles } from 'react-json-view-lite';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import { useSelector } from 'react-redux';

function EventViewer({ jsonData, columns, title, permissionsKey }) {
  const [show, setShow] = useState(false); // Parent popup visibility
  const [showChildPopup, setShowChildPopup] = useState(false); // Child popup visibility
  const [selectedEvent, setSelectedEvent] = useState(null); // Selected row's object
  const permissions = useSelector((state) => state.permissions.permissions);

  const onCloseParent = () => setShow(false);
  const onCloseChild = () => setShowChildPopup(false);

  const tableHeaderStyle = {
    padding: '15px',
    backgroundColor: '#f4f4f4',
    textAlign: 'left',
    fontWeight: 'bold',
    borderBottom: '2px solid #ddd',
    position: 'sticky',
    top: 0,
    zIndex: 1,
  };

  const tableCellStyle = {
    padding: '15px',
    borderBottom: '1px solid #ddd',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  const handleRowClick = (event) => {
    setSelectedEvent(event);
    setShowChildPopup(true);
  };

  return (
    <>
      <IconButton
        style={{
          display:
            permissions[permissionsKey] && permissions[permissionsKey].READ
              ? ''
              : 'none',
        }}
        onClick={() => setShow(true)}
      >
        <EventAvailableOutlinedIcon />
      </IconButton>

      {/* Parent popup for displaying events */}
      <Dialog open={show} onClose={onCloseParent} maxWidth="md" fullWidth>
        <DialogTitle
          disableTypography
          className="d-flex align-items-center"
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Typography variant="h6">{title}</Typography>
          <IconButton onClick={onCloseParent}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {jsonData && jsonData.length > 0 ? (
            <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
              <table
                style={{
                  width: '100%',
                  borderCollapse: 'collapse',
                  tableLayout: 'fixed',
                }}
              >
                <thead>
                  <tr>
                    {columns.map((col, index) => (
                      <th
                        key={index}
                        style={{
                          ...tableHeaderStyle,
                          width: col.width || 'auto',
                        }}
                      >
                        {col.label}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {jsonData.map((event, index) => (
                    <tr
                      key={index}
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleRowClick(event)}
                    >
                      {columns.map((col, colIndex) => (
                        <td key={colIndex} style={tableCellStyle}>
                          {col.format
                            ? col.format(event[col.field])
                            : event[col.field]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <Typography variant="body2">No events available</Typography>
          )}
        </DialogContent>
      </Dialog>

      {/* Child popup to show details of the selected row */}
      {selectedEvent && (
        <Dialog open={showChildPopup} onClose={onCloseChild}>
          <DialogTitle
            disableTypography
            className="d-flex align-items-center"
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Typography variant="h6">Selected Event Details</Typography>
            <IconButton onClick={onCloseChild}>
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <JsonView
              data={selectedEvent}
              shouldExpandNode={allExpanded}
              style={defaultStyles}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

export default EventViewer;
