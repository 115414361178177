/* eslint-disable default-param-last */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addMonths, format, parseISO } from 'date-fns';
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import DateFnsUtils from '@date-io/date-fns';
import ReactExport from 'react-excel-exportz';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { getUserReport } from '../store/actions/user.actions';
import FilterModal from '../components/Filter';
import { PAGINATION_OPTIONS } from '../constants';
import userApi from '../api/user.api';

const getNameFromDealerCache = (id, dealerCache = {}) => {
  return dealerCache && id && dealerCache[id.toUpperCase()]
    ? dealerCache[id.toUpperCase()].dealerName
    : id;
};

const REPORT_TABLE_FIELDS = [
  {
    display: 'Name',
    render: (e) => e.name,
  },
  {
    display: 'Created',
    render: (e) =>
      e.createdAt ? format(parseISO(e.createdAt), 'MM/dd/yyyy') : '',
  },
  {
    display: 'OKTA ID',
    render: (e) => e.oktaId,
  },
  {
    display: 'Creating Dealer',
    render: (e) => getNameFromDealerCache(e.creatingDealerId, e.dealerCaches),
  },
  {
    display: 'Phone Verified',
    render: (e) => (e.phoneNumberVerified ? 'YES' : 'NO'),
  },
  {
    display: 'Car count',
    render: (e) => e.cars.length,
  },
  {
    display: 'Successful messages',
    render: (e) => e.successSMS,
  },
  {
    display: 'Failed messages',
    render: (e) => e.failedSMS,
  },
];

function ReportRow(props) {
  const { data } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow hover>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        {REPORT_TABLE_FIELDS.map((field) => (
          <TableCell
            key={field.display}
            align="left"
            scope="row"
            className="p-2"
          >
            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {field.render(data)}
            </div>
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Cars
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>VIN</TableCell>
                    <TableCell>Dealer</TableCell>
                    <TableCell>Repair Shop</TableCell>
                    <TableCell align="right">Make</TableCell>
                    <TableCell align="right">Model</TableCell>
                    <TableCell align="right">Year</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.cars.map((car) => (
                    <TableRow key={car._id}>
                      <TableCell component="th" scope="row">
                        {car.vin}
                      </TableCell>
                      <TableCell>
                        {getNameFromDealerCache(
                          car.dealerId,
                          data.dealerCaches
                        )}
                      </TableCell>
                      <TableCell>
                        {getNameFromDealerCache(
                          car.repairShopId,
                          data.dealerCaches
                        )}
                      </TableCell>
                      <TableCell align="right">{car.make}</TableCell>
                      <TableCell align="right">{car.model}</TableCell>
                      <TableCell align="right">{car.year}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function Reports() {
  const report = useSelector((state) => state.user.report);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [appliedFilter, setAppliedFilter] = useState({});
  const [filterBeforeDate, setFilterBeforeDate] = useState(null);
  const [filterAfterDate, setFilterAfterDate] = useState(null);
  const [filterDealerId, setFilterDealerId] = useState('');
  const [filterDisplay, setFilterDisplay] = useState('');
  const [name, setName] = useState('');
  const [pageNumber, setPageNumber] = useState(0);
  const [countPerPage, setCountPerPage] = useState(PAGINATION_OPTIONS[2]);
  const [downloadData, setDownloadData] = useState([]);
  const [downloadSummary, setDownloadSummary] = useState([]);
  const [isSummaryDownloading, setIsSummaryDownloading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadSummaryMsg, setDownloadSummaryMsg] = useState('');
  const [downloadMsg, setDownloadMsg] = useState('');
  const [applyQuickFilter, setApplyQuickFilter] = useState(false);
  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ReactExport.ExcelFile;
  const { ExcelColumn } = ReactExport.ExcelFile;
  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      const params = {
        filter: JSON.stringify(appliedFilter),
        skip: countPerPage * pageNumber,
        limit: countPerPage,
      };
      await dispatch(getUserReport(params));
      setIsLoading(false);
    };
    loadData();
  }, [appliedFilter, countPerPage, pageNumber, dispatch]);

  const changePage = (event, newPageNumber) => {
    setPageNumber(newPageNumber);
  };
  const changeRowsPerPage = (event) => {
    const newCountPerPage = event.target.value;
    setCountPerPage(newCountPerPage);
  };
  const saveFilters = () => {
    const newFilter = {};
    const displays = [];
    if (name) {
      newFilter.name = `/${name}/`;
      displays.push(`Name: ${name}`);
    }
    if (filterDealerId) {
      newFilter.creatingDealerId = `/${filterDealerId}/`;
      displays.push(`Dealer: ${filterDealerId}`);
    }
    let dateDisplay = '';
    const filterStartDate = new Date(filterAfterDate);
    const filterEndDate = new Date(filterBeforeDate);
    if (filterAfterDate) {
      newFilter.createdAt = { $gte: `${filterStartDate}` };
      dateDisplay = `Dates: ${format(filterStartDate, 'MM/dd/yyyy')}`;
    }
    if (filterBeforeDate) {
      newFilter.createdAt = {
        ...newFilter.createdAt,
        $lte: `ISO${filterEndDate}`,
      };
      if (!dateDisplay) {
        dateDisplay = `Dates: -${format(filterEndDate, 'MM/dd/yyyy')}`;
      } else {
        dateDisplay = `${dateDisplay}-${format(filterEndDate, 'MM/dd/yyyy')}`;
      }
    }
    if (dateDisplay) {
      displays.push(dateDisplay);
    }

    setAppliedFilter(newFilter);
    setFilterDisplay(displays.join(' '));
  };

  useEffect(() => {
    if (applyQuickFilter && !!filterAfterDate) {
      setApplyQuickFilter(false);
      saveFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyQuickFilter, filterAfterDate]);
  const setLastMonthFilter = () => {
    setFilterAfterDate(addMonths(new Date(), -1));
    setApplyQuickFilter(true);
  };
  const setLastSixMonthsFilter = () => {
    setFilterAfterDate(addMonths(new Date(), -6));
    setApplyQuickFilter(true);
  };
  const resetFilters = () => {
    setFilterAfterDate(null);
    setFilterBeforeDate(null);
    setFilterDealerId('');
    setName('');
    setFilterDisplay('');
    setAppliedFilter({});
    setPageNumber(0);
  };

  async function fetchReportsInBatches(
    index = 0,
    limit,
    allReports = [],
    includeCars,
    totalCount = 1
  ) {
    const params = {
      filter: JSON.stringify(appliedFilter),
      limit,
      skip: index * limit,
    };
    if (includeCars) {
      setDownloadMsg(
        `Downloading ${index + 1} of ${
          totalCount || Math.ceil(report.totalCount / limit)
        } Pages`
      );
    } else {
      setDownloadSummaryMsg(
        `Downloading ${index + 1} of  ${
          totalCount || Math.ceil(report.totalCount / limit)
        } Pages`
      );
    }
    const userReport = await userApi.getUserReport(dispatch, params);
    allReports = allReports.concat(userReport.data); // eslint-disable-line no-param-reassign
    if (userReport.currentPage !== userReport.pageCount) {
      return fetchReportsInBatches(
        userReport.currentPage,
        limit,
        allReports,
        includeCars,
        userReport.pageCount
      );
    }
    return allReports;
  }

  const downloadResults = async (includeCars = true) => {
    if (includeCars) {
      setIsDownloading(true);
      setDownloadData([]);
    } else {
      setIsSummaryDownloading(true);
      setDownloadSummary([]);
    }
    const userReport = await fetchReportsInBatches(0, 500, [], includeCars);
    const counts = { rows: 0 };
    const updateDownloadData = userReport.reduce((data, user) => {
      counts.rows += user.cars.length ? user.cars.length : 1;
      const userData = {
        name: user.name,
        createdAt: user.createdAt
          ? format(parseISO(user.createdAt), 'MM/dd/yyyy')
          : '',
        oktaId: user.oktaId,
        creatingDealerId: getNameFromDealerCache(
          user.creatingDealerId,
          user.dealerCaches
        ),
        phoneNumberVerified: user.phoneNumberVerified ? 'YES' : 'NO',
        carCount: (user.cars && user.cars.length) || 0,
        successSMS: user.successSMS,
        failedSMS: user.failedSMS,
      };
      if (!includeCars || !user.cars || !user.cars.length) {
        data.push(userData);
        return data;
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const car of user.cars) {
        data.push({
          ...userData,
          ...car,
          dealerId: getNameFromDealerCache(car.dealerId, user.dealerCaches),
          repairShopId: getNameFromDealerCache(
            car.repairShopId,
            user.dealerCaches
          ),
        });
      }
      return data;
    }, []);
    if (includeCars) {
      setDownloadData(updateDownloadData);
      setIsDownloading(false);
      setDownloadMsg('');
    } else {
      setDownloadSummary(updateDownloadData);
      setIsSummaryDownloading(false);
      setDownloadSummaryMsg('');
    }
    setIsDownloading(false);
  };

  return (
    <Grid container className="reports">
      <Grid item sm={12}>
        <h2 className="d-flex align-content-center align-items-center mb-0">
          <span className="mr-2">Reports</span>
          <Button
            disabled={isSummaryDownloading}
            color="primary"
            variant="outlined"
            onClick={() => downloadResults(false)}
            className="mr-2"
          >
            {isSummaryDownloading ? (
              <>
                <span style={{ fontSize: '12px' }}>{downloadSummaryMsg}</span>
                <CircularProgress style={{ height: '25px', width: '25px' }} />
              </>
            ) : (
              'Download summary'
            )}
          </Button>
          <Button
            disabled={isDownloading}
            color="primary"
            variant="outlined"
            onClick={downloadResults}
          >
            {isDownloading ? (
              <>
                <span style={{ fontSize: '12px' }}>{downloadMsg}</span>
                <CircularProgress style={{ height: '25px', width: '25px' }} />
              </>
            ) : (
              'Download details'
            )}
          </Button>
          {downloadData && downloadData.length ? (
            <ExcelFile hideElement>
              <ExcelSheet data={downloadData} name="ReportsDetails">
                <ExcelColumn label="name" value="name" />
                <ExcelColumn label="createdAt" value="createdAt" />
                <ExcelColumn label="oktaId" value="oktaId" />
                <ExcelColumn label="creatingDealer" value="creatingDealerId" />
                <ExcelColumn label="dealer" value="dealerId" />
                <ExcelColumn
                  label="phoneNumberVerified"
                  value="phoneNumberVerified"
                />
                <ExcelColumn label="carCount" value="carCount" />
                <ExcelColumn label="successSMS" value="successSMS" />
                <ExcelColumn label="failedSMS" value="failedSMS" />
                <ExcelColumn label="_id" value="_id" />
                <ExcelColumn label="vin" value="vin" />
                <ExcelColumn label="make" value="make" />
                <ExcelColumn label="model" value="model" />
                <ExcelColumn label="year" value="year" />
                <ExcelColumn label="repairShop" value="repairShopId" />
              </ExcelSheet>
            </ExcelFile>
          ) : (
            ''
          )}
          {downloadSummary && downloadSummary.length ? (
            <ExcelFile hideElement>
              <ExcelSheet data={downloadSummary} name="ReportsSummary">
                <ExcelColumn label="name" value="name" />
                <ExcelColumn label="createdAt" value="createdAt" />
                <ExcelColumn label="oktaId" value="oktaId" />
                <ExcelColumn label="creatingDealer" value="creatingDealerId" />
                <ExcelColumn
                  label="phoneNumberVerified"
                  value="phoneNumberVerified"
                />
                <ExcelColumn label="carCount" value="carCount" />
                <ExcelColumn label="successSMS" value="successSMS" />
                <ExcelColumn label="failedSMS" value="failedSMS" />
              </ExcelSheet>
            </ExcelFile>
          ) : (
            ''
          )}
          <div className="flex-grow-1" />
          {appliedFilter && !Object.keys(appliedFilter).length && (
            <>
              <Button color="primary" onClick={setLastMonthFilter}>
                Last month
              </Button>
              <Button color="primary" onClick={setLastSixMonthsFilter}>
                Last 6 month
              </Button>
            </>
          )}
          <FilterModal
            currentFilters={appliedFilter}
            onSave={saveFilters}
            itemName="Reports"
            resetFilters={resetFilters}
            filterDisplay={filterDisplay}
          >
            <div style={{ marginBottom: '16px', marginTop: '10px' }}>
              <TextField
                id="filter-name"
                label="name"
                placeholder="Enter a name"
                fullWidth
                value={name}
                onChange={(e) => setName(e.currentTarget.value)}
              />
            </div>
            <div style={{ marginBottom: '16px' }}>
              <TextField
                id="filter-dealer-id"
                label="Creating Dealer"
                placeholder="Enter a dealer ID"
                fullWidth
                value={filterDealerId}
                onChange={(e) => setFilterDealerId(e.currentTarget.value)}
              />
            </div>
            <div style={{ marginBottom: '16px' }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  utils={DateFnsUtils}
                  label="After Date (including)"
                  variant="inline"
                  className="mr-2"
                  fullWidth
                  id="after-date-filter"
                  value={filterAfterDate}
                  onChange={setFilterAfterDate}
                  autoOk
                />
              </LocalizationProvider>
            </div>
            <div style={{ marginBottom: '16px' }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  utils={DateFnsUtils}
                  label="Before Date (including)"
                  variant="inline"
                  className="mr-2"
                  fullWidth
                  id="before-date-filter"
                  value={filterBeforeDate}
                  onChange={setFilterBeforeDate}
                  autoOk
                />
              </LocalizationProvider>
            </div>
          </FilterModal>
        </h2>
        {isLoading || !report ? (
          <LinearProgress style={{ height: '1rem' }} color="secondary" />
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table
                size="small"
                padding="none"
                style={{ tableLayout: 'fixed' }}
              >
                <TableHead className="bg-dark font-white">
                  <TableRow>
                    <TableCell />
                    {REPORT_TABLE_FIELDS.map((field) => (
                      <TableCell
                        key={field.display}
                        align="left"
                        className="p-2"
                        component="th"
                        style={field.styles}
                      >
                        <span className="text-white">{field.display}</span>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {report.data.map((data) => (
                    <ReportRow key={data._id} data={data} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPage={report.data.length}
              component="div"
              count={
                report.totalCount
                  ? report.totalCount
                  : report.data.length * report.pageCount
              }
              page={report.currentPage - 1}
              rowsPerPageOptions={PAGINATION_OPTIONS}
              onPageChange={changePage}
              onRowsPerPageChange={changeRowsPerPage}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
}

export default Reports;
