import { combineReducers } from 'redux';
import user from './user.reducers';
import error from './error.reducers';
import car from './cars.reducers';
import errors from './errors.reducers';
import sms from './sms.reducers';
import localizedString from './localizedString.reducers';
import currentUser from './userGroup.reducers';
import permissions from './permission.reducer';
import contactUs from './contactUs.reducers';
import app from './app.reducers';

import carBackgroundImages from './carBackgroundImages.reducers';

export default combineReducers({
  user,
  error,
  car,
  errors,
  carBackgroundImages,
  sms,
  localizedString,
  currentUser,
  permissions,
  contactUs,
  app,
});
