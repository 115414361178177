import api from '.';

const CONFIG_URL = '/admin/config';
const BUSINESS_CONFIG_URL = '/admin/business-config';

const getConfigs = async (params, dispatch) => {
  return api.pocketDriveApi(dispatch, 'get', CONFIG_URL, null, params);
};

const updateConfigs = async (data, params, dispatch) => {
  return api.pocketDriveApi(dispatch, 'post', CONFIG_URL, data, params);
};

const getBusinessConfigs = async (params, dispatch) => {
  return api.pocketDriveApi(dispatch, 'get', BUSINESS_CONFIG_URL, null, params);
};

const updateBusinessConfigs = async (data, params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'post',
    BUSINESS_CONFIG_URL,
    data,
    params
  );
};

export default {
  getConfigs,
  updateConfigs,
  getBusinessConfigs,
  updateBusinessConfigs,
};
