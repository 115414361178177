import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import reportApi from '../api/report.api';
import { PAGINATION_OPTIONS } from '../constants';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

function SellingDealersList({ appliedFilter }) {
  const columns = [
    {
      headerName: 'Dealer Name',
      render: (e) => e.dealerName,
      field: 'dealerName',
      headerClassName: 'custom-dark-theme--header',
      width: 300,
    },
    {
      headerName: 'Dealer Account Number',
      render: (e) => e.dealerId,
      field: 'dealerId',
      headerClassName: 'custom-dark-theme--header',
      width: 180,
    },
    {
      headerName: 'Cars Count',
      render: (e) => e.carsCount,
      field: 'carsCount',
      headerClassName: 'custom-dark-theme--header',
      width: 150,
    },
    {
      headerName: 'Dealer Address',
      render: (e) => e.dealerAddress,
      field: 'dealerAddress',
      headerClassName: 'custom-dark-theme--header',
      width: 400,
    },
  ];
  const [dataSource, setDataSource] = useState([]);
  const [pageSize, setPageSize] = React.useState(PAGINATION_OPTIONS[2]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const updateDataSource = (data) => {
    const startIndex =
      dataSource && dataSource.length
        ? dataSource[dataSource.length - 1]._id + 1
        : 0;
    const _updatedDataSource = dataSource.concat(
      data.map((item, index) => {
        return { ...item, _id: index + startIndex };
      })
    );
    setDataSource(_updatedDataSource);
    return _updatedDataSource;
  };

  const fetchReport = async (skip = dataSource.length, limit = pageSize) => {
    const params = {
      skip,
      limit,
      filter: JSON.stringify({
        ...appliedFilter,
      }),
    };
    setIsLoading(true);

    return reportApi.getSellingDealers(params, dispatch);
  };

  useEffect(() => {
    const loadData = async () => {
      const reports = await fetchReport();
      if (reports && reports.data) {
        updateDataSource(reports.data);
        setRowCount(reports.count);
      }
      setIsLoading(false);
    };
    loadData();
  }, [page, pageSize]);

  useEffect(() => {
    const loadData = async () => {
      if (page !== 0) {
        setDataSource([]);
        return;
      }
      setIsLoading(true);
      const reports = await fetchReport(0);
      if (reports && reports.data) {
        setDataSource(
          reports.data.map((item, index) => {
            return { ...item, _id: index };
          })
        );
        if (reports.totalCount !== rowCount) {
          setRowCount(reports.totalCount);
        }
        setPage(0);
      }
      setIsLoading(false);
    };
    loadData();
  }, [appliedFilter]);

  const handlePageSizeChange = (params) => {
    setPage(params.page);
    setPageSize(params.pageSize);
  };

  const handlePageChange = (params) => {
    setPage(params.page);
    setPageSize(params.pageSize);
  };

  return (
    <div className="container">
      <div
        style={{
          height: '80vh',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <DataGrid
            density="compact"
            paginationModel={{ page, pageSize }}
            pagination
            pageSizeOptions={PAGINATION_OPTIONS}
            onPageSizeChange={handlePageSizeChange}
            rowCount={rowCount}
            getRowId={(row) => row._id}
            rows={dataSource}
            columns={columns}
            onPaginationModelChange={handlePageChange}
            slots={{
              toolbar: CustomToolbar,
            }}
          />
        )}
      </div>
    </div>
  );
}

export default SellingDealersList;
