import React from 'react';
import {
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
// import MomentUtils from '@date-io/moment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ExpandMore } from '@mui/icons-material';

function CarRecalls(props) {
  const {
    car: { recalls },
  } = props;
  return (
    <div className="car-recalls">
      {recalls.map((recall) => (
        <Accordion key={recall._id} className="car-recall">
          <AccordionSummary expandIcon={<ExpandMore />} className="d-flex">
            <div>{recall.nhtsaCampaignNumber}</div>
            <div className="flex-grow-1" />
          </AccordionSummary>
          <AccordionDetails className="d-block">
            <TextField
              id={`campaign-${recall._id}`}
              label="NHTSA Campaign Number"
              type="string"
              className="mb-4"
              value={recall.nhtsaCampaignNumber}
              fullWidth
              variant="outlined"
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Report Received Date"
                fullWidth
                variant="inline"
                className="mr-2 mb-4"
                id={`report-${recall._id}`}
                value={recall.reportReceivedDate}
                readOnly
                inputVariant="outlined"
              />
            </LocalizationProvider>
            <TextField
              id={`component-${recall._id}`}
              label="Component"
              type="string"
              multiline
              className="mb-4"
              value={recall.component}
              fullWidth
              variant="outlined"
            />
            <TextField
              id={`summary-${recall._id}`}
              label="Summary"
              type="string"
              multiline
              className="mb-4"
              value={recall.summary}
              fullWidth
              variant="outlined"
            />
            <TextField
              id={`consequence-${recall._id}`}
              label="Consequence"
              type="string"
              multiline
              className="mb-4"
              value={recall.consequence}
              fullWidth
              variant="outlined"
            />
            <TextField
              id={`remedy-${recall._id}`}
              label="Remedy"
              type="string"
              multiline
              className="mb-4"
              value={recall.remedy}
              fullWidth
              variant="outlined"
            />
            <TextField
              id={`notes-${recall._id}`}
              label="Notes"
              type="string"
              multiline
              className="mb-4"
              value={recall.notes}
              fullWidth
              variant="outlined"
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

export default CarRecalls;
