import api from '.';

const REPORT_URL = `/admin/report`;

const getInvitations = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/welcomekit/invites`,
    null,
    params
  );
};

const getWelcomekitDashboardStats = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/welcomekit-dashboard-stats`,
    null,
    params
  );
};

const getWelcomekitDashboardTotalStats = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/welcomekit-dashboard-total-stats`,
    null,
    params
  );
};

const getWelcomekitDashboardInvitationByDealer = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/welcomekit/invites-by-dealers`,
    null,
    params
  );
};

export default {
  getInvitations,
  getWelcomekitDashboardStats,
  getWelcomekitDashboardTotalStats,
  getWelcomekitDashboardInvitationByDealer,
};
