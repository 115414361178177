/* eslint-disable default-param-last */
import {
  GET_USERS,
  GET_USER,
  UPDATE_USER,
  DELETE_USER,
  GET_USER_NOTIFICATIONS,
  GET_USER_REPORTS,
  GET_USER_GROUP,
} from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        users: action.payload,
      };
    case GET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        user: action.payload.updated,
      };
    case DELETE_USER:
      return {
        ...state,
        user: null,
      };
    case GET_USER_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case GET_USER_REPORTS:
      return {
        ...state,
        report: action.payload,
      };
    case GET_USER_GROUP:
      return {
        ...state,
        userGroup: { ...action.payload, userHasAdminWriteAccess: true },
      };
    default:
      return state;
  }
};
