import React, { useEffect, useState } from 'react';
import { LinearProgress, Button, Card } from '@mui/material';
import { addHours, addMonths, addDays, format } from 'date-fns';
import { Row, Col } from 'react-bootstrap';
import DateFnsUtils from '@date-io/date-fns';
import { useSelector } from 'react-redux';
import {
  CarCrashOutlined,
  Person2,
  ScheduleRounded,
  SmsFailedSharp,
} from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import FilterModal from '../components/Filter';
import reportApi from '../api/report.api';
import RepairBookingsList from '../components/RepairBookingsList';
import WcfmcCallbacksList from '../components/WcfmcCallbacksList';

function RepairBookingDashboard() {
  const [repairBookingDashboardStats, setRepairBookingDashboardStats] =
    useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [appliedFilter, setAppliedFilter] = useState({});
  const [filterDisplay, setFilterDisplay] = useState('');
  const [filterBeforeDate, setFilterBeforeDate] = useState(null);
  const [filterAfterDate, setFilterAfterDate] = useState(null);
  const [applyQuickFilter, setApplyQuickFilter] = useState(false);

  const permissions = useSelector((state) => state.permissions.permissions);

  const saveFilters = () => {
    const newFilter = {};
    const displays = [];
    const filterStartDate = new Date(filterAfterDate);
    const filterEndDate = new Date(filterBeforeDate);
    let dateDisplay = '';
    if (filterAfterDate) {
      newFilter.createdAt = { $gte: `${filterStartDate}` };
      dateDisplay = `Dates: ${format(filterStartDate, 'MM/dd/yyyy')}`;
    }
    if (filterBeforeDate) {
      newFilter.createdAt = {
        ...newFilter.createdAt,
        $lte: `${filterEndDate}`,
      };
      if (!dateDisplay) {
        dateDisplay = `Dates: -${format(filterEndDate, 'MM/dd/yyyy')}`;
      } else {
        dateDisplay = `${dateDisplay}-${format(filterEndDate, 'MM/dd/yyyy')}`;
      }
    }
    if (dateDisplay) {
      displays.push(dateDisplay);
    }

    setAppliedFilter(newFilter);
    setFilterDisplay(displays.join(' '));
  };

  useEffect(() => {
    if (applyQuickFilter && !!filterAfterDate) {
      setApplyQuickFilter(false);
      saveFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyQuickFilter, filterAfterDate]);

  const setLastDayFilter = () => {
    setFilterAfterDate(addHours(new Date(), -24));
    setApplyQuickFilter(true);
  };

  const setLastMonthFilter = () => {
    setFilterAfterDate(addMonths(new Date(), -1));
    setApplyQuickFilter(true);
  };

  const setLastSevenDays = () => {
    setFilterAfterDate(addDays(new Date(), -7));
    setApplyQuickFilter(true);
  };

  const resetFilters = () => {
    setFilterAfterDate(null);
    setFilterBeforeDate(null);
    setFilterDisplay('');
    setAppliedFilter({});
  };

  useEffect(() => {
    const loadData = async () => {
      const params = {
        filter: JSON.stringify(appliedFilter),
      };
      const result = await reportApi.getRepairBookingsDashboardStats(params);
      setRepairBookingDashboardStats({ ...result });
      setIsLoading(false);
    };
    setIsLoading(true);
    loadData();
  }, [appliedFilter]);

  if (isLoading) {
    return (
      <div className="container">
        <div className="row my-2">
          <h2>WCFMC Dashboard</h2>
        </div>
        <LinearProgress style={{ height: '1rem' }} color="secondary" />
      </div>
    );
  }
  return (
    <div
      style={{
        display: permissions.SUPPORT.READ ? 'block' : 'none',
      }}
    >
      <div>
        <h2 className="d-flex align-content-center align-items-center mb-0">
          <span className="mr-2">WCFMC Dashboard</span>
          <div className="flex-grow-1" />
          {appliedFilter && !Object.keys(appliedFilter).length && (
            <>
              <Button color="primary" onClick={setLastDayFilter}>
                Last 24 Hours
              </Button>
              <Button color="primary" onClick={setLastSevenDays}>
                Last 7 Days
              </Button>
              <Button color="primary" onClick={setLastMonthFilter}>
                Last month
              </Button>
            </>
          )}
          <FilterModal
            currentFilters={appliedFilter}
            onSave={saveFilters}
            itemName="Reports"
            resetFilters={resetFilters}
            filterDisplay={filterDisplay}
          >
            <div style={{ marginBottom: '16px', marginTop: '10px' }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  utils={DateFnsUtils}
                  label="After Date (including)"
                  variant="inline"
                  className="mr-2"
                  fullWidth
                  id="after-date-filter"
                  value={filterAfterDate}
                  onChange={setFilterAfterDate}
                  autoOk="true"
                />
              </LocalizationProvider>
            </div>
            <div style={{ marginBottom: '16px' }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  utils={DateFnsUtils}
                  label="Before Date (including)"
                  variant="inline"
                  className="mr-2"
                  fullWidth
                  id="before-date-filter"
                  value={filterBeforeDate}
                  onChange={setFilterBeforeDate}
                  autoOk="true"
                />
              </LocalizationProvider>
            </div>
          </FilterModal>
        </h2>
      </div>
      <Card className="p-2">
        <h4 style={{ textAlign: 'left' }}>Overall</h4>
        <Row className="t-4">
          <Col>
            <div
              className="d-flex justify-content-left align-items-center"
              style={{ paddingLeft: 20 }}
            >
              <ScheduleRounded color="disabled" fontSize="large" />
              <p className="card-content-align">
                <b>
                  {repairBookingDashboardStats
                    ? repairBookingDashboardStats.repairBookings
                    : 0}
                </b>
                <br />
                <span>Total Bookings</span>
              </p>
            </div>
          </Col>
          <Col>
            <div
              className="d-flex justify-content-left align-items-center"
              style={{ paddingLeft: 20 }}
            >
              <Person2 color="disabled" fontSize="large" />
              <p className="card-content-align">
                <b>
                  {repairBookingDashboardStats
                    ? repairBookingDashboardStats.uniqueCustomers
                    : 0}
                </b>
                <br />
                <span>Unique Customers</span>
              </p>
            </div>
          </Col>
          <Col>
            <div
              className="d-flex justify-content-left align-items-center"
              style={{ paddingLeft: 20 }}
            >
              <CarCrashOutlined color="disabled" fontSize="large" />
              <p className="card-content-align">
                <b>
                  {repairBookingDashboardStats
                    ? repairBookingDashboardStats.uniqueVehicles
                    : 0}
                </b>
                <br />
                <span>Unique Vehicles</span>
              </p>
            </div>
          </Col>
          <Col>
            <div
              className="d-flex justify-content-left align-items-center"
              style={{ paddingLeft: 20 }}
            >
              <SmsFailedSharp color="disabled" fontSize="large" />
              <p className="card-content-align">
                <b>
                  {repairBookingDashboardStats
                    ? repairBookingDashboardStats.repairBookingsWithoutBookingId
                    : 0}
                </b>
                <br />
                <span>Bookings without Booking#</span>
              </p>
            </div>
          </Col>
        </Row>
      </Card>
      <br />
      <br />
      <h4 style={{ textAlign: 'left' }}>Bookings</h4>
      <div className="row my-2 ">
        <RepairBookingsList viewHeight="60vh" />
      </div>
      <br />
      <br />
      <h4 style={{ textAlign: 'left' }}>Callbacks</h4>
      <div className="row my-2 ">
        <WcfmcCallbacksList viewHeight="60vh" />
      </div>
    </div>
  );
}

export default RepairBookingDashboard;
