/* eslint-disable default-param-last */
import { CONTACT_US_FAILED_REQ } from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case CONTACT_US_FAILED_REQ:
      return {
        contactUsFailedReq: action.payload,
      };
    default:
      return state;
  }
};
