/* eslint-disable no-unused-vars */
import React from 'react';
import { Radio, IconButton } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import Carousel from 'react-multi-carousel';
import { grayBg } from '../../assets/images';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function RadioImage(props) {
  const { name, selectedValue, handleInputChange, image } = props;
  const isChecked =
    (selectedValue.startsWith('blob') && image.startsWith('blob')) ||
    selectedValue === image;
  return (
    <div>
      <img className="dcr-image" alt="" src={image || grayBg} />
      <div>
        <Radio
          checked={isChecked}
          onChange={handleInputChange}
          value={image}
          name={name}
          inputProps={{ 'aria-label': 'A' }}
        />
        <span>Select</span>
      </div>
    </div>
  );
}

function CustomRightArrow({ onClick, ...rest }) {
  return (
    <IconButton
      style={{ position: 'absolute', marginBottom: '40px', right: -10 }}
      onClick={() => onClick()}
    >
      <ChevronRight fontSize="large" />
    </IconButton>
  );
}
function CustomLeftArrow({ onClick, ...rest }) {
  return (
    <IconButton
      style={{ position: 'absolute', marginBottom: '40px' }}
      onClick={() => onClick()}
    >
      <ChevronLeft fontSize="large" />
    </IconButton>
  );
}

export function StoreImageSection(props) {
  const { images, selectedValue, handleInputChange, uploadImagePath } = props;

  return (
    <div className="w-100">
      {images.length === 0 && !uploadImagePath.store && (
        <div className="no-img-available">No Images Available</div>
      )}
      <Carousel
        autoPlay={false}
        infinite={false}
        shouldResetAutoplay={false}
        arrows
        responsive={responsive}
        customLeftArrow={<CustomLeftArrow />}
        customRightArrow={<CustomRightArrow />}
        partialVisible
      >
        {uploadImagePath.store && (
          <RadioImage
            name="googleStorePictureUrl"
            handleInputChange={handleInputChange}
            selectedValue={selectedValue}
            image={URL.createObjectURL(uploadImagePath.store)}
          />
        )}
        {images &&
          images.map((i, index) => (
            <RadioImage
              name="googleStorePictureUrl"
              handleInputChange={handleInputChange}
              selectedValue={selectedValue}
              key={i}
              index={index}
              image={i}
            />
          ))}
      </Carousel>
    </div>
  );
}

export function LogoImageSection(props) {
  const { images, selectedValue, handleInputChange, uploadImagePath } = props;

  const handleChange = (e) => {
    handleInputChange('logo');
  };

  return (
    <div className="w-100">
      {images.length === 0 && !uploadImagePath.logo && (
        <div className="no-img-available">No Images Available</div>
      )}
      <Carousel
        autoPlay={false}
        infinite={false}
        shouldResetAutoplay={false}
        arrows
        responsive={responsive}
        customLeftArrow={<CustomLeftArrow />}
        customRightArrow={<CustomRightArrow />}
        partialVisible
      >
        {uploadImagePath.logo && (
          <RadioImage
            name="googleBrandLogoUrl"
            handleInputChange={handleInputChange}
            selectedValue={selectedValue}
            image={URL.createObjectURL(uploadImagePath.logo)}
          />
        )}
        {images &&
          images.map((i, index) => (
            <RadioImage
              name="googleBrandLogoUrl"
              handleInputChange={handleInputChange}
              selectedValue={selectedValue}
              handleChange={handleChange}
              key={i}
              index={index}
              image={i}
            />
          ))}
      </Carousel>
    </div>
  );
}

export function RepairImageSection(props) {
  const { images, selectedValue, handleInputChange, uploadImagePath } = props;

  return (
    <div className="w-100">
      {images.length === 0 && !uploadImagePath.shop && (
        <div className="no-img-available">No Images Available</div>
      )}
      <Carousel
        autoPlay={false}
        infinite={false}
        shouldResetAutoplay={false}
        arrows
        responsive={responsive}
        customLeftArrow={<CustomLeftArrow />}
        customRightArrow={<CustomRightArrow />}
      >
        {uploadImagePath.shop && (
          <RadioImage
            name="googleShopPictureUrl"
            handleInputChange={handleInputChange}
            selectedValue={selectedValue}
            image={URL.createObjectURL(uploadImagePath.shop)}
          />
        )}
        {images &&
          images.map((i, index) => (
            <RadioImage
              name="googleShopPictureUrl"
              handleInputChange={handleInputChange}
              selectedValue={selectedValue}
              key={i}
              index={index}
              image={i}
            />
          ))}
      </Carousel>
    </div>
  );
}
