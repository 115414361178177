import api from '.';

const CONTACTUS_URL = `/admin/contactUs`;

const contactUsFailedRequests = async (dispatch, params) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${CONTACTUS_URL}/failed-requests`,
    null,
    params
  );
};

export default {
  contactUsFailedRequests,
};
