import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import moment from 'moment';
import reportApi from '../api/report.api';
import { PAGINATION_OPTIONS } from '../constants';
import JSONViewer from './JSONViewer';

const dateTimeFormat = process.env.REACT_APP_DATE_TIME_FORMAT;

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

function ClaimUpdatesList({ appliedFilter, viewHeight = '50vh' }) {
  const columns = [
    {
      headerName: 'Date',
      field: 'createdAt',
      renderCell: (c) => moment(c.value).format(dateTimeFormat),
      headerClassName: 'custom-dark-theme--header',
      width: 180,
    },
    {
      headerName: 'Claim#',
      render: (e) => e.claimNumber,
      field: 'claimNumber',
      headerClassName: 'custom-dark-theme--header',
      width: 140,
    },
    {
      headerName: 'Contract',
      render: (e) => e.contractNumber,
      field: 'contractNumber',
      headerClassName: 'custom-dark-theme--header',
      width: 160,
    },
    {
      headerName: 'Form',
      render: (e) => e.formNumber,
      field: 'formNumber',
      headerClassName: 'custom-dark-theme--header',
      width: 170,
    },
    {
      headerName: 'VIN',
      render: (e) => e.vin,
      field: 'vin',
      headerClassName: 'custom-dark-theme--header',
      width: 200,
    },
    {
      headerName: 'New Status',
      render: (e) => e.newStatus,
      field: 'newStatus',
      headerClassName: 'custom-dark-theme--header',
      width: 200,
    },
    {
      headerName: 'Payload',
      field: 'payload',
      renderCell: (params) => {
        return (
          <div>
            <JSONViewer
              title="Payload"
              jsonData={JSON.parse(params.row.payload)}
            />
            {params.row.payload}
          </div>
        );
      },
      width: 500,
    },
  ];
  const [dataSource, setDataSource] = useState([]);
  const [pageSize, setPageSize] = React.useState(PAGINATION_OPTIONS[1]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  // const updateDataSource = (data) => {
  //   const startIndex =
  //     dataSource && dataSource.length
  //       ? dataSource[dataSource.length - 1]._id + 1
  //       : 0;
  //   const _updatedDataSource = dataSource.concat(
  //     data.map((item, index) => {
  //       return { ...item, _id: index + startIndex };
  //     })
  //   );
  //   setDataSource(_updatedDataSource);
  //   return _updatedDataSource;
  // };

  const fetchReport = async () => {
    const params = {
      page: page + 1,
      limit: pageSize,
      filter: JSON.stringify({
        ...appliedFilter,
      }),
    };
    // setIsLoading(true);

    return reportApi.getClaimUpdatesList(params, dispatch);
  };

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      const reports = await fetchReport();
      if (reports && reports.data && reports.data.length > 0) {
        setDataSource(reports.data);
        setRowCount(reports.totalCount);
      } else {
        setDataSource([]); // Clear data if no new data is returned
        setRowCount(0);
      }
      setIsLoading(false);
    };
    loadData();
  }, [page, pageSize, appliedFilter]);

  const handlePageChange = (params) => {
    setPage(params.page);
    setPageSize(params.pageSize);
  };

  const handlePageSizeChange = (params) => {
    setPage(params.page);
    setPageSize(params.pageSize);
  };

  return (
    <div className="container">
      <div
        style={{
          height: viewHeight,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <DataGrid
            density="compact"
            paginationModel={{ page, pageSize }}
            pagination
            pageSizeOptions={PAGINATION_OPTIONS}
            onPageSizeChange={handlePageSizeChange}
            rowCount={rowCount}
            getRowId={(row) => row._id}
            rows={dataSource}
            columns={columns}
            onPaginationModelChange={handlePageChange}
            slots={{
              toolbar: CustomToolbar,
            }}
          />
        )}
      </div>
    </div>
  );
}

export default ClaimUpdatesList;
