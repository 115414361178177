/* eslint-disable default-param-last */
import {
  GET_CARS,
  GET_CAR,
  UPDATE_CAR,
  DELETE_CAR,
  UPDATE_CAR_GLOVE_BOX,
  GET_CAR_CONTRACTS,
} from '../types';

export default (state = {}, action) => {
  switch (action.type) {
    case GET_CARS:
      return {
        cars: action.payload,
      };
    case GET_CAR:
      return {
        ...state,
        car: action.payload,
      };
    case GET_CAR_CONTRACTS:
      return {
        ...state,
        contracts: action.payload,
      };
    case UPDATE_CAR:
    case UPDATE_CAR_GLOVE_BOX:
      return {
        ...state,
        car: action.payload,
      };
    case DELETE_CAR:
      return {
        ...state,
        car: null,
      };
    default:
      return state;
  }
};
