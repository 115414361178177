import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid,
  LinearProgress,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  TablePagination,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { getDetailedErrorsAction } from '../store/actions/errors.actions';
import { PAGINATION_OPTIONS } from '../constants';

const ERROR_TABLE_FIELDS = [
  {
    display: 'Message',
    render: (e) => e.errorMessage,
  },
  {
    display: 'URL',
    render: (e) => e.url,
  },
  {
    display: 'Status Code',
    render: (e) => e.statusCode,
  },
  {
    display: 'Date',
    render: (e) => moment(e.createdAt).format('L'),
  },
];

function ErrorDetails() {
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [countPerPage, setCountPerPage] = useState(PAGINATION_OPTIONS[2]);

  const dispatch = useDispatch();
  const errors = useSelector((state) => state.errors.detailed);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const filter = {
    url: params.get('url'),
    errorMessage: params.get('message'),
  };

  useEffect(() => {
    const loadErrors = async () => {
      setIsLoading(true);
      await dispatch(getDetailedErrorsAction({ filter }));
      setIsLoading(false);
    };
    if (filter.url && filter.errorMessage) {
      loadErrors();
    }
    // eslint-disable-next-line
  }, [dispatch]);

  const getErrors = async (newCountPerPage, newPageNumber) => {
    setIsLoading(true);
    await dispatch(
      getDetailedErrorsAction({
        filter,
        skip: newCountPerPage * newPageNumber,
        limit: newCountPerPage,
      })
    );
    setIsLoading(false);
  };

  const changePage = (event, newPageNumber) => {
    setPageNumber(newPageNumber);
    getErrors(countPerPage, newPageNumber);
  };
  const changeRowsPerPage = (event) => {
    const newCountPerPage = event.target.value;
    setCountPerPage(newCountPerPage);
    getErrors(newCountPerPage, pageNumber);
  };

  return (
    <Grid container>
      <Grid item sm={12}>
        <h2 className="d-flex align-content-center align-items-center mb-0">
          <span>Detailed Errors</span>
        </h2>
        {isLoading || !errors ? (
          <LinearProgress style={{ height: '1rem' }} color="secondary" />
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table
                size="small"
                padding="none"
                style={{ width: 'auto', tableLayout: 'auto' }}
              >
                <TableHead className="bg-dark font-white">
                  <TableRow>
                    {ERROR_TABLE_FIELDS.map((field) => {
                      return (
                        <TableCell
                          key={field.display}
                          align="left"
                          className="text-white p-2"
                          sortDirection={field.isActive ? 'desc' : false}
                        >
                          <TableSortLabel
                            active={field.isActive}
                            direction="desc"
                          >
                            {field.display}
                          </TableSortLabel>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {errors.data.map((error) => (
                    <TableRow key={error._id} hover>
                      {ERROR_TABLE_FIELDS.map((field) => (
                        <TableCell
                          key={field.display}
                          align="left"
                          scope="row"
                          component="th"
                          className="p-2"
                        >
                          <div>{field.render(error)}</div>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPage={errors.data.length}
              component="div"
              count={errors.data.length * errors.pageCount}
              page={errors.currentPage - 1}
              rowsPerPageOptions={PAGINATION_OPTIONS}
              onPageChange={changePage}
              onRowsPerPageChange={changeRowsPerPage}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
}

export default ErrorDetails;
